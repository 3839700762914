import React from "react";
import {connect} from "react-redux";
import {GroupObjectPermissionsProps, GroupObjectPermissionsState} from "../../interfaces/groups";
import {Table} from "reactstrap";
import {get_project_list} from "../../store/actions";
import {get, set, map} from "lodash"
import {ProjectProps} from "../../interfaces/portal";
import {ObjectPermissions} from "../../permissions";
import Switch from "react-switch";
import {Toast} from "../../SWAL";
import {changeGroupObjectPermission} from "../../store/actions/portalGroups";
import {ChangeObjectPermission} from "../../store/actions/changeUserPermissions";


class GroupObjectPermissions extends React.Component<GroupObjectPermissionsProps, GroupObjectPermissionsState> {
  async componentDidMount() {
    await this.props.onTryFetchProjectList(this.props.portal);
    let _this = this;
    setTimeout(function () {
      _this.fillState();
    }, 300)
  }

  fillState() {
    let stateHashed = {};
    let objectPermissions = this.props.permissions;
    let _this = this;
    map(this.props.projectList, (project: ProjectProps) => {
      let projectName = project.name;
      let projectId = project.id;
      let currentProjectPermissions = get(objectPermissions, projectId, []);
      map(ObjectPermissions, (perm) => {
        let hasPermission = currentProjectPermissions.indexOf(perm) > -1;
        set(stateHashed, _this.getHash(projectName, perm), hasPermission);
      })
    });
    this.setState(stateHashed);
  }

  normalize(str: string) {
    str = str.split("_").join(" ");
    str = str.toLowerCase();
    return str.charAt(0).toUpperCase() + str.substring(1);
  }

  getHash(a: string, b: string) {
    let res = "",
      i = a.length,
      j = b.length;
    while (i-->0 && j-->0)
      res = (parseInt(a.charAt(i), 16) ^ parseInt(b.charAt(j), 16)).toString(16) + res;
    return res;
  }

  changePermission(permName: string, id: string, model: string, hash: string) {
    let newState = {};
    newState[hash] = !get(this.state, hash);
    let action: "give-permission" | "remove-permission" = "give-permission";
    if (get(this.state, hash)) {
      action = "remove-permission";
    }
    let data: ChangeObjectPermission = {
      name: permName,
      objectId: id,
      objectModel: model
    };
    changeGroupObjectPermission(this.props.portal, this.props.groupId, action, data)
      .then(_ => {
        Toast.fire("Success", "Permission changed successfully", "success");
      })
      .catch(_ => {
        Toast.fire("Error", "Permission changing failed", "error");
      });
    this.setState(newState);
  }

  render() {
    return (
      <div>
        <h6 className="subheading">Project Permissions</h6>
        <Table responsive striped bordered small={'true'}>
          <thead>
          <tr>
            <th>Name</th>
            <th>Permissions</th>
          </tr>
          </thead>
          <tbody>
          {this.props.projectList && this.props.projectList.map((project, pItem) =>
            <tr key={pItem}>
              <td>{project.name}</td>
              <td>
                {ObjectPermissions.map((perm, index) =>
                  <span style={{display: "inline-block", width: "33%"}} key={index}>
                    <Switch
                      checked={get(this.state, this.getHash(project.name, perm)) ? get(this.state, this.getHash(project.name, perm)) : false }
                      onChange={() => {this.changePermission(perm, project.id, "Project", this.getHash(project.name, perm))}}
                      onColor="#86d3ff"
                      onHandleColor="#2693e6"
                      handleDiameter={30}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={20}
                      width={48}
                      className="react-switch"
                      id="material-switch"
                    />
                  <div key={index}>{this.normalize(perm)}</div></span>
                )}
              </td>
            </tr>
          )}
          </tbody>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    projectList: state.project.project_list
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onTryFetchProjectList: (portal: string) => dispatch(get_project_list(portal))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupObjectPermissions);
