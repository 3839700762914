import _ from 'lodash';
import * as actionTypes from '../actions/actionTypes/aws'
import {updateObject} from "../utility";

const InitialState = {
  uploadCredentials: {
    success: false,
    data: null,
    errors: null
  }
}

const reducer = (state = InitialState, action) => {
  const stateClone = _.cloneDeep(state);
  switch (action.type) {
    case actionTypes.GET_TEMPORARY_UPLOAD_CREDENTIALS_SUCCESS:
      stateClone.uploadCredentials = updateObject(stateClone.uploadCredentials, { success: true, data: action.data });
      return stateClone;
    case actionTypes.GET_TEMPORARY_UPLOAD_CREDENTIALS_FAIL:
      stateClone.uploadCredentials = updateObject(stateClone.uploadCredentials, { success: true, errors: action.errors });
      return stateClone;
    default:
      return stateClone;
  }
}

export default reducer;