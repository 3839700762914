import * as actionTypes from '../actions/actionTypes/authActionTypes';
import {updateObject} from '../utility';
import {ISignupReducer} from "../types";

const initialState: ISignupReducer = {
    loading: false,
    message: "",
    success: false,
    email: "",
    authRedirectPath: '/dashboard',
    registeredUser: null,
    errors: {}
};
const signupStart = (state, action) => {
    return updateObject(state, {errors: null, loading: true});
};
const signupSuccess = (state, action) => {
    return updateObject(state, {
        message: action.message,
        email: action.email,
        errors: null,
        loading: false,
        success: action.success,
        registeredUser: action.registeredUser,
    })
};
const signupFail = (state, action) => {
    return updateObject(state, {errors: action.errors, success: false, loading: false})
};

// const authLogout = (state,action) => {
//      return updateObject(state,{token:null, userId:null})
// }

const setAuthRedirectPath = (state, action) => {
    return updateObject(state, {authRedirectPath: action.path})
};
const reducer = (state: ISignupReducer = initialState, action) => {
    switch (action.type) {
        case actionTypes.SOCIAL_AUTH_LOGIN:
            const {token, userId, expiresIn, userName, email} = action.payload;
            return updateObject(state, {token, userId, expiresIn, userName, email})
        case actionTypes.SIGNUP_START:
            return signupStart(state, action);
        case actionTypes.SIGNUP_SUCCESS:
            return signupSuccess(state, action);
        case actionTypes.SIGNUP_FAIL:
            return signupFail(state, action);
        case actionTypes.SET_SIGNUP_REDIRECT_PATH:
            return setAuthRedirectPath(state, action);
        case actionTypes.CLEAR_SIGNUP_STATE:
            return updateObject(state, initialState);
        default:
            return state;
    }
};

export default reducer;
