import { updateObject } from "../utility";
import { IPortalVersionState } from "../../interfaces/versions";
import * as VersionActionTypes from "../actions/actionTypes/versionActionTypes";

let initialState: IPortalVersionState = {
  loading: false,
  message: "",
  versions: [],
  error: "",
  success: false
};

const getPortalVersionsStart = (state) => {
  return updateObject(state, {loading: true});
};

const getPortalVersionsSuccess = (state, action) => {
  return updateObject(state, {loading: false, versions: action.versions, success: true});
};

const getPortalVersionsFail = (state, action) => {
  return updateObject(state, {loading: false, error: action.error, success: false})
};

const portalVersionChangeStateStart = (state) => {
  return updateObject(state, {loading: true});
};

const portalVersionChangeStateSuccess = (state, action) => {
  return updateObject(state, {loading: false, message: action.message, success: true});
};

const portalVersionChangeStateFail = (state, action) => {
  return updateObject(state, {loading: false, error: action.error, success: false});
};

const reducer = (state: IPortalVersionState = initialState, action) => {
  switch (action.type) {
    case VersionActionTypes.GET_PORTAL_VERSIONS_LIST_START:
      return getPortalVersionsStart(state);
    case VersionActionTypes.GET_PORTAL_VERSIONS_LIST_SUCCESS:
      return getPortalVersionsSuccess(state, action);
    case VersionActionTypes.GET_PORTAL_VERSIONS_LIST_FAIL:
      return getPortalVersionsFail(state, action);
    default:
      return state;
  }
};

export default reducer;
