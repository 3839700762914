import React, { useState } from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Input} from 'reactstrap';
import {initializePanoParams} from '../../../../helpers/clearDataset';

import * as _ from 'lodash';
import {PinColors} from "../../../../interfaces/dataset";

export function addPano (that, ds, index) {
    that.setState(
        (prevState: any, props : any) => {
            return { openModalDownloadLinks: false,
                     openModalPano: true,
                     selectedDS: index };
        }
    );
  };

/*export function submitPano (that) {
    let dataSets = that.state.dataSets;
    const _datasetIndex = that.state.selectedDS;

    dataSets[_datasetIndex].dataObject.panoArray.value.push(that.state.panoData);
   
    that.setState({
      dataSets: dataSets,
      panoData: initializePanoParams(),
      openModalPano: false
    });
  };*/

/*export function onChangePanoHandler (that, event) {
    event.preventDefault();
    const { value, name } : any = event.target;
    let _panoData = that.state.panoData;
    _panoData[name] = value;
    that.setState({ panoData: _panoData  });
  };*/

export function editPano (that, ds_index, pano_index) {
    let _panoItem = _.cloneDeep(that.state.dataSets[ds_index].dataObject.panoArray.value[pano_index]);
    _panoItem.index = pano_index;
    that.setState(
      (prevState: any, props : any) => {
          return { 
              openModalDownloadLinks: false,
              openModalPano: true,
              panoData: _panoItem,
              selectedDS: ds_index,
              downloadLinkMode: false };
      }
    );
  };

export function removePano (that, ds, ds_index, pano_index) {
    let dataSets = that.state.dataSets;
    dataSets[ds_index].dataObject.panoArray.value.splice(pano_index, 1);
    that.setState({dataSets: dataSets,
                         panoData: initializePanoParams(),
                         openModalDownloadLinks: false,
                         selectedDS: 0
                        });
  };

interface PanoModalProps {
    className: string;
    panoData: any;
    openModalPano: boolean;
    submit: any;
    closeModalDownloadLinks: any;
    changeHandler: any;
}

const PanoModal = (props: PanoModalProps) => {
    const { className, panoData, openModalPano } = props;
    return (
        <div>
            <Modal isOpen={openModalPano} toggle={props.closeModalDownloadLinks} className={className}>
                <ModalHeader className='text-center'>Panorama Pin</ModalHeader>
                <ModalBody>
                    <label> <strong> Latitude (decimal degrees) </strong></label>
                    <Input type={'text'}
                           name={'lat'}
                           onChange={(event) => props.changeHandler(event)}
                           value={panoData.lat} />

                    <label><strong> Longitude (decimal degrees) </strong></label>
                    <Input type={'text'}
                           name={'lon'}
                           onChange={(event) => props.changeHandler(event)}
                           value={panoData.lon} />

                    <label><strong> Height (m)</strong></label>
                    <Input type={'text'}
                           name={'height'}
                           onChange={(event) => props.changeHandler(event)}
                           value={panoData.height} />

                    <label><strong> URL </strong></label>
                    <Input type={'text'}
                           name={'URL'}
                           onChange={(event) => props.changeHandler(event)}
                           value={panoData.URL} />

                    <label><strong> Style </strong></label>
                    <Input type={'text'}
                           name={'style'}
                           onChange={(event) => props.changeHandler(event)}
                           value={panoData.style} />

                    <label><strong> Colour </strong></label>
                    <Input type={'select'}
                           name={'pinColour'}
                           onChange={(event) => props.changeHandler(event)}>
                           {
                               PinColors.map((obj, index)=>{
                                   return <option key={index} value={obj.value}>{obj.color}</option>
                               })
                           }
                    </Input>


                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={props.submit}>Submit</Button>
                    <Button color="secondary" onClick={props.closeModalDownloadLinks}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default PanoModal;
