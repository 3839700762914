import AnnotationOptionCondition from "../components/AnnotationOptions/components/annotation-options-conditions.component";

export enum AnnotationOptionTypes {
    DROPDOWN = 'DROPDOWN',
    STRING = 'STRING',
    CHECKBOX = 'CHECKBOX',
    INTEGER = 'INTEGER',
    FLOAT = 'FLOAT',
    FILE = 'FILE'
}

export const INSITEAnnotationTypes = {
    POINT: 1,
    LINE: 2,
    PATH: 3,
    AREA: 4,
};

export enum AnnotationOptionConditionParams {
    FIELD = 'FIELD',
    TEXT = 'TEXT',
    NUMBER = 'NUMBER',
}

export enum AnnotationOptionConditionsTargets {
    SHOW = 'SHOW',
    ENABLE = 'ENABLE'
}

export enum AnnotationOptionConditionTypes {
    STR_EQUAL = 'STR_EQUAL',
    STR_CONTAINS = 'STR_CONTAINS',
    NUM_EQUAL = 'NUM_EQUAL',
    NUM_GREATER = 'NUM_GREATER',
    NUM_LESSER = 'NUM_LESSER',
    IS_TRUE = 'IS_TRUE',
    IS_FALSE = 'IS_FALSE'
}

export const AnnotationOptionConditions = [
    {
        type: AnnotationOptionConditionTypes.STR_EQUAL,
        label: 'Is equal',
        paramChoices: [AnnotationOptionConditionParams.FIELD, AnnotationOptionConditionParams.TEXT],
        validFor: [AnnotationOptionTypes.DROPDOWN, AnnotationOptionTypes.STRING]
    },
    {
        type: AnnotationOptionConditionTypes.STR_CONTAINS,
        label: 'Contains',
        paramChoices: [AnnotationOptionConditionParams.TEXT],
        validFor: [AnnotationOptionTypes.DROPDOWN, AnnotationOptionTypes.STRING]
    },
    {
        type: AnnotationOptionConditionTypes.NUM_EQUAL,
        label: 'Is equal',
        paramChoices: [AnnotationOptionConditionParams.FIELD, AnnotationOptionConditionParams.NUMBER],
        validFor: [AnnotationOptionTypes.INTEGER, AnnotationOptionTypes.FLOAT]
    },
    {
        type: AnnotationOptionConditionTypes.NUM_GREATER,
        label: 'Is greater than',
        paramChoices: [AnnotationOptionConditionParams.FIELD, AnnotationOptionConditionParams.NUMBER],
        validFor: [AnnotationOptionTypes.INTEGER, AnnotationOptionTypes.FLOAT]
    },
    {
        type: AnnotationOptionConditionTypes.NUM_LESSER,
        label: 'Is lesser than',
        paramChoices: [AnnotationOptionConditionParams.FIELD, AnnotationOptionConditionParams.NUMBER],
        validFor: [AnnotationOptionTypes.INTEGER, AnnotationOptionTypes.FLOAT]
    },
    {
        type: AnnotationOptionConditionTypes.IS_TRUE,
        label: 'Is true',
        paramChoices: [],
        validFor: [AnnotationOptionTypes.CHECKBOX]
    },
    {
        type: AnnotationOptionConditionTypes.IS_FALSE,
        label: 'Is false',
        paramChoices: [],
        validFor: [AnnotationOptionTypes.CHECKBOX]
    }
]