export const SIGNUP_START = 'SIGNUP_START';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_SIGNUP_REDIRECT_PATH = 'SET_SIGNUP_REDIRECT_PATH';
export const SOCIAL_AUTH_LOGIN = 'SOCIAL_AUTH_LOGIN';

export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const SET_LOGIN_REDIRECT_PATH = 'SET_LOGIN_REDIRECT_PATH';

export const CHANGE_PASS_SUCCESS = 'CHANGE_PASS_SUCCESS';
export const CLEAR_SIGNUP_STATE = 'CLEAR_SIGNUP_STATE';


export const GET_USER_INFO_START = "GET_USER_INFO";
export const GET_USER_INFO_FAIL = "GET_USER_INFO_FAIL";
export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS";

export const UPDATE_TOKEN_SUCCESS = 'UPDATE_TOKEN_SUCCESS';

export const SET_LOGIN_STEP2_VERIFICATION_TOKEN_SUCCESS = 'SET_LOGIN_STEP2_VERIFICATION_TOKEN_SUCCESS';

export const SHOULD_LOGOUT = "SHOULD_LOGOUT";

export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';
export const CLEAR_UPDATE_USER_STATE = 'CLEAR_UPDATE_USER_STATE';
