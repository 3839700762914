import * as React from 'react';
import {
    CustomInput,
    Button,
    Col,
    Form,
    Input,
    Label,
    Row,
    Collapse, Table,
} from "reactstrap";
import './projects.styles.css';
import {CreateProjectProps, createProjectState} from "../../interfaces/project";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as actions from "../../store/actions/project"
import {connect} from "react-redux";
import Spinner from "../UI/Spinner/Spinner";
import * as _ from "lodash";
import {MySwal} from '../../SWAL';
import {
    datasetTypeDropDown,
    FlightPath,
    INSITEDataType,
    KML,
    MODEL3D,
    Tiles3D,
    POINTS,
    Ortho,
    PANO,
    VIDEO,
    VIDEOSET,
    PoleImage,
    Photos
} from '../../interfaces/dataset';
import {initializeDownloadLinks, initializePanoParams, initializeVideoParams} from '../../helpers/clearDataset';
import DownLoadLinks from "./components/download-links/download-links.component";
import PanoModal, {addPano, editPano, removePano} from "./components/pano-modal/pano-modal.component";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import ErrorDisplayer from '../UI/ErrorHandling/ErrorDisplayer';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import VideoModal, {addVideo, editVideo, removeVideo} from "./components/pano-modal/video-modal.component";
//for icons
// import { fetchTemporaryUploadCredentials, getTemporaryUploadCredentials } from '../../store/actions/aws';

const dataSet: any = {
  datasetType: {
    value: INSITEDataType.TILE3D,
    touched: false,
    valid: false,
  },
  captureDate: {
      value: '',
      touched: false,
      valid: false,
  },
  show: {
        value: true,
        touched: false,
        valid: false,
        checked: true,
  },
  dataObject: {},
  downloadLinks: []
};

class createProject extends React.Component<CreateProjectProps, createProjectState> {

  constructor(props: CreateProjectProps) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    return {
      portalSlug: _.get(this.props.match.params, "portalSlug"),
      project_name: {
        value: "",
        touched: false,
        valid: false,
      },
      show: true,
      layerOpacity: {
        value: 100,
        touched: true,
        valid: true,
      },
      openModalDownloadLinks: false,
      openModalPano: false,
      openModalVideo:false,
      dataSets: [dataSet],
      dataSetTypeState: INSITEDataType.TILE3D,
      downloadLink: initializeDownloadLinks(),
      panoData:     initializePanoParams(),
      videoData: initializeVideoParams(),
      selectedDS: 0,
      downloadLinkMode: true,
      withoutGlobe: false,
    }
  };

  datasetTypeChangeHandler = (event, index) => {
    let dataSets = this.state.dataSets;
    dataSets[index].datasetType = {
      value: Number(event.target.value),
      valid: event.target.value.trim() !== "",
      touched: true,
    };
    dataSets[index].dataObject = this.getInitialDataObject(dataSets[index].dataSetType);
    dataSets[index].downloadLink = [];
    this.setState({dataSets: dataSets});
  };

  showhideChangeHandler = (event, index) => {
      let dataSets = this.state.dataSets;
      dataSets[index].show = {
            value: event.target.checked ? event.target.checked : false,
            checked: event.target.checked,
            valid: true,
            touched: true,
      };
      dataSets[index].dataObject = this.getInitialDataObject(dataSets[index].dataSetType);
      this.setState({dataSets: dataSets});
  };

  checkboxHandler = (event, index, field) => {
      let dataSets = this.state.dataSets;
      dataSets[index].dataObject[field.fieldName] = {
          name: field.title,
          value: event.target.checked ? event.target.checked : false,
          checked: event.target.checked,
          valid: event.target.value.trim() !== "",
          touched: true,
          type: 'checkbox'
      };
      this.setState({dataSets: dataSets});
  };

    checkboxHandlerVideoSet = (event, index, field) => {
        let dataSets = this.state.dataSets;
        dataSets[index].dataObject[field.fieldName] = {
            name: field.title,
            value: event.target.checked ? event.target.checked : false,
            checked: event.target.checked,
            valid: event.target.value.trim() !== "",
            touched: true,
            type: 'checkbox'
        };
        dataSets[index].dataObject.videoArray.value.forEach((val)=>{
            val.is360 = event.target.checked
        });
        this.setState({ dataSets: dataSets });
    };

  fieldValueChangeHandler = (event, index, field) => {
      let dataSets = this.state.dataSets;
      dataSets[index].dataObject[field.fieldName] = {
          name: field.title,
          value: event.target.value,
          valid: event.target.value.trim() !== "",
          touched: true,
          type: event.target.type,
      };
      this.setState({dataSets: dataSets});
  };

  projectNameChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      project_name: {
        value: event.target.value,
        valid: event.target.value.trim() !== "",
        touched: true,
      }
    })
  };

    globeVisibilityChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({withoutGlobe: event.target.checked})
    };

    classIdChangeHandler = (event, index,dsIndex,)=>{
        let _dataset = this.state.dataSets;
        _dataset[dsIndex].dataObject.classArray.value[index].id = event.target.value;
        this.setState({dataSets:_dataset});
    }

    classNameChangeHandler = (event, index,dsIndex,)=>{
        let _dataset = this.state.dataSets;
        _dataset[dsIndex].dataObject.classArray.value[index].name = event.target.value;
        this.setState({dataSets:_dataset});
    }

    classRemoveHandler = (index, dsIndex,)=>{
      let _dataset = this.state.dataSets;
      _dataset[dsIndex].dataObject.classArray.value.splice(index, 1);
      this.setState({dataSets:_dataset});
    }

    classImageUrlChangeHandler = (event, index,dsIndex,)=>{
        let _dataset = this.state.dataSets;
        _dataset[dsIndex].dataObject.imagePath.value[index].url = event.target.value;
        this.setState({dataSets:_dataset});
    }


    classImageRemoveHandler = (index, dsIndex,)=>{
        let _dataset = this.state.dataSets;
        _dataset[dsIndex].dataObject.imagePath.value.splice(index, 1);
        this.setState({dataSets:_dataset});
    }


    addImagesURL = (ds, index) => {
        let _dataset = this.state.dataSets;
        const newField = {
            url:'',
        }
        _dataset[index].dataObject.imagePath.value.push(newField);
        this.setState({dataSets:_dataset});
    };


  submitHandler = (event) => {
    event.preventDefault();
    const portal_name = _.get(this.props.match.params, "portalSlug");
    let _datasets = [];
    const _project: any = {
            name: this.state.project_name.value,
            show: this.state.show,
            withoutGlobe: this.state.withoutGlobe,

    };

    this.state.dataSets.forEach(ds => {
      const _mapperDs: any =  { datasetType: ds.datasetType.value,
                                captureDate: ds.captureDate.value,
                                downloadLinks: ds.downloadLinks,
                                show: ds.show.checked };
      const _dsAttributes = { layerOpacity: this.state.layerOpacity.value};
      if(!_.isEmpty(ds.dataObject)) {
          for (let [key, objectAsValue] of Object.entries(ds.dataObject)) {
               _dsAttributes[key] = objectAsValue['value'];
          }
          _mapperDs.dsAttributes = {..._dsAttributes};
          _datasets.push(_mapperDs);
      }
    });
    _project.dataset = [..._datasets];

    if (!portal_name) {
      this.props.onCreateProject('demo/project/create', _project);
    } else {
        this.props.onCreateProject(`${portal_name}/project/create`, _project);
    }
  };

  handleAddMore = () => {
    const _dataset = {
        datasetType : { value: INSITEDataType.TILE3D, touched: false, valid: false },
        captureDate : { value: '', touched: false, valid: false },
        show: { value: true, touched: false, valid: false, checked: true},
        dataObject: {},
        downloadLinks: []
    };
    const _datasets = this.state.dataSets;
    _datasets.push(_dataset);
    this.setState({dataSets: _datasets});
  };

  removeHandler = (index) => {
    let dataSets = this.state.dataSets;
    dataSets.splice(index, 1);
    this.setState({dataSets: dataSets});
  };

  componentDidMount() {
    this.setState(_.cloneDeep(this.getInitialState()));
  }

  componentDidUpdate(prevProps, prevState) {
    if(!prevProps.success && this.props.success) {
      MySwal.fire("Success", "Project was successfully created!", "success");
      this.props.onClearProjectState();
      this.props.history.goBack();
    }
  }

  renderDataObject = (dataSetType: number, index: number) => {
      if(dataSetType) {
        const _dataSets = this.state.dataSets;
        switch (dataSetType) {
            case INSITEDataType.TILE3D:
                if(_.isEmpty(_dataSets[index].dataObject)) {
                    _dataSets[index].dataObject = _.cloneDeep(Tiles3D);
                }
                const _dataObject = _dataSets[index].dataObject;
                return Object.keys(_dataObject).map((d, key) => (
                        <Col className="col-12 col-sm-6 col-md-6 text-left" key={key}>
                              <Label className="labelText">{_dataObject[d].name} </Label>
                            {  Tiles3D[d].name === 'Rust Detection' ?
                                <CustomInput id={`rust_detection-${index}`}
                                             onChange={(event) => this.checkboxHandler(event, index, {fieldName: d, title: _dataObject[d].name})}
                                             type={_dataObject[d].type}
                                             checked = {_dataObject[d].checked}
                                             value = {_dataObject[d].value}
                                             valid={_dataObject[d].valid && _dataObject[d].touched}
                                             invalid={!_dataObject[d].valid && _dataObject[d].touched} />
                                : <Input
                                    type={_dataObject[d].type}
                                    className="inputItem"
                                    value={_dataObject[d].value}
                                    valid={_dataObject[d].valid && _dataObject[d].touched}
                                    invalid={!_dataObject[d].valid && _dataObject[d].touched}
                                    onChange={(event) => this.fieldValueChangeHandler(event, index, {
                                        fieldName: d,
                                        title: _dataObject[d].name
                                    })}
                                />
                            }
                              <p/>
                        </Col>

              ));
            case INSITEDataType.KML:
                if(_.isEmpty(_dataSets[index].dataObject)) {
                    _dataSets[index].dataObject = _.cloneDeep(KML);
                }
                const _dataObjectKML = _dataSets[index].dataObject;
                return Object.keys(_dataObjectKML).map((d, key) => (
                    <Col className="col-12 col-sm-6 col-md-6 text-left" key={key}>
                            <Label className="labelText">{_dataObjectKML[d].name} </Label>
                            {  KML[d].name === 'Clamp to Terrain' ?
                                <CustomInput id={`clamp_to_terrian-${index}`}
                                             label="Clamp to terrain"
                                             onChange={(event) => this.checkboxHandler(event, index, {fieldName: d, title: _dataObjectKML[d].name})}
                                             type={_dataObjectKML[d].type}
                                             checked = {_dataObjectKML[d].checked}
                                             value = {_dataObjectKML[d].value}
                                             valid={_dataObjectKML[d].valid && _dataObjectKML[d].touched}
                                             invalid={!_dataObjectKML[d].valid && _dataObjectKML[d].touched} />
                                : <Input
                                    type={_dataObjectKML[d].type}
                                    className="inputItem"
                                    checked = {_dataObjectKML[d].value}
                                    value = {_dataObjectKML[d].value}
                                    valid={_dataObjectKML[d].valid && _dataObjectKML[d].touched}
                                    invalid={!_dataObjectKML[d].valid && _dataObjectKML[d].touched}
                                    onChange={(event) => this.fieldValueChangeHandler(event, index, {fieldName: d, title: _dataObjectKML[d].name})}
                                />
                            }
                            <p/>
                    </Col>
                ));
            case INSITEDataType.OBJ3D:
                if(_.isEmpty(_dataSets[index].dataObject)){
                    _dataSets[index].dataObject = _.cloneDeep(MODEL3D);
                }
                const _dataObjectModel3d = _dataSets[index].dataObject;
                return Object.keys(_dataObjectModel3d).map((d, key) => (
                    <Col className="col-12 col-sm-6 col-md-6 text-left" key={key}>
                            <Label className="labelText">{_dataObjectModel3d[d].name} </Label>
                            <Input
                                type={_dataObjectModel3d[d].type}
                                className="inputItem"
                                value={_dataObjectModel3d[d].value}
                                valid={_dataObjectModel3d[d].valid && _dataObjectModel3d[d].touched}
                                invalid={!_dataObjectModel3d[d].valid && _dataObjectModel3d[d].touched}
                                onChange={(event) => this.fieldValueChangeHandler(event, index, {fieldName: d, title: _dataObjectModel3d[d].name})}
                            />
                    </Col>
                ));
            case INSITEDataType.FlightPath:
                if(_.isEmpty(_dataSets[index].dataObject)) {
                    _dataSets[index].dataObject = _.cloneDeep(FlightPath);
                }
                const _dataObjectFlight = _dataSets[index].dataObject;
                return Object.keys(_dataObjectFlight).map((d, key) => (
                        <Col className="col-12 col-sm-6 col-md-6 text-left" key={key}>
                            <Label className="labelText">{_dataObjectFlight[d].name} </Label>
                            {
                                FlightPath[d].type === "checkbox" ?
                                    <CustomInput id={`is360-${index}`}
                                                 label="Is 360 Video"
                                                 onChange={(event) => this.checkboxHandler(event, index, { fieldName: d, title: _dataObjectFlight[d].name })}
                                                 type={_dataObjectFlight[d].type}
                                                 checked={_dataObjectFlight[d].checked}
                                                 value={_dataObjectFlight[d].value}
                                                 valid={_dataObjectFlight[d].valid && _dataObjectFlight[d].touched}
                                                 invalid={!_dataObjectFlight[d].valid && _dataObjectFlight[d].touched} />
                                    :
                                    <Input
                                        type={_dataObjectFlight[d].type}
                                        className="inputItem"
                                        value={_dataObjectFlight[d].value}
                                        valid={_dataObjectFlight[d].valid && _dataObjectFlight[d].touched}
                                        invalid={!_dataObjectFlight[d].valid && _dataObjectFlight[d].touched}
                                        onChange={(event) => this.fieldValueChangeHandler(event, index, {fieldName: d, title: _dataObjectFlight[d].name})}
                                    />
                            }
                        </Col>
                ));
            case INSITEDataType.POINTS:
                if (_.isEmpty(_dataSets[index].dataObject)) {
                    _dataSets[index].dataObject = _.cloneDeep(POINTS);
                }
                const _dataObjectPoints = _.cloneDeep(_dataSets[index].dataObject);

                return Object.keys(_dataObjectPoints).map((d, key) => (
                  <>
                    {!_dataObjectPoints[d].hidden ? <Col key={key} className="col-12 col-sm-6 col-md-6 text-left">
                        <Label className="labelText">{_dataObjectPoints[d].name}</Label>
                        {_dataObjectPoints[d].name === 'Classified' ?
                            <CustomInput id={`classified-${index}`}
                                         onChange={(event) => this.checkboxHandler(event, index, {
                                             fieldName: d,
                                             title: _dataObjectPoints[d].name
                                         })}
                                         type={_dataObjectPoints[d].type}
                                         checked={_dataObjectPoints[d].checked}
                                         value={_dataObjectPoints[d].value}
                                         valid={_dataObjectPoints[d].valid && _dataObjectPoints[d].touched}
                                         invalid={!_dataObjectPoints[d].valid && _dataObjectPoints[d].touched}/>
                            :
                            <Input
                                type={_dataObjectPoints[d].type}
                                className="inputItem"
                                checked={_dataObjectPoints[d].value}
                                value={_dataObjectPoints[d].value}
                                valid={_dataObjectPoints[d].valid && _dataObjectPoints[d].touched}
                                invalid={!_dataObjectPoints[d].valid && _dataObjectPoints[d].touched}
                                onChange={(event) => this.fieldValueChangeHandler(event, index, {
                                    fieldName: d,
                                    title: _dataObjectPoints[d].name
                                })}
                            />
                        }
                        {
                            _dataObjectPoints[d].name === 'Classified' && _dataObjectPoints[d].checked === true ?
                                <Col key={key} className="text-left">
                                    <div className='text-md-right'>
                                        <Button
                                                onClick={() => this.addClassification(d, index)}>
                                             <span>
                                                    <FontAwesomeIcon icon={faPlusCircle}/>
                                             </span>
                                        </Button>
                                    </div>

                                    { _dataObjectPoints?.classArray?.value?.map((value, idx) => {
                                        return(
                                          <tr className="col-12 col-sm-6 col-md-6 text-left">
                                            <td className="px-2">
                                                <Label className="labelText">ID</Label>
                                                <Input type="number"
                                                      className="inputItem"
                                                      placeholder= {value.id}
                                                      onChange={(event) =>{this.classIdChangeHandler(event, idx,index)}}
                                                />
                                            </td>
                                            <td className="px-2">

                                                <Label className="labelText">Related Name</Label>
                                                <Input type="text"
                                                      className="inputItem"
                                                      placeholder ={value.name}
                                                      onChange={(event) =>{this.classNameChangeHandler(event, idx,index)}}
                                                />
                                            </td>
                                            <td className="px-2" style={{verticalAlign: "bottom"}}>
                                                <Button color="danger" className="mb-1" onClick={() => this.classRemoveHandler(idx,index)}>
                                                    <span>
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </span>
                                                </Button>
                                            </td>
                                        </tr>
                                        )

                                    })}
                                </Col> :
                                <></>
                        }
                        <p/>
                    </Col>: null}
                    </>
                ));
            case INSITEDataType.VIDEO:
                if (_.isEmpty(_dataSets[index].dataObject)) {
                    _dataSets[index].dataObject = _.cloneDeep(VIDEO);
                }
                const _dataObjectVideo = _.cloneDeep(_dataSets[index].dataObject);
                return Object.keys(_dataObjectVideo).map((d, key) => (
                    <Col key={key} className="col-12 col-sm-6 col-md-6 text-left">
                        <Label className="labelText">{_dataObjectVideo[d].name}</Label>
                        {
                          VIDEO[d].type === "checkbox" ?
                          <CustomInput id={`is360-${index}`}
                              label="Is 360 Video"
                              onChange={(event) => this.checkboxHandler(event, index, { fieldName: d, title: _dataObjectVideo[d].name })}
                              type={_dataObjectVideo[d].type}
                              checked={_dataObjectVideo[d].checked}
                              value={_dataObjectVideo[d].value}
                              valid={_dataObjectVideo[d].valid && _dataObjectVideo[d].touched}
                              invalid={!_dataObjectVideo[d].valid && _dataObjectVideo[d].touched} />
                          :
                          <Input type={_dataObjectVideo[d].type}
                               className="inputItem"
                               value={_dataObjectVideo[d].value}
                               valid={_dataObjectVideo[d].valid && _dataObjectVideo[d].touched}
                               invalid={!_dataObjectVideo[d].valid && _dataObjectVideo[d].touched}
                               onChange={(event) => this.fieldValueChangeHandler(event, index, { fieldName: d, title: _dataObjectVideo[d].name })}
                          />
                        }
                        <p />
                    </Col>
                ));

            case INSITEDataType.VIDEOSET:
                if(_.isEmpty(_dataSets[index].dataObject)) {
                    _dataSets[index].dataObject = _.cloneDeep(VIDEOSET);
                }
                const _dataObjectVideoSet = _dataSets[index].dataObject;
                let fieldArray = Object.keys(_dataObjectVideoSet).map((d, key) => (
                        <Col className={d === "is360" ? "col-12 text-left" : "col-12 col-sm-6 col-md-6 text-left"} key={key}>
                            <Label className="labelText">{_dataObjectVideoSet[d].name} </Label>
                            {  VIDEOSET[d].name === 'Video Array' ? null :
                                VIDEOSET[d].type === "checkbox" ?
                                    <CustomInput id={`is360-${index}`}
                                                 label="Select All"
                                                 onChange={(event) => this.checkboxHandlerVideoSet(event, index, { fieldName: d, title: _dataObjectVideoSet[d].name })}
                                                 type={_dataObjectVideoSet[d].type}
                                                 checked={_dataObjectVideoSet[d].checked}
                                                 value={_dataObjectVideoSet[d].value}
                                                 valid={_dataObjectVideoSet[d].valid && _dataObjectVideoSet[d].touched}
                                                 invalid={!_dataObjectVideoSet[d].valid && _dataObjectVideoSet[d].touched} />
                                    : <Input
                                        type={_dataObjectVideoSet[d].type}
                                        className="inputItem"
                                        value={_dataObjectVideoSet[d].value}
                                        valid={_dataObjectVideoSet[d].valid && _dataObjectVideoSet[d].touched}
                                        invalid={!_dataObjectVideoSet[d].valid && _dataObjectVideoSet[d].touched}
                                        onChange={(event) => this.fieldValueChangeHandler(event, index, {
                                            fieldName: d,
                                            title: _dataObjectVideoSet[d].name
                                        })}
                                    />
                            }
                            <p />
                        </Col>
                    )
                );
                fieldArray.push(
                    <Row className="centerForm text-left m-0" style={{ paddingTop: 0 }}>
                        <Col className="centerForm" lg={12} style={{ marginTop: 0 }}>
                            <div className='text-md-right'>
                                Add Video <Button onClick={() => addVideo(this, dataSet, index)}>
                                        <span>
                                            <FontAwesomeIcon icon={faPlusCircle} />
                                        </span>
                            </Button>
                            </div>
                            <Table responsive striped bordered>
                                <thead>
                                <tr>
                                    <th> Lat </th>
                                    <th> Lon </th>
                                    <th> Height </th>
                                    <th> URL </th>
                                    <th> Name </th>
                                    <th> is360 </th>
                                    <th> Actions </th>
                                </tr>
                                </thead>
                                <tbody>
                                {_dataObjectVideoSet.videoArray.value.map((video, video_index) => {
                                    if(video.is360 === 'false' || !video.is360){
                                        video.is360 = false;
                                    }
                                    else{
                                        video.is360 = true;
                                    }
                                    return (
                                        <tr key={video_index}>
                                            <td>{video.lat}</td>
                                            <td>{video.lon}</td>
                                            <td>{video.height}</td>
                                            <td>{video.URL}</td>
                                            <td>{video.name}</td>
                                            <td>
                                                <Input
                                                    type={"checkbox"}
                                                    name={'is360'}
                                                    value={video.is360}
                                                    checked={video.is360}
                                                    style={{position:"relative"}}
                                                >
                                                </Input>
                                            </td>
                                            <td>
                                                <Button
                                                    onClick={() => editVideo(this, index, video_index)}
                                                    outline color="info">
                                                    Edit
                                                </Button>
                                                <Button onClick={() => removeVideo(this, dataSet, index, video_index)}
                                                        className={"ml-3"}
                                                        outline
                                                        color={"danger"}>
                                                    Remove
                                                </Button>
                                            </td>
                                        </tr>)
                                })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                )
                return fieldArray;
                break;
              case INSITEDataType.ORTHO:
                if(_.isEmpty(_dataSets[index].dataObject)) {
                    _dataSets[index].dataObject = _.cloneDeep(Ortho);
                }
                const _dataObjectOrtho = _dataSets[index].dataObject;
                return Object.keys(_dataObjectOrtho).map((d, key) => (
                        <Col className="col-12 col-sm-6 col-md-6 text-left" key={key}>
                            <Label className="labelText">{_dataObjectOrtho[d].name} </Label>
                            <Input
                                type={_dataObjectOrtho[d].type}
                                className="inputItem"
                                value={_dataObjectOrtho[d].value}
                                valid={_dataObjectOrtho[d].valid && _dataObjectOrtho[d].touched}
                                invalid={!_dataObjectOrtho[d].valid && _dataObjectOrtho[d].touched}
                                onChange={(event) => this.fieldValueChangeHandler(event, index, {fieldName: d, title: _dataObjectOrtho[d].name})}
                            />
                        </Col>
                ));
              case INSITEDataType.PANO:
                if(_.isEmpty(_dataSets[index].dataObject)) {
                  _dataSets[index].dataObject = _.cloneDeep(PANO);
                }
                const _dataObjectPano = _dataSets[index].dataObject;

                let fieldsArray = Object.keys(_dataObjectPano).map((d, key) => (
                    <Col className={d === "useVrPlayer" ? "col-12 text-left" : "col-12 col-sm-6 col-md-6 text-left"} key={key}>
                            <Label className="labelText">{_dataObjectPano[d].name} </Label>
                            {  PANO[d].name === 'Pano Array' ? null :
                               PANO[d].type === "checkbox" ?
                                <CustomInput id={`useVrPlayer-${index}`}
                                    label="Show in VR Player"
                                    onChange={(event) => this.checkboxHandler(event, index, { fieldName: d, title: _dataObjectPano[d].name })}
                                    type={_dataObjectPano[d].type}
                                    checked={_dataObjectPano[d].checked}
                                    value={_dataObjectPano[d].value}
                                    valid={_dataObjectPano[d].valid && _dataObjectPano[d].touched}
                                    invalid={!_dataObjectPano[d].valid && _dataObjectPano[d].touched} />
                                : <Input
                                    type={_dataObjectPano[d].type}
                                    className="inputItem"
                                    value={_dataObjectPano[d].value}
                                    valid={_dataObjectPano[d].valid && _dataObjectPano[d].touched}
                                    invalid={!_dataObjectPano[d].valid && _dataObjectPano[d].touched}
                                    onChange={(event) => this.fieldValueChangeHandler(event, index, {
                                        fieldName: d,
                                        title: _dataObjectPano[d].name
                                    })}
                                />
                            }
                          <p />
                      </Col>
                  )
                  );

                  fieldsArray.push(
                    <Row className="centerForm text-left m-0" style={{paddingTop: 0}}>
                      <Col className="centerForm" lg={12} style={{marginTop: 0}}>
                          <div className='text-md-right'>
                              Add Pano <Button onClick={() => addPano(this, _dataSets[index], index)}>
                              <span>
                                  <FontAwesomeIcon icon={faPlusCircle} />
                              </span>
                              </Button>
                          </div>
                          <Table responsive striped bordered>
                              <thead>
                              <tr>
                                  <th> Lat </th>
                                  <th> Lon </th>
                                  <th> Height </th>
                                  <th> URL </th>
                                  <th> Style </th>
                                  <th> Colour </th>
                                  <th> Actions </th>
                              </tr>
                              </thead>
                              <tbody>
                                  {_dataObjectPano.panoArray.value.map((pano, pano_index) => {
                                      return (
                                          <tr key={pano_index}>
                                              <td>{pano.lat}</td>
                                              <td>{pano.lon}</td>
                                              <td>{pano.height}</td>
                                              <td>{pano.URL}</td>
                                              <td>{pano.style}</td>
                                              <td>{pano.pinColour ? pano.pinColour : 'Yellow'}</td>
                                              <td>
                                                  <Button
                                                      onClick={() => editPano(this, index, pano_index) }
                                                      outline color="info">
                                                      Edit
                                                  </Button>
                                                  <Button onClick={() => removePano(this, dataSet, index, pano_index)}
                                                          className={"ml-3"}
                                                          outline
                                                          color={"danger"}>
                                                      Remove
                                                  </Button>
                                              </td>
                                          </tr>)
                                  })}
                              </tbody>
                          </Table>
                       </Col>
                   </Row>
                  )
                
                return fieldsArray;
              break;
            case INSITEDataType.PoleImage:
                if(_.isEmpty(_dataSets[index].dataObject)) {
                    _dataSets[index].dataObject = _.cloneDeep(PoleImage);
                }
                const _dataPoleImageObject = _dataSets[index].dataObject;
                return Object.keys(_dataPoleImageObject).map((d, key) => (
                    <Col key={key} className="col-12 col-sm-6 col-md-6 text-left">
                        <Label className="labelText">{_dataPoleImageObject[d].name} </Label>
                        {
                            _dataPoleImageObject[d].name === 'Image Paths' ?
                                <></> :
                                _dataPoleImageObject[d].name === 'Clamp to Terrain' ?
                                    <CustomInput id={`clamp_to_terrian-${index}`}
                                                 onChange={(event) => this.checkboxHandler(event, index, {fieldName: d, title: _dataPoleImageObject[d].name})}
                                                 type={_dataPoleImageObject[d].type}
                                                 checked = {_dataPoleImageObject[d].checked}
                                                 value = {_dataPoleImageObject[d].value}
                                                 valid={_dataPoleImageObject[d].valid && _dataPoleImageObject[d].touched}
                                                 invalid={!_dataPoleImageObject[d].valid && _dataPoleImageObject[d].touched} />
                                    :
                                <Input
                                    type={_dataPoleImageObject[d].type}
                                    className="inputItem"
                                    value={_dataPoleImageObject[d].value}
                                    valid={_dataPoleImageObject[d].valid && _dataPoleImageObject[d].touched}
                                    invalid={!_dataPoleImageObject[d].valid && _dataPoleImageObject[d].touched}
                                    onChange={(event) => this.fieldValueChangeHandler(event, index, {
                                        fieldName: d,
                                        title: _dataPoleImageObject[d].name
                                    })}
                                />
                        }
                        {
                            _dataPoleImageObject[d].name === 'Image Paths' ?
                                <Col key={key} className="text-left">
                                    <div className='text-md-right'>
                                        <Button
                                            onClick={() => this.addImagesURL(dataSet, index)}>
                                             <span>
                                                    <FontAwesomeIcon icon={faPlusCircle}/>
                                             </span>
                                        </Button>
                                    </div>

                                    { _dataPoleImageObject?.imagePath?.value?.map((value, idx) => {
                                        return(
                                            <div>
                                                <tr className={"col-12 col-sm-6 col-md-6 text-left"}>
                                                    <td style={{width: "inherit"}}>
                                                        <Label className="labelText">URL</Label>
                                                        <Input type="text"
                                                               className="inputItem"
                                                               value = {value.url}
                                                               onChange={(event) =>{this.classImageUrlChangeHandler(event, idx,index)}}
                                                        />
                                                    </td>
                                                    <td className="px-2" style={{verticalAlign: "bottom"}}>
                                                        <Button color="danger" className="mb-1" onClick={() => this.classImageRemoveHandler(idx,index)}>
                                                                <span>
                                                                    <FontAwesomeIcon icon={faTrash} />
                                                                </span>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            </div>
                                        )

                                    })}
                                </Col> :
                                <></>
                        }
                        <p />
                    </Col>
                ));
                break;

            case INSITEDataType.Photos:
                if(_.isEmpty(_dataSets[index].dataObject)) {
                    _dataSets[index].dataObject = _.cloneDeep(Photos);
                }
                const _dataCameraImageObject = _dataSets[index].dataObject;
                return Object.keys(_dataCameraImageObject).map((d, key) => (
                    <Col key={key} className="col-12 col-sm-6 col-md-6 text-left">
                        <Label className="labelText">{_dataCameraImageObject[d].name} </Label>
                        {
                            _dataCameraImageObject[d].name === 'Image Paths' ?
                                <></> :
                                _dataCameraImageObject[d].name === 'Clamp to Terrain' ?
                                        <CustomInput id={`clamp_to_terrian-${index}`}
                                                     onChange={(event) => this.checkboxHandler(event, index, {fieldName: d, title: _dataCameraImageObject[d].name})}
                                                     type={_dataCameraImageObject[d].type}
                                                     checked = {_dataCameraImageObject[d].checked}
                                                     value = {_dataCameraImageObject[d].value}
                                                     valid={_dataCameraImageObject[d].valid && _dataCameraImageObject[d].touched}
                                                     invalid={!_dataCameraImageObject[d].valid && _dataCameraImageObject[d].touched} />
                                        :
                                    <Input
                                        type={_dataCameraImageObject[d].type}
                                        className="inputItem"
                                        value={_dataCameraImageObject[d].value}
                                        valid={_dataCameraImageObject[d].valid && _dataCameraImageObject[d].touched}
                                        invalid={!_dataCameraImageObject[d].valid && _dataCameraImageObject[d].touched}
                                        onChange={(event) => this.fieldValueChangeHandler(event, index, {
                                            fieldName: d,
                                            title: _dataCameraImageObject[d].name
                                        })}
                                    />
                        }
                        {
                            _dataCameraImageObject[d].name === 'Image Paths' ?
                                <Col key={key} className="text-left">
                                    <div className='text-md-right'>
                                        <Button
                                            onClick={() => this.addImagesURL(dataSet, index)}>
                                             <span>
                                                    <FontAwesomeIcon icon={faPlusCircle}/>
                                             </span>
                                        </Button>
                                    </div>

                                    { _dataCameraImageObject?.imagePath?.value?.map((value, idx) => {
                                        return(
                                            <div>
                                                <tr className={"col-12 col-sm-6 col-md-6 text-left"}>
                                                    <td style={{width: "inherit"}}>
                                                        <Label className="labelText">URL</Label>
                                                        <Input type="text"
                                                               className="inputItem"
                                                               value = {value.url}
                                                               onChange={(event) =>{this.classImageUrlChangeHandler(event, idx,index)}}
                                                        />
                                                    </td>
                                                    <td className="px-2" style={{verticalAlign: "bottom"}}>
                                                        <Button color="danger" className="mb-1" onClick={() => this.classImageRemoveHandler(idx,index)}>
                                                                <span>
                                                                    <FontAwesomeIcon icon={faTrash} />
                                                                </span>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            </div>
                                        )

                                    })}
                                </Col> :
                                <></>
                        }
                        <p />
                    </Col>
                ));
                break;

        }
      }
  };

    addClassification = (ds, index) => {
        let _dataset = this.state.dataSets;
        const newField = {
            id:null,
            name:'',
            colour:null
        }
        _dataset[index].dataObject.classArray.value.push(newField);
        this.setState({dataSets:_dataset});

    };

  isFormValid = () => {
      const validObject: any = {
        tile3d: true,
        kml: true,
        mesh: true,
        flight: true,
      };
      this.state.dataSets.map(dataset => {
          switch(dataset.datasetType.value) {
              case INSITEDataType.TILE3D:
                  validObject.tile3d = (dataset.dataObject.tile_path.valid &&
                      dataset.dataObject.name.valid &&
                      dataset.dataObject.height_offset.valid);
                  break;
              case INSITEDataType.KML:
                  validObject.kml = (dataset.dataObject.kml_path.valid &&
                      dataset.dataObject.name.valid &&
                      dataset.dataObject.height_offset.valid);
                  break;
              case INSITEDataType.OBJ3D:
                  validObject.mesh = (dataset.dataObject.mesh_path.valid &&
                      dataset.dataObject.name.valid &&
                      dataset.dataObject.latitude.valid &&
                      dataset.dataObject.longitude.valid &&
                      dataset.dataObject.rotation.valid &&
                      dataset.dataObject.scale.valid);
                  break;
              case INSITEDataType.FlightPath:
                  validObject.flight = ((dataset.dataObject.flight_path.valid &&
                      dataset.dataObject.name.valid) || (dataset.dataObject.flight_path_gpx.valid &&
                      dataset.dataObject.name.valid));
                  break;
          }
      });
      return (this.state.project_name.valid && this.state.project_name.touched &&
              this.state.layerOpacity.valid && this.state.layerOpacity.touched &&
              validObject.tile3d &&
              validObject.kml &&
              validObject.mesh &&
              validObject.flight);
  };

  goBack = (e) => {
    this.props.history.push(`/details/${this.state.portalSlug}`);
  };

  handleDateChange = (date, index) => {
      let dataSets = this.state.dataSets;
      if(date){
          const _timeStamp = new Date().toLocaleTimeString();
          const _date = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${_timeStamp}`;
          dataSets[index].captureDate = {
              value: _date,
              touched: true,
              valid: true,
          };
      } else {
          const _timeStamp = new Date().toLocaleTimeString();
          const _date = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${_timeStamp}`;
          dataSets[index].captureDate = {
              value: new Date(),
              touched: true,
              valid: true,
          };
      }
     dataSets[index].dataObject = this.getInitialDataObject(dataSets[index].dataSetType);
     this.setState({dataSets: dataSets});
  };

  getInitialDataObject(dataSetType) {
    switch (dataSetType){
      case INSITEDataType.TILE3D:
        return _.cloneDeep(Tiles3D);
      case INSITEDataType.KML:
        return _.cloneDeep(KML);
      case INSITEDataType.OBJ3D:
        return _.cloneDeep(MODEL3D);
      case INSITEDataType.FlightPath:
        return _.cloneDeep(FlightPath);
      case INSITEDataType.POINTS:
        return _.cloneDeep(POINTS);
      case INSITEDataType.ORTHO:
        return _.cloneDeep(Ortho);
      case INSITEDataType.PANO:
        return _.cloneDeep(PANO);
      case INSITEDataType.VIDEO:
        return _.cloneDeep(VIDEO);
      case INSITEDataType.VIDEOSET:
        return _.cloneDeep(VIDEOSET);
      case INSITEDataType.PoleImage:
        return _.cloneDeep(PoleImage);
      case INSITEDataType.Photos:
        return _.cloneDeep(Photos);
    }
  }

  reformatDateSelection = (dateCapture) => {
      let _reformatDate = dateCapture;
      if(dateCapture === ''){
          _reformatDate = new Date();
      } else {
          _reformatDate = Date.parse(dateCapture);
      }
      return _reformatDate;
  };

  addDownloadLinks = (ds, index) => {
      this.setState(
          (prevState: any, props : any) => {
              return { openModalDownloadLinks: true,
                       openModalPano: false,
                       downloadLinkMode: true,
                       downloadLink: initializeDownloadLinks(),
                       selectedDS: index };
          }
      );
  };

  closeModal = () => {
      this.setState({ openModalDownloadLinks: false, openModalPano: false, downloadLink: initializeDownloadLinks() });
  };

    closeVideoModal = () => {
        this.setState({ openModalDownloadLinks: false, openModalVideo: false, downloadLink: initializeDownloadLinks() });
    };

    onChangeVideoHandler = (event) => {
        event.preventDefault();
        const { value, name }: any = event.target;
        let _videoData = this.state.videoData;
        _videoData[name] = value;
        this.setState({ videoData: _videoData });
    };

    onChangeCheckboxHandler = (event) => {
        //  event.preventDefault();
        const  value = event.target.checked ? event.target.checked : false;
        const {name}:any  = event.target;
        let _videoData = this.state.videoData;
        _videoData[name] = value;
        this.setState({ videoData: _videoData });
    };

    submitVideo = () => {
        let dataSets = this.state.dataSets;
        const _datasetIndex = this.state.selectedDS;

        // we use a temporary 'index' property on the pano data to keep track of whether we're editing or adding a new one
        // index is -1 if new, and we delete that index property before storing it
        const videoEditIndex = this.state.videoData.index;
        delete this.state.videoData['index']
        if (videoEditIndex >= 0) {
            dataSets[_datasetIndex].dataObject.videoArray.value[videoEditIndex] = this.state.videoData;
        } else {
            dataSets[_datasetIndex].dataObject.videoArray.value.push(this.state.videoData);
        }

        this.setState({
            dataSets: dataSets,
            videoData: initializeVideoParams(),
            openModalVideo : false
        });
    };

  submitDownloadLinks = () => {
      const _datasetIndex = this.state.selectedDS;
      const _downloadItem = this.state.downloadLink;
      let dataSets = this.state.dataSets;
      if(!this.state.downloadLinkMode){
          const _downLoadIndex = _.findIndex(dataSets[_datasetIndex].downloadLinks, {'index': _downloadItem.index,
                                                                                              'label': _downloadItem.label});
          dataSets[_datasetIndex].downloadLinks.splice(_downLoadIndex, 1);
      }
      dataSets[_datasetIndex].downloadLinks.push(this.state.downloadLink);
      this.setState({dataSets: dataSets,
                           downloadLink: initializeDownloadLinks(),
                           openModalDownloadLinks: false});
  };

  submitPano = () => {
    let dataSets = this.state.dataSets;
    const _datasetIndex = this.state.selectedDS;

    // we use a temporary 'index' property on the pano data to keep track of whether we're editing or adding a new one
    // index is -1 if new, and we delete that index property before storing it
    const panoEditIndex = this.state.panoData.index;
    delete this.state.panoData['index']
    if (panoEditIndex >= 0) {
        dataSets[_datasetIndex].dataObject.panoArray.value[panoEditIndex] = this.state.panoData;
    } else {
        dataSets[_datasetIndex].dataObject.panoArray.value.push(this.state.panoData);
    }
   
    this.setState({
      dataSets: dataSets,
      panoData: initializePanoParams(),
      openModalPano: false
    });
  };

  onChangeDownloadLinksHandler = (event) => {
    event.preventDefault();
    const { value, name } : any = event.target;
    let _selectedDownloadLink = this.state.downloadLink;
    _selectedDownloadLink[name] = value;
    this.setState({ downloadLink: _selectedDownloadLink  });
  };

  onChangePanoHandler = (event) => {
    event.preventDefault();
    const { value, name } : any = event.target;
    let _panoData = this.state.panoData;
    _panoData[name] = value;
    this.setState({ panoData: _panoData  });
  };

  removeDownloadLink = (ds, ds_index, downloadLink_index) => {
      let dataSets = this.state.dataSets;
      dataSets[ds_index].downloadLinks.splice(downloadLink_index, 1);
      this.setState({dataSets: dataSets,
                           downloadLink: initializeDownloadLinks(),
                           openModalDownloadLinks: false,
                           selectedDS: 0
                          });
  };

  editDownloadLink = (ds_index, index) => {
      const _downloadLinkItem = this.state.dataSets[ds_index].downloadLinks[index];
      this.setState(
          (prevState: any, props : any) => {
              return { 
                  openModalDownloadLinks: true,
                  openModalPano: false,
                  downloadLink: _downloadLinkItem,
                  selectedDS: ds_index,
                  downloadLinkMode: false };
          }
      );
  };

  render() {
    let content = null;
    let redirect = null;
    let project_name_error = "";
    if (this.props.errors && this.props.errors.name !== "") {
      project_name_error = this.props.errors.name;
    }
    let form = <>
        {this.state.openModalDownloadLinks ?
            <DownLoadLinks
                openModalDownloadLinks={this.state.openModalDownloadLinks}
                closeModalDownloadLinks={this.closeModal}
                className={'download-links-add'}
                submit={this.submitDownloadLinks}
                changeHandler={this.onChangeDownloadLinksHandler}
                downloadLinkItem={this.state.downloadLink}/>
            : null
        }
        {this.state.openModalPano ?
            <PanoModal
                openModalPano={this.state.openModalPano}
                closeModalDownloadLinks={this.closeModal}
                className={'download-links-add'}
                submit={this.submitPano}
                changeHandler={this.onChangePanoHandler}
                panoData={this.state.panoData}/>
            : null
        }
        {this.state.openModalVideo ?
            <VideoModal
                openModalVideo={this.state.openModalVideo}
                closeModalDownloadLinks={this.closeVideoModal}
                className={'download-links-add'}
                submit={this.submitVideo}
                changeHandler={this.onChangeVideoHandler}
                changeHandlerCheckbox = {this.onChangeCheckboxHandler}
                videoData={this.state.videoData} />
            : null
        }
      <Row>
        <h1 className="heading">Create a New Project</h1>
      </Row>
      <Form>
        <Row>
          <Col className="mx-auto" sm={6}>
            <Label className="labelText">Project Name</Label>
            <Input
              className="inputItem"
              value={this.state.project_name.value}
              valid={this.state.project_name.valid && this.state.project_name.touched}
              invalid={!this.state.project_name.valid && this.state.project_name.touched}
              onChange={(event) => this.projectNameChangeHandler(event)}
            />
              <Label style={{marginRight: '30px', marginTop: '10px'}} className="labelText">Without Globe</Label>
              <Input
                  type={'checkbox'}
                  className="inputItem"
                  style={{marginTop: '16px'}}
                  checked={this.state.withoutGlobe}
                  onChange={(event) => this.globeVisibilityChangeHandler(event)}
              />
            <ErrorDisplayer errorObj={this.props.errors} keyName="name"/>

            <p style={{color: "red"}}>{project_name_error}</p>
          </Col>
        </Row>

        {this.state.dataSets.map((dataSet, index) => {
          return (
            <>
                <div className='m-2'>
                    <Row id={`ds_${index}`} className='collapse-header'>Dataset {index}</Row>
                    <Collapse isOpen={true} toggler={`#ds_${index}`}>
                       <Row className='collapse-body'>
                            <Col className="col-12 col-sm-6 col-md-6 text-left">
                                <Label className="labelText">Dataset Type </Label>
                                <Input type='select' name='select'
                                       className="inputItem"
                                       value={dataSet.datasetType.value}
                                       valid={dataSet.datasetType.valid && dataSet.datasetType.touched}
                                       invalid={!dataSet.datasetType.valid && dataSet.datasetType.touched}
                                       onChange={(event) => this.datasetTypeChangeHandler(event, index)}>
                                    { datasetTypeDropDown.map((value, ind) => {
                                        return <option key={ind} value={value.id}> {value.name} </option>
                                    })};
                                </Input>
                            </Col>
                            <Col className="col-12 col-sm-6 col-md-6 text-left">
                                <Label className="labelText">Show/Hide </Label>
                                <CustomInput
                                    type="checkbox"
                                    name='show'
                                    id={`show-${index+1}`}
                                    label={`Show`}
                                    checked={dataSet.show.checked}
                                    value={dataSet.show.value}
                                    valid={dataSet.show.valid && dataSet.show.touched}
                                    onChange={(event) => this.showhideChangeHandler(event, index)}>
                                </CustomInput>
                            </Col>
                            <Col className="col-12 col-sm-6 col-md-6 text-left">
                                <Label className="labelText">Capture Date </Label>
                                <DatePicker
                                    placeholderText="Add capture Date"
                                    dateFormat="dd/MM/yyyy"
                                    selected={this.reformatDateSelection(dataSet.captureDate.value)}
                                    onChange={(event) => this.handleDateChange(event, index)}
                                    className="inputItem w-100 form-control"
                                    showWeekNumbers
                                />
                            </Col>
                            {this.renderDataObject(dataSet.datasetType.value, index)}
                              <Row className="centerForm text-left m-0">
                                  <Col className="centerForm" lg={12}>
                                  <Table responsive>
                                      <tr>
                                          <td style={{textAlign:"left"}}><Label className="labelText">Download Links</Label></td>
                                          <td style={{textAlign:"right"}}><Button onClick={() => this.addDownloadLinks(dataSet, index)}>
                                          <span>
                                              <FontAwesomeIcon icon={faPlusCircle} />
                                          </span>
                                          </Button></td>
                                      </tr>
                                  </Table>
                                   <Table responsive striped bordered>
                                       <thead>
                                       <tr>
                                           <th> Index </th>
                                           <th> Label </th>
                                           <th> URL </th>
                                           <th> Format </th>
                                           <th> SizeMB </th>
                                           <th> Actions </th>
                                       </tr>
                                       </thead>
                                       <tbody>
                                       {dataSet.downloadLinks.map((downloadLink, download_index) => {
                                           return (
                                               <tr key={download_index}>
                                                   <td>{downloadLink.index}</td>
                                                   <td>{downloadLink.label}</td>
                                                   <td>{downloadLink.URL}</td>
                                                   <td>{downloadLink.format}</td>
                                                   <td>{downloadLink.sizeMB}</td>
                                                   <td>
                                                       <Button
                                                           onClick={() => this.editDownloadLink(index, download_index) }
                                                           outline color="info">
                                                           Edit
                                                       </Button>
                                                       <Button onClick={() => this.removeDownloadLink(dataSet, index, download_index)}
                                                               className={"ml-3"}
                                                               outline
                                                               color={"danger"}>
                                                           Remove
                                                       </Button>
                                                   </td>
                                               </tr>)
                                       })}
                                       </tbody>
                                   </Table>
                               </Col>
                           </Row>
                        </Row>
                    </Collapse>
                </div>
              {index > 0 &&
                <Button className={"m-2"}
                        outline color={"danger"}
                        onClick={_ => this.removeHandler(index)}>Remove {index}</Button>
              }
            </>)
        })
        }
        <Button className={"mx-auto"} outline color={"info"} onClick={this.handleAddMore}>Add Dataset</Button>
        <br/><br/>
        <Row className="px-2 d-flex justify-content-center">
          <Button onClick={(event) => this.submitHandler(event)} color="success" outline disabled={!this.isFormValid()}> Submit </Button>
          &nbsp;&nbsp;&nbsp;
          <Button onClick={(e) => this.goBack(e)} color="danger" outline> Cancel </Button>
         </Row>
      </Form>
    </>;
    if (this.props.loading) {
      content = <Spinner/>
    } else {
      content = form;
    }
    return (
      <div className="centerForm mb-2">
        <div>
          {redirect}
          {content}
        </div>
      </div>
    );
  };

  componentWillUnmount() {
  }

}

const mapStateToProps = (state) => {
  return {
    aws: state.aws,
    loading: state.project.loading,
    success: state.project.success,
    slug: state.project.slug,
    errors: state.project.errors,
    token: state.login.token,
    portal: state.portal,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateProject: (url, project) => { dispatch(actions.create_project(url, project) );
    },
    onClearProjectState: () => dispatch(actions.clear_project_state()),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(createProject);
