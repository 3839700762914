import * as actionTypes from "./actionTypes/pendingApprovalActionTypes";
import {User} from "../../interfaces/auth";
import {axiosInstance} from "../../Axios";
import * as _ from "lodash";
import {MySwal, Toast} from "../../SWAL";

const pendingApprovalFetchStart = () => {
  return {
    type: actionTypes.PENDING_APPROVAL_START,
    loading: true
  }
};

const pendingApprovalFetchSuccess = (users: Array<User>) => {
  return {
    type: actionTypes.PENDING_APPROVAL_SUCCESS,
    loading: false,
    users: users
  }
};

const pendingApprovalFailed = () => {
  return {
    type: actionTypes.PENDING_APPROVAL_FAILED,
    loading: false,
    users: []
  }
};

export const getPendingApprovals= () => {
  return dispatch => {
    dispatch(pendingApprovalFetchStart());
    axiosInstance("users/portal/pending-approvals", "GET")
      .then(response => {
        let data = _.get(response, "data");
        if (data.success) {
          dispatch(pendingApprovalFetchSuccess(data.result));
        } else {
          dispatch(pendingApprovalFailed());
        }
      })
      .catch(err => {
        dispatch(pendingApprovalFailed());
      })
  }
};

export const approveUser = (userId: string, userName: string) => {
  return dispatch => {
    axiosInstance(`users/portal/approve/${userId}`, "POST")
      .then(response => {
        let data = _.get(response, "data");
        if (data.success) {
          dispatch(getPendingApprovals());
          Toast.fire({
            titleText: userName + " was successfully approved!",
            icon: "success"
          })
        } else {
          MySwal.fire({
            title: "Error!",
            titleText: userName + " approval failed",
            icon:"error"
          }
          );
        }
      })
      .catch(err => {
        MySwal.fire({
          title:"Error!",
          titleText: userName + " approval failed. Check your internet connection",
          icon:"error"
        }
        );
      })
  }
};

export const declineUser = (userId: string, userName: string) => {
  return dispatch => {
    axiosInstance(`users/portal/decline/${userId}`, "POST")
      .then(response => {
        let data = _.get(response, "data");
        if (data.success) {
          dispatch(getPendingApprovals());
          // alert("User declined successfully");
          MySwal.fire({
            title: "Declined!",
            titleText: userName + "'s request to join has been declined",
            icon:"success"}
          );
        } else {
          alert("User decline failed");
        }
      })
      .catch(err => {
        alert("User decline failed!");
      })
  }
};
