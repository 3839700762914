import React, {Component} from "react";
import Signup from "../Auth/Signup";
import {connect} from "react-redux";
import * as _ from "lodash";
import {CreatePortalAdminProps} from "../../interfaces/portal";
import * as actions from "../../store/actions";

class CreatePortalAdmin extends Component<CreatePortalAdminProps> {
  state = {
    portalSlug: _.get(this.props.match.params, "portalSlug")
  };
  componentWillUnmount(): void {
    this.props.onClearSignupState();
  }
  render() {
    return (
      <>
        <Signup buttonText="Add Admin"
                title="Create Portal Admin"
                url={`${_.get(this.state, "portalSlug")}/create/admin`} authenticatedRequest={true}/>
      </>
    )

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onClearSignupState: () => dispatch(actions.clearSignupState())
  }
};

export default connect(null, mapDispatchToProps)(CreatePortalAdmin);
