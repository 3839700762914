export const GET_USER_LIST_START="GET_USER_LIST_START";
export const GET_USER_LIST_SUCCESS="GET_USER_LIST_SUCCESS";
export const GET_USER_LIST_FAIL="GET_USER_LIST_SUCCESS";
export const CLEAR_USER_LIST_STATE="CLEAR_USER_LIST_STATE";

export const CHANGE_USER_STATUS_START = 'CHANGE_USER_STATUS_START';
export const CHANGE_USER_STATUS_SUCCESS = 'CHANGE_USER_STATUS_SUCCESS';
export const CHANGE_USER_STATUS_FAIL = 'CHANGE_USER_STATUS_FAIL';

export const CHANGE_USER_ACTIVATION_START = "CHANGE_USER_ACTIVATION_START";
export const CHANGE_USER_ACTIVATION_SUCCESS = "CHANGE_USER_ACTIVATION_SUCCESS";
export const CHANGE_USER_ACTIVATION_FAIL = "CHANGE_USER_ACTIVATION_FAIL";