import React from "react";
import {connect} from "react-redux";
import {CreateGroupCompProps, CreateGroupCompState, CreateGroupData} from "../../interfaces/groups";
import * as _ from "lodash";
import {Button, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {createGroup} from "../../store/actions/portalGroups";
import {Toast} from "../../SWAL";
import {generatePortalSpecificUrl} from "../../helpers/urlGenerators";


class CreateGroup extends React.Component<CreateGroupCompProps, CreateGroupCompState> {
  constructor(props) {
    super(props);
    this.state = {
      portal: _.get(this.props.match.params, "portal"),
      name: ""
    }
  }
  inputChangedHandler(event, field) {
    let value = event.target.value;
    let newState = {};
    newState[field] = value;
    this.setState(newState);
  }

  async submitHandler() {
    let groupFields = ["name"];
    // @ts-ignore
    let groupFieldsObject: CreateGroupData = _.pick(this.state, groupFields);
    await this.props.createGroup(this.state.portal, groupFieldsObject);
    if (this.props.success && this.props.createGroup) {
      Toast.fire("Success", "Group successfully created", "success");
      this.props.history.push(generatePortalSpecificUrl(this.props.portal, `/details/${this.state.portal}`));
    }
  }

  isFormvalid = () => {
        return (this.state.name !== '');
    };

  render() {
    return (
      <Container>
        <Row>
          <Col className="centerForm" lg={12}>
            <h1 className="heading">Create Group</h1>
            <FormGroup>
              <Label className="labelText" for="name">Name</Label>
              <Input
                className="inputItem"
                placeholder="Name"
                value={this.state.name}
                type="text"
                onChange={(event) => this.inputChangedHandler(event, "name")}/>
            </FormGroup>
            <Row className="px-2 d-flex justify-content-center">
              <Button disabled={!this.isFormvalid()} color={"success"} outline onClick={this.submitHandler.bind(this)}>Create</Button>
              &nbsp;&nbsp;&nbsp;
              <Button onClick={(e) => this.props.history.push(`/details/${this.state.portal}`)} color="danger" outline> Cancel </Button>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

const mapStateToProps= state => {
  return {
    success: state.portalGroups.success,
    createdGroup: state.portalGroups.createdGroup,
    portal: state.portalListReducer.portal
  }
};

const mapDispatchToProps = dispatch => {
  return {
    createGroup: (portal: string, data: CreateGroupData) => dispatch(createGroup(portal, data)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateGroup);
