import * as actionTypes from '../actions/actionTypes/authActionTypes';
import { updateObject } from '../utility';
import { IUpdateUserReducer } from "../types";

const initialState: IUpdateUserReducer = {
  loading: false,
  message: "",
  success: false,
  email: "",
  errors: {}
};

const updateUserStart = (state, action) => {
  return updateObject(state, {errors: null, loading: true});
};
const updateUserSuccess = (state, action) => {
  return updateObject(state, {
      message: action.message,
      email: action.email,
      errors: null,
      loading: false,
      success: action.success
  })
};
const updateUserFail = (state, action) => {
  return updateObject(state, {errors: action.errors, success: false, loading: false})
};

const reducer = (state: IUpdateUserReducer = initialState, action) => {
  switch (action.type) {
      case actionTypes.UPDATE_USER_START:
          return updateUserStart(state, action);
      case actionTypes.UPDATE_USER_SUCCESS:
          return updateUserSuccess(state, action);
      case actionTypes.UPDATE_USER_FAIL:
          return updateUserFail(state, action);
      case actionTypes.CLEAR_UPDATE_USER_STATE:
          return updateObject(state, initialState);
      default:
          return state;
  }
};

export default reducer;