import axios from "axios";
import { store } from ".";
import { checkCookieExist } from "./helpers/cookieHandler";
import { shouldLogout } from "./store/actions/login";

let base_url = process.env.REACT_APP_API_HOST;

export const axiosObj = axios.create();

export const axiosInterceptors = [];

export const requestHandler = (request) => {
  // Modify request here
  const token = checkCookieExist('_insiteToken');
  request.headers['Authorization'] = token;
  return request;
};


export const axiosInstance = (url, method, headers?, body = {}) => {
  return new Promise((resolve, reject) => {
    axiosObj({
      url: base_url + url,
      method: method,
      headers: headers,
      data: body,
    })
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        if(err && !err.response) {
          err.message = err.message + " (" +  base_url + url + ")"
          store.dispatch(shouldLogout());
        } else if(err && err.response.status === 401) {
          err.message = err.message + " (" +  base_url + url + ")"
          store.dispatch(shouldLogout());
        } 
        reject(err);
      })
  });
};
