import { RouteComponentProps } from "react-router";

export interface ProjectParamsProps extends RouteComponentProps {
}

export interface UpdateProjectParamsProps {
    buttonLabel: string;
    className: string;
    optionItem: any;
    openModal: boolean;
    submitOption: (option) => void;
    closeModal: any;
}

export enum ProjectParamTypes {
    DROPDOWN = 'DROPDOWN',
    STRING = 'STRING',
    CHECKBOX = 'CHECKBOX',
    INTEGER = 'INTEGER',
    FLOAT = 'FLOAT'
}