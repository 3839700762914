import React from "react";
import ReCAPTCHA from "react-google-recaptcha"


export default function GoogleCaptchaV2({inputHandler}) {

 const onChange = (value) =>{
    console.log("Captcha value:", value);
    inputHandler(value);
  }

  return <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_API_KEY} onChange={onChange} />
}