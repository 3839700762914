import React, { useCallback, useEffect, useState } from "react";
import * as _ from "lodash";
import { Button, Col, Row, Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { axiosInstance } from "../../Axios";
import { ProjectParamsProps } from "../../interfaces/project-params";
import UpdateProjectParams from "./components/update-project-params.component";
import { isNumber } from "lodash";
import "./project-params.css";

const ProjectParams: React.FC<ProjectParamsProps> = (props: ProjectParamsProps) => {

  const [optionsList, setOptionsList] = useState([]);

  const [portalSlug, setPortalSlug] = useState(null);

  const [currentOption, setCurrentOption] = useState(null);

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const portal = _.get(props.match.params, "portalSlug");
    if (portal) {
      setPortalSlug(portal);
    }
  }, [props.match.params]);

  useEffect(() => {
    if (portalSlug) {
      const _url = `${portalSlug}/project-params`;
      const _headers = {};
      axiosInstance(_url, 'get', _headers)
        .then(response => {
          const res = _.get(response, "data");
          if (res.success) {
            setOptionsList(res.result.options);
          }
        }).catch(err => { });
    }
  }, [portalSlug])

  const addOption = useCallback(() => {
    setOpenModal(true);
  }, [setOpenModal]);

  const editOption = useCallback((optionIndex: number) => {
    setCurrentOption(optionIndex);
    setOpenModal(true);
  }, [setOpenModal]);

  const saveOptions = useCallback((options) => {
    const _headers = { 'content-type': 'application/json' };
    const _optionBody = { options };
    const _url = `${portalSlug}/project-params/`;
    axiosInstance(_url, 'post', _headers, _optionBody).then(response => { }).catch(err => { });
  }, [portalSlug]);

  const submitOptionHandler = useCallback((option) => {
    setOpenModal(false);
    const updatedOptions = [...optionsList];
    if (isNumber(currentOption)) {
      updatedOptions[currentOption] = option;
    } else {
      updatedOptions.push(option);
    }

    setCurrentOption(null);
    setOptionsList(updatedOptions);
    saveOptions(updatedOptions);
  }, [optionsList, setOptionsList, currentOption, saveOptions]);

  const onClose = useCallback(() => {
    setOpenModal(false);
    setCurrentOption(null);
  }, []);

  const move = (index: number, indexDiff: number) => {
    const updatedOptions = [...optionsList];
    [updatedOptions[index], updatedOptions[index + indexDiff]] = [updatedOptions[index + indexDiff], updatedOptions[index]];
    setOptionsList(updatedOptions);
    saveOptions(updatedOptions);
  }

  const removeOption = useCallback((index) => {
    const updatedOptions = [...optionsList];
    updatedOptions.splice(index, 1);
    setOptionsList(updatedOptions);
    saveOptions(updatedOptions);
  }, [optionsList, setOptionsList, saveOptions]);

  return (
    <div className="centerForm col-12 mx-0 px-0">

      <UpdateProjectParams
        openModal={openModal}
        closeModal={onClose}
        className={'tag-add-edit'}
        buttonLabel={'Submit'}
        submitOption={submitOptionHandler}
        optionItem={currentOption !== null ? optionsList[currentOption] : null} />

      <Row className="centerForm">
        <h1 className="heading">Project Params</h1>
        <Col className="centerForm" lg={12}>
          <div className='text-md-right'>
            <Button onClick={() => addOption()}>
              <span>
                <FontAwesomeIcon icon={faPlusCircle} />
              </span>
            </Button>
          </div>
          <Table responsive striped bordered>
            <thead>
              <tr>
                <th> No. </th>
                <th> Label </th>
                <th> Type </th>
                <th> Values </th>
                <th> Actions </th>
              </tr>
            </thead>
            <tbody>
              {optionsList.map((option, index) => {
                return (
                  <tr key={index}>
                    <td>{index}</td>
                    <td>{option.label}</td>
                    <td>{option.type}</td>
                    <td>{option.values.join(', ')}</td>
                    <td className={'d-flex align-items-center flex-grow-0 justify-content-center'}>
                      <div className='d-flex flex-column ml-1 mr-3'>
                          <FontAwesomeIcon icon={faChevronUp} onClick={() => move(index, -1)} className={`reorder-btn ${index <= 0 ? 'disabled' : ''}`} />
                          <FontAwesomeIcon icon={faChevronDown} onClick={() => move(index, 1)} className={`reorder-btn  ${index >= (optionsList.length - 1) ? 'disabled' : ''}`} />
                      </div>
                      <Button
                        onClick={() => editOption(index)}
                        outline color="info">
                        Edit
                        </Button>
                      <Button
                        onClick={() => removeOption(index)}
                        className={"ml-3"}
                        outline
                        color={"danger"}>
                        Remove
                        </Button>
                    </td>
                  </tr>)
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  )
}

export default ProjectParams;