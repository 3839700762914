import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import { ProjectParamTypes, UpdateProjectParamsProps } from '../../../interfaces/project-params';

const UpdateProjectParams: React.FC<UpdateProjectParamsProps> = (props) => {
  const { className, optionItem, openModal } = props;

  const [values, setValues] = useState(optionItem ? optionItem.values : []);

  const [label, setLabel] = useState(optionItem ? optionItem.label : '');

  const [type, setType] = useState(optionItem ? optionItem.type : ProjectParamTypes.DROPDOWN);

  const [errors, setErrors] = useState({});

  const [submitted, setSubmitted] = useState(false);

  const [defaultValue, setDefaultValue] = useState(optionItem ? optionItem.defaultValue : '');

  useEffect(() => {
    // Reset all data
    setValues(optionItem ? optionItem.values : []);
    setLabel(optionItem ? optionItem.label : '');
    setType(optionItem ? optionItem.type : ProjectParamTypes.DROPDOWN);
    setDefaultValue(optionItem ? optionItem.defaultValue : '');
    setErrors({});
    setSubmitted(false);
  }, [openModal, optionItem]);


  const addValue = useCallback(() => {
    const updatedValues = values.concat('');
    setValues(updatedValues);
  }, [values]);

  const validateData = (fromSubmit?): boolean => {
    const isFormSubmitted = fromSubmit ?? submitted;
    if (!isFormSubmitted) {
      return true;
    }

    let isValid = true;

    if (!label) {
      errors['label'] = 'Label cannot be empty';
      isValid = false;
    } else {
      errors['label'] = null;
    }

    if (type === ProjectParamTypes.DROPDOWN && !values.length) {
      errors['values'] = 'Dropdown must have atleast one value';
      isValid = false;
    } else {
      errors['values'] = null;
    }

    setErrors({ ...errors });

    return isValid;
  };

  useEffect(() => {
    validateData();
  }, [label, values, type, submitted]);

  const submitOption = useCallback((event) => {
    setSubmitted(true);
    if (validateData(true)) {
      props.submitOption({ label, values, type, defaultValue });
    }
  }, [setSubmitted, validateData, label, values, type, props]);

  const valuesChangeHandler = useCallback((event, index) => {
    event.preventDefault();
    const { value }: any = event.target;
    const updatedValues = values.slice();
    updatedValues[index] = value;

    setValues(updatedValues);
  }, [values, setValues]);

  const removeValue = useCallback((index) => {
    const updatedValues = values.slice();
    updatedValues.splice(index, 1);
    setValues(updatedValues);
  }, [values, setValues]);

  const onTypeChange = useCallback((value) => {
    setType(value);
    if (value !== ProjectParamTypes.DROPDOWN) {
      setValues([]);
    }

    // Set default value according to type
    if (value === ProjectParamTypes.DROPDOWN) {
      setDefaultValue(_.first(values));
    } else if (value === ProjectParamTypes.CHECKBOX) {
      setDefaultValue(false);
    } else {
      setDefaultValue('');
    }
  }, [setType, setValues]);

  const defaultValueInput = useMemo(() => {
    switch (type) {
      case ProjectParamTypes.DROPDOWN:
        return (
          <Input type='select'
            value={defaultValue}
            onChange={(e) => { setDefaultValue(e.target.value) }}>
            {
              values.map((option, index) => {
                return (<option key={index} value={option}> {option} </option>)
              })
            }
          </Input>
        )
      case ProjectParamTypes.CHECKBOX:
        return (
          <Input type='checkbox'
            checked={defaultValue}
            className={'position-static ml-2 d-inline-block'}
            onChange={(e) => { setDefaultValue(e.target.checked) }}>
          </Input>
        )
      case ProjectParamTypes.INTEGER:
      case ProjectParamTypes.FLOAT:
        return (
          <Input type='number'
            value={defaultValue}
            onChange={(e) => { setDefaultValue(e.target.value) }}>
          </Input>
        )
      case ProjectParamTypes.STRING:
      default:
        return (
          <Input type='textarea' rows="1"
            value={defaultValue}
            onChange={(e) => { setDefaultValue(e.target.value) }}>
          </Input>
        )
    }
  }, [type, values, defaultValue, setDefaultValue]);

  return (
    <>
      <Modal isOpen={openModal} toggle={props.closeModal} className={className}>
        <ModalHeader className='text-center'>Project Param</ModalHeader>
        <ModalBody>
          <label className="d-block">
            <strong> Option Label </strong>
          </label>
          <Input type={'text'}
            name={'value'}
            onChange={(event) => setLabel(event.target.value)}
            value={label} />
          {errors['label'] && <span className="text-danger">{errors['label']}</span>}

          <label className="d-block">
            <strong> Option Type </strong>
            <Input type={'select'} value={type} onChange={(event) => onTypeChange(event.target.value)}>
              {
                Object.values(ProjectParamTypes).map((option, i) =>
                  <option key={`type-${i}`} value={option}>{option}</option>
                )
              }
            </Input>
          </label>

          {
            type === ProjectParamTypes.DROPDOWN && (
              <>
                <label className="mt-2"><strong>Option Values</strong></label>
                <Button className={'ml-2'} onClick={() => addValue()}>
                  <span>
                    <FontAwesomeIcon icon={faPlusCircle} />
                  </span>
                </Button>
                <br />
                {errors['values'] && <span className="text-danger">{errors['values']}</span>}
                {
                  values.map((value, index) =>
                    <div key={`value-${index}`} className={'d-flex align-items-center'}>
                      <Input type={'text'}
                        name={'value'}
                        placeholder={'Value'}
                        className={'my-2'}
                        onChange={(event) => valuesChangeHandler(event, index)}
                        value={value} />

                      <Button color="danger" className={'ml-2'} onClick={() => removeValue(index)}>
                        <span>
                          <FontAwesomeIcon icon={faTrash} />
                        </span>
                      </Button>
                    </div>
                  )
                }
              </>
            )
          }

          <label className="d-block">
            <strong> Default Value: </strong>
            {defaultValueInput}
          </label>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={submitOption}>Submit</Button>
          <Button color="secondary" onClick={props.closeModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default UpdateProjectParams;
