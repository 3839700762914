import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLinkedin, faGoogle} from "@fortawesome/free-brands-svg-icons";
import LocalStorageWrapped from "../../LocalStorageWrapped";


class SocialAuth extends Component {

  render() {
    const portalInfo = LocalStorageWrapped.getItem("portalInfo");
    return (
      <div>
        <a href={"http://salman.com:9000/" + (portalInfo ? portalInfo.portalSlug : "esktchers") + "/users/auth/google"}>
          <FontAwesomeIcon className="pr-3" size="2x" color="red" icon={faGoogle}/>
        </a>
        <a href={"http://salman.com:9000/" +  (portalInfo ? portalInfo.portalSlug : "esktchers") + "/users/auth/linkedin"}>
          <FontAwesomeIcon className="pr-3" size="2x" color="blue" icon={faLinkedin}/>
        </a>
      </div>
    )
  }

}

export default SocialAuth;
