import * as actionTypes from './actionTypes/authActionTypes';
import {loginSuccess} from "./login";
import {axiosInstance} from "../../Axios";
import _ from 'lodash';
import {MySwal, Toast} from "../../SWAL";
import {IUser} from "../../interfaces/user";

export const signupStart = () => {
  return {
    type: actionTypes.SIGNUP_START
  };
};

export const signupSuccess = (message: string, email: string, success: boolean, registeredUser: IUser) => {
  return {
    type: actionTypes.SIGNUP_SUCCESS,
    message: message,
    email: email,
    success: success,
    registeredUser: registeredUser
  };
};

export const signupFail = (errors: object) => {
  return {
    type: actionTypes.SIGNUP_FAIL,
    errors: errors
  };
};

export const updateUserStart = () => {
  return {
    type: actionTypes.UPDATE_USER_START
  };
}

export const updateUserSuccess = (message: string, email: string, success: boolean) => {
  return {
    type: actionTypes.UPDATE_USER_SUCCESS,
    message: message,
    email: email,
    success: success
  };
}

export const updateUserFail = (errors: object) => {
  return {
    type: actionTypes.UPDATE_USER_FAIL,
    errors: errors
  };
}

export const clearUpdateUserState = () => {
  return {
    type: actionTypes.CLEAR_UPDATE_USER_STATE
  }
};

export const signup = (email: string, first_name: string, last_name: string, invitationProjectID: string, url: string, showSuccessMsg: boolean, invitationPortalIDs: string[] ) => {
  return async (dispatch) => {
    dispatch(signupStart());
    const authData = {
      "email": email,
      "first_name": first_name,
      "last_name": last_name,
      // "password1": password1,
      // "password2": password2,
      //"sendInviteEmail": sendInviteEmail,
      "invitationProjectID": invitationProjectID,
      "invitationPortalIDs": invitationPortalIDs,
    };

    axiosInstance(url, 'post',
      {
        'Content-Type': 'application/json',
      },
      JSON.stringify(authData)
    ).then(response => {
      let res = _.get(response, "data");
      if (res.success) {
        let userInfo: IUser = {
          id: res.object.id,
          email: res.object.email,
          firstName: res.object.firstName,
          lastName: res.object.lastName,
          portals: res.object.portals,
          isSuperUser: res.object.isSuperUser,
          linkedInId: res.object.linkedInId,
          googleId: res.object.googleId
        };
        dispatch(signupSuccess(res.message, res.object.email, res.success, userInfo));
        if (url==="admin/create") {
          dispatch(loginSuccess(res.message, res.object.token, res.success,
            "email", "/dashboard", userInfo));
        }
        if(showSuccessMsg) {
          MySwal.fire("Success", "you are signed up waiting for approval","success");
        }
        dispatch(setAuthRedirectPath('/dashboard'));
      }
    }).catch(err => {
      dispatch(signupFail(err.response.data.errors));
    });
  }
};

export const updateUser = (portalSlug: string, userId: string, email: string, first_name, last_name) => {
  return async (dispatch) => {
    dispatch(updateUserStart());
    const authData = {
      "email": email,
      "first_name": first_name,
      "last_name": last_name,
    };

    axiosInstance(`${portalSlug}/users/${userId}`, 'put',
      {
        'Content-Type': 'application/json',
      },
      JSON.stringify(authData)
    ).then(response => {
      let res = _.get(response, "data");
      if (res.success) {
        Toast.fire("Success", "Profile updated successfully", "success");
        dispatch(updateUserSuccess(res.message, res.object.email, res.success));
      }
    }).catch(err => {
      dispatch(updateUserFail(err.response.data.errors));
    });
  }
}

export const socialAuthLogin = (userData: object) => {

  return {
    type: actionTypes.SOCIAL_AUTH_LOGIN,
    payload: userData

  }
}

export const setAuthRedirectPath = (path: string) => {
  return {
    type: actionTypes.SET_SIGNUP_REDIRECT_PATH,
    path: path,
  }
};

export const clearSignupState = () => {
  return {
    type: actionTypes.CLEAR_SIGNUP_STATE
  }
};

// export const authCheckState = () =>{
//     return dispatch => {
//         const token = localStorage.getItem('token');
//         if(!token){
//             dispatch(logout());
//         }
//         else{
//             const expirationDate = new Date( localStorage.getItem('expirationDate'));
//             const userId = localStorage.getItem('userId');
//             const token = localStorage.getItem('token');
//             if(expirationDate <= new Date())
//             {
//                 dispatch(logout());
//             }
//             else{
//                 dispatch(authSuccess(token,userId));
//                 dispatch(checkAuthTimout((expirationDate.getTime()-new Date().getTime())/1000));

//             }


//         }
//     }
// }
