import * as VersionActionTypes from "./actionTypes/versionActionTypes";
import {axiosInstance} from "../../Axios";
import * as _ from "lodash";

const getPortalVersionsStart = () => {
  return {
    type: VersionActionTypes.GET_PORTAL_VERSIONS_LIST_START
  }
};

const getPortalVersionsSuccess = (versions) => {
  return {
    type: VersionActionTypes.GET_PORTAL_VERSIONS_LIST_SUCCESS,
    versions
  }
};

const getPortalVersionsFail = (error: string) => {
  return {
    type: VersionActionTypes.GET_PORTAL_VERSIONS_LIST_FAIL,
    error: error
  }
};

// const portalVersionChangeStateStart = () => {
//   return {
//     type: Version.PORTAL_GROUP_CHANGE_STATE_START
//   }
// };

// const portalVersionChangeStateSuccess = (message: string) => {
//   return {
//     type: PortalGroupActionTypes.PORTAL_GROUP_CHANGE_STATE_SUCCESS,
//     message: message
//   }
// };

// const portalVersionChangeStateFail = (error: string) => {
//   return {
//     type: PortalGroupActionTypes.PORTAL_GROUP_CHANGE_STATE_FAIL,
//     error: error
//   }
// };

export const getAvailableVersions = () => {
  return async dispatch => {
    dispatch(getPortalVersionsStart());
    let url = `available-versions`;
    let response = null;
    try {
      response = await axiosInstance(url, "GET");
    } catch (e) {
      dispatch(getPortalVersionsFail(e.message));
    }

    let versions = _.get(response, "data.result");
    dispatch(getPortalVersionsSuccess(versions));
  }
};

// export const changePortalVersionState = (portal: string, groupId: string, action: "enable" | "disable") => {
//   return async dispatch => {
//     dispatch(portalGroupChangeStateStart());
//     let url = `${portal}/version/change-state`;
//     let response = null;
//     try {
//       response = await axiosInstance(url, "POST", {}, {action: action});
//     } catch (e) {
//       return dispatch(portalGroupChangeStateFail(e.message));
//     }

//     let message = _.get(response, "data.message");
//     dispatch(portalGroupChangeStateSuccess(message));
//   }
// };