import * as actionTypes from './actionTypes/authActionTypes';


export const updateToken = (message: string, token: string, success: boolean) => {
    return {
        type: actionTypes.UPDATE_TOKEN_SUCCESS,
        message: message,
        token: token,
        success: success
    };
};

export const TokenUpdate = (token: string) => {
    return (dispatch) => {
        dispatch(updateToken(
            "Token successfully updated",
            "Bearer " + token,
            true
        ));
    }
}