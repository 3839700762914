export const CHANGE_PERMISSION_START = "CHANGE_PERMISSION_START";
export const CHANGE_PERMISSION_SUCCESS = "CHANGE_PERMISSION_SUCCESS";
export const CHANGE_PERMISSION_FAIL = "CHANGE_PERMISSION_FAIL";


export const GET_USER_PERMISSION_START = "GET_USER_PERMISSIONS_START";
export const GET_USER_PERMISSIONS_SUCCESS = "GET_USER_PERMISSIONS_SUCCESS";
export const GET_USER_PERMISSIONS_FAIL = "GET_USER_PERMISSIONS_FAIL";

export const CLEAR_USER_PERMISSIONS = "CLEAR_USER_PERMISSIONS";


export const CHANGE_OBJ_PERMISSIONS_START = "CHANGE_OBJ_PERMISSIONS_START";
export const CHANGE_OBJ_PERMISSIONS_SUCCESS = "CHANGE_OBJ_PERMISSIONS_SUCCESS";
export const CHANGE_OBJ_PERMISSIONS_FAIL = "CHANGE_OBJ_PERMISSIONS_FAIL";

export const GET_PORTAL_USER_PERMISSIONS_START = "GET_PORTAL_USER_PERMISSIONS_START";
export const GET_PORTAL_USER_PERMISSIONS_SUCCESS = "GET_PORTAL_USER_PERMISSIONS_SUCCESS";
export const GET_PORTAL_USER_PERMISSIONS_FAIL = "GET_PORTAL_USER_PERMISSIONS_FAIL";
