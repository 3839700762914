import * as PortalGroupActionTypes from "./actionTypes/groupActionTypes";
import {axiosInstance} from "../../Axios";
import * as _ from "lodash";
import {CreateGroupData, IGroup} from "../../interfaces/groups";
import {ChangeObjectPermission} from "./changeUserPermissions";
import {IUser} from "../../interfaces/user";

interface ChangePermissionData {
  name: string,
  model: string
}

const getPortalGroupsStart = () => {
  return {
    type: PortalGroupActionTypes.GET_PORTAL_GROUPS_START
  }
};

const getPortalGroupsSuccess = (groups) => {
  return {
    type: PortalGroupActionTypes.GET_PORTAL_GROUPS_SUCCESS,
    groups: groups
  }
};

const getPortalGroupsFail = (error: string) => {
  return {
    type: PortalGroupActionTypes.GET_PORTAL_GROUPS_FAIL,
    error: error
  }
};

const portalGroupChangeStateStart = () => {
  return {
    type: PortalGroupActionTypes.PORTAL_GROUP_CHANGE_STATE_START
  }
};

const portalGroupChangeStateSuccess = (message: string) => {
  return {
    type: PortalGroupActionTypes.PORTAL_GROUP_CHANGE_STATE_SUCCESS,
    message: message
  }
};

const portalGroupChangeStateFail = (error: string) => {
  return {
    type: PortalGroupActionTypes.PORTAL_GROUP_CHANGE_STATE_FAIL,
    error: error
  }
};

const portalGroupCreateStart = () => {
  return {
    type: PortalGroupActionTypes.CREATE_PORTAL_GROUP_START
  }
};

const portalGroupCreateSuccess = (group: IGroup) => {
  return {
    type: PortalGroupActionTypes.CREATE_PORTAL_GROUP_SUCCESS,
    createdGroup: group
  }
};

const portalGroupCreateFail = (error: string) => {
  return {
    type: PortalGroupActionTypes.CREATE_PORTAL_GROUP_FAIL,
    error: error
  }
};

const portalGroupGetDetailsStart = () => {
  return {
    type: PortalGroupActionTypes.PORTAL_GROUP_GET_DETAILS_START
  }
};

const portalGroupGetDetailsSuccess = (group: IGroup) => {
  return {
    type: PortalGroupActionTypes.PORTAL_GROUP_GET_DETAILS_SUCCESS,
    selectGroupDetails: group
  }
};

const portalGroupGetDetailsFail = (error: string) => {
  return {
    type: PortalGroupActionTypes.PORTAL_GROUP_GET_DETAILS_FAIL,
    error: error
  }
};

const portalGroupGetUsersStart = () => {
  return {
    type: PortalGroupActionTypes.PORTAL_GROUP_GET_USERS_START
  }
};

const portalGroupGetUsersSuccess = (users: IUser[]) => {
  return {
    type: PortalGroupActionTypes.PORTAL_GROUP_GET_USERS_SUCCESS,
    groupUsers: users
  }
};

const portalGroupGetUsersFail = (error: string) => {
  return {
    type: PortalGroupActionTypes.PORTAL_GROUP_GET_USERS_FAIL,
    error: error
  }
};

const portalGroupGetNonUsersStart = () => {
  return {
    type: PortalGroupActionTypes.PORTAL_GROUP_GET_NON_USERS_START
  }
};

const portalGroupGetNonUsersSuccess = (users: IUser[]) => {
  return {
    type: PortalGroupActionTypes.PORTAL_GROUP_GET_NON_USERS_SUCCESS,
    nonGroupUsers: users
  }
};

const portalGroupGetNonUsersFail = (error: string) => {
  return {
    type: PortalGroupActionTypes.PORTAL_GROUP_GET_NON_USERS_FAIL,
    error: error
  }
};

export const getPortalGroups = (portal: string) => {
  return async dispatch => {
    dispatch(getPortalGroupsStart());
    let url = `${portal}/group/list`;
    let response = null;
    try {
      response = await axiosInstance(url, "GET");
    } catch (e) {
      dispatch(getPortalGroupsFail(e.message));
    }

    let groups = _.get(response, "data.result");
    dispatch(getPortalGroupsSuccess(groups));
  }
};

export const changeGroupState = (portal: string, groupId: string, action: "enable" | "disable") => {
  return async dispatch => {
    dispatch(portalGroupChangeStateStart());
    let url = `${portal}/group/change-state/${groupId}`;
    let response = null;
    try {
      response = await axiosInstance(url, "POST", {}, {action: action});
    } catch (e) {
      return dispatch(portalGroupChangeStateFail(e.message));
    }

    let message = _.get(response, "data.message");
    dispatch(portalGroupChangeStateSuccess(message));
  }
};

export const createGroup = (portal: string, data: CreateGroupData) => {
  return async dispatch => {
    dispatch(portalGroupCreateStart());
    let url = `${portal}/group/create`;
    let response = null;
    try {
      response = axiosInstance(url, "POST", {}, data);
    } catch (e) {
      return dispatch(portalGroupCreateFail(e.message));
    }

    let group: IGroup = _.get(response, "data.object");
    dispatch(portalGroupCreateSuccess(group));
  }
};

export const getGroupDetails = (portal: string, groupId: string) => {
  return async dispatch => {
    dispatch(portalGroupGetDetailsStart());
    let url = `${portal}/group/${groupId}`;
    let response = null;
    try {
      response = await axiosInstance(url, "GET");
    } catch (e) {
      return dispatch(portalGroupGetDetailsFail(e.message));
    }

    let group: IGroup = _.get(response, "data.object");
    let objectPermissionsFinal = {};
    let objectPermissions = group.permissions;
    for (let i = 0; i < objectPermissions.length; i++) {
      let currentPermission = objectPermissions[i];
      if (Object.keys(objectPermissionsFinal).indexOf(currentPermission.objectId) > -1) {
        objectPermissionsFinal[currentPermission.objectId].push(currentPermission.name);
      } else {
        objectPermissionsFinal[currentPermission.objectId] = [currentPermission.name];
      }
    }
    group.permissionsMapped = objectPermissionsFinal;
    dispatch(portalGroupGetDetailsSuccess(group));
  }
};

export const changeGroupModelPermission = (portal: string, groupId: string, action: "give" | "remove",
                                           data: ChangePermissionData) => {
  return new Promise(async (resolve, reject) => {
    let url = `${portal}/group/${action}-model-permission/${groupId}`;
    let response = null;
    try {
      response = await axiosInstance(url, "POST", {}, {permissions: data});
    } catch (e) {
      return reject(e.message);
    }

    resolve(response);
  })
};

export const changeGroupObjectPermission = (portal: string, groupId: string,
                                            action: "give-permission" | "remove-permission",
                                            data: ChangeObjectPermission) => {
  return new Promise(async (resolve, reject) => {
    let url = `${portal}/group/${action}/${groupId}`;
    let response = null;
    try {
      response = axiosInstance(url, "POST", {}, {permissions: data});
    } catch (e) {
      reject(e);
    }

    resolve(response);
  })
};

export const portalGroupGetUsers = (portal: string, groupId: string) => {
  return async dispatch => {
    dispatch(portalGroupGetUsersStart());
    let url = `${portal}/group/users/${groupId}`;
    let response = null;
    try {
      response = await axiosInstance(url, "GET");
    } catch (e) {
      return dispatch(portalGroupGetUsersFail(e.message));
    }

    let groupUsers: IUser[] = _.get(response, "data.result");
    dispatch(portalGroupGetUsersSuccess(groupUsers));
  }
};

export const portalGroupGetNonGroupUsers = (portal: string, groupId: string) => {
  return async dispatch => {
    dispatch(portalGroupGetNonUsersStart());
    let url = `${portal}/group/non-group-users/${groupId}`;
    let response = null;
    try {
      response = await axiosInstance(url, "GET");
    } catch (e) {
      return dispatch(portalGroupGetNonUsersFail(e.message));
    }

    let groupUsers: IUser[] = _.get(response, "data.result");
    dispatch(portalGroupGetNonUsersSuccess(groupUsers));
  }
};

export const changeGroupUser = (portal: string, groupId: string, userId: string, action: "add" | "remove") => {
  return new Promise(async (resolve, reject) => {
    let url = `${portal}/group/${action}-user/${groupId}`;
    let response = null;
    try {
      response = await axiosInstance(url, "POST", {}, {userId});
    } catch (e) {
      reject(e);
    }

    resolve(response);
  })
};
