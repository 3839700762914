import 'core-js/stable';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {createStore, applyMiddleware, compose, combineReducers} from 'redux';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import signupReducer from './store/reducers/signup';
import updateUserReducer from './store/reducers/updateUser';
import loginReducer from './store/reducers/login'
import projectReducer from './store/reducers/project';
import portalReducer from "./store/reducers/urlPortalDetector";
import portalListReducer from "./store/reducers/portal";
import portalUsersListReducer from "./store/reducers/users";
import pendingApprovalReducer from "./store/reducers/pendingApprovals";
import permissionsReducer from "./store/reducers/permission";
import datasetReducer from "./store/reducers/dataset";
import thunk from 'redux-thunk'
import portalGroupReducer from "./store/reducers/portalGroups";
import userGroups from "./store/reducers/userGroups";
import userViewableProjects from "./store/reducers/userViewableProjects";
import portalVersionsReducer from "./store/reducers/portalVersions";
import awsReducer from "./store/reducers/aws";
import * as actionTypes from './store/actions/actionTypes/authActionTypes';

const composeEnhancers = window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] as typeof compose || compose;


const appReducer = combineReducers({
  signup: signupReducer,
  updateUser: updateUserReducer,
  login: loginReducer,
  project: projectReducer,
  portal: portalReducer,
  portalListReducer: portalListReducer,
  portalUsersListReducer: portalUsersListReducer,
  pendingApprovalUsers: pendingApprovalReducer,
  userPermissions: permissionsReducer,
  dataset: datasetReducer,
  portalGroups: portalGroupReducer,
  userGroups: userGroups,
  userViewableProjects: userViewableProjects,
  portalVersion: portalVersionsReducer,
  aws: awsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === actionTypes.AUTH_LOGOUT) {
    state = undefined;
  }
  return appReducer(state, action)
}



export const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(thunk)
));

const app = (
    <Provider store={store}>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </Provider>

);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
