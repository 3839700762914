import _ from "lodash";
import { axiosInstance } from "../../Axios";

export const getPortalUserDetails = async (userId: string, portalSlug: string) => {
  let userDetailsUrl = `${portalSlug}/users/details/${userId}`;

  let userDetailsResponse = null;
  try {
    userDetailsResponse = await axiosInstance(userDetailsUrl, "GET");
  } catch (e) {
    return { success: false, data: null };
  }

  const userData = _.get(userDetailsResponse, "data.object")

  return { success: true, data: userData };
}