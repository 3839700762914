import {updateObject} from '../utility';
import * as portalActionTypes from "../actions/actionTypes/portalActionTypes";
import LocalStorageWrapped from "../../LocalStorageWrapped";


const initialState = {
  message: "",
  success: false,
  createdAt: "",
  id: "",
  name: "",
  slug: "",
  customScripts: '',
  loading: false,
  version: '',
  comments: '',
  errors: [],
  portal_list: [],
  group_list: [],
  user_list: [],
  dataset_list: [],
  project_list: [],
  get_portal_list_success: false,
  get_portal_list_loading: false,
  get_portal_list_errors: [],
  portal_admin_list: [],
  get_portal_admin_list_success: false,
  get_portal_admin_list_loading: false,
  get_portal_admin_list_errors: [],
  get_portal_group_list_loading: false,
  get_portal_group_list_success: false,
  get_portal_group_list_errors: [],

  get_all_user_list_loading: false,
  get_all_user_list_success: false,
  get_all_user_list_errors: [],

  get_all_dataset_list_loading: false,
  get_all_dataset_list_success: false,
  get_all_dataset_list_errors: [],

  get_all_project_list_loading: false,
  get_all_project_list_success: false,
  get_all_project_list_errors: [],

  selectedPortal: null,
  portalSelected: null,
  changeStateLoading: false,
  changeStateSuccess: false,
  changeStateErrors: false,

  updatePortalLoading: false,
  updatePortalSuccess: false,
  updatePortalErrors: null,
};

const get_portal_list_start = (state, _action) => {
  return updateObject(state, {
    get_portal_list_loading: true,
  })
};

const get_portal_list_success = (state, action) => {
  return updateObject(state, {
    get_portal_list_loading: false,
    get_portal_list_success: action.success,
    portal_list: action.portal_list,
    get_portal_list_errors: [],
  })
};

const get_portal_list_fail = (state, action) => {
  return updateObject(state, {
    get_portal_list_loading: false,
    get_portal_list_success: action.success,
    portal_list: [],
    get_portal_list_errors: action.errors,
  })
};


const get_portal_group_list_start = (state, _action) => {
  return updateObject(state, {
    get_portal_group_list_loading: true,
  })
};

const get_portal_group_list_success = (state, action) => {
  return updateObject(state, {
    get_portal_group_list_loading: false,
    get_portal_group_list_success: action.success,
    group_list: action.group_list,
    get_portal_group_list_errors: [],
  })
};

const get_portal_group_list_fail = (state, action) => {
  return updateObject(state, {
    get_portal_group_list_loading: false,
    get_portal_group_list_success: action.success,
    group_list: [],
    get_portal_group_list_errors: action.errors,
  })
};


const get_all_users_list_start = (state, _action) => {
  return updateObject(state, {
    get_all_user_list_loading: true,
  })
};

const get_all_users_list_success = (state, action) => {
  return updateObject(state, {
    get_all_user_list_loading: false,
    get_all_user_list_success: action.success,
    user_list: action.user_list,
    get_all_user_list_errors: [],
  })
};


const get_all_users_list_fail = (state, action) => {
  return updateObject(state, {
    get_all_user_list_loading: false,
    get_all_user_list_success: action.success,
    user_list: [],
    get_all_user_list_errors: action.errors,
  })
};


const get_all_datasets_list_start = (state, _action) => {
  return updateObject(state, {
    get_all_dataset_list_loading: true,
  })
};

const get_all_datasets_list_success = (state, action) => {
  return updateObject(state, {
    get_all_dataset_list_loading: false,
    get_all_dataset_list_success: action.success,
    dataset_list: action.dataset_list,
    get_all_dataset_list_errors: [],
  })
};


const get_all_datasets_list_fail = (state, action) => {
  return updateObject(state, {
    get_all_dataset_list_loading: false,
    get_all_dataset_list_success: action.success,
    dataset_list: [],
    get_all_dataset_list_errors: action.errors,
  })
};

const get_all_projects_list_start = (state, _action) => {
  return updateObject(state, {
    get_all_project_list_loading: true,
  })
};

const get_all_projects_list_success = (state, action) => {
  return updateObject(state, {
    get_all_project_list_loading: false,
    get_all_project_list_success: action.success,
    project_list: action.project_list,
    get_all_project_list_errors: [],
  })
};


const get_all_projects_list_fail = (state, action) => {
  return updateObject(state, {
    get_all_project_list_loading: false,
    get_all_project_list_success: action.success,
    project_list: [],
    get_all_project_list_errors: action.errors,
  })
};


const create_portal_success = (state, action) => {
  return updateObject(state, {
    success: action.success,
    message: action.message,
    name: action.name,
    comments: action.comment,
    slug: action.slug,
    id: action.id,
    createdAt: action.createdAt,
    loading: false,
    version: action.version,
    customScripts: action.customScripts,
  })
};

const create_portal_failure = (state, action) => {
  return updateObject(state, {
      errors: action.errors,
      loading: false,
    }
  )
};
// get portal admin list functions

const get_portal_admin_list_success = (state, action) => {
  return updateObject(state, {
    get_portal_admin_list_loading: false,
    get_portal_admin_list_success: action.success,
    portal_admin_list: action.portal_admin_list,
    get_portal_admin_list_errors: [],
  })
}

const get_portal_admin_list_fail = (state, action) => {
  return updateObject(state, {
    get_portal_admin_list_loading: false,
    get_portal_admin_list_success: action.success,
    portal_admin_list: [],
    get_portal_admin_list_errors: action.errors,
  })
};

const get_portal_admin_list_start = (state, _action) => {
  return updateObject(state, {
    get_portal_list_loading: true,
  })
};

const changeSelectedPortal = (state, action) => {
  LocalStorageWrapped.setItem("selectedPortal", action.portal);
  return updateObject(state, {
    selectedPortal: action.portal
  })
};


const setPortalSelected = (state, action) => {
  LocalStorageWrapped.setItem("selectedPortal", action.portal);
  return updateObject(state, {
    portalSelected: action.portal
  })
}


const clearSelectedPortal = (state, action) => {
  LocalStorageWrapped.setItem("selectedPortal", null);
  return updateObject(state, {
    portalSelected: null
  })

}
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case portalActionTypes.CREATE_PORTAL_SUCCESS:
      return create_portal_success(state, action);
    case portalActionTypes.CREATE_PORTAL_FAILURE:
      return create_portal_failure(state, action);
    case portalActionTypes.GET_PORTAL_LIST_START:
      return get_portal_list_start(state, action);
    case portalActionTypes.GET_PORTAL_LIST_SUCCESS:
      return get_portal_list_success(state, action);
    case portalActionTypes.GET_PORTAL_LIST_FAIL:
      return get_portal_list_fail(state, action);
    case portalActionTypes.GET_PORTAL_GROUP_LIST_START:
      return get_portal_group_list_start(state, action);
    case portalActionTypes.GET_PORTAL_GROUP_LIST_SUCCESS:
      return get_portal_group_list_success(state, action);
    case portalActionTypes.GET_PORTAL_GROUP_LIST_FAIL:
      return get_portal_group_list_fail(state, action);
    case portalActionTypes.GET_ALL_USERS_LIST_START:
      return get_all_users_list_start(state, action);
    case portalActionTypes.GET_ALL_USERS_LIST_SUCCESS:
      return get_all_users_list_success(state, action);
    case portalActionTypes.GET_ALL_USERS__LIST_FAIL:
      return get_all_users_list_fail(state, action);
    case portalActionTypes.GET_ALL_DATASETS_LIST_START:
      return get_all_datasets_list_start(state, action);
    case portalActionTypes.GET_ALL_DATASETS_LIST_SUCCESS:
      return get_all_datasets_list_success(state, action);
    case portalActionTypes.GET_ALL_DATASETS_LIST_FAIL:
      return get_all_datasets_list_fail(state, action);
    case portalActionTypes.GET_ALL_PROJECTS_LIST_START:
      return get_all_projects_list_start(state, action);
    case portalActionTypes.GET_ALL_PROJECTS_LIST_SUCCESS:
      return get_all_projects_list_success(state, action);
    case portalActionTypes.GET_ALL_PROJECTS_LIST_FAIL:
      return get_all_projects_list_fail(state, action);
    case portalActionTypes.CLEAR_PORTAL_STATE:
      return updateObject(state, initialState);
    // get portal_admin list
    case portalActionTypes.GET_PORTAL_ADMIN_LIST_START:
      return get_portal_admin_list_start(state, action);
    case portalActionTypes.GET_PORTAL_ADMIN_LIST_SUCCESS:
      return get_portal_admin_list_success(state, action);
    case portalActionTypes.GET_PORTAL_ADMIN_LIST_FAIL:
      return get_portal_admin_list_fail(state, action);
    case portalActionTypes.CHANGE_SELECTED_PORTAL:
      return changeSelectedPortal(state, action);
    case portalActionTypes.SET_SELECTED_PORTAL:
      return setPortalSelected(state, action);
    case portalActionTypes.CLEAR_SELECTED_PORTAL:
      return clearSelectedPortal(state, action);
    case portalActionTypes.DEACTIVATE_PORTAL_START:
      return updateObject(state, {changeStateLoading: true});
    case portalActionTypes.DEACTIVATE_PORTAL_SUCCESS:
      return updateObject(state, {changeStateSuccess: true, changeStateLoading: false});
    case portalActionTypes.DEACTIVATE_PORTAL_FAIL:
      return updateObject(state, {changeStateLoading: false, changeStateErrors: true});
    case portalActionTypes.BACKUP_PORTAL_START:
      return updateObject(state, {changeStateSuccess: true, changeStateLoading: false});
    case portalActionTypes.BACKUP_PORTAL_SUCCESS:
      return updateObject(state, {changeStateSuccess: true, changeStateLoading: false});
    case portalActionTypes.BACKUP_PORTAL_FAIL:
      return updateObject(state, {changeStateSuccess: true, changeStateLoading: false});
    case portalActionTypes.UPDATE_PORTAL_START:
      return updateObject(state, {updatePortalLoading: true});
    case portalActionTypes.UPDATE_PORTAL_SUCCESS:
      return updateObject(state, {updatePortalLoading: false, updatePortalSuccess: true, updatePortalErrors: null});
    case portalActionTypes.UPDATE_PORTAL_FAIL:
      return updateObject(state, {updatePortalLoading: false, updatePortalErrors: action.errors});

    default:
      return state;
  }
};

export default reducer;
