import * as permissionsActionTypes from "./actionTypes/permissionsActionTypes";
import {axiosInstance} from "../../Axios";
import * as _ from "lodash";
import {Toast} from "../../SWAL";
import LocalStorageWrapped from "../../LocalStorageWrapped";


const getPermissionStart = () => {
  return {
    type: permissionsActionTypes.GET_USER_PERMISSION_START
  }
};

export const getPermissionSuccess = (permissions) => {
  LocalStorageWrapped.setItem("userPermissions", permissions);
  return {
    type: permissionsActionTypes.GET_USER_PERMISSIONS_SUCCESS,
    permissions: permissions
  }
};
const getPermissionFail = () => {
  return {
    type: permissionsActionTypes.GET_USER_PERMISSIONS_FAIL
  }
};

export const getPermissions = (portalSlug: string, userId: string) => {
  return dispatch => {
    dispatch(getPermissionStart());
    let finalPermissionsObject = null;
    let url = `${portalSlug}/users/get-model-permissions/${userId}?portalAdmin=true`;
    axiosInstance(url, "get", {}, {})
      .then(response => {
        let res = _.get(response, "data");
        finalPermissionsObject = res.object;
        let newUrl = `users/permissions/${userId}`;
        axiosInstance(newUrl, "GET", {}, {})
          .then(response => {
            let result = _.get(response, "data.result");
            for (let i = 0; i < result.length; i++) {
              let currentObjectPermission = result[i];
              if (Object.keys(finalPermissionsObject).indexOf(currentObjectPermission.objectId) > -1) {
                finalPermissionsObject[currentObjectPermission.objectId].push(currentObjectPermission.name);
              } else {
                finalPermissionsObject[currentObjectPermission.objectId] = [currentObjectPermission.name];
              }
            }
            dispatch(getPermissionSuccess(finalPermissionsObject));
          })
          .catch(err => {
            dispatch(getPermissionFail());
          })

      })
      .catch(err => {
        dispatch(getPermissionFail());
      })
  }
};

const changePermissionStart = () => {
  return {
    type: permissionsActionTypes.CHANGE_PERMISSION_START,
  }
};

const changePermissionSuccess = () => {
  return {
    type: permissionsActionTypes.CHANGE_PERMISSION_SUCCESS,
  }
};

const changePermissionFail = () => {
  return {
    type: permissionsActionTypes.CHANGE_PERMISSION_FAIL
  }
};

export const changePermission = (action: string, portalSlug: string, userId: string, permissionName: string, model: string) => {
  return dispatch => {
    dispatch(changePermissionStart());
    const url = `${portalSlug}/users/${action}/${userId}`;
    const requestBody = {
      "permissions": {
        "userId": userId,
        "name": permissionName,
        "model": model
      }
    };
    axiosInstance(url, "post", {}, requestBody)
      .then(res => {
        dispatch(changePermissionSuccess());
        if(action === "give-model-permission") {
          Toast.fire("Success", "Permission successfully granted", "success");
        }
        else {
          Toast.fire("Success", "Permission successfully removed", "success");
        }
        dispatch(getPermissions(portalSlug, userId));
      })
      .catch(err => {
        dispatch(changePermissionFail());
        if(action === "give-model-permission") {
          Toast.fire("Error", "Permission granting failed!", "error");
        }
        else {
          Toast.fire("Error", "Permission removal failed!", "error");
        }

      })
  }
};

export const clearPermissionState = () => {
  return {
    type: permissionsActionTypes.CLEAR_USER_PERMISSIONS
  }
};

export const changeObjectPermissions = (portalSlug, userId: string, requestBody: object, action: string) => {
  return dispatch => {
    dispatch({type: permissionsActionTypes.CHANGE_OBJ_PERMISSIONS_START});
    axiosInstance(portalSlug + "/users/" + action + "/" + userId, "post", {},requestBody)
      .then(res=>{
        dispatch({type: permissionsActionTypes.CHANGE_OBJ_PERMISSIONS_SUCCESS});
      })
      .catch(err=> {
        dispatch({type:permissionsActionTypes.CHANGE_OBJ_PERMISSIONS_FAIL});
      })
  }
};
