import * as actionTypes from '../actions/actionTypes/projectActionTypes'
import {updateObject} from "../utility";

const InitialState = {
  success: false,
  loading: false,
  slug: "",
  name: "",
  show: true,
  layerOpacity: 0,
  camOrientation: {},
  camLocation: {},
  errors: {},
  tiles_path: "",
  camera_path: "",
  project_list: [],
  get_project_list_success: false,
  get_project_list_loading: false,
  get_project_list_errors: [],
  updateProjectLoading: false,
  updateProjectSuccess: false,
  updateProjectErrors: false,
  getProjectLoading: false,
  getProjectSuccess: false,
  getProjectErrors: false,
  selectedProject: [],
  projectSelected: null,
};

const get_project_list_start = (state, action) => {
  return updateObject(state, {
    get_project_list_loading: true,
  })
};

const get_project_list_success = (state, action) => {
  return updateObject(state, {
    get_project_list_loading: false,
    get_project_list_success: action.success,
    project_list: action.project_list,
    get_project_list_errors: [],
  })
};
const get_project_list_fail = (state, action) => {
  return updateObject(state, {
    get_project_list_loading: false,
    get_project_list_success: action.success,
    portal_list: [],
    get_project_list_errors: action.errors,
  })
};

const create_project_success = (state, action) => {
  return updateObject(state, {
    success: action.success,
    loading: false,
    slug: action.slug,
    name: action.name,
    show: action.show,
    layerOpacity: action.layerOpacity,
    camOrientation: action.camOrientation,
    camLocation: action.camLocation,
    dataSets: action.dataSets,
    errors: {},
  })
};
const create_project_failure = (state, action) => {
  return updateObject(state, {
    errors: action.errors,
    loading: false,
  })
};

const reducer = (state = InitialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_PROJECT_SUCCESS:
      return create_project_success(state, action);

    case actionTypes.CREATE_PROJECT_FAILURE:
      return create_project_failure(state, action);

    case actionTypes.CREATE_PROJECT_START:
      return {...state, loading: true,};

    case actionTypes.GET_PROJECT_LIST_START:
      return get_project_list_start(state, action);

    case actionTypes.GET_PROJECT_LIST_SUCCESS:
      return get_project_list_success(state, action);

    case actionTypes.GET_PROJECT_LIST_FAIL:
      return get_project_list_fail(state, action);

    case actionTypes.CLEAR_PROJECT_STATE:
      return updateObject(state, InitialState);

    case actionTypes.UPDATE_PROJECT_START:
      return updateObject(state, {updateProjectLoading: true});

    case actionTypes.UPDATE_PROJECT_SUCCESS:
      return updateObject(state, {updateProjectLoading: false, updateProjectSuccess: true});

    case actionTypes.UPDATE_PROJECT_FAIL:
      return updateObject(state, {updateProjectLoading: false, updateProjectErrors: true});

    case actionTypes.GET_PROJECT_START:
      return updateObject(state, {getProjectLoading: true});
    case actionTypes.GET_PROJECT_SUCCESS:
      return updateObject(state, {getProjectLoading: false, getProjectSuccess: true, selectedProject: action.project});
    case actionTypes.GET_PROJECT_FAIL:
      return updateObject(state, {getProjectLoading: false, getProjectErrors: true});
    case actionTypes.SET_PROJET_SELECTED:
      return updateObject(state, {projectSelected: action.project});
    case actionTypes.CLEAR_PROJET_SELECTED:
      return updateObject(state, {projectSelected: null});
    default:
      return state;
  }
};

export default reducer;
