import React, {Component} from "react";
import Signup from "../Auth/Signup";
import {connect} from "react-redux";
import * as actions from "../../store/actions";
import * as _ from "lodash";
import {CreatePortalUsersProps} from "../../interfaces/portal";

class CreatePortalUsers extends Component<CreatePortalUsersProps> {
  state = {
    portalSlug: _.get(this.props.match.params, "portalSlug")
  };
  componentWillUnmount(): void {
    this.props.onClearSignupState();
    this.props.onClearProjectState();
  }

  componentDidMount(): void {
    this.props.onTryFetchProjectList(_.get(this.state, "portalSlug"));
  }

  render() {
    return (
      <>
        <Signup buttonText="Add User" title="Create Portal User"
                url={`${_.get(this.state, "portalSlug")}/users/register`} authenticatedRequest={true}/>
      </>
    )

  }
}

const mapStateToProps = (state) => {
  return {
    portal_name: state.portal.portalName,
    projects: state.project.project_list,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClearSignupState: () => dispatch(actions.clearSignupState()),
    onTryFetchProjectList: (portal_name) => dispatch(actions.get_project_list(portal_name)),
    onClearProjectState: () => dispatch(actions.clear_project_state())
  }
};

export default connect(null, mapDispatchToProps)(CreatePortalUsers);
