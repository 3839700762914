import React, { useEffect } from 'react';
import './App.css'
import {BrowserRouter} from 'react-router-dom';
import Layout from './pages/Layout'
import Modal from "react-modal"
import { ADMIN_WINDOW_NAME } from './helpers/utils';
import { updateToken } from './helpers/updateSession';
import { useDispatch } from 'react-redux';


Modal.setAppElement("#root");

const App = () => {
    useEffect(() => {
        window.name = ADMIN_WINDOW_NAME;

        setInterval(() => {
            dispatch(updateToken())
        }, 900000);
        
    }, []);

    const dispatch = useDispatch();
    
    
    return (
        <BrowserRouter>
            <div className="App">
                <Layout />        
            </div>
        </BrowserRouter>
    );
};

export default App;
