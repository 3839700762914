const passwordGenerator = require('generate-password');

export const getRandomPassword = () => {
    return passwordGenerator.generate({
        length: 14,
        numbers: true,
        uppercase: true,
        lowercase: true
    });
};

export const ADMIN_WINDOW_NAME = '_admin';
export const VIEWER_WINDOW_NAME = '_viewer';