import React, {Component} from "react";
import {UserProps, UsersState} from "../../interfaces/user";
import {Col, Container, Row, Table} from "reactstrap";
import {connect} from "react-redux";
import {clearPermissionState, getPermissions} from "../../store/actions/permission"
import * as _ from "lodash";
import ProjectPermissions from "./ProjectPermissions";
import ReactSwitch from "./Switch/Switch";
import Spinner from "../UI/Spinner/Spinner";

class Users extends Component<UserProps, UsersState> {
  constructor(props) {
    super(props);
    this.state = {
      portalSlug: _.get(props.match.params, "portal"),
      userId: _.get(props.match.params, "userId")
    };
  };

  componentWillMount(): void {
    // Redux does a shallow comparison and does not rerender on state changes within nested objects
    // to overcome this, I am clearing the state of user permissions in redux here
    this.props.clearUserPermissions();
  }

  componentDidMount(): void {
    setTimeout(() => {
      this.props.getUserPermissions(this.state.portalSlug, this.state.userId)
    }, 0)
  }

  componentWillUnmount(): void {
    this.props.clearUserPermissions();
  }

  render() {
    const {permissions} = this.props;
    let content = null;
    if (this.props.loading) {
      content = <Spinner/>;
    }
    if (this.props.success) {
      content = <>

        {permissions && (Object.keys(permissions)).map((permissionObj, index) => (
          <Container key={index} className="centerForm mb-4">
            <Row>
              <Col>
                <>
                  <h1 className="heading">{permissionObj}</h1>
                  <Table responsive striped bordered>
                    <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {(Object.keys(this.props.permissions[permissionObj])).map((permission, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{permission.toString().split("_").join(" ")}</td>
                        <td>
                          <ReactSwitch
                            portalSlug={this.state.portalSlug}
                            userId={this.state.userId}
                            permissionName={permission}
                            model={permissionObj}
                            checked={permissions[permissionObj][permission]}/>
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  </Table>
                </>
                {/*<Permissions portalSlug={this.state.portalSlug} userId={this.state.userId} permissionTitle={permission} permissions={this.props.permissions[permission]} />*/}
              </Col>
            </Row>
          </Container>

        ))
        }
      </>
    }
    return (
      <>
        <Container className="mb-4">
          <Row>
            <Col>
              <h1 className="heading text-center">Manage permissions of {this.props.userName || "Portal User"}</h1>
            </Col>
          </Row>
        </Container>
        {content}
        <Container className="centerForm mb-4">
          <Row>
            <Col xs={12}>
              <h1 className={"heading"}>Individual Project Permissions of this User</h1>
            </Col>
            <Col>
              <ProjectPermissions userId={this.state.userId} portalSlug={this.state.portalSlug}/>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    permissions: state.userPermissions.permissions,
    loading: state.userPermissions.getPermissionsLoading,
    success: state.userPermissions.getPermissionsSuccess,
    error: state.userPermissions.getPermissionsErrors,
    userName: ownProps.location.state.userName
  }
};


const mapDispatchToProps = dispatch => {
  return {
    getUserPermissions: (portalSlug: string, userId: string) => dispatch(getPermissions(portalSlug, userId)),
    clearUserPermissions: () => dispatch(clearPermissionState())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
