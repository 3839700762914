import _, {get} from "lodash";
import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router";
import {Button, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";
import { IUpdateUserState, IValidation, UpdateUserProps } from "../../interfaces/auth";
import { ManualChangeEvent } from "../../interfaces/forms";
import Spinner from '../UI/Spinner/Spinner';
import { IUser } from "../../interfaces/user";
import { getPortalUserDetails } from "../../store/actions/portalUserDetails";
import { clearUpdateUserState, updateUser } from "../../store/actions/signup";
import ErrorDisplayer from "../UI/ErrorHandling/ErrorDisplayer";
import ChangeSecretQuestionComponent from "../common/password-change/secretQuestion-change.component";
import UserPermissions from "./Permissions";

class UpdateUser extends Component<UpdateUserProps, IUpdateUserState> {
  constructor(props: UpdateUserProps) {
    super(props);
    const _portalSlug = _.get(this.props.match.params, 'portal');

    this.state = {
      portalSlug: _portalSlug ? _portalSlug : null,
      canUpdateSecretQuestion: false, 
      UpdateSecretQuestionModal: false,
      controls: {
        email: {
          errorMessage: 'Valid email is required',
          value: '',
          valid: false,
          touched: false,
          validation: {
            required: false,
            isEmail: true
          },
        },
        first_name: {
          errorMessage: 'First Name is required',
          value: '',
          valid: false,
          touched: false,
          validation: {
            required: false,
          },
        },
        last_name: {
          errorMessage: 'Last Name is required',
          value: '',
          valid: false,
          touched: false,
          validation: {
            required: false,
          },
        },
      },
      portalUserDetails: {
        success: false,
        loading: true,
      }
    }
  }

  componentDidMount() {
    const userId = _.get(this.props.match.params, "userId");
    this.setState({canUpdateSecretQuestion: this.canUpdateSecretQuestion(userId)});
    this.loadUser(userId);
  }

  componentDidUpdate(prevProps: UpdateUserProps) {
    if (!this.props.loading && prevProps.loading) {
      const userId = _.get(this.props.match.params, "userId");
      this.reInitializeForm();
    }
  }

  checkValidity(value: string, rules: IValidation) {
    let isValid = true;

    if (!rules) {
      return true;
    }

    if (rules.required) {
      isValid = value.trim() !== '' && isValid;
    }

    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid
    }

    if (rules.isEmail) {
      const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}$/
      isValid = pattern.test(value) && isValid
    }

    if (rules.shouldMatchPassword) {
      isValid = value === this.state.controls.password.value && isValid
    }

    if (rules.shouldMatchPassword) {
      isValid = value === this.state.controls.password.value && isValid
    }

    return isValid;
  }

  goBack = () => {
    const portalSlug = _.get(this.props.match.params, 'portalSlug') || _.get(this.props.match.params, 'portal');
    this.props.history.push(`/details/${portalSlug}`);
  };

  public inputChangedHandler = async (event: React.ChangeEvent<HTMLInputElement> | ManualChangeEvent, controlName: string, onComplete?: () => void) => {
    const updatedControls = {
      ...this.state.controls,
      [controlName]: {
        ...this.state.controls[controlName],
        value: event.target.value,
        valid: this.checkValidity(event.target.value, this.state.controls[controlName].validation),
        touched: true
      }
    };
    this.setState({controls: updatedControls}, onComplete);
  }

  canUpdateSecretQuestion = (userId) =>{
    if (this.props.loggedInUser.id == userId || this.props.loggedInUser.isSuperUser == true){
      return true;
    }
    return false;
  } 

  componentWillUnmount() {
    this.props.clearUpdateState();
  }

  onSubmitHandler = (e: any) => {
    e.preventDefault();

    const authData = {
      email:      this.state.controls.email.value.toLowerCase(),
      first_name: this.state.controls.first_name.value,
      last_name:  this.state.controls.last_name.value,
    };

    const userId = _.get(this.props.match.params, "userId");
    this.props.onUpdate(this.state.portalSlug, userId, authData.email, authData.first_name, authData.last_name);
  }

  async loadUser(userId) {
    const portalUserDetails = await getPortalUserDetails(userId, this.state.portalSlug);

    if (portalUserDetails.success) {
      this.setState({ portalUserDetails: { loading: false, success: true, userDetails: portalUserDetails.data } }, () => this.initializeFormValues(portalUserDetails.data));
    } else {
      this.setState({ portalUserDetails: { loading: false, success: false, error: portalUserDetails.data } });
    }
  }


  HandleUserSecretQuestionChange = async () => {
    this.setState({UpdateSecretQuestionModal: true});
  };


  closeSetSecretQuestionModal = () => {
    this.setState({ UpdateSecretQuestionModal: false});
    const userId = _.get(this.props.match.params, "userId");
    this.loadUser(userId);
  };

  initializeFormValues(user: IUser) {
    const controls = this.state.controls;
    controls.email.value = user.email;
    controls.email.valid = this.checkValidity(user.email, this.state.controls.email.validation);
    controls.email.touched = false;
    controls.first_name.value = user.firstName;
    controls.first_name.valid = this.checkValidity(user.firstName, this.state.controls.first_name.validation);
    controls.first_name.touched = false;
    controls.last_name.value = user.lastName;
    controls.last_name.valid = this.checkValidity(user.lastName, this.state.controls.last_name.validation);
    controls.last_name.touched = false;
    this.setState({controls});
  }

  reInitializeForm() {
    const controls = this.state.controls;
    for (const controlName of Object.keys(controls)) {
      controls[controlName].valid = this.checkValidity(controls[controlName].value, controls[controlName].validation);
      controls[controlName].touched = false;
    }
    this.setState({controls});
  }

  render() {
    let emailErrorMessage = null;
    if (this.props.errors) {
      if (this.props.errors.email) {
        emailErrorMessage = <p style={{color: "red"}}>{this.props.errors.email}</p>
      }
    }

    const formElementsArray = [];
    for (let key in this.state.controls) {
      formElementsArray.push({
        id: key,
        config: this.state.controls[key]
      });
    }

    const controls = this.state.controls;

    let form = (
      <div className="mx-5">
        <FormGroup>
          <Label className="labelText" for="first_name">First Name</Label>
          <Input
            className="inputItem custom-width"
            placeholder="First Name"
            value={controls.first_name.value}
            type="text"
            valid={controls.first_name.valid && controls.first_name.touched}
            invalid={!controls.first_name.valid && controls.first_name.touched}
            onChange={(event) => this.inputChangedHandler(event, "first_name")}/>
            <ErrorDisplayer errorObj={this.props.errors} keyName="first_name"/>


        </FormGroup>
      
        <FormGroup>
          <Label className="labelText" for="first_name">Last Name</Label>
          <Input
            className="inputItem custom-width"
            placeholder="Last Name"
            value={controls.last_name.value}
            type="text"
            valid={controls.last_name.valid && controls.last_name.touched}
            invalid={!controls.last_name.valid && controls.last_name.touched}
            onChange={(event) => this.inputChangedHandler(event, "last_name")}
          />
          <ErrorDisplayer errorObj={this.props.errors} keyName="last_name"/>

        </FormGroup>
      
        <FormGroup>
          <Label className="labelText" for="first_name">Email</Label>
          <Input
            className="inputItem custom-width"
            placeholder="Email"
            value={controls.email.value}
            type="email"
            valid={controls.email.valid && controls.email.touched}
            invalid={(!controls.email.valid && controls.email.touched) || (emailErrorMessage !== null)}
            onChange={(event) => this.inputChangedHandler(event, "email")}
          />
          <p style={{color: "red"}}>{emailErrorMessage}</p>
        </FormGroup>

        <Row className="mx-auto register-button-wrapper">
          <Col className={"text-center"}>
            <Row className="px-2 d-flex justify-content-center">
              <Button
                  onClick={(event) => this.onSubmitHandler(event)}
                  disabled={!((this.state.controls.email.valid &&
                      this.state.controls.first_name.valid &&
                      this.state.controls.last_name.valid
                    ) && (this.state.controls.email.touched || this.state.controls.first_name.touched || this.state.controls.last_name.touched))
                  }
                  size="md"
                  color="success" >
                Update
              </Button>
             
            </Row>
          </Col>
        </Row>

        {
          this.state.canUpdateSecretQuestion && 
          <Row className="mx-auto mt-2 register-button-wrapper">
          <Col className={"text-center"}>
            <Row className="px-2 d-flex justify-content-center">
            <Button
               size="md"
               color="success" 
               outline
              onClick={() => this.HandleUserSecretQuestionChange()}

               >Update Security Question</Button>
             
            </Row>
          </Col>
        </Row>
        }

      </div>

    )

    let content = null;
    let authRedirect = null;

    if (this.props.success && !window.location.pathname.includes("portaluser")) {
      authRedirect = <Redirect to={`/details/${this.state.portalSlug}`}/>
    }

    if (this.props.loading || this.state.portalUserDetails.loading) {
      content = <Spinner/>
    } else {
      content = (
        <Row>
          <Col sm={12} md={8} lg={7} className="centerForm">
            {this.state.UpdateSecretQuestionModal
                ? <ChangeSecretQuestionComponent email={this.state.controls.email.value}
                                           secretQuestion={this.state.portalUserDetails.userDetails.secretQuestion}
                                           modalOpen={this.state.UpdateSecretQuestionModal}
                                           toggleModal={this.closeSetSecretQuestionModal}/>
                : null}
            <Row>
              <Col>
                <h1 className="heading">Update User</h1><br/>
              </Col>
            </Row>
            <Form>
              {authRedirect}
              {form}
            </Form>
          </Col>
          <Container>
            <Row>
              <UserPermissions userId={get(this.props.match.params, "userId")}/>
              <div className='d-flex align-items-center justify-content-center w-100 mt-4'>
                <Button color="secondary" onClick={() => { this.goBack() }}>
                  Back
                </Button>
              </div>
            </Row>
          </Container>
        </Row>
      );
    }

    return content;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdate: (portalSlug: string, userId: string, email: string, first_name: string, last_name: string) => {
      dispatch(updateUser(portalSlug, userId, email, first_name, last_name));
    },
    clearUpdateState: () => {
      dispatch(clearUpdateUserState())
    },
  }
}

const mapStateToProps = (state) => {
  return {
    errors: state.updateUser.errors,
    loading: state.updateUser.loading,
    message: state.updateUser.message,
    success: state.updateUser.success,
    loggedInUser: state.login.loggedInUser
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpdateUser));