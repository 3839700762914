import {IPortalReducer} from "../store/types";

export function generatePortalSpecificUrl(portal: IPortalReducer | null, url: string) {
  if (!portal) {
    return url;
  } else {
    if (portal.portalType === "subdomain") {
      return url;
    } else if (portal.slug) {
      return "/" + portal.slug + url;
    } else if (portal.portalName) {
      return "/" + portal.portalName + url;
    } else {
      return url;
    }
  }
}

export function generateVersionUrl(baseUrl: string, version: string | null, url: string) {
  if (version) {
      // if(process.env.REACT_APP_PROD_VERSION) {
      //     url = baseUrl + 'v/' + version + url;
      // } else {
      url = baseUrl + version + url;
      //}
  } else {
    url = baseUrl + url;
  }
  return url;
}
