import {IUserGroups} from "../../interfaces/groups";
import {updateObject} from "../utility";
import * as PortalGroupActionTypes from "../actions/actionTypes/groupActionTypes";
import LocalStorageWrapped from "../../LocalStorageWrapped";


let initialState: IUserGroups = {
  groups: [],
  error: '',
  loading: false,
  success: false
};

const getUserGroupsStart = (state) => {
  return updateObject(state, {loading: true});
};

const getUserGroupsSuccess = (state, action) => {
  LocalStorageWrapped.setItem("userGroups", action.groups);
  return updateObject(state, {loading: false, success: true, groups: action.groups});
};

const getUserGroupsFail = (state, action) => {
  return updateObject(state, {loading: false, success: false, error: action.error});
};

const reducer = (state: IUserGroups = initialState, action) => {
  switch (action.type) {
    case PortalGroupActionTypes.GET_USER_GROUPS_START:
      return getUserGroupsStart(state);
    case PortalGroupActionTypes.GET_USER_GROUPS_SUCCESS:
      return getUserGroupsSuccess(state, action);
    case PortalGroupActionTypes.GET_USER_GROUPS_FAIL:
      return getUserGroupsFail(state, action);
    default:
      return state;
  }
};

export default reducer;
