import React, { useEffect, useState } from "react";

export default function ErrorDisplayer({errorObj, keyName}) {
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(()=>{
    if (errorObj?.errors?.length) {
      const fieldErrors = errorObj.errors.filter((err) => err.param === keyName);
      
      setErrorMessage(fieldErrors.length ? fieldErrors[0].msg : "");
    } else {
      setErrorMessage(null);
    }
  },[errorObj])
  
  return (
    <div>
      {errorMessage && <div style={{color: "#ff0000"}}> {errorMessage} </div>}
    </div>
  );
}