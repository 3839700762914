import React from "react";
import {connect} from "react-redux";
import {UserPermissionsProps, UserPermissionsState} from "../../interfaces/permissions";
import {Col} from "reactstrap";
import "./permissions.css";
import {Toast} from "../../SWAL";
import ModelLevelPermissions from "./ModelLevelPermissions";
import _ from "lodash";
import ObjectLevelPermissions from "./ObjectLevelPermissions";
import { getPortalUserPermissions } from "../../store/actions/portalUserPermissions";
import { getPortalUserDetails } from "../../store/actions/portalUserDetails";
import { setSelectedPortal } from "../../store/actions/portal";


class UserPermissions extends React.Component<UserPermissionsProps, UserPermissionsState> {

  constructor(props) {
    super(props);

    this.state = {
      portalUserDetails: {
        success: false,
        loading: true,
      }
    }
  }

  componentDidMount(): void {
    if (!this.props.portal) {
      this.props.setSelectedPortal();
    } else {
      this.loadPermissions(this.props.userId);
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.portal && this.props.portal) {
      this.loadPermissions(this.props.userId);
    }
  }

  async loadPermissions(userId) {
    const portalUserDetails = await getPortalUserDetails(userId, this.props.portal?.slug.toString());

    const userPortal = portalUserDetails.data ? _.find(portalUserDetails.data.portals, (portal) => portal.portal.toString() === this.props.portal?.id.toString()) : null;

    const portalUserPermissions = await getPortalUserPermissions(this.props.portal?.slug.toString(), userId, userPortal?.isPortalAdmin ?? false);
    if (portalUserDetails.success && portalUserPermissions.success) {
      this.setState({ portalUserDetails: { loading: false, success: true, permissions: portalUserPermissions.data, userDetails: portalUserDetails.data } });
    } else {
      this.setState({ portalUserDetails: { loading: false, success: false, error: portalUserPermissions.data || portalUserDetails.data } });
    }
  }

  render() {
    const userPortal = this.state.portalUserDetails?.userDetails ? _.find(this.state.portalUserDetails.userDetails.portals, (portal) => portal.portal.toString() === this.props.portal?.id.toString()) : null;
    if (!this.state.portalUserDetails.success && this.state.portalUserDetails?.error?.length > 0) {
      Toast.fire({ title:"Failed", titleText:this.state.portalUserDetails.error, icon:"error"});
    }
    return (
      <Col className="centerForm" lg={12}>
        {
          this.state.portalUserDetails.success && !this.state.portalUserDetails.loading && (
            <>
              <ModelLevelPermissions isPortalAdmin={userPortal?.isPortalAdmin} portalSlug={this.props.portal?.slug} userId={this.props.userId} portalUserDetails={this.state.portalUserDetails} />
              {
                !userPortal?.isPortalAdmin && (
                  <ObjectLevelPermissions portalSlug={this.props.portal?.slug} userId={this.props.userId} portalUserDetails={this.state.portalUserDetails} />
                )
              }
            </>
          )
        }
      </Col>
    );
  }
}
const mapStateToProps = state => {
  return {
    loggedInUser: state.login.loggedInUser,
    portal: state.portalListReducer.selectedPortal
  }
};

const mapDispatchToProps = dispatch => {
  return {
    setSelectedPortal: () => dispatch(setSelectedPortal()),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPermissions);
