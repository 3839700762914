import React from "react";
import "./Spinner.module.css";
import classes from "./Spinner.module.css";

const Spinner = () => {
  return (
    <div className={classes.loader}>Loading...</div>
  );
};
export default Spinner;
