import React, {Component} from 'react';
import {FormGroup, Input, Form, Label, Alert, Button} from 'reactstrap';
import './Auth.module.css';
import {changePassword} from '../../store/actions';
import {connect} from 'react-redux';
import {IChangePasswordState, IValidation, ForgotPasswordProps} from "../../interfaces/auth";
import LocalStorageWrapped from "../../LocalStorageWrapped";
import { getRandomPassword } from '../../helpers/utils';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { ManualChangeEvent } from '../../interfaces/forms';
import {axiosInstance} from "../../Axios";
import * as _ from "lodash";

class ForgotPassword extends Component<ForgotPasswordProps, IChangePasswordState> {

  constructor(props: ForgotPasswordProps) {
    super(props);
    this.state = {
      controls: {
        old_password: {
          value: '',
          valid: false,
          touched: false,
          validation: {
            required: true,
            minLength: 8,
          }
        },

        new_password: {
          value: '',
          valid: false,
          touched: false,
          validation: {
            required: true,
            minLength: 8,
          },
        },

        confirm_password: {
          value: '',
          valid: false,
          touched: false,
          validation: {
            required: true,
            minLength: 8,
            shouldMatchNewPassword: true,
          },
        },
      },
      passwordMissMatchError: "",
      copyFlag: false,
      secretQuestionAlert: false
    }
  }

  inputChangedHandler = (event: React.ChangeEvent<HTMLInputElement> | ManualChangeEvent, controlName: string, onComplete?: () => void) => {
    const updatedControls = {
      ...this.state.controls,
      [controlName]: {
        ...this.state.controls[controlName],
        value: event.target.value,
        valid: this.checkValidity(event.target.value, this.state.controls[controlName].validation),
        touched: true,
      },
    };
    this.setState({controls: updatedControls}, onComplete);
  };

  checkValidity(value: string, rules: IValidation) {
    let isValid = true;
    
    if (!rules) {
      return true;
    }

    if (rules.required) {
      isValid = value.trim() !== '' && isValid;
    }

    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid;
    }

    if (rules.isEmail) {
      const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}$/;
      // const pattern = /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/;
      isValid = pattern.test(value) && isValid;
    }

    if (rules.shouldMatchNewPassword) {
      isValid = value === this.state.controls.new_password.value && isValid;
      // const pattern = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
    }

    return isValid;
  }

  submitHandler = (event) => {
    this.setState({passwordMissMatchError: ""});
    event.preventDefault();
    if (this.state.controls.new_password.value !== this.state.controls.confirm_password.value) {
      this.setState({passwordMissMatchError: "New password & Confirm Password don't match"});
      return;
    }
    const _userDetails = LocalStorageWrapped.getItem('userInfo');
    this.props.onChangePassword(
      _userDetails.email,
      this.state.controls.old_password.value,
      this.state.controls.new_password.value,
      this.state.controls.confirm_password.value
    );
  };

  generatePassword = () => {
      const _password : any = getRandomPassword();
      return _password;
  };

  onCopy = (text: string, result) => {
    if (result) {
      this.setState({copyFlag: true });
      this.inputChangedHandler({ target: { value: text } }, 'new_password', () => {
        this.inputChangedHandler({ target: { value: text } }, 'confirm_password');
      });
    }
  };

  componentWillUnmount() {
    this.setState({copyFlag: false });
  }

  componentDidMount() {
    this.checkSecretQuestion();
  }

  checkSecretQuestion() {
    const _userDetails = LocalStorageWrapped.getItem('userInfo');
    const userEmail = _userDetails.email;
    axiosInstance(
          "admin/check-secret-question",
          "post",
          {
            "Content-Type": "application/json",
          },
        {userEmail}
      ).then((response) => {
        let res = _.get(response, "data");
        if(res.success){
          this.setState({secretQuestionAlert:true});
        }
      });
  }



  render() {

    let old_password_error_message = "";
    if (this.props.errors && this.props.errors.old_password) {
      old_password_error_message = this.props.errors.old_password;
    }
    let form = (
      <Form>
        <FormGroup>
          {
            this.state.secretQuestionAlert ?
                <div> <Alert color="danger"> Kindly set your Security Question</Alert>
                </div>
                :<></>
          }
          <h1 className="heading">Change your password</h1>
          {this.state.copyFlag ? <p style={{color: 'red'}}> Password Generated and copy to clipboard. </p> : null}
          <Label className="labelText">Old Password</Label>
          <Input
            value={this.state.controls.old_password.value}
            valid={this.state.controls.old_password.valid && this.state.controls.old_password.touched}
            invalid={!this.state.controls.old_password.valid && this.state.controls.old_password.touched}
            onChange={(event) => this.inputChangedHandler(event, "old_password")}
            className="inputItem"
            type="password"
            placeholder="Old Password"
          />
          <p style={{color: "red"}}>{old_password_error_message}</p>
          <Label className="labelText">New Password</Label>
          <Input
            value={this.state.controls.new_password.value}
            valid={this.state.controls.new_password.valid && this.state.controls.new_password.touched}
            invalid={!this.state.controls.new_password.valid && this.state.controls.new_password.touched}
            onChange={(event) => this.inputChangedHandler(event, "new_password")}
            className="inputItem"
            type="password"
            placeholder="New Password"
          />
          <p/>
          <Label className="labelText">Confirm New Password</Label>
          <Input
            value={this.state.controls.confirm_password.value}
            valid={this.state.controls.confirm_password.valid && this.state.controls.confirm_password.touched}
            invalid={!this.state.controls.confirm_password.valid && this.state.controls.confirm_password.touched}
            onChange={(event) => this.inputChangedHandler(event, "confirm_password")}
            className="inputItem"
            type="password"
            placeholder="Confirm Password"
          />
          <p style={{color: "red"}}>
            {this.state.passwordMissMatchError}
          </p>
          <Button
            disabled={!(this.state.controls.old_password.valid && this.state.controls.old_password.touched &&
              this.state.controls.new_password.valid && this.state.controls.new_password.touched &&
              this.state.controls.confirm_password.valid && this.state.controls.confirm_password.touched)}
            onClick={(event) => this.submitHandler(event)}
            color="info"
          >Submit
          </Button>
          <CopyToClipboard text={this.generatePassword()}
                           onCopy={this.onCopy}>
              <Button className={"ml-3"}
                      color="secondary">Generate password</Button>
          </CopyToClipboard>
        </FormGroup>
      </Form>

    );

    return (
      <div style={{width: "60%"}} className="centerForm">
        {/*/!* tslint:disable-next-line:jsx-no-multiline-js *!/*/}
        {this.props.changePasswordSuccess ?
          <Alert color="success">
            Password Changed Successfully
          </Alert>
          :
          <div>
            {form}
          </div>
        }

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    email: state.login.email,
    token: state.login.token,
    authRedirectPath: state.login.authRedirectPath,
    changePasswordSuccess: state.login.changePasswordSuccess,
    errors: state.login.errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangePassword: (email: string, old_password: string, new_password: string, confirm_password: string,) => {
      dispatch(changePassword(email, old_password, new_password, confirm_password));
    }

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
