import React from "react";
import {connect} from "react-redux";
import * as _ from "lodash";
import {IGroup, PortalGroupProps, PortalGroupState} from "../../interfaces/groups";
import {changeGroupState, getPortalGroups} from "../../store/actions/portalGroups";
import {Button, Table} from "reactstrap";
import {Confirm, Toast} from "../../SWAL";
import {NavLink} from "react-router-dom";

class PortalGroups extends React.Component<PortalGroupProps, PortalGroupState> {
  constructor(props) {
    super(props);
    this.state = {
      portal: _.get(props.match.params, "portalSlug")
    }
  }
  componentDidMount(): void {
    this.props.getPortalGroups(this.state.portal);
  }

  async handleGroupStateChange(group: IGroup) {
    let portal: string = this.state.portal;
    let groupId: string = group.id;
    let action: "enable" | "disable" = group.isEnabled? "disable": "enable";
    Confirm(`Do you really want to ${action} ${group.name}?`, `Yes, ${action}`)
      .then(async result => {
        if (result.value) {
          await this.props.changeGroupState(portal, groupId, action);
          if (this.props.success) {
            Toast.fire({
              title:"Success",
              titleText: this.props.message,
              icon: "success"
            });
          } else {
            Toast.fire({
              title:"Error",
              titleText: this.props.error,
              icon: "error"
            });
          }
          this.props.getPortalGroups(this.state.portal);
        }
      })
  }

  render() {
    return (
      <div>
        <h1 className="heading">Portal User Groups</h1>
        <Table responsive striped bordered>
          <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Actions</th>
          </tr>
          </thead>
          <tbody>
          { this.props.groups && this.props.groups.length > 0 ? this.props.groups.map((item, index) =>
              <tr key={index}>
                <th>{index + 1}</th>
                <td>
                  <NavLink to={`/${this.state.portal}/group/${item.id}`}>{item.name}</NavLink>
                </td>
                <td>
                  <Button onClick={_ => this.handleGroupStateChange(item)} className={"ml-3"} outline
                          color={item.isEnabled ? "danger" : "success"}>
                    {item.isEnabled ? "Disable" : "Enable"}
                  </Button>
                </td>
              </tr>
            ) : null
          }
          </tbody>
        </Table>
        <NavLink to={`/${this.state.portal}/group/create`}>
          <Button outline color="success">Add New Group</Button>
        </NavLink>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    groups: state.portalGroups.groups,
    loggedInUser: state.login.loggedInUser,
    success: state.portalGroups.success,
    message: state.portalGroups.message,
    error: state.portalGroups.error,
    portal: state.portalListReducer.portal
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getPortalGroups: (portal: string) => dispatch(getPortalGroups(portal)),
    changeGroupState: (portal: string, groupId: string, action: "enable" | "disable") => dispatch(changeGroupState(
      portal, groupId, action))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(PortalGroups);
