import {FlightPath, KML, MODEL3D, Tiles3D, POINTS, Ortho, PANO} from "../interfaces/dataset";

export const restoreDatasetInitialState = () => {
    try {
        const _datasetType = [Tiles3D, KML, MODEL3D, FlightPath, POINTS, Ortho, PANO];
        for(let _ds of _datasetType) {
            Object.keys(_ds).map((key, index) => {
                _ds[key].value = '';
                _ds[key].valid = false;
                _ds[key].touched = false;
            });
        }
    } catch (e) {
        console.log(e);
    }
};

export const setDatasetInitialState = (ds: any) => {
    try {
        Object.keys(ds).map((key, index) => {
            ds[key].value = '';
            ds[key].valid = false;
            ds[key].touched = false;
        });
        return ds;
    } catch (e) {
        return {};
    }
};

export const initializeDownloadLinks = () => {
    return {
        "index": 0,
        "label": '',
        "URL": '',
        "format": '',
        "sizeMB": 0
    };
};

export const initializePanoParams = () => {
    return {
        "index": -1,
        "lat": '',
        "lon": '',
        "height": '',
        "URL": 'https://',
        "Colour": 'Yellow',
        "style": '0',
    };
};

export const initializeVideoParams = () => {
    return {
        "index": -1,
        "lat": '',
        "lon": '',
        "height": '',
        "URL": 'https://',
        "is360": false,
    };
};
