import * as permissionsActionTypes from "../actions/actionTypes/permissionsActionTypes";
import {updateObject} from "../utility";

const initialState = {
  getPermissionsLoading: false,
  getPermissionsSuccess: false,
  getPermissionsErrors: false,
  changePermissionLoading: false,
  changePermissionErrors: false,
  changePermissionSuccess: false,
  permissions: [],
  message: ""
};

const getPermissionSuccess = (state, action) => {
  return updateObject(state, {
    getPermissionsLoading: false,
    getPermissionsSuccess: true,
    permissions: action.permissions
  })
};

const getPermissionFail = (state, action) => {
  return updateObject(state, {
    getPermissionsLoading: false,
    getPermissionsErrors: true,
  })
};


export const reducer = (state=initialState, action) => {
  switch (action.type) {
    case permissionsActionTypes.GET_USER_PERMISSION_START:
      return updateObject(state, {
        getPermissionsLoading: true
      });
    case permissionsActionTypes.GET_USER_PERMISSIONS_SUCCESS:
      return getPermissionSuccess(state, action);
    case permissionsActionTypes.GET_USER_PERMISSIONS_FAIL:
      return getPermissionFail(state, action);
    case permissionsActionTypes.CHANGE_PERMISSION_START:
      return updateObject(state,  {
        changePermissionLoading: true,
      });
    case permissionsActionTypes.CHANGE_PERMISSION_SUCCESS:
      return updateObject(state, {
        changePermissionsLoading: false,
        changePermissionSuccess: true,
      });
    case permissionsActionTypes.CHANGE_PERMISSION_FAIL:
      return updateObject(state, {
        changePermissionsLoading: false,
        changePermissionErrors: true,
      });
    case permissionsActionTypes.CLEAR_USER_PERMISSIONS:
      return updateObject(state, initialState);
    default:
      return state;
  }
};

export default reducer;
