import React, { Component } from 'react';
import { FormGroup, Input, Form, Label, Alert, Button, Row, Col} from 'reactstrap';
import { IChangePasswordState, IValidation, SetPasswordPageProps } from "../../interfaces/auth";
import {SecretQuestionOptionTypes} from "../../interfaces/user";
import { axiosInstance } from "../../Axios";
import * as _ from "lodash";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ManualChangeEvent } from '../../interfaces/forms';
import { Link, withRouter } from 'react-router-dom';
import { getRandomPassword } from '../../helpers/utils';
import { connect } from 'react-redux';
import { logout } from '../../store/actions';


class SetPassword extends Component<SetPasswordPageProps, IChangePasswordState> {

    constructor(props: SetPasswordPageProps) {
        super(props);
        this.state = {
            controls: {
                new_password: {
                    value: '',
                    valid: false,
                    touched: false,
                    validation: {
                        required: true,
                        minLength: 8,
                    },
                },
                confirm_password: {
                    value: '',  
                    valid: false,
                    touched: false,
                    validation: {
                        required: true,
                        minLength: 8,
                        shouldMatchNewPassword: true,
                    },
                },

                selected_secret_question:{
                    value: '',
                    valid: false,
                    touched: false,
                    validation: {
                        required: true,
                    },

                },

                answer:{
                    value: '',
                    valid: false,
                    touched: false,
                    validation:{
                        required: true,
                    }
                }
            },
            passwordMissMatchError: '',
            passwordChangeSuccess: false,
            loadingState: false,
            copyFlag: false,
            secretQuestion: [],
            secretQuestionError: ''
        }
    }

    generatePassword = () => {
        const _password: any = getRandomPassword();
        return _password;
    };


    getSecretQuestions = () => {
        
        const _url = 'users/secret-questions';
        const _headers = { 'Content-Type': 'application/json' };

        axiosInstance(_url, 'get', _headers)
            .then(response => {
                let res = _.get(response, "data");
                if (res.success) {
                    this.setState({ secretQuestion: res.result});
                }
            }).catch(err => {
                this.setState({ secretQuestionError: err.response.data.message, loadingState: false });
            });
   
    }

    inputChangedHandler = (event: React.ChangeEvent<HTMLInputElement> | ManualChangeEvent, controlName: string, onComplete?: () => void) => {
        const updatedControls = {
            ...this.state.controls,
            [controlName]: {
                ...this.state.controls[controlName],
                value: event.target.value,
                valid: this.checkValidity(event.target.value, this.state.controls[controlName].validation),
                touched: true,
            },
        };
        this.setState({ controls: updatedControls }, onComplete);
    };



    checkValidity(value: string, rules: IValidation) {
        let isValid = true;

        if (!rules) {
            return true;
        }

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid;
        }

        if (rules.isEmail) {
            const pattern = /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/;
            isValid = pattern.test(value) && isValid;
        }

        if (rules.shouldMatchNewPassword) {
            isValid = value === this.state.controls.new_password.value && isValid;
        }

        return isValid;
    }



    submitHandler = (event) => {
        this.setState({ passwordMissMatchError: "" });
        event.preventDefault();
        if (this.state.controls.new_password.value !== this.state.controls.confirm_password.value) {
            this.setState({ passwordMissMatchError: "New password & Confirm Password don't match" });
            return;
        }
        const currentUrl = window.location.href;
        const splitUrl = currentUrl.split('/');
        const _token = splitUrl[splitUrl.length - 1];
        const passwordData = {
            "token": _token,
            "new_password": this.state.controls.new_password.value,
            "confirm_password": this.state.controls.confirm_password.value,
            "secret_question": this.state.controls.selected_secret_question.value,
            "answer": this.state.controls.answer.value, 
        };
        const _url = 'users/set-password';
        const _headers = { 'Content-Type': 'application/json' };
        this.setState({ loadingState: true });

        axiosInstance(_url, 'post', _headers, JSON.stringify(passwordData))
            .then(response => {
                let res = _.get(response, "data");
                if (res.success) {
                    this.setState({ passwordChangeSuccess: true, loadingState: false });
                }
            }).catch(err => {
                this.setState({ passwordMissMatchError: err.response.data.message, loadingState: false });
            });

        return false;
    };

    reInitState = () => {
        this.setState({
            controls: {
                new_password: {
                    value: '',
                    valid: false,
                    touched: false,
                    validation: {
                        required: true,
                        minLength: 8,
                    },
                },
                confirm_password: {
                    value: '',
                    valid: false,
                    touched: false,
                    validation: {
                        required: true,
                        minLength: 8,
                        shouldMatchNewPassword: true,
                    },
                },

                selected_secret_question:{
                    value: '',
                    valid: false,
                    touched: false,
                    validation: {
                        required: true,
                    },

                },

                answer:{
                    value: '',
                    valid: false,
                    touched: false,
                    validation:{
                        required: true,
                    }
                }
            },
            passwordMissMatchError: '',
            passwordChangeSuccess: false,
            loadingState: false,
            copyFlag: false,
        });
    };

    componentDidMount() {
        this.reInitState();
        this.props.onLogout();
        this.getSecretQuestions();
    }

    componentWillUnmount() {
        this.reInitState();
    }

    cancel = () => {
        this.props.history.push('/login');
    }

    onCopy = (text: string, result) => {
        if (result) {
            this.inputChangedHandler({ target: { value: text } }, 'new_password', () => {
                this.inputChangedHandler({ target: { value: text } }, 'confirm_password');
            });
            this.setState({ copyFlag: true });
        }
    };

    secretQuestionInput = () => {
        const selectedSecretQuestion = this.state.secretQuestion.find((sq) => sq.id === this.state.controls.selected_secret_question.value);

        switch (selectedSecretQuestion?.type) {
            case SecretQuestionOptionTypes.STRING:
                return <Input type='text' value={this.state.controls.answer.value} className="inputItem mb-3" onChange={(event) => this.inputChangedHandler(event, "answer")}/>
                break;
            case SecretQuestionOptionTypes.NUMBER:
                return <Input type='number' value={this.state.controls.answer.value} className="inputItem mb-3" onChange={(event) => this.inputChangedHandler(event, "answer")}/>
                break;
            case SecretQuestionOptionTypes.DATE:
                return <Input type='date' value={this.state.controls.answer.value} className="inputItem mb-3" onChange={(event) => this.inputChangedHandler(event, "answer")}/>
        }
    }


    render() {
        let form = (
            <Form>
                <FormGroup >
                    <h1 className="heading"> Set Password </h1>
                    <Label className="labelText">New Password</Label>
                    <Input
                        value={this.state.controls.new_password.value}
                        valid={this.state.controls.new_password.valid && this.state.controls.new_password.touched}
                        invalid={!this.state.controls.new_password.valid && this.state.controls.new_password.touched}
                        onChange={(event) => this.inputChangedHandler(event, "new_password")}
                        className="inputItem mb-3"
                        type="password"
                        placeholder="New Password"
                    />
                    <Label className="labelText">Confirm New Password</Label>
                    <Input
                        value={this.state.controls.confirm_password.value}
                        valid={this.state.controls.confirm_password.valid && this.state.controls.confirm_password.touched}
                        invalid={!this.state.controls.confirm_password.valid && this.state.controls.confirm_password.touched}
                        onChange={(event) => this.inputChangedHandler(event, "confirm_password")}
                        className="inputItem mb-3"
                        type="password"
                        placeholder="Confirm Password"
                    />

                    
                    <Label className="labelText">Security Questions</Label><br/>

                    
                    <select name="Secret Questions" id="secQuestions" className="inputItem mb-3 form-control"
                            value={this.state.controls.selected_secret_question.value}
                            onChange={(event) => {
                                this.setState({
                                    controls: {
                                        ...this.state.controls,
                                        selected_secret_question: {
                                            ...this.state.controls.selected_secret_question,
                                            value: event.target.value,
                                            valid: this.checkValidity(event.target.value, this.state.controls.selected_secret_question.validation),
                                            touched: true,
                                        },
                                        answer: { ...this.state.controls.answer, value: '', touched: false, valid: false }
                                    }
                                });
                            }
                    }>

                        <option>Select a question...</option>

                        {this.state.secretQuestion.map((question)=>{
                            return(
                            <option value={question.id}>{question.question}</option>

                            )
                        })}; 
                    </select>

                    {
                        this.secretQuestionInput()
                    }
                    
                    
                    {this.state.copyFlag ? <span style={{ color: 'orange' }}> Random password filled and copied to clipboard</span> : null}
                    <p style={{ color: "red" }}>
                        {this.state.passwordMissMatchError}
                    </p>

                    <div className='text-right'>
                        <Button disabled={!(this.state.controls.new_password.valid &&
                            this.state.controls.new_password.touched &&
                            this.state.controls.confirm_password.valid &&
                            this.state.controls.confirm_password.touched)}
                            type='submit'
                            onClick={(event) => this.submitHandler(event)}
                            color="info">Submit</Button>
                        <Button className={"ml-3"}
                            color="secondary"
                            onClick={this.cancel}
                        >Cancel</Button>
                        <CopyToClipboard text={this.generatePassword()}
                            onCopy={this.onCopy}>
                            <Button className={"ml-3"}
                                color="secondary"
                                onClick={() => this.generatePassword()}>Generate password</Button>
                        </CopyToClipboard>
                    </div>
                </FormGroup>
            </Form>
        );

        return (
            <div className="mx-5">

                <Row>
                    <Col sm={12} md={8} lg={7} className="centerForm">
                        <Form>
                            {this.state.passwordChangeSuccess ?
                                <div> <Alert color="success"> Password Changed Successfully.</Alert>
                                    <Link color="secondary" to='/login' > Back </Link>
                                </div>
                                :
                                <div> {form} </div>
                            }
                        </Form>
                    </Col>
                </Row>
            </div>
        );
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        onLogout: () => dispatch(logout()),
    };
};


export default withRouter(connect(null, mapDispatchToProps)(SetPassword));
