import React, { useState } from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Input} from 'reactstrap';
import {initializeVideoParams} from '../../../../helpers/clearDataset';

import * as _ from 'lodash';

export function addVideo (that, ds, index) {
    that.setState(
        (prevState: any, props : any) => {
            return { openModalDownloadLinks: false,
                openModalVideo: true,
                selectedDS: index };
        }
    );
};


export function editVideo (that, ds_index, video_index) {
    let _videoItem = _.cloneDeep(that.state.dataSets[ds_index].dataObject.videoArray.value[video_index]);
    _videoItem.index = video_index;
    that.setState(
        (prevState: any, props : any) => {
            return {
                openModalDownloadLinks: false,
                openModalVideo: true,
                videoData: _videoItem,
                selectedDS: ds_index,
                downloadLinkMode: false };
        }
    );
};

export function removeVideo (that, ds, ds_index, video_index) {
    let dataSets = that.state.dataSets;
    dataSets[ds_index].dataObject.videoArray.value.splice(video_index, 1);
    that.setState({dataSets: dataSets,
        videoData: initializeVideoParams(),
        openModalDownloadLinks: false,
        selectedDS: 0
    });
};

interface VideoModalProps {
    className: string;
    videoData: any;
    openModalVideo: boolean;
    submit: any;
    closeModalDownloadLinks: any;
    changeHandler: any;
    changeHandlerCheckbox:any;
}

const VideoModal = (props: VideoModalProps) => {
    const { className, videoData, openModalVideo } = props;
    videoData.is360 = !(videoData.is360 === 'false' || !videoData.is360);
    return (
        <div>
            <Modal isOpen={openModalVideo} toggle={props.closeModalDownloadLinks} className={className}>
                <ModalHeader className='text-center'>Video Pin</ModalHeader>
                <ModalBody>
                    <label> <strong> Latitude (decimal degrees) </strong></label>
                    <Input type={'text'}
                           name={'lat'}
                           onChange={(event) => props.changeHandler(event)}
                           value={videoData.lat} />

                    <label><strong> Longitude (decimal degrees) </strong></label>
                    <Input type={'text'}
                           name={'lon'}
                           onChange={(event) => props.changeHandler(event)}
                           value={videoData.lon} />

                    <label><strong> Height (m)</strong></label>
                    <Input type={'text'}
                           name={'height'}
                           onChange={(event) => props.changeHandler(event)}
                           value={videoData.height} />

                    <label><strong> URL </strong></label>
                    <Input type={'text'}
                           name={'URL'}
                           onChange={(event) => props.changeHandler(event)}
                           value={videoData.URL} />

                    <label><strong> Name </strong></label>
                    <Input type={'text'}
                           name={'name'}
                           onChange={(event) => props.changeHandler(event)}
                           value={videoData.name} />

                    <label><strong> is360 </strong></label>
                    <Input type={'checkbox'}
                           name={'is360'}
                           className={"form-check m-0"}
                           checked={videoData.is360}
                           onChange={(event) => props.changeHandlerCheckbox(event)}
                           value={videoData.is360} />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={props.submit}>Submit</Button>
                    <Button color="secondary" onClick={props.closeModalDownloadLinks}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default VideoModal;
