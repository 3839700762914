import React from 'react'
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'
import {ColourPickerProps, ColourPickerState} from "../../interfaces/colourGrid";

class ColourPicker extends React.Component<ColourPickerProps, ColourPickerState> {

    constructor(props) {
        super(props);
        this.state = {
            displayColorPicker: false,
            colour: this.props.colour? this.props.colour : '#f4f4f6',
        };
    }


    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker });
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };

    handleChange = (event) => {
        this.setState({ colour: event.hex});
        this.props.onChange(event,this.props.index);
    };

    render() {

        const styles = reactCSS({
            'default': {
                color: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                    background: this.state.colour,
                },
                swatch: {
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '2',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });

        return (
            <div>
                <div style={ styles.swatch } onClick={ this.handleClick }>
                    <div style={ styles.color } />
                </div>
                { this.state.displayColorPicker ? <div style={ styles.popover }>
                    <div style={ styles.cover } onClick={ this.handleClose }/>
                    <SketchPicker color={ this.state.colour } onChange={(event)=>{ this.handleChange(event) }}/>
                </div> : null }

            </div>
        )
    }
}

export default ColourPicker