import React, { Component } from 'react';
import { FormGroup, Input, Form, Label, Alert, Button, Modal, ModalBody } from 'reactstrap';
import { AddSubProjectProps, IAddSubProjectState, IValidation } from "../../interfaces/auth";
import { axiosInstance } from "../../Axios";
import * as _ from "lodash";
import { ManualChangeEvent } from '../../interfaces/forms';

class AddSubProject extends Component<AddSubProjectProps, IAddSubProjectState> {

    constructor(props: AddSubProjectProps) {
        super(props);
        this.state = {
            controls: {
                sub_project_name: {
                    value: '',
                    valid: false,
                    touched: false,
                    validation: {
                        required: true,
                    },

                }
            },
            loadingState: false,
            subProjectError: '',
            subProjectSaveSuccess: false,
        }
    }

    inputChangedHandler = (event: React.ChangeEvent<HTMLInputElement> | ManualChangeEvent, controlName: string, onComplete?: () => void) => {
        const updatedControls = {
            ...this.state.controls,
            [controlName]: {
                ...this.state.controls[controlName],
                value: event.target.value,
                valid: this.checkValidity(event.target.value, this.state.controls[controlName].validation),
                touched: true,
            },
        };
        this.setState({ controls: updatedControls }, onComplete);
    };

    checkValidity(value: string, rules: IValidation) {
        let isValid = true;

        if (!rules) {
            return true;
        }

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid;
        }

        if (rules.isEmail) {
            const pattern = /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/;
            isValid = pattern.test(value) && isValid;
        }

        if (rules.shouldMatchNewPassword) {
            isValid = value === this.state.controls.new_password.value && isValid;
        }

        return isValid;
    }

    submitHandler = (event) => {
        this.setState({ subProjectError: "" });
        event.preventDefault();

        if(this.props.projectId && this.state.controls.sub_project_name.value){
            let subProjectName = this.state.controls.sub_project_name.value;

            const _url = `${this.props.portalSlug}/project/${this.props.projectId}/${subProjectName}/add-sub-project`;
            const _headers = { 'Content-Type': 'application/json' };
            this.setState({ loadingState: true });
            axiosInstance(_url, 'post', _headers)
                .then(response => {
                    let res = _.get(response, "data");
                    if (res.success) {
                        this.setState({ subProjectSaveSuccess: true, loadingState: false });
                    }
                }).catch(err => {
                this.setState({ subProjectError: err.response.data.errors, loadingState: false });
            });
        }
    };


    reInitState = () => {
        this.setState({
            controls: {
                selected_secret_question: {
                    value: '',
                    valid: false,
                    touched: false,
                    validation: {
                        required: true,
                    },

                },
            },
            loadingState: false,
            subProjectError: '',
            subProjectSaveSuccess: false,
        });
    };

    componentDidMount() {
        this.reInitState();
    }

    componentWillUnmount() {
        this.reInitState();
    }


    render() {
        let form = (
            <Form>
                <FormGroup>
                    <h1 className="heading"> Add Sub Project</h1>
                    <Label className="labelText">Name</Label>
                    <Input type={"text"} className={"subProjectName"} onChange={(e)=>{
                        this.setState({
                            controls: {
                                ...this.state.controls,
                                sub_project_name: {
                                    ...this.state.controls.sub_project_name,
                                    value: e.target.value,
                                }
                            }
                        });
                    }}>
                    </Input>
                   <div className='text-right' style={{marginTop: "4px"}}>
                        <Button
                                onClick={(event) => this.submitHandler(event)}
                                color="info">Submit
                        </Button>
                        <Button className={"ml-3"}
                                color="secondary"
                                onClick={() => this.props.toggleModal()}>Cancel</Button>
                    </div>
                </FormGroup>
            </Form>
        );

        return (
            <div className="centerForm">
                <Modal isOpen={this.props.modalOpen} toggle={() => this.props.toggleModal()}>
                    <ModalBody>
                        {this.state.subProjectSaveSuccess ?
                            <div> <Alert color="success"> Sub Project Saved Successfully.</Alert>
                                <Button color="secondary" onClick={() => this.props.toggleModal()}>Back</Button>
                            </div> :
                            this.state.subProjectError ?
                                <div> <Alert color="danger"> Sub Project not saved.</Alert>
                                    <Button color="secondary" onClick={() => this.props.toggleModal()}>Back</Button>
                                </div>
                            :
                            <div> {form} </div>
                        }
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default AddSubProject;
