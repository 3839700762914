import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import {Alert, Col, Row, Button, Form, FormGroup, Label, Input, Container} from 'reactstrap';
import Spinner from '../UI/Spinner/Spinner';
import { NavLink, Redirect, withRouter } from 'react-router-dom';
import './Auth.module.css';
import "./Signup.css";
import SocialAuth from './SocialAuth';
import * as actions from '../../store/actions/signupActions';
import { ISignupState, IValidation, SignupProps } from '../../interfaces/auth';
import LocalStorageWrapped from "../../LocalStorageWrapped";
import { MySwal, Toast } from '../../SWAL';
import { generatePortalSpecificUrl } from '../../helpers/urlGenerators';
import { clearSignupState } from '../../store/actions';
import * as _ from "lodash";
import { getRandomPassword } from '../../helpers/utils';
import { ManualChangeEvent } from '../../interfaces/forms';
import { axiosInstance } from '../../Axios';
import ErrorDisplayer from '../UI/ErrorHandling/ErrorDisplayer';
import UserPermissions from "../User/Permissions";

class Signup extends Component<SignupProps, ISignupState> {
  checkEvent;
  constructor(props: SignupProps) {
    super(props);
    const _portalSlug = _.get(this.props.match.params, 'portalSlug');
    this.state = {
      portalSlug: _portalSlug ? _portalSlug : null,
      userExists: false,
      userEnabled: false,
      controls: {
        email: {
          errorMessage: 'Valid email is required',
          value: '',
          valid: false,
          touched: false,
          validation: {
            required: true,
            isEmail: true
          },
        },
        first_name: {
          errorMessage: 'First Name is required',
          value: '',
          valid: false,
          touched: false,
          validation: {
            required: false,
          },
        },
        last_name: {
          errorMessage: 'Last Name is required',
          value: '',
          valid: false,
          touched: false,
          validation: {
            required: false,
          },
        },
        invitationProjectID: {
          value: '',
          valid: false,
          touched: false,
          validation: {
            required: false,
          },
        }
      },
      copyFlag: false,
      randomFlag: false,
      passwordMatchMessage: false,
      //  sendInviteEmail: true, // TEMP: change this to true by default soon, just false for initial month or two of production use
    }
  }

  checkUserExists = async (email) => {
    const response = await axiosInstance(`users/exists?email=${encodeURIComponent(email)}`, "get", {});

    return { userExists: !!_.get(response, 'data.userExists', false), isEnabled: !!_.get(response, 'data.isEnabled', false) };
  }

  debouncedCheck(email) {
    clearTimeout(this.checkEvent);
    this.checkEvent = setTimeout(async () => {
      const userExistance = await this.checkUserExists(email);
      this.setState({ userExists: userExistance.userExists, userEnabled: userExistance.isEnabled });
      if (userExistance.userExists) {
        const controls = { ...this.state.controls };
        for (const controlName of Object.keys(controls)) {
          if (controlName !== 'email') {
            controls[controlName].value = '';
          }
        }
        this.setState({ controls });
      }
    }, 500);
  };

  generatePassword = () => {
    const _password: any = getRandomPassword();
    return _password;
  };

  onCopy = (text: string, result) => {
    if (result) {
      this.inputChangedHandler({ target: { value: text } }, 'password', () => {
        this.inputChangedHandler({ target: { value: text } }, 'password2');
      });
      this.setState({ copyFlag: true });
      this.setState({ randomFlag: false });
    }
  };

  public inputChangedHandler = async (event: React.ChangeEvent<HTMLInputElement> | ManualChangeEvent, controlName: string, onComplete?: () => void) => {

    const updatedControls = {
      ...this.state.controls,
      [controlName]: {
        ...this.state.controls[controlName],
        value: event.target.value,
        valid: this.checkValidity(event.target.value, this.state.controls[controlName].validation),
        touched: true
      }
    };
    this.setState({ controls: updatedControls }, onComplete);

    if (controlName === 'email') {
      this.debouncedCheck(event.target.value);
    }
  };

  checkValidity(value: string, rules: IValidation) {
    let isValid = true;

    if (!rules) {
      return true;
    }

    if (rules.required) {
      isValid = value.trim() !== '' && isValid;
    }

    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid
    }

    if (rules.isEmail) {
      const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
      isValid = pattern.test(value) && isValid
    }

    if (rules.shouldMatchPassword) {
      // const pattern = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^/&*]{8,16}$/;
      isValid = value === this.state.controls.password.value && isValid

      // isValid = pattern.test(value) && value === this.state.controls.password.value && isValid
    }

    if (rules.shouldMatchPassword) {
      // const pattern = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^/&*]{8,16}$/;
      isValid = value === this.state.controls.password.value && isValid
      // isValid = pattern.test(value) && value === this.state.controls.password2.value && isValid
    }

    return isValid;
  }

  goBack = () => {
    const portalSlug = _.get(this.props.match.params, 'portalSlug') || _.get(this.props.match.params, 'portal');
    this.props.history.push(`/details/${portalSlug}`);
  };

  onSubmitHandler = (e: any) => {
    e.preventDefault();
    if (!this.props.authenticatedRequest && !this.props.portal.found) {
      MySwal.fire("Portal Not Found", "Portal was not found. Please make sure you have typed the portal name in url while signing up.", "error");
      return;
    }

    this.setState({ passwordMatchMessage: false })
    const authData = {
      email: this.state.controls.email.value.toLowerCase(),
      first_name: this.state.controls.first_name.value,
      last_name: this.state.controls.last_name.value,
      invitationProjectID: this.state.controls.invitationProjectID.value,
    };
    let url = null;
    let showSuccessMsg = false;
    if (!this.props.url) {
      const portalInfo = LocalStorageWrapped.getItem("portalInfo");
      url = portalInfo.portalSlug + "/users/register";
      showSuccessMsg = true;
    }
    else url = this.props.url;
    this.props.onSignup(authData.email, authData.first_name, authData.last_name, authData.invitationProjectID, url, showSuccessMsg, []);

  };

  getProjectDropdowns() {
    return <Fragment>
      <option>Portal (no specific project)</option>
      {this.props.projects.map((project) => { return (<option value={project.id}> -- {project.name}</option>) })}
    </Fragment>
  }

  componentWillMount() { }

  componentWillUnmount() {
    this.props.clearSignupState();
  }

  render() {
    let password2ErrorMessage = null;
    let emailErrorMessage = null;
    if (this.props.errors !== null) {
      if (this.props.errors && this.props.errors.password2) {
        password2ErrorMessage = <p style={{ color: "red" }}>{this.props.errors.password2}</p>
      }
      if (this.props.errors.email) {
        emailErrorMessage = <p style={{ color: "red" }}>{this.props.errors.email}</p>
      }
    }
    const formElementsArray = [];
    for (let key in this.state.controls) {
      formElementsArray.push({
        id: key,
        config: this.state.controls[key]
      });
    }

    const controls = this.state.controls;
    let form = (
      <div className="mx-5">
        <FormGroup>
          <Label className="labelText">Email *</Label>
          <Input
            className="inputItem custom-width"
            name="email"
            placeholder="Email"
            value={controls.email.value}
            type="email"
            valid={controls.email.valid && controls.email.touched}
            invalid={(!controls.email.valid && controls.email.touched) || (emailErrorMessage !== null)}
            onChange={(event) => this.inputChangedHandler(event, "email")}
          />
          <ErrorDisplayer errorObj={this.props.errors} keyName="email" />
        </FormGroup>

        <FormGroup>
          <Label className="labelText">First Name</Label>
          <Input
            className="inputItem custom-width"
            name="firstName"
            placeholder="First Name (optional)"
            value={controls.first_name.value}
            type="text"
            valid={controls.first_name.valid && controls.first_name.touched}
            invalid={!controls.first_name.valid && controls.first_name.touched}
            disabled={this.state.userExists}
            onChange={(event) => this.inputChangedHandler(event, "first_name")} />
          <ErrorDisplayer errorObj={this.props.errors} keyName="first_name" />


        </FormGroup>

        <FormGroup>
          <Label className="labelText">Last Name</Label>
          <Input
            className="inputItem custom-width"
            name="lastName"
            placeholder="Last Name (optional)"
            value={controls.last_name.value}
            type="text"
            valid={controls.last_name.valid && controls.last_name.touched}
            invalid={!controls.last_name.valid && controls.last_name.touched}
            disabled={this.state.userExists}
            onChange={(event) => this.inputChangedHandler(event, "last_name")}
          />
          <ErrorDisplayer errorObj={this.props.errors} keyName="last_name" />
        </FormGroup>

        <Row className="mx-auto register-button-wrapper">
          {!this.props.authenticatedRequest ?
            <Col className="text-left">
              <span> Already have a account ?</span>
              <NavLink to={generatePortalSpecificUrl(this.props.portal, "/login")}>Login</NavLink>
            </Col>
            : null}
          <Col className={this.props.authenticatedRequest ? "text-center" : "text-right"}>
            <Row className="px-2 d-flex justify-content-center">
              <Button
                onClick={(event) => this.onSubmitHandler(event)}
                disabled={!(this.state.controls.email.valid && this.state.controls.email.touched
                  // &&
                  // this.state.controls.password.valid && this.state.controls.password.touched &&
                  // this.state.controls.password2.valid && this.state.controls.password2.touched
                  // this.state.controls.first_name.valid && this.state.controls.first_name.touched &&
                  // this.state.controls.last_name.valid && this.state.controls.last_name.touched
                ) && !(this.state.controls.email.valid && this.state.controls.email.touched && this.state.userExists)
                }
                size="md"
                color="success"
                outline
              >
                {this.props.buttonText ?
                  this.props.buttonText
                  :
                  "Register"
                }
              </Button>
              &nbsp;&nbsp;&nbsp;
              {this.props && this.props.title ?
                <Button onClick={(e) => this.props.history.push(`/details/${this.state.portalSlug}`)} color="danger" outline> Cancel </Button>
                : null
              }
            </Row>
            <Row className="px-2 d-flex justify-content-center">
              {this.state.copyFlag ? <span style={{ color: 'orange', marginTop: '0.5em' }}> Random password filled and copied to clipboard</span> : null}
              {this.state.randomFlag ? <span style={{ color: 'orange', marginTop: '0.5em' }}> Random password generated and filled</span> : null}
            </Row>
          </Col>
        </Row>
      </div>
    );

    let content = null;


    let authRedirect = null;
    if (this.props.success) {
    /*  authRedirect = <Redirect to={`/details/${this.state.portalSlug}`} /> commented so that Permissions component can render below the signup instead of redirection */
      Toast.fire("Success", "Invitation email sent successfully.", "success");
    }
    if (this.props.isAuthenticated && !this.props.authenticatedRequest) {
      authRedirect = <Redirect to='/dashboard' />
    }
    if (this.props.loading) {
      content = <Spinner />
    } else {
      content = (
        <Row>
          <Col sm={12} md={8} lg={7} className="centerForm">
            <Row>
              <Col>
                <h1 className="heading">{this.props.title ? this.props.title : "Create Account"}</h1><br />
              </Col>
            </Row>
            <Form>
              {authRedirect}
              {form}
              {/* tslint:disable-next-line:jsx-no-multiline-js */}
              {this.state.passwordMatchMessage &&
                <Alert color="danger">
                  Both passwords must match.
                </Alert>}

            </Form>

            {!this.props.authenticatedRequest &&
              <Row className="mt-3">
                <Col className="text-center">
                  <SocialAuth />
                </Col>
              </Row>
            }
          </Col>
          {
              this.props.registeredUser &&
              <Container>
                <Row>
                  <UserPermissions userId={this.props.registeredUser.id}/>
                  <div className='d-flex align-items-center justify-content-center w-100 mt-4'>
                    <Button color="secondary" onClick={() => {
                      this.goBack()
                    }}>
                      Back
                    </Button>
                  </div>
                </Row>
              </Container>
          }
        </Row>
      );
    }


    return content;
  }

}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.login.token !== "",
    errors: state.signup.errors,
    loading: state.signup.loading,
    message: state.signup.message,
    success: state.signup.success,
    authRedirectPath: state.signup.authRedirectPath,
    token: state.login.token,
    portal: state.portalListReducer.portal,
    projects: state.project.project_list,
    registeredUser: state.signup.registeredUser,
  }
};


const mapDispatchToProps = (dispatch) => {
  return {
    onSignup: (email: string, first_name: string, last_name: string, invitationProjectID: string, url: string, showSuccessMsg: boolean, invitationPortalIDs: string[]) => {
      dispatch(actions.signup(email, first_name, last_name, invitationProjectID, url, showSuccessMsg,invitationPortalIDs))
    },
    clearSignupState: () => {
      dispatch(clearSignupState())
    }
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Signup));
