import './password-change.styles.css';
import React, {Component} from 'react';
import {FormGroup, Input, Form, Label, Alert, Button, Modal, ModalBody} from 'reactstrap';
import {IChangePasswordState, IValidation, SetPasswordProps} from "../../../interfaces/auth";
import {axiosInstance} from "../../../Axios";
import * as _ from "lodash";
import { getRandomPassword } from "../../../helpers/utils";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { ManualChangeEvent } from '../../../interfaces/forms';

class ChangePasswordComponent extends Component<SetPasswordProps, IChangePasswordState> {

    constructor(props: SetPasswordProps) {
        super(props);
        this.state = {
            controls: {
                new_password: {
                    value: '',
                    valid: false,
                    touched: false,
                    validation: {
                        required: true,
                        minLength: 8,
                    },
                },
                confirm_password: {
                    value: '',
                    valid: false,
                    touched: false,
                    validation: {
                        required: true,
                        minLength: 8,
                        shouldMatchNewPassword: true,
                    },
                },
            },
            passwordMissMatchError: '',
            passwordChangeSuccess: false,
            loadingState: false,
            copyFlag: false
        }
    }

    inputChangedHandler = (event: React.ChangeEvent<HTMLInputElement> | ManualChangeEvent, controlName: string, onComplete?: () => void) => {
        const updatedControls = {
            ...this.state.controls,
            [controlName]: {
                ...this.state.controls[controlName],
                value: event.target.value,
                valid: this.checkValidity(event.target.value, this.state.controls[controlName].validation),
                touched: true,
            },
        };
        this.setState({controls: updatedControls}, onComplete);
    };

    checkValidity(value: string, rules: IValidation) {
        let isValid = true;

        if (!rules) {
            return true;
        }

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid;
        }

        if (rules.isEmail) {
            const pattern = /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/;
            isValid = pattern.test(value) && isValid;
        }

        if (rules.shouldMatchNewPassword) {
            isValid = value === this.state.controls.new_password.value && isValid;
        }

        return isValid;
    }

    submitHandler = (event) => {
        this.setState({ passwordMissMatchError: "" });
        event.preventDefault();
        if (this.state.controls.new_password.value !== this.state.controls.confirm_password.value) {
            this.setState({ passwordMissMatchError: "New password & Confirm Password don't match"});
            return;
        }
        const passwordData = {
            "email": this.props.email,
            "new_password": this.state.controls.new_password.value,
            "confirm_password": this.state.controls.confirm_password.value,
        };
        const _url = `${this.props.portal}/admin/set-password`;
        const _headers = { 'Content-Type': 'application/json' };
        this.setState({loadingState: true });
        axiosInstance(_url, 'post', _headers, JSON.stringify(passwordData))
            .then(response => {
                let res = _.get(response, "data");
                if (res.success) {
                    this.setState({passwordChangeSuccess: true, loadingState: false });
                }
            }).catch(err => {
                this.setState({passwordMissMatchError: err.response.data.errors, loadingState:false });
        });
    };

    reInitState = () => {
        this.setState({
            controls: {
                new_password: {
                    value: '',
                    valid: false,
                    touched: false,
                    validation: {
                        required: true,
                        minLength: 8,
                    },
                },
                confirm_password: {
                    value: '',
                    valid: false,
                    touched: false,
                    validation: {
                        required: true,
                        minLength: 8,
                        shouldMatchNewPassword: true,
                    },
                },
            },
            passwordMissMatchError: '',
            passwordChangeSuccess: false,
            loadingState: false,
            copyFlag: false,
        });
    };

    componentDidMount() {
        this.reInitState();
    }

    componentWillUnmount() {
        this.reInitState();
    }

    generatePassword = () => {
        const _password : any = getRandomPassword();
        return _password;
    };
  
    onCopy = (text: string, result) => {
      if (result) {
        this.inputChangedHandler({ target: { value: text } }, 'new_password', () => {
            this.inputChangedHandler({ target: { value: text } }, 'confirm_password');
        });
        this.setState({copyFlag: true});
      }
    };

    render() {
        let form = (
            <Form>
                <FormGroup>
                    <h1 className="heading"> Set Password </h1>
                    <Label className="labelText">New Password</Label>
                    <Input
                        value={this.state.controls.new_password.value}
                        valid={this.state.controls.new_password.valid && this.state.controls.new_password.touched}
                        invalid={!this.state.controls.new_password.valid && this.state.controls.new_password.touched}
                        onChange={(event) => this.inputChangedHandler(event, "new_password")}
                        className="inputItem mb-3"
                        type="password"
                        placeholder="New Password"
                    />
                    <Label className="labelText">Confirm New Password</Label>
                    <Input
                        value={this.state.controls.confirm_password.value}
                        valid={this.state.controls.confirm_password.valid && this.state.controls.confirm_password.touched}
                        invalid={!this.state.controls.confirm_password.valid && this.state.controls.confirm_password.touched}
                        onChange={(event) => this.inputChangedHandler(event, "confirm_password")}
                        className="inputItem mb-3"
                        type="password"
                        placeholder="Confirm Password"
                    />

                    {this.state.copyFlag ? <span style={{color: 'orange'}}> Random password filled and copied to clipboard</span> : null}
                    <p style={{color: "red"}}>
                        {this.state.passwordMissMatchError}
                    </p>

                    <div className='text-right'>
                        <Button disabled={!(this.state.controls.new_password.valid &&
                                            this.state.controls.new_password.touched &&
                                            this.state.controls.confirm_password.valid &&
                                            this.state.controls.confirm_password.touched)}
                                onClick={(event) => this.submitHandler(event)}
                                color="info">Submit</Button>
                        <Button className={"ml-3"}
                                color="secondary"
                                onClick={() => this.props.toggleModal()}>Cancel</Button>
                        <CopyToClipboard text={this.generatePassword()}
                                         onCopy={this.onCopy}>
                            <Button className={"ml-3"}
                                    color="secondary"
                                    onClick={() => this.generatePassword()}>Generate password</Button>
                        </CopyToClipboard>
                    </div>
                </FormGroup>
            </Form>
        );

        return (
            <div className="centerForm">
                <Modal isOpen={this.props.modalOpen} toggle={() => this.props.toggleModal()}>
                  <ModalBody>
                      { this.state.passwordChangeSuccess ?
                          <div> <Alert color="success"> Password Changed Successfully.</Alert>
                              <Button color="secondary" onClick={() => this.props.toggleModal()}>Back</Button>
                          </div>
                           :
                           <div> {form} </div>
                      }
                  </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default ChangePasswordComponent;
