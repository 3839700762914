import React, {Component} from 'react';
import { Button, Form, Input, Label, FormGroup, Container, Row, Col } from 'reactstrap';
import axios, { AxiosResponse } from 'axios';
import {
    CreateMultiPortalUserProps, ICreateMultiPortalUserStates
} from "../../interfaces/portal";
import {connect} from "react-redux";
import * as actions from "../../store/actions";
import {changeProjectState, clearSignupState, get_project_list} from "../../store/actions";
import {deleteProject} from "../../store/actions/project";
import {axiosInstance} from "../../Axios";
import { MySwal, Toast } from '../../SWAL';
import {get_portal_group_list} from "../../store/actions/portal";
import * as _ from "lodash";
import ErrorDisplayer from "../UI/ErrorHandling/ErrorDisplayer";
import {ManualChangeEvent} from "../../interfaces/forms";
import {IValidation} from "../../interfaces/auth";

class CreateMultiPortalUser extends Component<CreateMultiPortalUserProps, ICreateMultiPortalUserStates> {
    checkEvent;
    constructor(props) {
        super(props);
        this.state = {
            userExists: false,
            userEnabled: false,
            controls: {
                email: {
                    errorMessage: 'Valid email is required',
                    value: '',
                    valid: false,
                    touched: false,
                    validation: {
                        required: true,
                        isEmail: true
                    },
                },
                first_name: {
                    errorMessage: 'First Name is required',
                    value: '',
                    valid: false,
                    touched: false,
                    validation: {
                        required: false,
                    },
                },
                last_name: {
                    errorMessage: 'Last Name is required',
                    value: '',
                    valid: false,
                    touched: false,
                    validation: {
                        required: false,
                    },
                },
            },
            portal_list: this.props?.portal_list || [],
            selectedPortals: []
        };
    }


    public inputChangedHandler = async (event: React.ChangeEvent<HTMLInputElement> | ManualChangeEvent, controlName: string, onComplete?: () => void) => {

        const updatedControls = {
            ...this.state.controls,
            [controlName]: {
                ...this.state.controls[controlName],
                value: event.target.value,
                valid: this.checkValidity(event.target.value, this.state.controls[controlName].validation),
                touched: true
            }
        };
        this.setState({ controls: updatedControls }, onComplete);

        if (controlName === 'email') {
            this.debouncedCheck(event.target.value);
        }
    };

    checkValidity(value: string, rules: IValidation) {
        let isValid = true;

        if (!rules) {
            return true;
        }

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid
        }

        if (rules.isEmail) {
            const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
            isValid = pattern.test(value) && isValid
        }

        if (rules.shouldMatchPassword) {
            // const pattern = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^/&*]{8,16}$/;
            isValid = value === this.state.controls.password.value && isValid

            // isValid = pattern.test(value) && value === this.state.controls.password.value && isValid
        }

        if (rules.shouldMatchPassword) {
            // const pattern = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^/&*]{8,16}$/;
            isValid = value === this.state.controls.password.value && isValid
            // isValid = pattern.test(value) && value === this.state.controls.password2.value && isValid
        }

        return isValid;
    }


    debouncedCheck(email) {
        clearTimeout(this.checkEvent);
        this.checkEvent = setTimeout(async () => {
            const userExistance = await this.checkUserExists(email);
            this.setState({ userExists: userExistance.userExists, userEnabled: userExistance.isEnabled });
            if (userExistance.userExists) {
                const controls = { ...this.state.controls };
                for (const controlName of Object.keys(controls)) {
                    if (controlName !== 'email') {
                        controls[controlName].value = '';
                    }
                }
                this.setState({ controls });
            }
        }, 500);
    };

    checkUserExists = async (email) => {
        const response = await axiosInstance(`users/exists?email=${encodeURIComponent(email)}`, "get", {});

        return { userExists: !!_.get(response, 'data.userExists', false), isEnabled: !!_.get(response, 'data.isEnabled', false) };
    }

    onSubmitHandler = (e: any) => {
        e.preventDefault();
        const authData = {
            email: this.state.controls.email.value.toLowerCase(),
            first_name: this.state.controls.first_name.value,
            last_name: this.state.controls.last_name.value,
        };
        let url = null;
        let showSuccessMsg = true;

        url = "users/register-multi";
        this.props.onSignupMultiPortals(authData.email, authData.first_name, authData.last_name,undefined, url, showSuccessMsg, this.state.selectedPortals);

    };

    componentWillUnmount() {
        this.props.clearSignupState();
    }

    render() {
        const controls = this.state.controls;

        const formElementsArray = [];
        for (let key in this.state.controls) {
            formElementsArray.push({
                id: key,
                config: this.state.controls[key]
            });
        }
        return (
            <Container style={{marginTop: '20px'}}>
                <Row>
                    <Col lg={12}>
                        <Form>
                            <FormGroup>
                                <Label for="groupName">Email *</Label>
                                <Input
                                    className="inputItem custom-width"
                                    name="email"
                                    placeholder="Email"
                                    value={controls.email.value}
                                    type="email"
                                    valid={controls.email.valid && controls.email.touched}
                                    invalid={(!controls.email.valid && controls.email.touched)}
                                    onChange={(event) => this.inputChangedHandler(event, "email")}
                                />
                                <ErrorDisplayer errorObj={this.props.errors} keyName="email" />

                            </FormGroup>
                            <FormGroup>
                                <Label className="labelText">First Name</Label>
                                <Input
                                    className="inputItem custom-width"
                                    name="firstName"
                                    placeholder="First Name (optional)"
                                    value={controls.first_name.value}
                                    type="text"
                                    valid={controls.first_name.valid && controls.first_name.touched}
                                    invalid={!controls.first_name.valid && controls.first_name.touched}
                                    disabled={this.state.userExists}
                                    onChange={(event) => this.inputChangedHandler(event, "first_name")} />
                                <ErrorDisplayer errorObj={this.props.errors} keyName="first_name" />
                            </FormGroup>
                            <FormGroup>
                                <Label className="labelText">Last Name</Label>
                                <Input
                                    className="inputItem custom-width"
                                    name="lastName"
                                    placeholder="Last Name (optional)"
                                    value={controls.last_name.value}
                                    type="text"
                                    valid={controls.last_name.valid && controls.last_name.touched}
                                    invalid={!controls.last_name.valid && controls.last_name.touched}
                                    disabled={this.state.userExists}
                                    onChange={(event) => this.inputChangedHandler(event, "last_name")}
                                />
                                <ErrorDisplayer errorObj={this.props.errors} keyName="last_name" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="portals">Select Portals</Label>
                                <Input
                                    type="select"
                                    id="portals"
                                    style={ { height: '200px' }}
                                    multiple
                                    value={this.state.selectedPortals}
                                    onChange={e => {
                                        const target = e.target as unknown as HTMLSelectElement;
                                        this.setState({
                                            selectedPortals: Array.from(target.selectedOptions, option => option.value)
                                        });
                                    }}
                                >
                                    {this.state.portal_list.map(portal => <option key={portal.id} value={portal.id}>{portal.name}</option>)}
                                </Input>
                            </FormGroup>

                            <Button
                                onClick={(event) => this.onSubmitHandler(event)}
                                disabled={!(this.state.controls.email.valid && this.state.controls.email.touched)
                                    && !(this.state.controls.email.valid && this.state.controls.email.touched && this.state.userExists)

                            }
                                size="md"
                            >Save
                            </Button>

                        </Form>
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        userId: state.login.loggedInUser.id,
        token: state.login.token,
        portal_list: state.portalListReducer.portal_list,
        success: state.project.get_project_list_success,
        loading: state.project.get_project_list_loading,
        isSuperUser: state.login.isSuperUser,
        isPortalAdmin: state.login.isPortalAdmin,
        errors: state.signup.errors,
        selectedPortal: state.portalListReducer.selectedPortal,
        permissions: state.userPermissions.permissions
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onTryFetchProjectList: (portal_name) => dispatch(actions.get_project_list(portal_name)),
        onChangeProjectStatus: (action: string, portalSlug: string, projectId: string, projectName: string) => dispatch(changeProjectState(action, portalSlug, projectId, projectName)),
        onProjectDelete: (portalSlug: string, projectId: string, projectName: string) => dispatch(deleteProject(portalSlug, projectId, projectName)),
        onClearProjectState: () => dispatch(actions.clear_project_state()),
        getPortalGroupList: () => dispatch(get_portal_group_list()),
        onSignupMultiPortals: (email: string, first_name: string, last_name: string, invitationProjectID: string, url: string, showSuccessMsg: boolean, invitationPortalIDs: string[]) => {
            dispatch(actions.signup(email, first_name, last_name, invitationProjectID, url, showSuccessMsg, invitationPortalIDs))
        },
        clearSignupState: () => {
            dispatch(clearSignupState())
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateMultiPortalUser);