import React from "react";
import {connect} from "react-redux";
import {GroupModelPermissionsProps, GroupModelPermissionsState} from "../../interfaces/groups";
import * as _ from "lodash";
import {Table} from "reactstrap";
import {ModelPermissionsObject} from "../../permissions";
import Switch from "react-switch";
import {changeGroupModelPermission} from "../../store/actions/portalGroups";
import {Toast} from "../../SWAL";


class GroupModelPermissions extends React.Component<GroupModelPermissionsProps, GroupModelPermissionsState> {


  componentDidMount(): void {
    setTimeout(() => {
      this.fillState();
    }, 100);
  }

  fillState = () => {
    let modelPermissions = this.props.modelPermissions;
    let models = Object.keys(modelPermissions);
    let finalState = {};
    _.map(models, (model) => {
      let currentModelPerms = _.get(modelPermissions, model);
      let currentModelPermKeys = Object.keys(currentModelPerms);
      _.map(currentModelPermKeys, (key) => {
        _.set(finalState, key, _.get(currentModelPerms, key));
      })
    });
    this.setState(finalState);
  };

  normalize = (str: string) => {
    str = str.split("_").join(" ");
    str = str.toLowerCase();
    return str.charAt(0).toUpperCase() + str.substring(1);
  };

  changePermission = (perm, model) => {
    let newState = {};
    newState[perm] = !_.get(this.state, perm);
    let action: "give" | "remove" = "give";
    if (_.get(this.state, perm)) { action = "remove"; }
    changeGroupModelPermission(this.props.portal, this.props.groupId, action, {name: perm, model: model})
      .then(_ => {
        Toast.fire("Success", "Permisssion changed successfully", "success");
      })
      .catch(_ => {
        Toast.fire("Error", "Error while changing permissions", "error");
      });
    this.setState(newState);
  };


  render() {
    return (
      <div>
        <h6 style={{textAlign: "left", marginTop: "35px"}}>Model permissions</h6>
        <Table responsive striped bordered small={'true'}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Permissions</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(ModelPermissionsObject).map((model, mIndex) =>
              <tr key={mIndex}>
                <td>{model}</td>
                <td>
                  {Object.keys(ModelPermissionsObject[model]).map((perm, pIndex) =>
                    <span key={`key_${pIndex}`}
                          style={{display: "inline-block", width: "33%"}}>
                      <Switch
                        checked={_.get(this.state, perm) ? _.get(this.state, perm) : false }
                        onChange={() => {this.changePermission(perm, model)}}
                        onColor="#86d3ff"
                        onHandleColor="#2693e6"
                        handleDiameter={30}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={20}
                        width={48}
                        className="react-switch"
                        id="material-switch"
                      />
                    <div key={pIndex}>{this.normalize(perm)}</div>
                    </span>
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    )
  }
}

export default connect(null, null)(GroupModelPermissions);
