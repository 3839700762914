import React from "react";

export default function Card (props) {
    return <div style={{
        padding: '3em',
        margin: '5em',
        boxShadow: '0 10px 30px #d4d4d4',
        borderRadius: '8px',
        width: "auto",
        minWidth: '850px',
        display: 'inline-block',
        textAlign: "center",
        ...props.style,
    }}>
        {props.children}
    </div>
}