import * as actionTypes from "./actionTypes/authActionTypes";
import { detectPortalSuccess } from "./urlPortalDetectors";
import {
  axiosInstance,
  axiosInterceptors,
  axiosObj,
  requestHandler,
} from "../../Axios";
import * as _ from "lodash";
import LocalStorageWrapped from "../../LocalStorageWrapped";
import {MySwal} from "../../SWAL";
import {getPermissions} from "./permission";
import {IUser} from "../../interfaces/user";
import {getUserGroups} from "./userGroups";
import {deleteCookie, setCookie, checkCookieExist} from '../../helpers/cookieHandler';
import { changeSelectedPortal, setSelectedPortal } from './portal';
import { IPortalReducer } from '../types';
import { updateLocalStorageToken } from '../../helpers/updateSession';

export const loginStart = () => {
  return {
    type: actionTypes.LOGIN_START,
  };
};

export const changePasswordSuccess = () => {
  return {
    type: actionTypes.CHANGE_PASS_SUCCESS,
  };
};

export const setLoginStep2VerificationToken = (
    loginStep2VerificationToken: string,
) => {
    return {
        type: actionTypes.SET_LOGIN_STEP2_VERIFICATION_TOKEN_SUCCESS,
        loginStep2VerificationToken: loginStep2VerificationToken,
    };
};

export const loginSuccess = (
  message: string,
  token: string,
  success: boolean,
  login_type: string,
  redirectPath: string = "/dashboard",
  loggedInUser: IUser = null
) => {
  mentainLoginStats(loggedInUser);
  return {
    type: actionTypes.LOGIN_SUCCESS,
    message: message,
    token: token,
    success: success,
    login_type: login_type,
    redirectPath,
    loggedInUser,
  };
};


const mentainLoginStats = (user: IUser) => {
  axiosInstance(
      "users/login-stats",
      "post",
      { "Content-Type": "application/json" },
      {"userId":user.id, "email": user.email }
  ).then((response) => {
      })
      .catch((err) => {
      });
}


export const portalUserAllowedUrls = [
  /[^\/]*\/portaluser\/update\/.*/
];

const redirectPortalUser = (user: IUser, portal: IPortalReducer) => {
  let _redirectUrl = "/logout";
  if (user.lastAccessURL && user.lastAccessURL !== "" && portal) {
    _redirectUrl = `${process.env.REACT_APP_CESIUM_VIEWER_URL}${portal.version}${user.lastAccessURL}`;
  } else if (portal && portal.slug) {
    _redirectUrl = `${process.env.REACT_APP_CESIUM_VIEWER_URL}${portal.version}/${portal.slug}`;
  } else {
    _redirectUrl = `${window.location.protocol}//${window.location.hostname}${_redirectUrl}`;
  }
  window.location.replace(_redirectUrl);
};

export const loginFail = (errors: object) => {
  return {
    type: actionTypes.LOGIN_FAIL,
    errors: errors,
  };
};

export const logout = () => {
  return (dispatch: any) => {
    const _userInfo = LocalStorageWrapped.getItem("userInfo");
    const _authInfo = LocalStorageWrapped.getItem("authInfo");
    deleteCookie();
    if (!_.isEmpty(_authInfo)) {
      const _splitToken = _authInfo.token.split(" ");
      const _authUser = {
        userId: _userInfo.id,
        useremail: _userInfo.email,
        authToken: _splitToken[1] ? _splitToken[1] : _authInfo.token,
      };
      axiosInstance(
        "users/logout",
        "post",
        { "Content-Type": "application/json" },
        JSON.stringify(_authUser)
      )
        .then((response) => {
          clearInterceptors();
          clearStorage();
          dispatch(AuthLogout());
        })
        .catch((err) => {
          clearInterceptors();
          clearStorage();
          dispatch(AuthLogout());
        });
    }
  };
};

const clearInterceptors = () => {
  axiosInterceptors.map((interceptorId) => {
    axiosObj.interceptors.request.eject(interceptorId);
  });
};

const clearStorage = () => {
  LocalStorageWrapped.removeItem("authInfo");
  LocalStorageWrapped.removeItem("userInfo");
  LocalStorageWrapped.removeItem("portalInfo");
  LocalStorageWrapped.removeItem("userPermissions");
  LocalStorageWrapped.removeItem("selectedPortal");
  LocalStorageWrapped.removeItem("userGroups");
};

export const socialLogin = (token: string) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_USER_INFO_START });
    axiosInstance(
      "users/logged-in",
      "get",
      { Authorization: "Bearer " + token },
      {}
    )
      .then((res) => {
        let response = _.get(res, "data");
        const authInfo = {
          token: "Bearer " + token,
          loginType: "social",
          success: true,
        };
        LocalStorageWrapped.setItem("authInfo", authInfo);
        const portalInfo = LocalStorageWrapped.getItem("portalInfo");
        const userInfo: IUser = {
          id: response.object.id,
          email: response.object.email,
          firstName: response.object.firstName,
          lastName: response.object.lastName,
          portals: response.object.portals,
          isSuperUser: false,
          googleId: response.object.googleId,
          lastAccessURL: response.object.lastAccessURL,
          linkedInId: "",
        };
        dispatch(
          detectPortalSuccess(
            response.object.portal.name,
            true,
            portalInfo.portalType,
            response.object.portal.id,
            response.object.portal.slug,
            response.object.portal.version
          )
        );
        LocalStorageWrapped.setItem("userInfo", userInfo);
        dispatch(
          loginSuccess(
            "User successfully logged in",
            "Bearer " + token,
            true,
            "social",
            "/dashboard",
            userInfo
          )
        );
        dispatch({ type: actionTypes.GET_USER_INFO_SUCCESS });
      })
      .catch((err) => {
        MySwal.fire("error", "Unidentified Token", "error");
        dispatch({ type: actionTypes.GET_USER_INFO_FAIL });
        dispatch(loginFail({}));
      });
  };
};

export const login = (
  email: string,
  password: string,
  secretQuestion?: string,
  secretQuestionAnswer?: string,
  captchaToken?: string,
  next?: string
) => {
  return (dispatch) => {
    dispatch(loginStart());
    const authData = {
      email: email,
      password: password,
      secretQuestion: secretQuestion,
      secretQuestionAnswer: secretQuestionAnswer,
      captchaToken: captchaToken,
    };
    axiosInstance(
      "users/login",
      "post",
      { "Content-Type": "application/json" },
      JSON.stringify(authData)
    )
      .then(async (response) => {
        let res = _.get(response, "data");
        if (res.success) {
          const processAuthToken = async () => {
            axiosInterceptors.push(
                axiosObj.interceptors.request.use((request) =>
                    requestHandler(request)
                )
            );
            const _domainName = process.env.REACT_APP_DOMAIN;
            setCookie(res.object.token, _domainName);
            const userInfo: IUser = {
              id: res.object.id,
              email: res.object.email,
              firstName: res.object.firstName,
              lastName: res.object.lastName,
              isSuperUser: res.object.isSuperUser,
              portals: res.object.portals,
              googleId: res.object.googleId,
              linkedInId: res.object.linkedInId,
              groups: res.object.groups,
              lastAccessURL: res.object.lastAccessURL,
              twofaEnabled: res.object.twofaEnabled,
            };

            LocalStorageWrapped.setItem("userInfo", userInfo);
            const authInfo = {
              token: res.object.token,
              loginType: "email",
              success: res.success,
            };
            LocalStorageWrapped.setItem("authInfo", authInfo);
            let redirectPath = "/dashboard";

            if (next) {
              window.location.replace(decodeURIComponent(next));
              return;
            }
            let userPortal = null;

            if (res.object.portals.length === 1) {
              userPortal = _.first(res.object.portals);
            }

            let portalDetail = null;
            if (userPortal) {
              // Fetch portal detail
              portalDetail = _.get(
                  await axiosInstance(`portal/${userPortal.portal}`, "get", {
                    "Content-Type": "application/json",
                  }),
                  "data.object"
              );
            }

            if (
                res.object &&
                !userPortal?.isPortalAdmin &&
                !res.object.isSuperUser
            ) {
              let isExempt = false;
              for (const pattern of portalUserAllowedUrls) {
                if (window.location.pathname.match(pattern)) {
                  isExempt = true;
                }
              }
              if (res.object.portals.length > 1 || isExempt) {
                redirectPath = "/admin";
              } else {
                redirectPortalUser(res.object, portalDetail);
                return;
              }
            }
            if (!res.object.isSuperUser) {
              if (userPortal) {
                dispatch(
                    detectPortalSuccess(
                        portalDetail.name,
                        true,
                        localStorage.getItem("portalType"),
                        portalDetail.id,
                        portalDetail.slug,
                        portalDetail.version
                    )
                );
                dispatch(
                    getUserGroups(
                        portalDetail ? portalDetail.slug : "",
                        userInfo.id
                    )
                );
                dispatch(
                    getPermissions(
                        portalDetail ? portalDetail.slug : "",
                        userInfo.id
                    )
                );
              }
            }
            setTimeout(function () {
              dispatch(
                  loginSuccess(
                      res.message,
                      res.object.token,
                      res.success,
                      "email",
                      redirectPath,
                      userInfo
                  )
              );
            }, 300);
          }

          const is2faEmailEnabled = res.object.twofaEmailEnabled && res.object?.loginStep2VerificationToken;
          const is2faEnabled = res.object.twofaEnabled && res.object?.loginStep2VerificationToken;

          if(is2faEmailEnabled){
            dispatch(setLoginStep2VerificationToken(res.object.loginStep2VerificationToken))
            LocalStorageWrapped.setItem("userInfo", {
              twofaEmailEnabled: res.object.twofaEmailEnabled,
            });
          }
          else if (is2faEnabled) {
            dispatch(setLoginStep2VerificationToken(res.object.loginStep2VerificationToken));
            LocalStorageWrapped.setItem("userInfo", {
              twofaEnabled: res.object.twofaEnabled,
            });
          } else {
            return processAuthToken()
          }
        }
      })
      .catch((err) => {
        if (err.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          if (err.response.status === 422) {
            MySwal.fire(
              "Not Approved Or Disabled",
              "Your account is not approved yet or has been disabled. You account needs to be approved or enabled by portal admin for you to start/resume using the application",
              "error"
            );
            return dispatch(
              loginFail({
                email:
                  "Your account is not approved yet or has been disabled. You account needs to be approved or enabled by portal admin for you to start/resume using the application",
              })
            );
          }
          if (err.response.data.message === "Portal not found!") {
            dispatch(
              loginFail({ portalNotFound: "Your account has been disabled!" })
            );
          }
          dispatch(loginFail(err.response.data.errors));
        } else if (err.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          dispatch(
            loginFail({
              request: "Authentication error (0x00010) -- " + err.message,
            })
          );
        } else {
          // Something happened in setting up the request and triggered an Error
          dispatch(
            loginFail({
              request: "Authentication error (0x00020) -- " + err.message,
            })
          );
        }
      });
  };
};

export const loginStep2 = (
    twofaToken: string,
    loginStep2VerificationToken: string,
    next?: string,
) => {
  return (dispatch) => {
    dispatch(loginStart());
    axiosInstance(
      "users/login-step2",
      "post",
      { "Content-Type": "application/json" },
      JSON.stringify({
        loginStep2VerificationToken,
        twofaToken,
      })
    )
      .then(async (response) => {
        let res = _.get(response, "data");
        if (res.success) {
          const processAuthToken = async () => {
            axiosInterceptors.push(
                axiosObj.interceptors.request.use((request) =>
                    requestHandler(request)
                )
            );
            const _domainName = process.env.REACT_APP_DOMAIN;
            setCookie(res.object.token, _domainName);
            const userInfo: IUser = {
              id: res.object.id,
              email: res.object.email,
              firstName: res.object.firstName,
              lastName: res.object.lastName,
              isSuperUser: res.object.isSuperUser,
              portals: res.object.portals,
              googleId: res.object.googleId,
              linkedInId: res.object.linkedInId,
              groups: res.object.groups,
              lastAccessURL: res.object.lastAccessURL,
              twofaEnabled: res.object.twofaEnabled,
            };
            LocalStorageWrapped.setItem("userInfo", userInfo);
            const authInfo = {
              token: res.object.token,
              loginType: "email",
              success: res.success,
            };
            LocalStorageWrapped.setItem("authInfo", authInfo);
            let redirectPath = "/dashboard";

            if (next) {
              window.location.replace(decodeURIComponent(next));
              return;
            }
            let userPortal = null;

            if (res.object.portals.length === 1) {
              userPortal = _.first(res.object.portals);
            }

            let portalDetail = null;
            if (userPortal) {
              // Fetch portal detail
              portalDetail = _.get(
                  await axiosInstance(`portal/${userPortal.portal}`, "get", {
                    "Content-Type": "application/json",
                  }),
                  "data.object"
              );
            }

            if (
                res.object &&
                !userPortal?.isPortalAdmin &&
                !res.object.isSuperUser
            ) {
              let isExempt = false;
              for (const pattern of portalUserAllowedUrls) {
                if (window.location.pathname.match(pattern)) {
                  isExempt = true;
                }
              }
              if (res.object.portals.length > 1 || isExempt) {
                redirectPath = "/admin";
              } else {
                redirectPortalUser(res.object, portalDetail);
                return;
              }
            }
            if (!res.object.isSuperUser) {
              if (userPortal) {
                dispatch(
                    detectPortalSuccess(
                        portalDetail.name,
                        true,
                        localStorage.getItem("portalType"),
                        portalDetail.id,
                        portalDetail.slug,
                        portalDetail.version
                    )
                );
                dispatch(
                    getUserGroups(
                        portalDetail ? portalDetail.slug : "",
                        userInfo.id
                    )
                );
                dispatch(
                    getPermissions(
                        portalDetail ? portalDetail.slug : "",
                        userInfo.id
                    )
                );
              }
            }
            setTimeout(function () {
              dispatch(
                  loginSuccess(
                      res.message,
                      res.object.token,
                      res.success,
                      "email",
                      redirectPath,
                      userInfo,
                  )
              );
            }, 800);

            window.location.replace('/')
          }

          return processAuthToken()
        }
      })
      .catch((err) => {
        if (err.response) {
            const status = err.response.status

          MySwal.fire(
              status === 400
                  ? 'OTP Error'
                  : status === 401
                      ? 'Authorization Error'
                      : 'Unknown Error',
              err.response.data.message,
              "error"
          ).then((result) => {
            window.location.reload()
          });

            // dispatch(
            //   loginFail({
            //     loginStep2:
            //       err.response.data.message,
            //   })
            // );
          }
      });
  };
};

export const authCheckState = () => {
  return async (dispatch: any) => {
    const userInfo: IUser = LocalStorageWrapped.getItem("userInfo");
    const authInfo = LocalStorageWrapped.getItem("authInfo");

    const cookieExists = checkCookieExist();
    if (!cookieExists) {
      return dispatch(logout());
    } else {
      dispatch(updateLocalStorageToken());
    }

    let userPortal = null;
    let redirectPath = "/dashboard";
    if (userInfo) {
      if (userInfo.isSuperUser) {
        redirectPath = "/admin";
      } else if (userInfo.portals.length > 1) {
        redirectPath = "/admin";
      } else {
        userPortal = _.first(userInfo.portals);
        if (userPortal?.isPortalAdmin) {
          redirectPath = "/portal-admin";
        }
      }
    }
    if (_.isEmpty(userInfo) || _.isEmpty(authInfo)) {
      dispatch(logout());
    } else {
      axiosInterceptors.push(
        axiosObj.interceptors.request.use((request) =>
          requestHandler(request)
        )
      );

      axiosInstance("users/logged-in", "get", {
          "Content-Type": "application/json",
        }).then().catch(() => dispatch(logout()));

      if ((userPortal && userPortal.isPortalAdmin) || userInfo?.isSuperUser) {
        dispatch(
          loginSuccess(
            "Success",
            authInfo.token,
            true,
            "email",
            redirectPath,
            userInfo
          )
        );
      } else {
        let isExempt = false;
        for (const pattern of portalUserAllowedUrls) {
          if (window.location.pathname.match(pattern)) {
            isExempt = true;
          }
        }
        if (userInfo.portals.length > 1 || isExempt) {
          redirectPath = "/admin";

          dispatch(
            loginSuccess(
              "Success",
              authInfo.token,
              true,
              "email",
              redirectPath,
              userInfo
            )
          );
        } else {
          let portalInfo = LocalStorageWrapped.getItem("portalInfo");
          if (!portalInfo) {
            portalInfo = _.get(
              await axiosInstance(`portal/${userPortal.portal}`, "get", {
                "Content-Type": "application/json",
              }),
              "data.object"
            );
          }
          redirectPortalUser(userInfo, portalInfo);
        }
      }
    }
  };
};

export const changePassword = (
  email: string,
  old_password: string,
  new_password: string,
  confirm_password: string
) => {
  return (dispatch: any) => {
    dispatch(loginStart());
    const passwordData = {
      email: email,
      old_password: old_password,
      new_password: new_password,
      confirm_password: confirm_password,
    };
    axiosInstance(
      "admin/change-password",
      "post",
      {
        "Content-Type": "application/json",
      },
      JSON.stringify(passwordData)
    )
      .then((response) => {
        let res = _.get(response, "data");
        if (res.success) {
          dispatch(changePasswordSuccess());
          const authInfo = {
            token: res.object.token,
            success: true,
            loginType: "email",
          };
          LocalStorageWrapped.setItem("authInfo", authInfo);
          const userInfo: IUser = LocalStorageWrapped.getItem("userInfo");
          dispatch(
            loginSuccess(
              res.message,
              res.object.token,
              res.success,
              "email",
              "/dashboard",
              userInfo
            )
          );
        }
      })
      .catch((err) => {
        dispatch(loginFail(err.response.data.errors));
      });
  };
};

export const shouldLogout = () => {
  return {
    type: actionTypes.SHOULD_LOGOUT,
  };
};

export const AuthLogout = () => {
  return {
    type: actionTypes.AUTH_LOGOUT,
    token: "",
    loading: false,
    message: "",
    success: false,
    authRedirectPath: "/login",
    errors: {},
    changePasswordSuccess: false,
    login_type: "",
    loggedInUser: null,
    shouldLogout: false,
  };
};
