import React, {Component} from "react";
import {Col, Container, InputGroup, Row, FormGroup, Table, Input, Button, Popover, PopoverBody, PopoverHeader, Card, CardHeader,CardBody,Collapse} from "reactstrap";
import {NavLink, Redirect} from "react-router-dom";
import {connect} from "react-redux";
import * as actions from "../../store/actions";
import Spinner from "../UI/Spinner/Spinner";
import {
  changeSelectedPortal,
  changeStatusPortal, get_all_datasets_list, get_all_users_list, get_portal_group_list, get_all_projects_list ,
  remove_selected_portal, set_portal_selected,changeStatusPortalBackup
} from "../../store/actions/portal";
import {GroupProps, PortalProps, PortalsMainProps, PortalsState} from "../../interfaces/portal";
import {Confirm, InputSWAL} from "../../SWAL";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faGlobe, faEdit, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {generateVersionUrl} from "../../helpers/urlGenerators";
import { VIEWER_WINDOW_NAME } from "../../helpers/utils";
import _ from "lodash";
import MessageListing from "./MessageListing";
import {axiosInstance} from "../../Axios";
import {io} from "socket.io-client";
import LocalStorageWrapped from "../../LocalStorageWrapped";
import PortalProjects from "./PortalProjects";
import GroupProjects from "./GroupProjects";
import {create_portal} from "../../store/actions";



class Portals extends Component<PortalsMainProps, PortalsState> {
  private socket: any;
  private delayHidePopover: any;
  constructor(props) {
    super(props)
    this.socket = io(process.env.REACT_APP_BACKEND_HOST,{
      auth:{
        token: LocalStorageWrapped.getItem("authInfo")?.token
      },
      transports: ["websocket"],
    });
    this.delayHidePopover = null;
  }
    state = {
    use_state_portals: false,
    portal_list: [],
    search_term: "",
    loading: false,
    chat_popover: '',
    is_chat_open: false,
    selected_portal_chat: undefined,
    portal_chat: [],
    portal_message_recieved: '',
    unread_portal_messages: [],
    isPopoverOpen: false,
    popoverTarget: '',
    portalSelected: '',
    expandedPortalGroups: {},
    selectedList: 'portal_list',
    expandedGroupsBasedOnSearch: {},
    isUngroupedExpanded:false,
    };


  onSearchPortals = (e) => {
    this.setState({search_term: e.target.value});
    const search_term = e.target.value.toString().toLowerCase().trim();
    if(!search_term.length){
      this.setState({ expandedGroupsBasedOnSearch: {} , isUngroupedExpanded: false});
      return;
    }
    let filtered_results: PortalProps[];
    const selectedList = this.state.selectedList || 'portal_list'; // default to 'portal_list'
    const expandedGroupsBasedOnSearch = {};
    let isUngroupedExpanded = false;
    this.setState({expandedGroupsBasedOnSearch : {}});
    this.setState({use_state_portals: true});

    switch (selectedList){
      case "portal_list":
        filtered_results = this.props.portal_list.filter(
            (portal: PortalProps) => {
              const _portaName = portal.name.toLowerCase().includes(search_term);
              const _portalComments = portal.comments.toLowerCase().includes(search_term);
              if(_portaName){
                return _portaName
              } else {
                return _portalComments;
              }
            });
        filtered_results.forEach((portal: PortalProps) => {
          portal.groups.forEach((groupId: string) => {
            expandedGroupsBasedOnSearch[groupId] = true;
          });
        });

        if (filtered_results.length === 0) {
          this.setState({ expandedGroupsBasedOnSearch: {}, isUngroupedExpanded: false });
        } else {
          filtered_results.forEach((portal: PortalProps) => {
            if (portal.groups.length === 0) {
              isUngroupedExpanded = true;
            }
            portal.groups.forEach((groupId: string) => {
              expandedGroupsBasedOnSearch[groupId] = true;
            });
          });
          this.setState({ expandedGroupsBasedOnSearch, isUngroupedExpanded });
        }

        this.setState({ expandedGroupsBasedOnSearch });
        this.setState({portal_list: filtered_results});
        break;
        case "project_list":
        // Filter the project list based on the search term
          const filtered_projects = this.props.project_list.filter(
            (project: any) => project.name.toLowerCase().includes(search_term)
        );

        // Get the portalIds associated with the filtered projects
        const portal_ids_from_projects = filtered_projects.map((project: any) => project.portalId);

        // Filter the portal list based on the portalIds we just found
        filtered_results = this.props.portal_list.filter(
            (portal: PortalProps) => portal_ids_from_projects.includes(portal.id)
        );

        // Your existing logic to handle expanded groups
        filtered_results.forEach((portal: PortalProps) => {
          portal.groups.forEach((groupId: string) => {
            expandedGroupsBasedOnSearch[groupId] = true;
          });
        });

        if (filtered_results.length === 0) {
          this.setState({ expandedGroupsBasedOnSearch: {}, isUngroupedExpanded: false });
        } else {
          filtered_results.forEach((portal: PortalProps) => {
            if (portal.groups.length === 0) {
              isUngroupedExpanded = true;
            }
            portal.groups.forEach((groupId: string) => {
              expandedGroupsBasedOnSearch[groupId] = true;
            });
          });
          this.setState({ expandedGroupsBasedOnSearch, isUngroupedExpanded });
        }

        this.setState({ portal_list: filtered_results });
        break;
      case "user_list":
        const filtered_users = this.props.user_list.filter(
            (user: any) => user.email.toLowerCase().includes(search_term)
        );

        const portal_ids_from_users = filtered_users.flatMap((user: any) => user.portals);

        filtered_results = this.props.portal_list.filter(
            (portal: PortalProps) => portal_ids_from_users.includes(portal.id)
        );
        filtered_results.forEach((portal: PortalProps) => {
          portal.groups.forEach((groupId: string) => {
            expandedGroupsBasedOnSearch[groupId] = true;
          });
        });

        if (filtered_results.length === 0) {
          this.setState({ expandedGroupsBasedOnSearch: {}, isUngroupedExpanded: false });
        } else {
          filtered_results.forEach((portal: PortalProps) => {
            if (portal.groups.length === 0) {
              isUngroupedExpanded = true;
            }
            portal.groups.forEach((groupId: string) => {
              expandedGroupsBasedOnSearch[groupId] = true;
            });
          });
          this.setState({ expandedGroupsBasedOnSearch, isUngroupedExpanded });
        }

        this.setState({ expandedGroupsBasedOnSearch });
        this.setState({ portal_list: filtered_results });
        break;
      case "dataset_list":
        const filtered_datasets = this.props.dataset_list.filter(
            (dataset: any) => dataset?.dsAttributes?.name?.toLowerCase().includes(search_term)
        );

        const portal_ids_from_datasets = filtered_datasets.map((dataset: any) => dataset.portal);

        filtered_results = this.props.portal_list.filter(
            (portal: PortalProps) => portal_ids_from_datasets.includes(portal.id)
        );
        filtered_results.forEach((portal: PortalProps) => {
          portal.groups.forEach((groupId: string) => {
            expandedGroupsBasedOnSearch[groupId] = true;
          });
        });

        if (filtered_results.length === 0) {
          this.setState({ expandedGroupsBasedOnSearch: {}, isUngroupedExpanded: false });
        } else {
          filtered_results.forEach((portal: PortalProps) => {
            if (portal.groups.length === 0) {
              isUngroupedExpanded = true;
            }
            portal.groups.forEach((groupId: string) => {
              expandedGroupsBasedOnSearch[groupId] = true;
            });
          });
          this.setState({ expandedGroupsBasedOnSearch, isUngroupedExpanded });
        }

        this.setState({ expandedGroupsBasedOnSearch });
        this.setState({ portal_list: filtered_results });
        break;
    }
  };

  clearSearch = (e) => {
    this.onSearchPortals({ target: { value: '' } });
    this.setState({ expandedGroupsBasedOnSearch: {} , isUngroupedExpanded: false});
  }

  async componentDidMount() {
    this.setState({loading: true});
    this.props.onTryFetchPortalList();
    this.props.onTryFetchPortalsGroupList();
    this.props.onTryFetchUsersList();
    this.props.onTryFetchDatasetsList();
    this.props.onTryFetchProjectsList();
    this.socket.on("message",(emitObj:any)=>{
      if(!_.isEmpty(emitObj)){
        if(this.state.selected_portal_chat && this.state.selected_portal_chat.slug === emitObj?.portal?.slug){
          this.setState({portal_message_recieved:emitObj.portal});
          this.appendMessage(emitObj);
        }
        else{
          this.getLatestMessageIds(this.state.portal_list);
          let element : any= document.querySelector(`[portal=${emitObj.portal.slug}]`);
          if(element){
            element.className= 'mx-2 my-2 btn btn-outline-warning'
          }
        }
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.portal_list !== this.props.portal_list) {
      this.setState({loading: false, portal_list: this.props.portal_list});
    }
  }

  componentWillUnmount() {
    this.socket.close();
  }


  // Add this function to your component
  toggleGroup = (groupName) => {
    this.setState({expandedGroupsBasedOnSearch : {}});
    this.setState(prevState => ({
      expandedPortalGroups: {
        ...prevState.expandedPortalGroups,
        [groupName]: !prevState.expandedPortalGroups[groupName]
      }
    }));
  }


  activateHandler = async (e, portal: PortalProps) => {
    await this.props.onChangePortalStatus("enable", portal.id, portal.name);
  };

  deactivateHandler = async (e, portal: PortalProps) => {
    await this.props.onChangePortalStatus("disable", portal.id, portal.name);
  };

  deletePortalHandler = async (event, portal: PortalProps) => {
        Confirm("Do you really want to delete this portal?","Yes, DELETE it")
            .then(async (result) => {
                if (result.value) {
                    await this.props.onChangePortalStatus("delete", portal.id, portal.name);
                }
            })
  };

  addUserStats = (portal) => {
    let user = this.props.loggedInUser;
    axiosInstance(
        "users/login-stats",
        "post",
        { "Content-Type": "application/json" },
        {"userId":user.id, "email": user.email , "portalId": portal.id}
    ).then((response) => {
    })
        .catch((err) => {
        });
  };

  getPortalMessages = (portal, page = 1) =>{
    axiosInstance(`portal/${portal.slug}/${portal.id ? portal.id : portal._id}/chat-messages?page=${page}`, "get", {}).then(response => {
      let res = _.get(response, "data");
      const success = res.success;
      if (success) {
        if(this.state.selected_portal_chat && this.state.selected_portal_chat.slug === portal.slug) {
          if (page > 1) {
            let updatedChat : Array<any> =  [...this.state.portal_chat,...res.object]
            this.setState({portal_chat: updatedChat});
          } else {
            this.setState({portal_chat: res.object});
            this.forceUpdate();
          }
        }
      }
    }).catch(err => {
      const errors = (err && err.response && err.response.data) ? err.response.data.errors : 'something went wrong';
    });
  }

  toggleChatDialogue = async (event, portal: PortalProps,index,group = '') => {
    let element:any = document.querySelector(`[portal=${portal.slug}]`);
    let chatPopover = '';
    if(element){
      let span = element.querySelector("span");
      span.innerText = '';
      element.className= 'mx-2 my-2 btn btn-outline-info'
    }
    this.saveLastReadMessage();
    this.setState({portal_chat: [], is_chat_open: false});
    await this.getPortalMessages(portal);
    if(group){
      let noSpaces = group.replace(/\s+/g, '');
      chatPopover = `chat_popover_${noSpaces}_${index}`;
    }else{
      chatPopover = `chat_popover_${index}`;
    }
    this.setState({selected_portal_chat:portal, chat_popover: this.state.chat_popover === chatPopover ? '' : chatPopover});
    setTimeout(() => {
      this.setState({is_chat_open: !!this.state.chat_popover });
      if(!this.state.is_chat_open){
        this.setState({selected_portal_chat: undefined});
      }
    });
  };


  saveLastReadMessage = ()=>{
    let chat: Array<any>= this.state.portal_chat;
    if(chat.length){
      let lastMessage: any = chat[0];
      lastMessage.loggedInUser = this.props.loggedInUser.id;
      axiosInstance(`users/last-message`, "post", {},{lastMessage}).then(response => {
      }).catch(err => {
        const errors = (err && err.response && err.response.data) ? err.response.data.errors : 'something went wrong';
      });

    }
  }

  getUserLatestMessages = async () =>{
    return axiosInstance(`users/latest-messages/${this.props.loggedInUser.id}`, "get", {},).then(response => {
      let res = _.get(response, "data");
      const success = res.success;
      if (success) {
        return new Promise((resolve,reject)=>{
          resolve(res.object)
        });
      }
    });
  }

  getUnreadMessagesCount = async (portalId) => {
    return axiosInstance(`users/unread-messages/${this.props.loggedInUser.id}/${portalId}`, "get", {},).then(response => {
      let res = _.get(response, "data");
      const success = res.success;
      if (success) {
        return new Promise((resolve,reject)=>{
          resolve(res.object)
        });
      }
    });
  }

  appendMessage = (emitObj) =>{
    if(emitObj?.message){
      let chatObject :any = _.cloneDeep(emitObj);
      chatObject.portalId = chatObject.portal._id;
      let portalChat = _.cloneDeep(this.state.portal_chat);
      portalChat.unshift(chatObject);
      this.setState({portal_chat: portalChat});
      const scrollDiv = document.getElementById('chat_box_popover');
      if(scrollDiv){
        scrollDiv.scrollTop = scrollDiv.scrollHeight;
        }
    }
  }

  getLatestMessageIds = (portalList) =>{
    axiosInstance(`portal/latest-messages`, "post", {},{portalList}).then(response => {
      let res = _.get(response, "data");
      const success = res.success;
      if (success) {
        let latestMessages: Array<any> = res.object;
        let user = this.getUserLatestMessages();
        user.then((user:any)=>{
          if(latestMessages.length){
            if(user && user.lastMessages.length){
              latestMessages.forEach((portalLatestMessage)=>{
                if(user.lastMessages.some(obj => obj.portal === portalLatestMessage.portal)){
                  let portal:any = _.filter(user.lastMessages, function(obj) {return obj.portal === portalLatestMessage.portal});
                  if(portal[0].messageId !==  portalLatestMessage.messageId){
                    let portal: any[] = _.filter(portalList, function(obj) {return obj.id === portalLatestMessage.portal});
                    if(portal.length > 0){
                      let unreadMessageCountPromise = this.getUnreadMessagesCount(portal[0].id);
                      unreadMessageCountPromise.then((count)=>{
                        let element : any = document.querySelector(`[portal=${portal[0].slug}]`);
                        if(element){
                          if(!this.state.is_chat_open){
                            let span = element.querySelector("span");
                            span.innerText = count;
                          }
                          element.className= 'mx-2 my-2 btn btn-outline-warning'
                        }
                      });
                    }
                  }else{
                    let portal: any[] = _.filter(portalList, function(obj) {return obj.id === portalLatestMessage.portal})
                    if(portal.length > 0 ){
                      let element : any = document.querySelector(`[portal=${portal[0].slug}]`);
                      if(element){
                        let span = element.querySelector("span");
                        span.innerText = '';
                        element.className= 'mx-2 my-2 btn btn-outline-info'
                      }
                    }
                  }
                }else{
                  let portalList: Array<any> = this.state.portal_list;
                  let portal: any[] = _.filter(portalList, function(obj) {return obj.id === portalLatestMessage.portal})
                  if(portal.length > 0){
                    let element : any = document.querySelector(`[portal=${portal[0].slug}]`);
                    if(element){
                      element.className = 'mx-2 my-2 btn btn-outline-warning'
                    }
                  }
                }
              });
            }
          }
        });
      }
    }).catch(err => {
      const errors = (err && err.response && err.response.data) ? err.response.data.errors : 'something went wrong';
    });

  }

  getProjectLink = (portal) => {
      if(portal.slug && portal.version) {
          let url = `/${portal.slug}`;
          return generateVersionUrl(process.env.REACT_APP_CESIUM_VIEWER_URL, portal.version, url)
      }
  };

  showPopover = (portalSlug , target) => {
    clearTimeout(this.delayHidePopover);
    this.setState({ isPopoverOpen: true , portalSelected: portalSlug ,popoverTarget: target });
  }

  hidePopover = () => {
    this.delayHidePopover = setTimeout(() => {
      this.props.removeSelectedPortal();
      this.setState({ isPopoverOpen: false });
    }, 200); // delay of 200ms
  }

  cancelHidePopover = () => {
    clearTimeout(this.delayHidePopover);
  }

  createPortal = () =>{
    this.props.toggleCreatePortalComponent();
  }

  groupPortals = () =>{
    this.props.toggleGroupPortalComponent();
  }

  BackupPortal = (portal)=>{
    Confirm("Do you really want to backup this portal?","Yes, Backup portal")
        .then(async (result) => {
          if (result.value) {
            await this.props.onPortalBackup( portal.id, portal.name);
          }
        })
  }


  copyPortal = (portal)=>{
    Confirm("Do you really want to copy this portal?","Yes, Copy Portal")
        .then(async (result) => {
          if (result.value) {
            const { value: text } = await InputSWAL();
            if(text){
              const _portalDetails = {
                name: text,
                comments: '',
                image: '',
                customScripts: '',
                portalId: portal.id,
              };
              this.props.onPortalCreate(_portalDetails,true);
            }
          }
        });
  }


  render() {
    if(!this.props.loggedInUser || (!this.props.loggedInUser.isSuperUser && this.props.loggedInUser.portals.length <= 1)) {
      return <Redirect to="/"/>;
    }
    let table = null;
    let portal_list: PortalProps[] = this.state.portal_list;
    let group_list:GroupProps[] = this.props.group_list;
    let portalsInGroups = [];
    const expandedGroups = this.state.expandedPortalGroups;
    if(portal_list.length){
      this.getLatestMessageIds(portal_list);
    }
    let categorizedPortals = { "Ungrouped": [] };
    if (this.props.loggedInUser.isSuperUser) {
      // Group the portals by their groups attribute
      portal_list.forEach((portal:any) => {
        if (portal.groups.length > 0) {
          portal.groups.forEach((group) => {
            if (!categorizedPortals[group]) {
              categorizedPortals[group] = [];
            }
            categorizedPortals[group].push(portal);
          });
        } else {
          categorizedPortals["Ungrouped"].push(portal);
        }
      });
    }

    if (this.state.loading) {
      table = <Spinner/>;
    } else if (this.props.success) {
      table = (
          <>

            {this.props.loggedInUser.isSuperUser ? (
                    <>
                      <h2 style = {{marginTop: '30px'}}>Groups</h2>
                      {group_list.map((group:any, index) => {
                        // Filter portals in this group
                        const portalsInGroup= portal_list.filter((portal) => {
                          if (group.portalIds.includes(portal.id)) {
                            portalsInGroups.push(portal.id);
                            return true;
                          }
                          return false;
                        });

                        // Skip this group if it doesn't have any portals
                        if (portalsInGroup.length === 0) return null;

                        return (
                            <div key={index}>
                              {/* Group Header */}
                              <Card style={{ marginTop: "10px" }}>
                                <CardHeader
                                    onClick={() => this.toggleGroup(group._id)}
                                    style={{
                                      cursor: "pointer",
                                      backgroundColor: index/2 ===0 ? "lightgray" :"grey",
                                    }}
                                >
                                  {group.name}  <span style={{paddingLeft: '50px' , fontSize: 'small'}}>Created At: {group.createdAt.split("T")[0]}</span>
                                </CardHeader>
                                <Collapse isOpen={expandedGroups[group._id] || this.state.expandedGroupsBasedOnSearch[group._id]}>
                                  <CardBody style={{ marginLeft: "70px" }}>
                                    <Table responsive striped bordered>
                                      <thead>
                                      <tr>
                                        <th>Name</th>
                                        <th>Created At</th>
                                        <th>Size</th>
                                        <th>Comments</th>
                                        <th>Actions</th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      {portalsInGroup.map((portal:any,index:number) => (
                                          <tr key={portal.id}>
                                            <td>
                                              {
                                                <NavLink
                                                    id={`portal-name-${group.name.replace(/\s+/g, '')}-${portal.id}`}
                                                    to={`/details/${portal.slug}`}
                                                    onClick={() => {
                                                      this.props.changeSelectedPortal(portal);
                                                      this.props.setPortalSelected(portal);
                                                    }}
                                                    onMouseEnter={() => {
                                                      this.showPopover(portal.slug, `portal-name-${group.name.replace(/\s+/g, '')}-${portal.id}`);
                                                      this.props.changeSelectedPortal(portal)
                                                    }}
                                                    onMouseLeave={this.hidePopover}
                                                >
                                                  {portal.name}
                                                </NavLink>
                                              }
                                            </td>
                                            <td>{portal.createdAt.split("T")[0]}</td>
                                            <td>{portal?.size ? portal?.size.toFixed(2) : 0  } GB</td>
                                            <td>{portal.comments ? portal.comments : 'None'}</td>
                                            <td>
                                              {
                                                this.props.loggedInUser.isSuperUser ||
                                                _.find(this.props.loggedInUser.portals, (userPortal) => userPortal.portal.toString() === portal.id).isPortalAdmin ?
                                                    <>
                                                      {!portal.isActive ?
                                                          <Button outline className='mx-2 my-2' color="success"
                                                                  onClick={(e) => this.activateHandler(e, portal)}>
                                                            Activate
                                                          </Button>
                                                          :
                                                          <Button outline className='mx-2 my-2' color="danger"
                                                                  onClick={(e) => this.deactivateHandler(e, portal)}>
                                                            Deactivate
                                                          </Button>
                                                      }
                                                      {!portal.isActive ?
                                                          <Button outline className='mx-2 my-2' color="danger"
                                                                  onClick={(e) => this.deletePortalHandler(e, portal)}>
                                                            Delete
                                                          </Button>
                                                          : null
                                                      }

                                                      <NavLink to={{
                                                        pathname: "/update-portal/" + portal.id,
                                                        state: {
                                                          portalName: portal.name,
                                                          comments: portal.comments,
                                                          portalId: portal.id,
                                                          customScripts: portal.customScripts,
                                                          version: portal.version,
                                                          portalSlug: portal.slug
                                                        }
                                                      }}
                                                       onClick={() => {
                                                         this.props.changeSelectedPortal(portal);
                                                         this.props.setPortalSelected(portal);
                                                       }}>
                                                        <Button className='mx-2 my-2' outline color={"info"}><FontAwesomeIcon
                                                            icon={faEdit}/> Edit Details</Button>
                                                      </NavLink>
                                                    </> : null
                                              }

                                              <NavLink to={{pathname: this.getProjectLink(portal)}} target={VIEWER_WINDOW_NAME}>
                                                <Button className='mx-2 my-2' outline color={"success"} onClick={() => this.addUserStats(portal)}><FontAwesomeIcon
                                                    icon={faGlobe}/> 3D View</Button>
                                              </NavLink>

                                              <Button id={`chat_popover_${group.name.replace(/\s+/g, '')}_${index}`} portal={portal.slug} outline
                                                      className={'mx-2 my-2'} color="info"
                                                      onClick={(e) => this.toggleChatDialogue(e, portal, index, group.name.replace(/\s+/g, ''))}>
                                                Chat
                                                <span id={`chat_notify_${index}`} className="badge"></span>
                                              </Button>

                                              <Button className='mx-2 my-2' outline color={"info"} onClick={()=> this.BackupPortal(portal)}>Backup</Button>
                                              <Button className='mx-2 my-2' outline color={"info"} onClick={()=> this.copyPortal(portal)}>Copy</Button>

                                            </td>
                                          </tr>
                                      ))}
                                      </tbody>
                                    </Table>
                                  </CardBody>
                                </Collapse>
                              </Card>
                            </div>
                        );
                      })}

                      <Card style={{ marginTop: "10px"}}>
                        <CardHeader
                            onClick={() => this.toggleGroup('Ungrouped')}
                            style={{
                              cursor: "pointer",
                              backgroundColor: "lightgray",
                            }}
                        >
                          {'Ungrouped'}
                        </CardHeader>
                      {/* Loop through the portals to find ungrouped ones */}
                          <Collapse isOpen={expandedGroups['Ungrouped'] || this.state.isUngroupedExpanded}>
                            <CardBody style={{ marginLeft: "70px" }}>
                              <Table responsive striped bordered>
                                <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Created At</th>
                                  <th>Size</th>
                                  <th>Comments</th>
                                  <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {portal_list.map((portal:any,index:number) => {
                                  // Skip if this portal is in a group
                                  if (portalsInGroups.includes(portal.id)) return null;

                                  return (
                                      <tr key={portal.id}>
                                        <td>
                                          {
                                            <NavLink
                                                id={`portal-name-Ungrouped-${portal.id}`}
                                                to={`/details/${portal.slug}`}
                                                onClick={() => {
                                                  this.props.changeSelectedPortal(portal);
                                                  this.props.setPortalSelected(portal);
                                                }}
                                                onMouseEnter={() => {
                                                  this.showPopover(portal.slug, `portal-name-Ungrouped-${portal.id}`);
                                                  this.props.changeSelectedPortal(portal)
                                                }}
                                                onMouseLeave={this.hidePopover}
                                            >
                                              {portal.name}
                                            </NavLink>
                                          }
                                        </td>
                                        <td>{portal.createdAt.split("T")[0]}</td>
                                        <td>{portal?.size ? portal?.size.toFixed(2) : 0  } GB</td>
                                        <td>{portal.comments ? portal.comments : 'None'}</td>
                                        <td>
                                          {
                                            this.props.loggedInUser.isSuperUser ||
                                            _.find(this.props.loggedInUser.portals, (userPortal) => userPortal.portal.toString() === portal.id).isPortalAdmin ?
                                                <>
                                                  {!portal.isActive ?
                                                      <Button outline className='mx-2 my-2' color="success"
                                                              onClick={(e) => this.activateHandler(e, portal)}>
                                                        Activate
                                                      </Button>
                                                      :
                                                      <Button outline className='mx-2 my-2' color="danger"
                                                              onClick={(e) => this.deactivateHandler(e, portal)}>
                                                        Deactivate
                                                      </Button>
                                                  }
                                                  {!portal.isActive ?
                                                      <Button outline className='mx-2 my-2' color="danger"
                                                              onClick={(e) => this.deletePortalHandler(e, portal)}>
                                                        Delete
                                                      </Button>
                                                      : null
                                                  }

                                                  <NavLink to={{
                                                    pathname: "/update-portal/" + portal.id,
                                                    state: {
                                                      portalName: portal.name,
                                                      comments: portal.comments,
                                                      portalId: portal.id,
                                                      customScripts: portal.customScripts,
                                                      version: portal.version,
                                                      portalSlug: portal.slug
                                                    }
                                                  }}
                                                   onClick={() => {
                                                     this.props.changeSelectedPortal(portal);
                                                     this.props.setPortalSelected(portal);
                                                   }}>
                                                    <Button className='mx-2 my-2' outline color={"info"}><FontAwesomeIcon
                                                        icon={faEdit}/> Edit Details</Button>
                                                  </NavLink>
                                                </> : null
                                          }

                                          <NavLink to={{pathname: this.getProjectLink(portal)}} target={VIEWER_WINDOW_NAME}>
                                            <Button className='mx-2 my-2' outline color={"success"} onClick={() => this.addUserStats(portal)}><FontAwesomeIcon
                                                icon={faGlobe}/> 3D View</Button>
                                          </NavLink>

                                          <Button id={`chat_popover_Ungrouped_${index}`} portal={portal.slug} outline
                                                  className={'mx-2 my-2'} color="info"
                                                  onClick={(e) => this.toggleChatDialogue(e, portal, index, 'Ungrouped')}>
                                            Chat
                                            <span id={`chat_notify_${index}`} className="badge"></span>
                                          </Button>
                                          <Button className='mx-2 my-2' outline color={"info"} onClick={()=> this.BackupPortal(portal)}>Backup</Button>
                                          <Button className='mx-2 my-2' outline color={"info"} onClick={()=> this.copyPortal(portal)}>Copy</Button>
                                        </td>
                                      </tr>
                                  );
                                })}
                                </tbody>
                              </Table>
                            </CardBody>
                          </Collapse>
                        </Card>
                    </>
                )
                :
                <div>
                  <Table responsive striped bordered>
                    <thead>
                    <tr>
                      <th>Name</th>
                      <th>Created At</th>
                      <th> Comments</th>
                      <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>

                    {portal_list.filter(portal => portal.slug).map((portal: PortalProps, index: number) => (
                        <tr key={portal.name}>
                          <td>
                            {
                              this.props.loggedInUser.isSuperUser ||
                              _.find(this.props.loggedInUser.portals, (userPortal) => userPortal.portal.toString() === portal.id).isPortalAdmin ?
                                  <NavLink id={`portal-name-${index}`}
                                           to={`/details/${portal.slug}`}
                                           onClick={() => {
                                             this.props.changeSelectedPortal(portal);
                                             this.props.setPortalSelected(portal);
                                           }}
                                           onMouseEnter={() => {
                                             this.showPopover(portal.slug, `portal-name-${index}`);
                                             this.props.changeSelectedPortal(portal)
                                           }}
                                           onMouseLeave={this.hidePopover}>
                                    {portal.name}
                                  </NavLink> :
                                  <>
                                    {portal.name}
                                  </>
                            }

                          </td>
                          <td>{portal.createdAt.split("T")[0]}</td>
                          <td>{portal.comments ? portal.comments : 'None'}</td>
                          <td>
                            {
                              this.props.loggedInUser.isSuperUser ||
                              _.find(this.props.loggedInUser.portals, (userPortal) => userPortal.portal.toString() === portal.id).isPortalAdmin ?
                                  <>
                                    {!portal.isActive ?
                                        <Button outline className='mx-2 my-2' color="success"
                                                onClick={(e) => this.activateHandler(e, portal)}>
                                          Activate
                                        </Button>
                                        :
                                        <Button outline className='mx-2 my-2' color="danger"
                                                onClick={(e) => this.deactivateHandler(e, portal)}>
                                          Deactivate
                                        </Button>
                                    }
                                    {!portal.isActive ?
                                        <Button outline className='mx-2 my-2' color="danger"
                                                onClick={(e) => this.deletePortalHandler(e, portal)}>
                                          Delete
                                        </Button>
                                        : null
                                    }

                                    <NavLink to={{
                                      pathname: "/update-portal/" + portal.id,
                                      state: {
                                        portalName: portal.name,
                                        comments: portal.comments,
                                        portalId: portal.id,
                                        customScripts: portal.customScripts,
                                        version: portal.version,
                                        portalSlug: portal.slug
                                      }
                                    }}>
                                      <Button className='mx-2 my-2' outline color={"info"}><FontAwesomeIcon
                                          icon={faEdit}/> Edit Details</Button>
                                    </NavLink>
                                  </> : null
                            }

                            <NavLink to={{pathname: this.getProjectLink(portal)}} target={VIEWER_WINDOW_NAME}>
                              <Button className='mx-2 my-2' outline color={"success"} onClick={() => this.addUserStats(portal)}><FontAwesomeIcon
                                  icon={faGlobe}/> 3D
                                View</Button>
                            </NavLink>

                            <Button id={`chat_popover_${index}`} portal={portal.slug} outline className={'mx-2 my-2'}
                                    color="info"
                                    onClick={(e) => this.toggleChatDialogue(e, portal, index)}>
                              Chat
                              <span id={`chat_notify_${index}`} className="badge"></span>
                            </Button>
                          </td>
                        </tr>
                    ))
                    }
                    </tbody>
                  </Table>
                  {!!this.state.is_chat_open ?
                      <Popover className='chat_popover'
                               placement="left"
                               isOpen={!!this.state.is_chat_open}
                               target={this.state.chat_popover}>
                        <div className='text-right'>
                        </div>
                        <PopoverBody>
                          <MessageListing
                              portal={this.state.selected_portal_chat}
                              portal_chat={this.state.portal_chat}
                              getPortalMessages={this.getPortalMessages}
                          />
                        </PopoverBody>
                      </Popover>
                      :
                      null}
                </div>
            }
            {(this.state.isPopoverOpen && this.props.loggedInUser.isSuperUser) &&
                <Popover placement="left" isOpen={this.state.isPopoverOpen} target={this.state.popoverTarget} className="project_list_popover">
                  <PopoverBody onMouseOver={this.cancelHidePopover}
                               onMouseOut={this.hidePopover}>
                    <GroupProjects portalSlug={this.state.portalSelected}/>
                  </PopoverBody>
                </Popover>
            }
            {!!this.state.is_chat_open ?
                <Popover className='chat_popover'
                         placement="left"
                         isOpen={!!this.state.is_chat_open}
                         target={this.state.chat_popover}>
                  <div className='text-right'>
                  </div>
                  <PopoverBody>
                    <MessageListing
                        portal={this.state.selected_portal_chat}
                        portal_chat={this.state.portal_chat}
                        getPortalMessages={this.getPortalMessages}
                    />
                  </PopoverBody>
                </Popover>
                :
                null}
          </>
      );
    }
    if (!this.props.loading && !this.props.success) {
      table = "Error fetching portals, please try again!";
    }

    return (
      <>
        <Container className="centerForm text-left mb-4">
          <h1 className="heading text-center">Portals</h1>
          <Row className="justify-content-center">
            <Col lg={5}>
              <InputGroup>
                {
                  this.props.loggedInUser.isSuperUser ?
                    <>
                      <Col lg="3">
                      <Input type="select" className="small-dropdown" onChange={(e) => this.setState({ selectedList: e.target.value })}>
                        <option value="portal_list">Portal Name</option>
                        <option value="project_list">Project Name</option>
                        <option value="dataset_list">Dataset Name</option>
                        <option value="user_list">User Email</option>
                      </Input>
                      </Col>
                      <Col lg="7">
                        <Input onChange={(e) => this.onSearchPortals(e)} value={this.state.search_term} ref="searchField" className="inputItem" placeholder="Search Portals" type="text"/>
                      </Col>
                      <Col lg="2">
                        <Button style={{margin:'4px', marginLeft: '0px'}} outline  onClick={this.clearSearch} disabled={!this.state.search_term} >
                        Clear
                        </Button>
                      </Col>
                    </> :
                      <>
                      <Input onChange={(e) => this.onSearchPortals(e)} value={this.state.search_term} ref="searchField" className="inputItem" placeholder="Search Portals" type="text"/>
                      <Button className={'ml-2'} outline  onClick={this.clearSearch} disabled={!this.state.search_term} >Clear
                      </Button>
                      </>
                }

              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              {table}
            </Col>
          </Row>

        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.login.token,
    portal_list: state.portalListReducer.portal_list,
    group_list: state.portalListReducer.group_list,
    user_list: state.portalListReducer.user_list,
    project_list: state.portalListReducer.project_list,
    dataset_list: state.portalListReducer.dataset_list,
    success: state.portalListReducer.get_portal_list_success || state.portalListReducer.changeStateSuccess,
    loading: state.portalListReducer.get_portal_list_loading,
    changeStatusLoading: state.portalListReducer.changeStateLoading,
    loggedInUser: state.login.loggedInUser,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryFetchPortalList: () => dispatch(actions.get_portal_list()),
    onTryFetchPortalsGroupList: () => dispatch(get_portal_group_list()),
    onTryFetchUsersList: ()=> dispatch(get_all_users_list()),
    onTryFetchDatasetsList: ()=> dispatch(get_all_datasets_list()),
    onTryFetchProjectsList: ()=> dispatch(get_all_projects_list()),
    changeSelectedPortal: (portal) => dispatch(changeSelectedPortal(portal)),
    onChangePortalStatus: (status, portalId, portalName) => dispatch(changeStatusPortal(status, portalId, portalName)),
    onPortalBackup: (portalId, portalName)  => dispatch(changeStatusPortalBackup(portalId, portalName)),
    setPortalSelected: (portal) => dispatch(set_portal_selected(portal)),
    removeSelectedPortal: () => dispatch(remove_selected_portal()),
    onPortalCreate: (portalDetails, createCopy) => dispatch(create_portal(portalDetails,createCopy)),

  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Portals);
