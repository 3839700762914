export const setCookie = (authToken: string, domainName: string) => {
    const isProduction = process.env.REACT_APP_PROD_VERSION==="true";
    let _currentDate = new Date();
    const _forecastDate = new Date(_currentDate);
    _forecastDate.setDate(_forecastDate.getDate() + 6);
    // path is added to access cookie on the all domains contain auav.com.au
    document.cookie=`_insiteToken=${authToken};domain=${domainName};expires=${_forecastDate.toUTCString()};path=/;${isProduction ? 'secure' : ''}`;
    return true;
};

export const deleteCookie = () => {
    const isProduction = process.env.REACT_APP_PROD_VERSION==="true";
    const _domainName = process.env.REACT_APP_DOMAIN;
    var cookies = document.cookie.split(";");
    let _currentDate = new Date();
    const _forecastDate = new Date(_currentDate);
    for (let i = 0; i < cookies.length; i++) {
        document.cookie = `_insiteToken=;domain=${_domainName};expires=${_forecastDate.toUTCString()};path=/;${isProduction ? 'secure' : ''}`;
        document.cookie = `signature=;domain=${_domainName};expires=${_forecastDate.toUTCString()};path=/;${isProduction ? 'secure' : ''}`;
    }
};


export const checkCookieExist = (cookieName: string = '_insiteToken') => {
    var match = document.cookie.match(new RegExp('(^| )' + cookieName + '=([^;]+)'));
    if (match) return match[2];
};