import React from "react";
import {UserDetailsProps, UserDetailsState} from "../../interfaces/UserDetails";
import {connect} from "react-redux";
import UserPermissions from "./Permissions";
import {get} from "lodash";
import {Button, Container, Row} from "reactstrap";
import _ from "lodash";


class UserDetails extends React.Component<UserDetailsProps, UserDetailsState> {
  goBack = () => {
    const portalSlug = _.get(this.props.match.params, 'portalSlug') || _.get(this.props.match.params, 'portal');
    this.props.history.push(`/details/${portalSlug}`);
  };

  render() {
    return (
      <Container>
        <Row>
          <h1 className="heading" style={{textAlign: "center"}}>User Details</h1>
          <UserPermissions userId={get(this.props.match.params, "userId")}/>
          <div className='d-flex align-items-center justify-content-center w-100 mt-4'>
            <Button color="secondary" onClick={() => { this.goBack() }}>
                Back
            </Button>
          </div>
        </Row>
      </Container>
    )
  }
}

export default connect(null, null)(UserDetails);
