import React, {Component} from "react";
import {connect} from "react-redux";
import {getPendingApprovals, approveUser, declineUser} from "../../store/actions/pendingApprovals";
import {PendingApprovalsProps, PendingApprovalsState} from "../../interfaces/pendingApprovals";
import Spinner from "../UI/Spinner/Spinner";
import {Button, Col, Container, Row, Table} from "reactstrap";
import {User} from "../../interfaces/auth";
import {Confirm} from "../../SWAL";

class PendingApprovals extends Component<PendingApprovalsProps, PendingApprovalsState> {

  componentDidMount() {
    setTimeout(this.props.getPendingApprovals, 10)
  }

  handleUserDecline = (userId: string, userName: string) => {
    Confirm("Do you really want to decline this approval request?", "Yes, Decline!")
      .then(result => {
        if(result.value) {
          this.props.declineUser(userId, userName)
        }
      })
  };

  render() {
    let table = (<div>No pending approval</div>);
    if (this.props.loading) {
      table = <Spinner/>;
    } else {
      table = (<div>
        <Table responsive striped bordered>
          <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Email</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          {this.props.users.length > 0 ?
            this.props.users.map((user: User, index: number) => (
              <tr key={user.id}>
                <th scope="row">{index + 1}</th>
                <td>{user.firstName} {user.lastName}</td>
                <td>{user.email}</td>
                <td>
                  <Button outline color="success"
                          onClick={() => this.props.approveUser(user.id, user.firstName + " " + user.lastName)}>Approve
                  </Button>
                  &nbsp;
                  <Button outline color="danger"
                          onClick={() => this.handleUserDecline(user.id, user.firstName + " " + user.lastName)}>Decline
                  </Button>
                </td>
              </tr>
            )) : (<tr aria-colspan={2}>
              <td><h5>No pending approvals</h5></td>
            </tr>)
          }
          </tbody>
        </Table>
      </div>)
    }

    return (
      <div>
        <Container className="centerForm text-left mb-4">
          <Row>
            <Col lg={12}>
              <h1 className="heading text-center">Users Pending Approval</h1>
              {table}
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPendingApprovals: () => dispatch(getPendingApprovals()),
    approveUser: (userId: string, userName: string) => dispatch(approveUser(userId, userName)),
    declineUser: (userId: string, userName: string) => dispatch(declineUser(userId, userName))
  }
};

const mapStateToProps = (state) => {
  return {
    loading: state.pendingApprovalUsers.loading,
    users: state.pendingApprovalUsers.users,
    portal: state.portal,
    isSuperUser: state.login.isSuperUser,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(PendingApprovals);
