export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_FAILURE = 'CREATE_PROJECT_FAILURE';
export const CREATE_PROJECT_START = 'CREATE_PROJECT_START';
export const GET_PROJECT_LIST_START = 'GET_PROJECT_LIST_START';
export const GET_PROJECT_LIST_SUCCESS = 'GET_PROJECT_LIST_SUCCESS';
export const GET_PROJECT_LIST_FAIL = 'GET_PROJECT_LIST_FAIL';
export const CLEAR_PROJECT_STATE = 'CLEAR_PROJECT_STATE';

export const CHANGE_PROJECT_STATE_START = "CHANGE_PROJECT_STATE_START";
export const CHANGE_PROJECT_STATE_SUCCESS = "CHANGE_PROJECT_STATE_SUCCESS";
export const CHANGE_PROJECT_STATE_FAIL = "CHANGE_PROJECT_STATE_FAIL";


export const UPDATE_PROJECT_START = "UPDATE_PROJECT_START";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";
export const UPDATE_PROJECT_FAIL = "UPDATE_PROJECT_FAIL";

export const GET_PROJECT_START = "GET_PROJECT_START";
export const GET_PROJECT_SUCCESS = "GET_PROJECT_SUCCESS";
export const GET_PROJECT_FAIL = "GET_PROJECT_FAIL";

export const SET_PROJET_SELECTED = "SET_PROJET_SELECTED";
export const CLEAR_PROJET_SELECTED = "CLEAR_PROJET_SELECTED";