import React from "react";
import {connect} from "react-redux";
import * as _ from "lodash";
import {Button, Col, Label, Input, Row} from "reactstrap";
import {Confirm} from "../../SWAL";
import { PortalVersionState, PortalVersionProps, IVersion } from "../../interfaces/versions";
import { getAvailableVersions } from "../../store/actions/portalVersions";
import { updatePortal } from "../../store/actions/portal";

class PortalVersion extends React.Component<PortalVersionProps, PortalVersionState> {
  constructor(props) {
    super(props);
    this.state = {
      selectedVersion: this.props.selectedPortal ? this.props.selectedPortal.version : 'latest',
      portal: _.get(props.match.params, "portalSlug"),
      versions: [],
      comments: this.props.selectedPortal ? this.props.selectedPortal.comments : '',
      customScripts: this.props.selectedPortal ? this.props.selectedPortal.customScripts : '',
    };
  }
  componentDidMount(): void {
    this.onVersionChanged(this.state.selectedVersion);
    this.props.getAvailableVersions();
  }

  onVersionChanged(version) {
    this.setState({ selectedVersion: version });
  }

  async handleVersionStateChange() {
    let portal = this.props.selectedPortal;
    if (!this.state.selectedVersion) {
      return;
    }
    let versionId: string = this.state.selectedVersion;
    Confirm(`Do you really want to change version to ${versionId}?`, `Yes, Change`)
      .then(async result => {
        if (result.value) {
          await this.props.updatePortalVersion(portal.slug, portal.name, portal.comments, portal.id,portal?.companyLogo,portal?.companyLogoSize, portal.customScripts, versionId,portal?.accessibleAnnotations,portal?.companyLogoLink);
        }
      })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedPortal && this.state.selectedVersion != nextProps.selectedPortal.version) {
      this.setState({ selectedVersion: nextProps.selectedPortal.version });
    }
  }

  render() {
    return (
      <Col className="centerForm" lg={12}>
        <h1 className="heading">Portal Version</h1>
        <Row className="px-2 d-flex justify-content-center">
          <Col lg={4} sm={6}>
            <Input value={this.state.selectedVersion} onChange={(event) => this.onVersionChanged(event.target.value)} type='select' name='select' className="inputItem">
              { (this.props.versions || [{ name: 'latest' }]).map((value, index) => {
                return <option className="capitalizeText" key={index} value={value.name}> {value.name} </option>
              })};
            </Input>
          </Col>
          <Col lg={2} sm={3} className="d-flex justify-content-left">
            <Label className="labelText" />
            <Button disabled={!this.props.selectedPortal?.version} onClick={() => this.handleVersionStateChange()} color="success" outline>
                Save
            </Button>
          </Col>
        </Row>
        <br></br>
      </Col>
    )
  }
}

const mapStateToProps = ({ login, portalVersion, portalListReducer }) => {
  return {
    versions: portalVersion.versions,
    loggedInUser: login.loggedInUser,
    success: portalVersion.success,
    message: portalVersion.message,
    error: portalVersion.error,
    selectedPortal: portalListReducer.selectedPortal
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getAvailableVersions: () => dispatch(getAvailableVersions()),
    updatePortalVersion: (portalSlug: string, portalName: string, portalComment: string, portal: string,portalLogo: string,portalLogoSize:string, scripts: string, versionId: string, accessibleAnnotations: any, companyLogoLink:string) => dispatch(updatePortal(portalSlug, portalName, portalComment , portal, portalLogo,portalLogoSize,companyLogoLink, scripts, versionId,accessibleAnnotations)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(PortalVersion);
