import React, {Component} from "react";
import {Button, Col, Input, InputGroup, Row, Table} from "reactstrap";
import {NavLink} from "react-router-dom";
import * as _ from "lodash";
import {
    GroupPortalProjectsProps, IGroupPortalProjectStates,
    IPortalProjectStates,
    PortalProjectsProps,
    ProjectProps
} from "../../interfaces/portal";
import {generatePortalSpecificUrl, generateVersionUrl} from "../../helpers/urlGenerators";
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import {Confirm} from "../../SWAL";
import {changeProjectState} from "../../store/actions";
import {hasPermissionToCreateProjects, hasPermissionToChangeProject, hasPermissionToViewProject, hasPermissionToDeleteProject} from "../../PermissionValidators";
import Spinner from "../UI/Spinner/Spinner";
import {deleteProject} from "../../store/actions/project";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faGlobe, faEdit} from "@fortawesome/free-solid-svg-icons";
import { VIEWER_WINDOW_NAME } from "../../helpers/utils";

class GroupProjects extends Component<GroupPortalProjectsProps, IGroupPortalProjectStates> {

    constructor(props) {
        super(props);
        this.state = {
            portalSlug: props?.portalSlug,
            project_list: this.props?.project_list || [],
            search_term: '',
            AddSubProjectModal: false,
            selectProjectId: '',
        };
    };

    componentDidMount(): void {
        this.props.onTryFetchProjectList(_.get(this.state, "portalSlug"));
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.project_list !== this.props.project_list) {
            this.setState({project_list: this.props.project_list});
        }
    }

    getProjectLink(item) {
        if(this.props.selectedPortal) {
            let url = `/${this.state.portalSlug}/${item.slug ? item.slug : item.id}`;
            return generateVersionUrl(process.env.REACT_APP_CESIUM_VIEWER_URL, this.props.selectedPortal['version'], url)
        }
    }

    onSearchProjects = (e) => {
        this.setState({search_term: e.target.value});
        const search_term = e.target.value.toString().toLowerCase().trim();
        let filtered_results: ProjectProps[];
        filtered_results = this.props.project_list.filter((project: ProjectProps) => {
            return project.name.toLowerCase().includes(search_term);
        });
        this.setState({project_list: filtered_results});
    };

    clearSearch = (e) => {
        this.onSearchProjects({ target: { value: '' } });
    }

    HandleAddSubProjectChange = async (item) => {
        this.setState({AddSubProjectModal: true, selectProjectId: item.id});
    };

    closeAddSubProjectModal = () => {
        this.setState({ AddSubProjectModal: false, selectProjectId: ''});
    };


    render() {
        // Group projects by year
        const projectsByYear = this.state.project_list.reduce((acc, project) => {
            const year = new Date(project.createdAt).getFullYear();
            if (!acc[year]) {
                acc[year] = [];
            }
            acc[year].push(project);
            return acc;
        }, {});

        // Sort years in descending order
        const sortedYears = Object.keys(projectsByYear).sort((a:any, b:any) => b - a);

        return (
            <>
                <Col className="centerForm projectList" lg={12}>
                    {sortedYears.map(year => (
                        <details key={year}>
                            <summary><h2 style={{marginBottom: '0px'}}>{year}</h2></summary>
                            <Table responsive striped bordered>
                                <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.props.loading ? (
                                    <tr>
                                        <td colSpan={2}>
                                            <Spinner/>
                                        </td>
                                    </tr>
                                ) : null}
                                {!this.props.loading &&
                                    projectsByYear[year]?.map((item, index) => {
                                        return ((this.props.isSuperUser || hasPermissionToViewProject(item.id)) &&
                                            <tr key={index}>
                                                <td>
                                                    {(this.props.isSuperUser || this.props.isPortalAdmin || hasPermissionToChangeProject(item.id)) && item && item.isActive ?
                                                        <NavLink
                                                            className="ml-3"
                                                            to={{
                                                                pathname:  `/details/${this.state.portalSlug}/project/${item.id}`,
                                                                state: {projectId: item.id, portalSlug: this.state.portalSlug}
                                                            }}>{item.name} </NavLink>
                                                        : <span> {item.name} </span>
                                                    }
                                                </td>
                                                <td>
                                                    <NavLink className='mx-2 my-2' to={{ pathname: this.getProjectLink(item)}} target={VIEWER_WINDOW_NAME}>
                                                        <Button outline color={"success"}><FontAwesomeIcon icon={faGlobe}/></Button>
                                                    </NavLink>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </details>
                    ))}
                </Col>
            </>
        );
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        userId: state.login.loggedInUser.id,
        token: state.login.token,
        project_list: state.project.project_list,
        success: state.project.get_project_list_success,
        loading: state.project.get_project_list_loading,
        isSuperUser: state.login.isSuperUser,
        isPortalAdmin: state.login.isPortalAdmin,
        selectedPortal: state.portalListReducer.selectedPortal,
        permissions: state.userPermissions.permissions
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onTryFetchProjectList: (portal_name) => dispatch(actions.get_project_list(portal_name)),
        onChangeProjectStatus: (action: string, portalSlug: string, projectId: string, projectName: string) => dispatch(changeProjectState(action, portalSlug, projectId, projectName)),
        onProjectDelete: (portalSlug: string, projectId: string, projectName: string) => dispatch(deleteProject(portalSlug, projectId, projectName)),
        onClearProjectState: () => dispatch(actions.clear_project_state())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupProjects);
