import React, {Component} from 'react';
import {Collapse, Button} from 'reactstrap';
import Switch from "react-switch";
import {connect} from 'react-redux';
import {changeObjectPermissions} from "../../store/actions/permission";
import {Confirm} from "../../SWAL";
import {PermissionsCollapseProps} from "../../interfaces/permissions";

class PermissionsCollapse extends Component<PermissionsCollapseProps> {
  state = {
    isOpen: false,
    checked: []
  };
  toggle = () => this.setState({isOpen: !this.state.isOpen});
  handleChange = (index, perm) => {
    let checked = this.state.checked;
    checked[index] = !checked[index];
    const requestBody = {
      "permissions": {
        "name": perm,
        "objectId": this.props.objectId,
        "objectModel": this.props.objectModel
      }
    };
    if (!checked[index]) {
      Confirm("Do you really want to remove this permission", "Yes remove!")
        .then(result => {
          if (result.value) {
            this.props.changeObjPermissions(this.props.portalSlug, this.props.userId, requestBody, "remove-permission");
          }
        })
    }
    else {
      this.props.changeObjPermissions(this.props.portalSlug, this.props.userId, requestBody, "give-permission");
    }
    this.setState({checked: checked});
  };

  render() {
    return (
      <div>
        <Button size={"sm"} color="info" onClick={this.toggle}>Show Permissions</Button>
        <Collapse isOpen={this.state.isOpen}>
          {["CAN_VIEW", "CAN_CHANGE", "CAN_DELETE"].map((perm, index) => {
            return (
              <>
                <label key={index}>
                  <p className="mt-2">{perm.replace("_", " ")}</p>
                  <Switch
                    checked={this.state.checked[index]}
                    onChange={() => this.handleChange(index, perm)}
                    onColor="#86d3ff"
                    onHandleColor="#2693e6"
                    handleDiameter={30}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={20}
                    width={48}
                    className="react-switch"
                    id="material-switch"
                  />
                </label>
                <br/>
              </>
            );
          })
          }
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
  }
};

const mapDispatchToProps = dispatch => {
  return {
    changeObjPermissions: (portalSlug, userId, requestBody, action) => dispatch(changeObjectPermissions(portalSlug, userId, requestBody, action))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(PermissionsCollapse);
