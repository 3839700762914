import React from "react";
import {connect} from "react-redux";
import {UserModelPermissionProps, UserModelPermissionState} from "../../interfaces/permissions";
import {get} from "lodash";
import {Table} from "reactstrap";
import Switch from "react-switch";
import {ChangeModelPermissions} from "../../store/actions/changeUserPermissions";
import {Toast} from "../../SWAL";
import _ from "lodash";


class ModelLevelPermissions extends React.Component<UserModelPermissionProps, UserModelPermissionState> {

  componentDidMount(): void {
    this.fillState();
  }

  fillState = () => {
    let modelLevelPermissions = get(this.props.portalUserDetails.permissions, "ModelPermissions", {});
    const finalState = Object.keys(modelLevelPermissions).reduce((allPermissions, permissionType) => {
      return { ...allPermissions, ...modelLevelPermissions[permissionType] };
    }, {});
    this.setState(finalState);
  }

  normalize = (str: string) => {
    str = str.split("_").join(" ");
    str = str.toLowerCase();
    return str.charAt(0).toUpperCase() + str.substring(1);
  }

  changePermission = (permName, model) => {
    let newState = {};
    let existingPermissionValue = get(this.state, permName);
    newState[permName] = !existingPermissionValue;
    let action: "give-model-permission" | "remove-model-permission" = "give-model-permission";
    if (existingPermissionValue) {
      action = "remove-model-permission";
    }

    ChangeModelPermissions(this.props.portalSlug, this.props.userId, action, {name: permName, model})
      .then(result => {
        Toast.fire("Success", "Permission successfully changed", "success");
      })
      .catch(e => {
        Toast.fire("Failed", "Permission changing failed!", "error");
      });
    this.setState(newState);
  }

  render() {
    let modelLevelPermissions = get(this.props.portalUserDetails.permissions, "ModelPermissions", {});
    return (
      <div className={'row'}>
        <h6 className="subheading">Model Level Permissions</h6>
        <Table responsive striped bordered small={'true'}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Permissions</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(modelLevelPermissions).map((item, index) =>
                  <tr key={index}>
                    <td>{item}</td>
                    <td>
                      {Object.keys(modelLevelPermissions[item]).map((permName, pIndex) =>
                        <span style={{display: "inline-block", width: "25%"}} key={`model_perm_${pIndex}`}>
                          <Switch
                            checked={get(this.state, permName) ? get(this.state, permName) : false }
                            onChange={() => {this.changePermission(permName, item)}}
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                            className="react-switch"
                            id="material-switch"
                          />
                        <div key={pIndex}>{permName === "CAN_VIEW_PROJECT" && !this.props.isPortalAdmin ? "Can view all projects" : this.normalize(permName)}</div></span>
                      )}
                    </td>
                  </tr>
              )}
            </tbody>
        </Table>
      </div>
    )
  }
}

export default connect(null, null)(ModelLevelPermissions);
