import * as portalActionTypes from "./actionTypes/portalActionTypes";
import axios from "axios";
import LocalStorageWrapped from "../../LocalStorageWrapped";

export const detectPortalStart = () => {
  return {
    type: portalActionTypes.DETECT_PORTAL_START,
  }
};

export const detectPortalFail = () => {
  return {
    type: portalActionTypes.DETECT_PORTAL_FAIL,
  }
};

export const detectPortalSuccess = (portalName: string, found: boolean, portalType: string, id: string, slug: string, version) => {
  return {
    type: portalActionTypes.DETECT_PORTAL_SUCCESS,
    portalName,
    found,
    portalType,
    id,
    slug,
    version
  }
};

export const detectPortal = (location: Location) => {

  return (dispatch: any) => {
    dispatch(detectPortalStart());
    let path = location.pathname;
    let pathList = path.split("/");
    let subdomain = location.hostname.split(".");
    let portalSlugFromURL = null;
    let portalType = "url";
    if (pathList.length > 2) {
      portalSlugFromURL = pathList[1];
    } else if (subdomain.length > 1) {
      portalSlugFromURL = subdomain[0];
      portalType = "subdomain";
    }
    const portalInfo = LocalStorageWrapped.getItem("portalInfo");
    let portalSlugFromLocal = portalInfo ? portalInfo.portalSlug : null;
    if (!portalSlugFromLocal || portalSlugFromLocal === "") {
      portalSlugFromLocal = portalInfo ? portalInfo.portalName : null;
    }
    // if ((portalSlugFromLocal || portalSlugFromURL)) {
    const authInfo = LocalStorageWrapped.getItem("authInfo");
      if(!portalSlugFromURL && authInfo && portalSlugFromLocal) {
        dispatch(detectPortalSuccess(portalSlugFromLocal, true, portalInfo ? portalType : null,
          portalInfo ? portalInfo.portalId: null, portalInfo ? portalInfo.portalSlug : null, portalInfo ? portalInfo.version : null));
        return;
      }

    // }

    if (!portalSlugFromURL) {
      dispatch(detectPortalFail());
      return;
    }

    axios.post(process.env.REACT_APP_API_HOST + "portal/verify", JSON.stringify({slug: portalSlugFromURL}), {
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(response => {
        let res = response.data;
        if (res.success) {
          const portalInfo = {
            portalName: res.object.name,
            portalId: res.object.id,
            portalType: portalType,
            portalSlug: res.object.slug,
            version: res.object.version,
            createdAt: res.object.createdAt,
            id: res.object.id,
            name: res.object.name,
            slug: res.object.slug,
            customScripts: res.object.customScripts,
            accessibleFolders: res.object.accessibleFolders
          };
          LocalStorageWrapped.setItem("portalInfo", portalInfo);
          LocalStorageWrapped.setItem("selectedPortal", portalInfo);
          dispatch(detectPortalSuccess(res.object.name, true, portalType, res.object.id, res.object.slug, res.object.version));
        } else {
          dispatch(detectPortalFail());
        }
      })
  }
};

