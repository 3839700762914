import * as PortalGroupActionTypes from "./actionTypes/groupActionTypes";
import {axiosInstance} from "../../Axios";
import * as _ from "lodash";


const getUserGroupsStart = () => {
  return {
    type: PortalGroupActionTypes.GET_USER_GROUPS_START
  }
};

const getUserGroupsSuccess = (groups) => {
  return {
    type: PortalGroupActionTypes.GET_USER_GROUPS_SUCCESS,
    groups
  }
};

const getUserGroupsFail = (error) => {
  return {
    type: PortalGroupActionTypes.GET_USER_GROUPS_FAIL,
    error
  }
};

export const getUserGroups = (portalSlug: string, userId: string) => {
  return async dispatch => {
    dispatch(getUserGroupsStart());
    let response = null;
    let url = `${portalSlug}/users/groups/${userId}`;
    try {
      response = await axiosInstance(url, "GET");
    } catch (e) {
      return dispatch(getUserGroupsFail(e.message));
    }
    let groups = _.get(response, "data.result");
    return dispatch(getUserGroupsSuccess(groups));
  }
};
