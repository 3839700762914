import React, {Component} from 'react';
import { Button, Form, Input, Label, FormGroup, Container, Row, Col } from 'reactstrap';
import axios, { AxiosResponse } from 'axios';
import {
    GroupPortalProps,
    IGroupPortalStates
} from "../../interfaces/portal";
import {connect} from "react-redux";
import * as actions from "../../store/actions";
import {changeProjectState, get_project_list} from "../../store/actions";
import {deleteProject} from "../../store/actions/project";
import {axiosInstance} from "../../Axios";
import {Toast} from "../../SWAL";
import {get_portal_group_list} from "../../store/actions/portal";

class CreatePortalsGroup extends Component<GroupPortalProps, IGroupPortalStates> {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            portal_list: this.props?.portal_list || [],
            selectedPortals: []
        };
    }

    handleSave = async (): Promise<void> => {
        let _URL = `portal/create-portal-group`;
        const body = {
            selectedPortals: this.state.selectedPortals,
            name : this.state.name
        };

        axiosInstance(_URL, 'put', {}, body)
            .then( (response:any) => {
                if(response.data.success) {
                    Toast.fire({
                        title: "Success",
                        titleText: `Group ${this.state.name} created successfully`,
                        icon: "success"
                    });
                    this.setState({name: '', selectedPortals: []});
                    this.props.toggleGroupPortalComponent();
                    this.props.getPortalGroupList();

                }
            }).catch(err => {
            Toast.fire("Failed", `Something went wrong`, "error");
        });
    }


    render() {
        const isSaveDisabled = this.state.name.trim() === '' || this.state.selectedPortals.length === 0;

        return (
            <Container style={{marginTop: '20px'}}>
                <Row>
                    <Col lg={12}>
                        <Form>
                            <FormGroup>
                                <Label for="groupName">Group Name</Label>
                                <Input type="text" id="groupName" value={this.state.name} onChange={e => this.setState({ name: e.target.value })} />
                            </FormGroup>

                            <FormGroup>
                                <Label for="portals">Select Portals</Label>
                                <Input
                                    type="select"
                                    id="portals"
                                    style={ { height: '200px' }}
                                    multiple
                                    value={this.state.selectedPortals}
                                    onChange={e => {
                                        const target = e.target as unknown as HTMLSelectElement;
                                        this.setState({
                                            selectedPortals: Array.from(target.selectedOptions, option => option.value)
                                        });
                                    }}
                                >
                                    {this.state.portal_list.map(portal => <option key={portal.id} value={portal.id}>{portal.name}</option>)}
                                </Input>
                            </FormGroup>

                            <Button onClick={this.handleSave} disabled={isSaveDisabled}>Save</Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        userId: state.login.loggedInUser.id,
        token: state.login.token,
        portal_list: state.portalListReducer.portal_list,
        success: state.project.get_project_list_success,
        loading: state.project.get_project_list_loading,
        isSuperUser: state.login.isSuperUser,
        isPortalAdmin: state.login.isPortalAdmin,
        selectedPortal: state.portalListReducer.selectedPortal,
        permissions: state.userPermissions.permissions
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onTryFetchProjectList: (portal_name) => dispatch(actions.get_project_list(portal_name)),
        onChangeProjectStatus: (action: string, portalSlug: string, projectId: string, projectName: string) => dispatch(changeProjectState(action, portalSlug, projectId, projectName)),
        onProjectDelete: (portalSlug: string, projectId: string, projectName: string) => dispatch(deleteProject(portalSlug, projectId, projectName)),
        onClearProjectState: () => dispatch(actions.clear_project_state()),
        getPortalGroupList: () => dispatch(get_portal_group_list())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePortalsGroup);

