import React, {Component} from 'react'
import './TopNavbar.css'
import {connect} from 'react-redux'
import {NavLink, withRouter,} from 'react-router-dom'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
} from 'reactstrap'
import {NavbarState, NavbarProps} from "../../interfaces/navbar";
import {generatePortalSpecificUrl} from "../../helpers/urlGenerators";
import LocalStorageWrapped from "../../LocalStorageWrapped";
import * as _ from "lodash";
import {changeDataSet} from "../../store/actions/dataset";
import {getUserViewableProjects} from "../../store/actions/userViewableProjects";
import {IProject} from "../../interfaces/project";


class TopNavbar extends Component<NavbarProps, NavbarState> {
  state = {
    isOpen: false,
    isOpenView: false,
    isOpenDataSet: false,
    projectSlug: _.get(this.props.match.params, "projectSlug"),
    project: null,
    isOpenProjects: false
  };
  toggle = () => {
    this.setState((state) => (
      {isOpen: !state.isOpen})
    );
  };
  toggleView = () => {
    this.setState((state) => (
      {isOpenView: !state.isOpenView})
    );
  };

  changeProject(project: IProject) {
    this.setState({project: project});
    this.props.changeDataSet(project.dataset[0]);
  }

  render() {
    let homeLink = null;
    const props = this.props;
    const authInfo = LocalStorageWrapped.getItem("authInfo");
    if (!authInfo) {
      homeLink = <NavLink style={{color: "white"}} className={"mr-2"} to={
        generatePortalSpecificUrl(this.props.portal, "/login")}>Home</NavLink>
    } else {
      homeLink = (<NavLink style={{color: "white"}} className="mr-3" to={
        generatePortalSpecificUrl(this.props.portal, "/dashboard")}>Home</NavLink>);
    }
    if (props.isSuperUser) {
      homeLink = (<NavLink style={{color: "white"}} className="mr-3" to={
        generatePortalSpecificUrl(this.props.portal, "/admin")}>Home</NavLink>);
    } else if (props.isPortalAdmin) {
      homeLink = (<NavLink style={{color: "white"}} className="mr-3" to={
        generatePortalSpecificUrl(this.props.portal, `/details/${this.props.portal.slug}`)}>Home</NavLink>);
    }
    return (
      <Navbar light expand="md" className="top-navbar">
        <NavbarToggler onClick={this.toggle}/>
        <Collapse isOpen={this.state.isOpen} navbar={true}>
          <Nav className="ml-auto" navbar={true}>
            <div style={{display: "flex", flexDirection: "row"}}>
              {homeLink}
              {!props.isAuthenticated
                  ? <>
                    <NavLink
                        style={{color: "white"}}
                        className="mr-3"
                        activeClassName="activeLink"
                        to={generatePortalSpecificUrl(this.props.portal, "/login")}>
                      Login
                    </NavLink>
                    <NavLink
                        style={{color: "white"}}
                        className="mr-3"
                        activeClassName="activeLink"
                        to={generatePortalSpecificUrl(this.props.portal, "/signup")}>
                      Signup
                    </NavLink>
                  </>
                  : <>
                    {
                      props.login_type === "email"
                          ? <>
                            <NavLink
                                style={{color: "white"}}
                                className="mr-3"
                                activeClassName="activeLink"
                                to={generatePortalSpecificUrl(this.props.portal, "/change-password")}>
                              Change Password
                            </NavLink>
                          </>
                          : null
                    }
                    {
                        LocalStorageWrapped.getItem("userInfo").isSuperUser || <NavLink
                            style={{color: "white"}}
                            className="mr-3"
                            activeClassName="activeLink"
                            to={generatePortalSpecificUrl(this.props.portal, "/configure-2fa")}>
                          Configure 2FA
                        </NavLink>
                    }
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                      <NavLink
                          style={{ color: "white" }}
                          className="mr-3"
                          activeClassName="activeLink"
                          to={generatePortalSpecificUrl(this.props.portal, "/logout")}>
                        Logout
                      </NavLink>
                      <div style={{position: 'absolute', top: '28px', right: '30px', color: 'white', fontStyle: 'italic'}}>
                        {this.props.loggedInUser.email}
                      </div>
                    </div>
                  </>
              }
            </div>
          </Nav>
        </Collapse>
      </Navbar>
    )
  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    isAuthenticated: state.login.token !== "",
    email: state.login.email,
    login_type: state.login.login_type,
    first_name:    _.get(state.login.loggedInUser, "firstName", ""),
    last_name:     _.get(state.login.loggedInUser, "lastName", ""),
    portal:        state.portal,
    isSuperUser:   _.get(state.login.loggedInUser, "isSuperUser", false),
    isPortalAdmin: _.get(state.login.loggedInUser, "isPortalAdmin", false),
    viewableProjects: state.userViewableProjects.projects,
    loggedInUser: state.login.loggedInUser,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    changeDataSet: (dataset) => dispatch(changeDataSet(dataset)),
    getUserViewableProjects: () => dispatch(getUserViewableProjects())
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopNavbar));
