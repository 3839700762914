import {IPortalGroupsState} from "../../interfaces/groups";
import {updateObject} from "../utility";
import * as PortalGroupActionTypes from "../actions/actionTypes/groupActionTypes";


let initialState: IPortalGroupsState = {
  loading: false,
  message: "",
  groups: [],
  error: "",
  success: false,
  createdGroup: null,
  selectGroupDetails: null,
  groupUsers: [],
  nonGroupUsers: []
};

const getPortalGroupsStart = (state) => {
  return updateObject(state, {loading: true});
};

const getPortalGroupsSuccess = (state, action) => {
  return updateObject(state, {loading: false, groups: action.groups, success: true});
};

const getPortalGroupsFail = (state, action) => {
  return updateObject(state, {loading: false, error: action.error, success: false})
};

const portalGroupChangeStateStart = (state) => {
  return updateObject(state, {loading: true});
};

const portalGroupChangeStateSuccess = (state, action) => {
  return updateObject(state, {loading: false, message: action.message, success: true});
};

const portalGroupChangeStateFail = (state, action) => {
  return updateObject(state, {loading: false, error: action.error, success: false});
};

const portalGroupCreateStart = (state) => {
  return updateObject(state, {loading: true});
};

const portalGroupCreateSuccess = (state, action) => {
  return updateObject(state, {loading: false, success: true, createdGroup: action.group});
};

const portalGroupCreateFail = (state, action) => {
  return updateObject(state, {loading: false, success: false, error: action.error});
};

const portalGroupGetDetailsStart = (state) => {
  return updateObject(state, {loading: true});
};

const portalGroupGetDetailsSuccess = (state, action) => {
  return updateObject(state, {loading: false, success: true, selectGroupDetails: action.selectGroupDetails});
};

const portalGroupGetDetailsFail = (state, action) => {
  return updateObject(state, {loading: false, success: false, error: action.error});
};

const portalGroupGetUsersStart = (state) => {
  return updateObject(state, {loading: true})
};

const portalGroupGetUsersSuccess = (state, action) => {
  return updateObject(state, {loading: false, success: true, groupUsers: action.groupUsers});
};

const portalGroupGetUsersFail = (state, action) => {
  return updateObject(state, {loading: false, success: false, error: action.error});
};

const portalGroupGetNonUsersStart = (state) => {
  return updateObject(state, {loading: true})
};

const portalGroupGetNonUsersSuccess = (state, action) => {
  return updateObject(state, {loading: false, success: true, nonGroupUsers: action.nonGroupUsers});
};

const portalGroupGetNonUsersFail = (state, action) => {
  return updateObject(state, {loading: false, success: false, error: action.error});
};

const reducer = (state: IPortalGroupsState = initialState, action) => {
  switch (action.type) {
    case PortalGroupActionTypes.GET_PORTAL_GROUPS_START:
      return getPortalGroupsStart(state);
    case PortalGroupActionTypes.GET_PORTAL_GROUPS_SUCCESS:
      return getPortalGroupsSuccess(state, action);
    case PortalGroupActionTypes.GET_PORTAL_GROUPS_FAIL:
      return getPortalGroupsFail(state, action);
    case PortalGroupActionTypes.PORTAL_GROUP_CHANGE_STATE_START:
      return portalGroupChangeStateStart(state);
    case PortalGroupActionTypes.PORTAL_GROUP_CHANGE_STATE_SUCCESS:
      return portalGroupChangeStateSuccess(state, action);
    case PortalGroupActionTypes.PORTAL_GROUP_CHANGE_STATE_FAIL:
      return portalGroupChangeStateFail(state, action);
    case PortalGroupActionTypes.CREATE_PORTAL_GROUP_START:
      return portalGroupCreateStart(state);
    case PortalGroupActionTypes.CREATE_PORTAL_GROUP_SUCCESS:
      return portalGroupCreateSuccess(state, action);
    case PortalGroupActionTypes.CREATE_PORTAL_GROUP_FAIL:
      return portalGroupCreateFail(state, action);
    case PortalGroupActionTypes.PORTAL_GROUP_GET_DETAILS_START:
      return portalGroupGetDetailsStart(state);
    case PortalGroupActionTypes.PORTAL_GROUP_GET_DETAILS_SUCCESS:
      return portalGroupGetDetailsSuccess(state, action);
    case PortalGroupActionTypes.PORTAL_GROUP_GET_DETAILS_FAIL:
      return portalGroupGetDetailsFail(state, action);
    case PortalGroupActionTypes.PORTAL_GROUP_GET_USERS_START:
      return portalGroupGetUsersStart(state);
    case PortalGroupActionTypes.PORTAL_GROUP_GET_USERS_SUCCESS:
      return portalGroupGetUsersSuccess(state, action);
    case PortalGroupActionTypes.PORTAL_GROUP_GET_USERS_FAIL:
      return portalGroupGetUsersFail(state, action);
    case PortalGroupActionTypes.PORTAL_GROUP_GET_NON_USERS_START:
      return portalGroupGetNonUsersStart(state);
    case PortalGroupActionTypes.PORTAL_GROUP_GET_NON_USERS_SUCCESS:
      return portalGroupGetNonUsersSuccess(state, action);
    case PortalGroupActionTypes.PORTAL_GROUP_GET_NON_USERS_FAIL:
      return portalGroupGetNonUsersFail(state, action);
    default:
      return state;
  }
};

export default reducer;
