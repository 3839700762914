import {IPendingApprovals} from "../types";
import {updateObject} from "../utility";
import * as actionType from "../actions/actionTypes/pendingApprovalActionTypes";

let InitialState: IPendingApprovals = {
  users: [],
  loading: false,
};


const pendingApprovalStart = (state, _action) => {
  return updateObject(state, {loading: true});
};

const pendingApprovalSuccess = (state, action) => {
  return updateObject(state, {loading: false, users: action.users});
};

const pendingApprovalFailed = (state, _action) => {
  return updateObject(state, {loading: false, users: []});
};

const reducer = (state: IPendingApprovals = InitialState, action) => {
  switch (action.type) {
    case actionType.PENDING_APPROVAL_START:
      return pendingApprovalStart(state, action);
    case actionType.PENDING_APPROVAL_SUCCESS:
      return pendingApprovalSuccess(state, action);
    case actionType.PENDING_APPROVAL_FAILED:
      return pendingApprovalFailed(state, action);
    default:
      return InitialState;
  }
};

export default reducer;
