import { axiosInstance } from "../../Axios";
import * as _ from "lodash";

export const getPortalUserPermissions = async (portalSlug: string, userId: string, isPortalAdmin=false) => {
  let finalPermissionsObject: object = {};
  let modelUserPermissionsUrl = `${portalSlug}/users/get-model-permissions/${userId}?portalAdmin=${isPortalAdmin}`;
  let objectPermissionUrl = `users/permissions/${userId}`;

  let modelPermissionsResponse = null;
  try {
    modelPermissionsResponse = await axiosInstance(modelUserPermissionsUrl, "GET");
  } catch (e) {
    return { success: false, data: e.message };
  }

  _.set(finalPermissionsObject, "ModelPermissions", _.get(modelPermissionsResponse, "data.object"));

  let objectPermissionsResponse = null;
  try {
    objectPermissionsResponse = await axiosInstance(objectPermissionUrl, "GET");
  } catch (e) {
    return { success: false, data: e.message };
  }

  let objectPermissionsFinal = {};
  let objectPermissions = _.get(objectPermissionsResponse, "data.result", []);
  for (let i = 0; i < objectPermissions.length; i++) {
    let currentPermission = objectPermissions[i];
    if (Object.keys(objectPermissionsFinal).indexOf(currentPermission.objectId) > -1) {
      objectPermissionsFinal[currentPermission.objectId].push(currentPermission.name);
    } else {
      objectPermissionsFinal[currentPermission.objectId] = [currentPermission.name];
    }
  }
  _.set(finalPermissionsObject, "ObjectPermissions", objectPermissionsFinal);

  return { success: true, data: finalPermissionsObject };
};
