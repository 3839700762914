export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};
// export const deleteAllCookies = () => {
//     var cookies = document.cookie.split(";");
//     let _currentDate = new Date();
//     const _forecastDate = new Date(_currentDate);
//     for (let i = 0; i < cookies.length; i++) {
//          let cookie = cookies[i];
//          let eqPos = cookie.indexOf("=");
//          let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
//          document.cookie = `${name}=;expires=${_forecastDate};path=/`;
//     }
// };