import React from "react";

const NotFound = () => {
  return (
    <div className="text-center">
      <h1 style={{fontSize:"20rem"}}>404</h1>
      The requested page doesn't exist. Please Make sure you've typed the correct URL.
      </div>
  )
};

export default NotFound;
