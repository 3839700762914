export const GET_PORTAL_GROUPS_START = "GET_PORTAL_GROUPS_START";
export const GET_PORTAL_GROUPS_SUCCESS = "GET_PORTAL_GROUPS_SUCCESS";
export const GET_PORTAL_GROUPS_FAIL = "GET_PORTAL_GROUPS_FAIL";

export const CREATE_PORTAL_GROUP_START = "CREATE_PORTAL_GROUP_START";
export const CREATE_PORTAL_GROUP_SUCCESS = "CREATE_PORTAL_GROUP_SUCCESS";
export const CREATE_PORTAL_GROUP_FAIL = "CREATE_PORTAL_GROUP_FAIL";

export const PORTAL_GROUP_CHANGE_STATE_START = "PORTAL_GROUP_CHANGE_STATE_START";
export const PORTAL_GROUP_CHANGE_STATE_SUCCESS = "PORTAL_GROUP_CHANGE_STATE_SUCCESS";
export const PORTAL_GROUP_CHANGE_STATE_FAIL = "PORTAL_GROUP_CHANGE_STATE_FAIL";

export const PORTAL_GROUP_GET_DETAILS_START = "PORTAL_GROUP_GET_DETAILS_START";
export const PORTAL_GROUP_GET_DETAILS_SUCCESS = "PORTAL_GROUP_GET_DETAILS_SUCCESS";
export const PORTAL_GROUP_GET_DETAILS_FAIL = "PORTAL_GROUP_GET_DETAILS_FAIL";

export const PORTAL_GROUP_GET_USERS_START = "PORTAL_GROUP_GET_USERS_START";
export const PORTAL_GROUP_GET_USERS_SUCCESS = "PORTAL_GROUP_GET_USERS_SUCCESS";
export const PORTAL_GROUP_GET_USERS_FAIL = "PORTAL_GROUP_GET_USERS_FAIL";

export const PORTAL_GROUP_GET_NON_USERS_START = "PORTAL_GROUP_GET_NON_USERS_START";
export const PORTAL_GROUP_GET_NON_USERS_SUCCESS = "PORTAL_GROUP_GET_NON_USERS_SUCCESS";
export const PORTAL_GROUP_GET_NON_USERS_FAIL = "PORTAL_GROUP_GET_NON_USERS_FAIL";

export const GET_USER_GROUPS_START = "GET_USER_GROUPS_START";
export const GET_USER_GROUPS_SUCCESS = "GET_USER_GROUPS_SUCCESS";
export const GET_USER_GROUPS_FAIL = "GET_USER_GROUPS_FAIL";
