import jwt_decode from "jwt-decode";
import React, {Component, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button, Form, Input} from "reactstrap";
import {axiosInstance} from "../../Axios";
import {generatePortalSpecificUrl} from "../../helpers/urlGenerators";
import {MySwal} from "../../SWAL";
import {loginStep2} from "../../store/actions/login";
import {useHistory} from "react-router";
import OtpForm from "../UI/OtpForm";
import Card from "../UI/Card";

export default function LoginStep2(props) {
    const {loginStep2VerificationToken} = useSelector((state: any) => state.login)
    const portal = useSelector((state: any) => state.portal)
    const history = useHistory();
    const [otp, setOtp] = useState<any>(null)
    const dispatch = useDispatch();

    useEffect(() => {
        loginStep2VerificationToken === '' && history.push(generatePortalSpecificUrl(portal, '/'))
    }, [loginStep2VerificationToken])

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        dispatch(loginStep2(
            otp,
            loginStep2VerificationToken,
        ))
        // history.push(generatePortalSpecificUrl(portal, '/'))
    }

    const getTokenIat = () => {
        try {
            const {iat} = jwt_decode<any>(loginStep2VerificationToken)
            return iat
        } catch (error) {
            return false
        }
    }

    const getTokenExp = () => {
        try {
            const {exp} = jwt_decode(loginStep2VerificationToken)
            return exp
        } catch (error) {
            return false
        }
    }

    return <div style={{display: 'flex'}}>
        <Card style={{
            maxWidth: '1000px',
            marginLeft: 'auto',
            marginRight: 'auto',
            alignSelf: 'center',
        }}>
            <div
                style={{
                    padding: '0 0 1.8em 0',
                    fontSize: '1.2em',
                }}
            >
                Enter your six-digit 2FA OTP
            </div>
            <OtpForm
                otp={otp}
                setOtp={setOtp}
                onSubmitHandler={onSubmitHandler}
                timerStartSeconds={getTokenIat}
                timerEndSeconds={getTokenExp}
                onTimerComplete={timeDelta => {
                    window.location.reload()
                }}
            />
        </Card>
    </div>
}