import React from "react";
import * as _ from "lodash";
import { Button, Col, Row, Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import EditAddOption from "./components/add-annotation-options.component";
import { axiosInstance } from "../../Axios";
import { remove } from "lodash";
import { AnnotationOptionTypes } from "../../interfaces/annotation-options";
import "./annotation-options.css";

interface OptionProps {
    router: any;
}

interface OptionState {
    optionsList: Array<any>;
    openModal: boolean;
    AddEdit: boolean;
    selectedOption: number | null;
}

class AnnotationOptions extends React.Component<OptionProps, OptionState> {

    constructor(props) {
        super(props);
        this.state = {
            optionsList: [],
            openModal: false,
            AddEdit: true,
            selectedOption: null,
        };
    }

    componentDidMount(): void {
        this.fetchOptions();
    }

    fetchOptions() {
        if (this.props.router.params.portalSlug) {
            const _url = `${this.props.router.params.portalSlug}/annotation-options?sendDefault=true`;
            const _headers = {};
            axiosInstance(_url, 'get', _headers,)
                .then(response => {
                    let res = _.get(response, "data");
                    if (res.success) {
                        this.setState({ optionsList: res.result.options });
                    }
                }).catch(err => { });
        }
    }

    addOption = () => {
        this.resetOption();
        const updatedOptions = [...this.state.optionsList || []];
        updatedOptions.push({ label: '', name: '', values: [], type: AnnotationOptionTypes.DROPDOWN });
        this.setState({ openModal: true, AddEdit: true, optionsList: updatedOptions, selectedOption: updatedOptions.length - 1 });
    };

    closeModal = (cancelled = true) => {
        this.setState({ openModal: false });
        if (cancelled) {
            this.resetOption();
        } else {
            this.setState({ selectedOption: null });
        }
    };

    submitOptionHandler = (data) => {
        const optionsList = [...this.state.optionsList];
        const newOption = { ...data, hidden: false };
        Object.assign(optionsList[this.state.selectedOption], newOption);
        this.setState({ optionsList });

        this.saveOptions(optionsList).then(response => {
                let res = _.get(response, "data");
                if (res.success) {
                    this.setState({ optionsList: res.object.options });
                    this.componentDidMount();
                    this.closeModal(false);
                }
            }).catch(err => {
                this.resetOption();
                this.closeModal(false);
            });
    };

    resetOption = () => {
        if (this.state.selectedOption !== null && this.state.AddEdit) {
            this.state.optionsList.splice(this.state.selectedOption, 1);
            this.setState({ optionsList: this.state.optionsList })
        }
        this.setState({ selectedOption: null });
    };

    removeOption = (optionIndex: number) => {
        if (this.props.router.params.portalSlug) {
            const updatedOptions = [...this.state.optionsList];
            updatedOptions.splice(optionIndex, 1);

            this.saveOptions(updatedOptions).then(response => {
                let res = _.get(response, "data");
                if (res.success) {
                    this.setState({ optionsList: updatedOptions });
                }
            }).catch(err => {
            });;
        }
    };

    editOption = (optionIndex: number) => {
        this.setState({ selectedOption: optionIndex, AddEdit: false, openModal: true });
    };

    getValues = (options) => {
        return options.length < 5 ? options.join(', ') : options.slice(0, 4).join(', ') + '...';
    }

    visibilityChangeHandler = (optionIndex: number, checked) => {
        const updatedOptions = [...this.state.optionsList];
        // Invert checked since checked means visible (hidden: false)
        _.set(updatedOptions[optionIndex], 'hidden', !checked);
        this.setState({ optionsList: updatedOptions });

        this.saveOptions(updatedOptions).then().catch();
    }

    move = (index: number, indexDiff: number) => {
        const updatedOptions = [...this.state.optionsList];
        [updatedOptions[index], updatedOptions[index + indexDiff]] = [updatedOptions[index + indexDiff], updatedOptions[index]];
        this.setState({ optionsList: updatedOptions });
        this.saveOptions(updatedOptions).then().catch();
    }

    saveOptions = (options) => {
        const _headers = { 'content-type': 'application/json' };
        const _optionBody = { options };
        const _url = `${this.props.router.params.portalSlug}/annotation-options`;
        return axiosInstance(_url, 'post', _headers, _optionBody);
    }

    render() {
        return (
            <div className="centerForm col-12 mx-0 px-0">
                {this.state.openModal ?
                    <EditAddOption openModal={this.state.openModal}
                        closeModal={this.closeModal}
                        className={'tag-add-edit'}
                        buttonLabel={'Submit'}
                        submitOption={this.submitOptionHandler}
                        annotationOptions={this.state.optionsList}
                        optionItem={this.state.selectedOption !== null && this.state.optionsList[this.state.selectedOption] ? { name: this.state.selectedOption, ...this.state.optionsList[this.state.selectedOption] } : {}} />
                    : null
                }
                <Row className="centerForm">
                    <h1 className="heading">Annotation Fields</h1>
                    <Col className="centerForm" lg={12}>
                        <div className='text-md-right'>
                            <Button onClick={() => this.addOption()}>
                                <span>
                                    <FontAwesomeIcon icon={faPlusCircle} />
                                </span>
                            </Button>
                        </div>
                        <Table responsive striped bordered>
                            <thead>
                                <tr>
                                    <th> No. </th>
                                    <th> Show/Hide </th>
                                    <th> Name </th>
                                    <th> Label </th>
                                    <th> Type </th>
                                    <th> Values </th>
                                    <th> Actions </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.optionsList.map((option, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{index}</td>
                                            <td>
                                                <input type="checkbox" checked={!option.hidden} onChange={(event) => this.visibilityChangeHandler(index, event.target.checked)} />
                                            </td>
                                            <td>{option.name}</td>
                                            <td>{option.label}</td>
                                            <td>{option.type}</td>
                                            <td>{this.getValues(option.values)}</td>
                                            <td className='d-flex align-items-center justify-content-center'>
                                                <div className='d-flex flex-column ml-1 mr-3'>
                                                    <FontAwesomeIcon icon={faChevronUp} onClick={() => this.move(index, -1)} className={`reorder-btn ${index <= 0 ? 'disabled' : ''}`} />
                                                    <FontAwesomeIcon icon={faChevronDown} onClick={() => this.move(index, 1)} className={`reorder-btn  ${index >= (this.state.optionsList.length - 1) ? 'disabled' : ''}`} />
                                                </div>
                                                <Button
                                                    onClick={() => this.editOption(index)}
                                                    outline color="info">
                                                    Edit
                                                </Button>
                                                <Button onClick={() => this.removeOption(index)}
                                                    className={"ml-3"}
                                                    outline
                                                    color={"danger"}>
                                                    Remove
                                                </Button>
                                            </td>
                                        </tr>)
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div>
        )
    }

}


export default AnnotationOptions;
