import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import "./accessible-folders.css";
import { AccessibleFoldersProps } from "../../interfaces/accessible-folders";
import { axiosInstance } from "../../Axios";

const AccessibleFolders: React.FC<AccessibleFoldersProps> = (props: AccessibleFoldersProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [accessibleFolders, setAccessibleFolders] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [selectedFolderIndex, setSelectedFolderIndex] = useState(-1);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const openAddModal = useCallback(() => {
    setIsOpen(true);
    setEditMode(false);
  }, []);

  const resetForm = useCallback(() => {
    setIsOpen(false);
    setFolderName("");
    setSelectedFolderIndex(-1);
    setEditMode(false);
  }, []);

  const openEditModal = useCallback((index) => {
    setFolderName(accessibleFolders[index]);
    setSelectedFolderIndex(index);
    setIsOpen(true);
    setEditMode(true);
  }, [accessibleFolders]);

  const saveFolders = useCallback((folders) => {
    const _headers = { 'content-type': 'application/json' };
    const _body = { accessibleFolders: folders };
    const _url = `portal/${props.portal.id}/accessible-folders/`;
    axiosInstance(_url, 'post', _headers, _body).then((response: any) => {
      const portal = props.portal;
      portal.accessibleFolders = response.data.object.accessibleFolders;
      props.changePortal(portal);
    }).catch(err => { });
  }, [props.changePortal, props.portal]);

  const submitHandler = useCallback(() => {
    let folders = accessibleFolders || [];
    if (editMode) {
      folders[selectedFolderIndex] = folderName;
    } else {
      folders.push(folderName);
    }
    setAccessibleFolders(folders);
    saveFolders(folders);
    resetForm();
  }, [accessibleFolders, resetForm, folderName]);

  const removeFolder = useCallback((index) => {
    accessibleFolders.splice(index, 1);
    saveFolders(accessibleFolders);
    setAccessibleFolders(accessibleFolders);
    setSelectedFolderIndex(index);
    setEditMode(false);
  }, [accessibleFolders]);

  useEffect(() => {
    setAccessibleFolders(props.portal?.accessibleFolders || []);
  }, [props.portal]);

  return (
    <div className="centerForm col-12 mx-0 px-0">

      <Modal isOpen={isOpen} toggle={closeModal}>
        <ModalHeader className='text-center'>{editMode === true ? 'Edit accessible folder' : 'Add accessible folder'}</ModalHeader>
        <ModalBody>
          <Input value={folderName} onChange={(e) => setFolderName(e.target.value)}></Input>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={submitHandler}>Submit</Button>
          <Button color="secondary" onClick={closeModal}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <Row className="centerForm">
        <h1 className="heading">Accessible Folders</h1>
        <Col className="centerForm" lg={12}>
          <div className='text-md-right'>
            <Button onClick={() => openAddModal()}>
              <span>
                <FontAwesomeIcon icon={faPlusCircle} />
              </span>
            </Button>
          </div>

          <Table responsive striped bordered>
            <thead>
              <tr>
                <th> Folder Name </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {(accessibleFolders || []).map((folder, index) => {
                return (
                  <tr>
                    <td>{folder}</td>
                    <td>
                      <Button
                        onClick={() => openEditModal(index)}
                        outline color="info">
                        Edit
                      </Button>
                      <Button
                        onClick={() => removeFolder(index)}
                        className={"ml-3"}
                        outline
                        color={"danger"}>
                        Remove
                      </Button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  )
}

export default AccessibleFolders;