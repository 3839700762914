import React, {Component} from "react"
import TopNavbar from "../components/TopNavbar/TopNavbar"
import Home from "../components/Home/Home"
import {matchPath, Redirect, Route, Switch, withRouter} from "react-router-dom"
import Login from "../components/Auth/Login"
import LoginStep2 from "../components/Auth/LoginStep2"
import Signup from "../components/Auth/Signup"
import CreatePortalAdmin from "../components/Portals/CreatePortalAdmin";
import Logout from "../components/Auth/Logout"
import ChangePassWord from "../components/Auth/ChangePassword"
import createProject from "../components/Projects/createProject";
import Sidebar from "../components/Sidebar/Sidebar";
import Portals from "../components/Portals/Portals"
import CreatePortal from "../components/Portals/createPortal";
import {connect} from "react-redux"
import PortalDetails from "../components/Portals/PortalDetails";
import CreatePortalUsers from "../components/Portals/CreatePortalUsers";
import NotFound from "../components/UI/NotFound";
import PortalProjects from "../components/Portals/PortalProjects";
import PortalUsers from "../components/Portals/PortalUsers";
import PortalAdmins from "../components/Portals/PortalAdmins";
import {LayoutProps, LayoutState} from "../interfaces/main";
import LocalStorageWrapped from "../LocalStorageWrapped";

import PendingApprovals from "../components/Auth/PendingApprovals";
import Users from "../components/User/Users";
import UpdateProject from "../components/Projects/UpdateProject";
import UpdatePortal from "../components/Portals/UpdatePortal";
import UserDetails from "../components/User/UserDetails";
import UserUpdate from "../components/User/UpdateUser";
import CreateGroup from "../components/Groups/createGroup";
import GroupDetails from "../components/Groups/groupDetails";
import {authCheckState} from "../store/actions";
import SetPassword from "../components/Auth/SetPassword"
import ForgetPassword from "../components/Auth/ForgetPassword";
import Configure2fa from "../components/Auth/Configure2fa";
import CreatePortalGroup from "../components/Portals/GroupPortals";
import Model from "../components/Portals/testModel";


const PrivateRoute = ({component: Component, extraProps = {}, ...rest}) => {
  const authInfo = LocalStorageWrapped.getItem("authInfo");
  return (
      <Route {...rest} render={(props) => (
          authInfo && authInfo.token
              ? <Component {...props} {...extraProps} />
              : <Redirect to='/login'/>
      )}/>
  );
};


class Layout extends Component<LayoutProps,LayoutState> {

  constructor(props){
    super(props);
    const url = window.location.href;
    if (!url.endsWith('logout') && !url.endsWith('logout/')) {
      this.props.authCheckState();
    }
  }
  state = {
    createPortalComponent: false,
    groupPortalComponent: false,
  }

  render() {
    let sideBarWidth = "0%";
    let bodyWidth = "100%";
    let bodyMarginLeft = "0%";
    let display = "block";
    let isProjectPageActive = matchPath(this.props.location.pathname, "/:portal/project/:projectSlug");
    let isProjectPageActiveWithoutPortal = matchPath(this.props.location.pathname, "/project/:projectSlug");
    let isProjectPageActiveExact = null;
    let isProjectPageActiveWithoutPortalExact = null;
    if (isProjectPageActive) {
      isProjectPageActiveExact = isProjectPageActive.isExact;
    }
    if(isProjectPageActiveWithoutPortal) {
      isProjectPageActiveWithoutPortalExact = isProjectPageActiveWithoutPortal.isExact;
    }
    if (this.props.isAuthenticated && !window.location.pathname.includes("portaluser")) {
      sideBarWidth = "17%";
      bodyWidth = "75%";
      bodyMarginLeft = "20%";
    }
    if (!this.props.isAuthenticated || isProjectPageActiveExact || isProjectPageActiveWithoutPortalExact || window.location.pathname.includes("portaluser")) {
      display = "none";
    }
    return (
      <React.Fragment>
        <div
          style={{
            display: display,
            width: sideBarWidth,
            float: "left",
            position: "fixed",
            zIndex: 1000,
            height: "100%",
            backgroundImage:  'linear-gradient(to bottom, #FFA07A, #F58220)'
          }}
        >
        <Sidebar/>
        </div>
        { this.props && this.props.isAuthenticated && !window.location.pathname.includes("portaluser") ?
            <div style={{width: '100%', marginLeft: '0%'}}>
              <TopNavbar/>
            </div>
            : null
        }
        <div id="body" style={{
          marginLeft: bodyMarginLeft,
          width: bodyWidth
        }}>
          <Switch>
            <PrivateRoute path="/:portal/user/details/:userId" exact={true} component={UserDetails} />
            <PrivateRoute path="/:portal/user/update/:userId" exact={true} component={UserUpdate} />
            <PrivateRoute path="/:portal/portaluser/update/:userId" exact={true} component={UserUpdate} />
            <PrivateRoute path="/user/details/:userId" exact={true} component={UserDetails} />
            <PrivateRoute path="/" exact={true} component={Home}/>
            <PrivateRoute path="/:portal/dashboard" exact={true} component={Home}/>
            <PrivateRoute path="/dashboard" exact={true} component={Home}/>
            <PrivateRoute path="/:portal/group/create" exact={true} component={CreateGroup}/>
            <PrivateRoute path="/:portal/group/:groupId" exact={true} component={GroupDetails}/>
            {/*// changed this to exact true*/}
            <PrivateRoute path="/:portal/admin" exact={true} component={Home}/>
            <PrivateRoute path={'/admin/create/:portalSlug'} exact={true} component={CreatePortalAdmin}/>
            <PrivateRoute path={'/user/create/:portalSlug'} exact={true} component={CreatePortalUsers}/>
            <PrivateRoute path="/admin" exact={true} component={Home}/>
            <PrivateRoute path="/:portal/portal-admin" exact={true} component={Home}/>
            <PrivateRoute path="/portal-admin" exact={true} component={Home}/>
            <PrivateRoute path="/test-model" exact={true} component={Model}/>
            <Route path="/:portal/login" exact={true} render={() => (<Login {...this.props} />)} />
            <Route path="/login" exact={true} render={() => (<Login {...this.props} />)}/>

            <Route path="/:portal/signup" exact={true} component={Signup}/>

            <Route path="/signup/setpassword/:token" exact={true} component={SetPassword}/>
            <Route path="/reset-password/:token" exact={true} component={SetPassword}/>

            <Route path="/signup" exact={true} component={Signup}/>
            <Route path="/forget-password" exact={true} component={ForgetPassword}/>
            <PrivateRoute path="/:portal/logout" exact={true} component={Logout}/>
            <Route path="/logout" exact={true} render={() => (<Logout/>)}/>
            <PrivateRoute path="/:portal/change-password" exact={true} component={ChangePassWord}/>
            <PrivateRoute path="/change-password" exact={true} component={ChangePassWord}/>
            <Route path="/demo/project/create" exact={true} component={createProject}/>
            <PrivateRoute path="/:portal/project/create/:portalSlug" exact={true} component={createProject}/>
            <PrivateRoute path="/project/create/:portalSlug" exact={true} component={createProject}/>

            <PrivateRoute path="/projects/update/:projectId/:portalSlug" exact={true} component={UpdateProject} key={window.location.pathname} />
            <PrivateRoute path="/:portal/projects/update/:projectId/:portalSlug" exact={true} component={UpdateProject} key={window.location.pathname} />
            <PrivateRoute path="/:portal/portals" exact={true} component={Portals}/>
            <PrivateRoute path="/portals" exact={true} component={Portals}/>
            <PrivateRoute path="/:portal/details/:portalSlug" exact={true} component={PortalDetails}/>
            <PrivateRoute path="/details/:portalSlug/project/:projectId" exact={true} component={PortalDetails}/>
            <PrivateRoute path="/:portal/details/:portalSlug/projects" exact={true} component={PortalProjects}/>
            <PrivateRoute path="/:portal/details/:portalSlug/portal-users" exact={true} component={PortalUsers}/>
            <PrivateRoute path="/:portal/details/:portalSlug/portal-admins" exact={true} component={PortalAdmins}/>
            <PrivateRoute path="/details/:portalSlug" exact={true} component={PortalDetails}/>

            <PrivateRoute path="/create-portal" exact={true} component={CreatePortal}/>
            <PrivateRoute path="/update-portal/:portalId" exact={true} component={UpdatePortal}/>
            <PrivateRoute path="/:portal/pending-approvals" exact={true} component={PendingApprovals}/>
            <PrivateRoute path="/pending-approvals" exact={true} component={PendingApprovals}/>
            <PrivateRoute path="/:portal/user/permissions/:userId" exact={true} component={Users}/>

            <PrivateRoute path="/:portal/configure-2fa" exact={true} component={Configure2fa}/>
            <PrivateRoute path="/configure-2fa" exact={true} component={Configure2fa}/>

            <Route path="/:portal/login-step2" exact={true} render={() => (<LoginStep2 {...this.props} />)} />
            <Route path="/login-step2" exact={true} render={() => (<LoginStep2 {...this.props} />)}/>
            <Route path="/login-step2-email" exact={true} render={() => (<LoginStep2 {...this.props} />)}/>

            <Route component={NotFound}/>
          </Switch>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // onLogout: () => dispatch(actions.logout()),
        authCheckState: () => dispatch(authCheckState()),
    }
};

const mapStateToProps = (state, ownProps) => {
  return {
    isAuthenticated: state.login.token !== '',
    cookies: ownProps.cookies,
    user: { ...state.user }
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
