import {IPortalReducer} from "../store/types";
import { UserPortalObject } from "./auth";
import {IGroup} from "./groups";

export interface UserProps  {
  userName: string;
  getUserPermissions: (portalSlug: string, userId: string) => void;
  clearUserPermissions: () => void;
  permissions: object;
  loading: boolean;
  success: boolean;
  error: boolean;
}

export interface UsersState {
  userId: string;
  portalSlug: string;
}

export interface IUser {
  id: string,
  email: string,
  firstName: string,
  lastName: string,
  isSuperUser: boolean,
  portals: Array<UserPortalObject>,
  googleId: string,
  linkedInId: string,
  // portal?: IPortalReducer,
  createdAt?: string,
  groups?: Array<IGroup>,
  lastAccessURL?: string,
  secretQuestion?: string;
  twofaEnabled?: boolean;
}


export enum SecretQuestionOptionTypes {
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  DATE = 'DATE',
}

