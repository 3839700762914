import * as actionTypes from '../actions/actionTypes/authActionTypes';
import {updateObject} from '../utility';
import {ILoginReducer} from "../types";

const initialState = {
  token: "",
  loginStep2VerificationToken: "",
  loading: false,
  message: "",
  success: false,
  authRedirectPath: '/dashboard',
  errors: {},
  changePasswordSuccess: false,
  login_type:"",
  loggedInUser: null,
  shouldLogout: false,
};

const changePasswordSuccess = (state, action) => {
  return updateObject(state, {changePasswordSuccess: true,})
};

const updateToken = (state, action) => {
  return updateObject(state, {
    token: action.token,
  })
};

const setLoginStep2VerificationToken = (state, action) => {
  return updateObject(state, {loginStep2VerificationToken: action.loginStep2VerificationToken})
}

const loginStart = (state, action) => {
  return updateObject(state, {errors: null, loading: true});
};

const loginSuccess = (state, action) => {
  return updateObject(state, {
    message: action.message,
    token: action.token,
    errors: null,
    loading: false,
    success: action.success,
    login_type: action.login_type,
    authRedirectPath: action.redirectPath,
    loggedInUser: action.loggedInUser
  })
};

const loginFail = (state, action) => {
  return updateObject(state, {errors: action.errors, success: false, loading: false})
};

// const authLogout = (state, action) => {
//   return updateObject(state, initialState);
// };

const setAuthRedirectPath = (state, action) => {
  return updateObject(state, {authRedirectPath: action.path})
};

const reducer = (state: ILoginReducer = initialState, action) => {
  switch (action.type) {
    case actionTypes.SOCIAL_AUTH_LOGIN:
      const {token, userId, expiresIn, userName, email} = action.payload;
      return updateObject(state, {token, userId, expiresIn, userName, email});
    case actionTypes.LOGIN_START:
      return loginStart(state, action);
    case actionTypes.LOGIN_SUCCESS:
      return loginSuccess(state, action);
    case actionTypes.LOGIN_FAIL:
      return loginFail(state, action);
    case actionTypes.SET_LOGIN_REDIRECT_PATH:
      return setAuthRedirectPath(state, action);
    // case actionTypes.AUTH_LOGOUT:
    //   return authLogout(state, action);
    case actionTypes.UPDATE_TOKEN_SUCCESS:
      return updateToken(state, action);
    case actionTypes.SET_LOGIN_STEP2_VERIFICATION_TOKEN_SUCCESS:
      return setLoginStep2VerificationToken(state, action);
    case actionTypes.CHANGE_PASS_SUCCESS:
      return changePasswordSuccess(state, action);
    case actionTypes.SHOULD_LOGOUT:
      return updateObject(state, {shouldLogout: true});
    default:
      return state;
  }
};

export default reducer;
