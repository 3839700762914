import React from "react";
import {connect} from "react-redux";
import {ProjectPermissionObject, UserObjectPermissionProps, UserObjectPermissionState} from "../../interfaces/permissions";
import {Table} from "reactstrap";
import * as actions from "../../store/actions";
import {findIndex, get, map, set} from "lodash";
import {ProjectProps} from "../../interfaces/portal";
import {ObjectPermissions, UserObjectPermissions} from "../../permissions";
import Switch from "react-switch";
import {ChangeObjectPermission} from "../../store/actions/changeUserPermissions";
import {Toast} from "../../SWAL";
import { setSelectedPortal } from "../../store/actions/portal";
import _ from "lodash";


class ObjectLevelPermissions extends React.Component<UserObjectPermissionProps, UserObjectPermissionState> {

  constructor(props) {
    super(props);
    this.state = {
      projectPermissions: []
    }
  }
  componentDidMount(): void {
    if (!this.props.portal) {
      this.props.setSelectedPortal();
    } else {
      if (!this.props.project_list?.length) {
        this.props.onTryFetchProjectList(this.props.portal.slug);
      } else {
        this.fillState();
      }
    }
  }

  componentDidUpdate(prevProps, prevState): void {
    if (!prevProps.portal && this.props.portal) {
      this.props.onTryFetchProjectList(this.props.portal.slug);
    }
    if (!prevProps.project_list?.length && this.props.project_list?.length) {
      // If project_list loaded call fill state but only once
      this.fillState();
    }
  }

  normalize = (str: string) => {
    str = str.split("_").join(" ");
    str = str.toLowerCase();
    return str.charAt(0).toUpperCase() + str.substring(1);
  };

  fillState = () => {
    let objectPermissions = get(this.props.portalUserDetails.permissions, "ObjectPermissions");

    const finalState = map(this.props.project_list, (project: ProjectProps) => {
      const projectName = project.name;
      const projectId = project.id;
      const currentProjectPermissions = get(objectPermissions, projectId, []);
      const permissions = ObjectPermissions.reduce((allPerms, currentPerm) => {
        allPerms[currentPerm] = currentProjectPermissions.indexOf(currentPerm) > -1;
        return allPerms;
      }, {});

      return { name: projectName, id: projectId, permissions };
    });

    this.setState({ projectPermissions: finalState });
  };

  changePermission = (permName: string, projectPerms: ProjectPermissionObject, model: string) => {
    const newPerms = {...projectPerms.permissions};
    newPerms[permName] = !projectPerms.permissions[permName];
    let action: "give-permission" | "remove-permission" = "give-permission";
    if (projectPerms.permissions[permName]) {
      action = "remove-permission";
    }
    let data: ChangeObjectPermission = {
      name: permName,
      objectId: projectPerms.id,
      objectModel: model
    };
    ChangeObjectPermission(this.props.portalSlug, this.props.userId, action, data)
      .then(response => {
        Toast.fire("Success", "Permission successfully changed", "success");
      })
      .catch(err => {
        Toast.fire("Failed", "Permission changing failed!", "error");
      });

    const newState = {...this.state};
    const projectPermissionIndex = findIndex(newState.projectPermissions, ['id', projectPerms.id]);
    newState.projectPermissions[projectPermissionIndex].permissions = newPerms;
    this.setState(newState);
  };

  render() {
    const userPortal = this.props.portalUserDetails?.userDetails ? _.find(this.props.portalUserDetails.userDetails.portals, (portal) => portal.portal.toString() === this.props.portal?.id.toString()) : null;

    const permissions = userPortal ? (userPortal.isPortalAdmin ? ObjectPermissions : UserObjectPermissions) : [];

    return (
      <div className='row'>
        <h6 className="subheading">Project Permissions</h6>
        <Table responsive striped bordered small={'true'}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Permissions</th>
            </tr>
          </thead>
          <tbody>
            {this.state.projectPermissions && this.state.projectPermissions.map((project, projectIndex) =>
                <tr key={`project_${projectIndex}`}>
                  <td>{project.name}</td>
                  <td>
                    {permissions.map((perm, index) =>
                      <span style={{display: "inline-block", width: "33%"}} key={`perm_${index}`}>
                        <Switch
                          checked={project.permissions[perm]}
                          onChange={() => {this.changePermission(perm, project, "Project")}}
                          onColor="#86d3ff"
                          onHandleColor="#2693e6"
                          handleDiameter={30}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                          height={20}
                          width={48}
                          className="react-switch"
                          id="material-switch"
                        />
                      <div key={index}>{this.normalize(perm)}</div>
                      </span>
                    )}
                  </td>
                </tr>
            )}
          </tbody>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    project_list: state.project.project_list,
    portal: state.portalListReducer.selectedPortal
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onTryFetchProjectList: (portal_name) => dispatch(actions.get_project_list(portal_name)),
    setSelectedPortal: () => dispatch(setSelectedPortal()),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ObjectLevelPermissions);
