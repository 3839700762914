import React from "react";
import {connect} from "react-redux";
import {GroupUsersProps, GroupUsersState} from "../../interfaces/groups";
import {Button, Col, Row, Table} from "reactstrap";
import {portalGroupGetNonGroupUsers, portalGroupGetUsers} from "../../store/actions/portalGroups";
import {Confirm, Toast} from "../../SWAL";
import {IUser} from "../../interfaces/user";
import {changeGroupUser} from "../../store/actions/portalGroups";


class GroupUsers extends React.Component<GroupUsersProps, GroupUsersState> {

  async componentDidMount() {
    await this.props.portalGroupGetUsers(this.props.portal, this.props.groupId);
    await this.props.portalGroupGetNonGroupUsers(this.props.portal, this.props.groupId);
  }

  async removeUser(user: IUser) {
    Confirm(`Do you really want to remove ${user.email} from this group?`, "Yes, remove!")
      .then(async result => {
        if (result.value) {
          changeGroupUser(this.props.portal, this.props.groupId, user.id, "remove")
            .then(_ => {
              Toast.fire("Success", "User removed successfully", "success");
            })
            .catch(_ => {
              Toast.fire("Error", "User changing failed", "error");
            });

          await this.reload();
        }
      })
  }

  async reload() {
    await this.props.portalGroupGetUsers(this.props.portal, this.props.groupId);
    await this.props.portalGroupGetNonGroupUsers(this.props.portal, this.props.groupId);
  }

  async addUser(user: IUser) {
    Confirm(`Do you really want to add ${user.email} to this group?`, "Yes, add!")
      .then(async result => {
        if (result.value) {
          changeGroupUser(this.props.portal, this.props.groupId, user.id, "add")
            .then(_ => {
              Toast.fire("Success", "User added successfully", "success");
            })
            .catch(_ => {
              Toast.fire("Error", "User changing failed", "error");
            });

          this.reload();
        }
      })
  }

  render() {
    return (
      <Row>
        <Col className="centerForm" lg={12}>
          <h4>Group Users</h4>
          <Table responsive striped bordered small={'true'} style={{marginTop: "35px"}}>
            <thead>
              <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            {this.props.groupUsers && this.props.groupUsers.map((user, uIndex) =>
              <tr key={uIndex}>
                <td>{user.firstName} {user.lastName}</td>
                <td>{user.email}</td>
                <td>
                  <Button onClick={() => {this.removeUser(user)}} className={"ml-3"} outline color="danger">
                    Remove from group
                  </Button>
                </td>
              </tr>
            )}
            </tbody>
          </Table>
          <h4 style={{marginTop: "35px"}}>Add users to the group</h4>
          <Table responsive striped bordered small={'true'} style={{marginTop: "35px"}}>
            <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            {this.props.nonGroupUsers && this.props.nonGroupUsers.map((user, uIndex) =>
              <tr key={uIndex}>
                <td>{user.firstName} {user.lastName}</td>
                <td>{user.email}</td>
                <td>
                  <Button onClick={() => {this.addUser(user)}} className={"ml-3"} outline
                          color="success">
                    Add
                  </Button>
                </td>
              </tr>
            )}
            </tbody>
          </Table>
        </Col>
      </Row>
    )
  }
}

export const mapStateToProps = state => {
  return {
    groupUsers: state.portalGroups.groupUsers,
    nonGroupUsers: state.portalGroups.nonGroupUsers
  }
};

export const mapDispatchToProps = dispatch => {
  return {
    portalGroupGetUsers: (portal: string, groupId: string) => dispatch(portalGroupGetUsers(portal, groupId)),
    portalGroupGetNonGroupUsers: (portal: string, groupId: string) => dispatch(portalGroupGetNonGroupUsers(portal, groupId))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupUsers);
