import {axiosInstance} from "../../Axios";
import {get} from "lodash";

interface ChangePermissionObject {
  name: string;
  model: string;
}

export interface ChangeObjectPermission {
  name: string,
  objectId: string,
  objectModel: string
}

export const ChangeModelPermissions = (portal: string, userId: string,
                                       action: "give-model-permission" | "remove-model-permission",
                                       data: ChangePermissionObject) => {
  return new Promise(async (resolve, reject) => {
    let url = `users/${action}/${userId}`;
    if (portal) {
      url = `${portal}/users/${action}/${userId}`;
    }

    let response = {};
    try {
      response = await axiosInstance(url, "POST", {}, {permissions: data});
    } catch (e) {
      reject(e);
    }
    resolve(get(response, "data"));
  });
};

export const ChangeObjectPermission = (portal: string,userId: string, action: "give-permission" | "remove-permission",
                                       data: ChangeObjectPermission) => {
  return new Promise(async (resolve, reject) => {
    let url = `users/${action}/${userId}`;
    if (portal) {
      url = `${portal}/users/${action}/${userId}`;
    }
      let response = {};
    try {
      response = await axiosInstance(url, "POST", {}, {permissions: data})
    } catch (e) {
      reject(e);
    }
    resolve(get(response, "data"));
  })
}
