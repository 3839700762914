import React from "react";
import {connect} from "react-redux";
import {GroupPermissionsProps, GroupPermissionsState} from "../../interfaces/groups";
import GroupModelPermissions from "./groupModelPermissions";
import GroupObjectPermissions from "./groupObjectPermissions";


class GroupPermissions extends React.Component<GroupPermissionsProps, GroupPermissionsState> {
  render() {
    return (
      <div>
        <h4>Group permissions</h4>
        <GroupModelPermissions modelPermissions={this.props.modelPermissions} groupId={this.props.groupId} portal={this.props.portal} />
        <GroupObjectPermissions permissions={this.props.permissions} groupId={this.props.groupId} portal={this.props.portal} />
      </div>
    )
  }
}

export default connect(null, null)(GroupPermissions)
