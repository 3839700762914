import React, {Component} from "react";
import {Button, Col, Input, InputGroup, Row, Table} from "reactstrap";
import {NavLink} from "react-router-dom";
import * as _ from "lodash";
import {IPortalProjectStates, PortalProjectsProps, ProjectProps} from "../../interfaces/portal";
import {generatePortalSpecificUrl, generateVersionUrl} from "../../helpers/urlGenerators";
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import {Confirm} from "../../SWAL";
import {changeProjectState} from "../../store/actions";
import {hasPermissionToCreateProjects, hasPermissionToChangeProject, hasPermissionToViewProject, hasPermissionToDeleteProject} from "../../PermissionValidators";
import Spinner from "../UI/Spinner/Spinner";
import {deleteProject, set_project_selected} from "../../store/actions/project";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faGlobe, faEdit} from "@fortawesome/free-solid-svg-icons";
import { VIEWER_WINDOW_NAME } from "../../helpers/utils";
import AddSubProject from "../Projects/AddSubProject";
import {axiosInstance} from "../../Axios";

class PortalProjects extends Component<PortalProjectsProps, IPortalProjectStates> {

  constructor(props) {
    super(props);
    this.state = {
      portalSlug: _.get(props.match.params, "portalSlug"),
      projectId: _.get(props.match.params, "projectId"),
      project_list: this.props?.project_list || [],
      search_term: '',
      AddSubProjectModal: false,
      selectProjectId: '',
    };
  };

  componentDidMount(): void {
      this.props.onTryFetchProjectList(_.get(this.state, "portalSlug"));
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.project_list !== this.props.project_list) {
      this.setState({project_list: this.props.project_list});
    }
  }

  HandleProjectStatusChange = (isActive, projectId: string, name: string) => {
    if (!isActive) {
      this.props.onChangeProjectStatus("enable", this.state.portalSlug, projectId, name);
    } else {
      Confirm("Do you really want to deactivate this project?", "Yes, deactivate it!")
        .then(async (result) => {
          if (result.value) {
            await this.props.onChangeProjectStatus("delete", this.state.portalSlug, projectId, name);
          }
        })
    }
  };

  deleteProjectHandler = (isDeleted, projectId: string, name: string) => {
        if (!isDeleted) {
            this.props.onProjectDelete(this.state.portalSlug, projectId, name);
        } else {
            Confirm("Do you really want to delete this project?", "Yes, delete it!")
                .then(async (result) => {
                    if (result.value) {
                        await this.props.onProjectDelete(this.state.portalSlug, projectId, name);
                    }
                })
        }
  };


  getProjectLink(item) {
    if(this.props.selectedPortal) {
        let url = `/${this.state.portalSlug}/${item.slug ? item.slug : item.id}`;
        return generateVersionUrl(process.env.REACT_APP_CESIUM_VIEWER_URL, this.props.selectedPortal['version'], url)
    }
  }

  addUserStats = () => {
    let user = this.props.user;

    axiosInstance(
        "users/login-stats",
        "post",
        { "Content-Type": "application/json" },
        {"userId":user.id, "email": user.email, "portalId": this.props.selectedPortal.id }
    ).then((response) => {
    })
        .catch((err) => {
        });
  };


  onSearchProjects = (e) => {
    this.setState({search_term: e.target.value});
    const search_term = e.target.value.toString().toLowerCase().trim();
    let filtered_results: ProjectProps[];
    filtered_results = this.props.project_list.filter((project: ProjectProps) => {
      return project.name.toLowerCase().includes(search_term);
    });
    this.setState({project_list: filtered_results});
  };

  clearSearch = (e) => {
    this.onSearchProjects({ target: { value: '' } });
  }

  HandleAddSubProjectChange = async (item) => {
    this.setState({AddSubProjectModal: true, selectProjectId: item.id});
  };

  closeAddSubProjectModal = () => {
    this.setState({ AddSubProjectModal: false, selectProjectId: ''});
  };


  render() {
    let projectList = this.state.project_list;
    if(this.state.projectId){
      projectList = _.filter(this.state.project_list , (project:any)=> String(project.id) === String(this.state.projectId));
    }
    return (
      <>
        <Col className="centerForm" lg={12}>
          <h1 className="heading">Projects</h1>
          <Row className="justify-content-center">
            <Col lg={5}>
              <InputGroup>
                <Input onChange={(e) => this.onSearchProjects(e)} value={this.state.search_term} ref="searchField" className="inputItem" placeholder="Search Projects" type="text"/>
                <Button className={'ml-2'} outline  onClick={this.clearSearch} disabled={!this.state.search_term} >
                  Clear
                </Button>
              </InputGroup>
            </Col>
          </Row>
          <br/>
          <Table responsive striped bordered>
            <thead>
              <tr>
                <th>Name</th>
                <th>Created At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {
                  this.props.loading ?
                      <tr>
                          <td colSpan={4}>
                              <div className="mx-auto text-center">
                                  <Spinner/>
                              </div>
                          </td>
                      </tr>
                      : null
              }
              {this.state.AddSubProjectModal
                  ? <AddSubProject
                      projectId = {this.state.selectProjectId}
                      portalSlug = {this.state.portalSlug}
                      modalOpen={this.state.AddSubProjectModal}
                      toggleModal={this.closeAddSubProjectModal}
                  />
                  : null}
              {
                !this.props.loading ?
                    projectList?.map((item, index) => {
                        return ((this.props.isSuperUser || hasPermissionToViewProject(item.id)) &&
                            <tr key={index}>
                                <td>
                                    {(this.props.isSuperUser || this.props.isPortalAdmin || hasPermissionToChangeProject(item.id)) && item && item.isActive ?
                                        <NavLink
                                        className="ml-3"
                                        onClick={ () => {this.props.setProjectSelected(item)}}
                                        to={{
                                            pathname: generatePortalSpecificUrl(this.props.selectedPortal, "/projects/update/" + item.id + "/" + this.state.portalSlug),
                                            state: {projectId: item.id, portalSlug: this.state.portalSlug}
                                        }}>{item.name} </NavLink>
                                        : <span> {item.name} </span>
                                    }
                                </td>
                                <td>{item.createdAt.split("T")[0]}</td>
                                <td>
                                  {
                                    (this.props.isSuperUser || hasPermissionToChangeProject(item.id)) && <Button
                                        outline
                                        className='mx-2 mxy-2'
                                        color={item.isActive ? "danger" : "success"}
                                        onClick={() => this.HandleProjectStatusChange(item.isActive, item.id, item.name)}>
                                        {
                                            item.isActive ? "Deactivate" : "Activate"
                                        }
                                    </Button>
                                  }
                                  
                                  { (!item.isActive && !item.isDeleted) && (this.props.isSuperUser || hasPermissionToDeleteProject(item.id)) ?
                                      <Button
                                          outline
                                          className='mx-2 mxy-2'
                                          color='danger'
                                          onClick={() => this.deleteProjectHandler(item.isDeleted, item.id, item.name)}>
                                          Delete
                                      </Button>
                                      : null
                                  }

                                  {
                                    (this.props.isSuperUser || hasPermissionToChangeProject(item.id)) && <NavLink
                                      className="mx-2"
                                      onClick={ () => {this.props.setProjectSelected(item)}}
                                      to={{
                                          pathname: generatePortalSpecificUrl(this.props.selectedPortal, "/projects/update/" + item.id + "/" + this.state.portalSlug),
                                          state: {projectId: item.id, portalSlug: this.state.portalSlug}
                                      }}>
                                      <Button outline color={"info"}><FontAwesomeIcon icon={faEdit}/> Edit Details </Button>
                                    </NavLink>
                                  }

                                  <NavLink className='mx-2 my-2' to={{ pathname: this.getProjectLink(item)}} target={VIEWER_WINDOW_NAME}>
                                    <Button outline color={"success"} onClick={this.addUserStats}><FontAwesomeIcon icon={faGlobe}/> 3D View</Button>
                                  </NavLink>
                                    <Button outline color={"info"}
                                    onClick={()=>{this.HandleAddSubProjectChange(item)}}>Add Sub Project</Button>
                                </td>
                            </tr>
                        )
                    })
              : null
              }
            </tbody>
          </Table>
          {hasPermissionToCreateProjects() && <NavLink to={generatePortalSpecificUrl(this.props.selectedPortal,
            "/project/create/" + _.get(this.state, "portalSlug", ""))}>
            <Button outline color="success">Add New Project</Button>{' '}
          </NavLink>}
        </Col>
      </>
    );
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    user: state.login.loggedInUser,
    userId: state.login.loggedInUser.id,
    token: state.login.token,
    project_list: state.project.project_list,
    success: state.project.get_project_list_success,
    loading: state.project.get_project_list_loading,
    isSuperUser: state.login.isSuperUser,
    isPortalAdmin: state.login.isPortalAdmin,
    selectedPortal: state.portalListReducer.selectedPortal,
    permissions: state.userPermissions.permissions
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryFetchProjectList: (portal_name) => dispatch(actions.get_project_list(portal_name)),
    onChangeProjectStatus: (action: string, portalSlug: string, projectId: string, projectName: string) => dispatch(changeProjectState(action, portalSlug, projectId, projectName)),
    onProjectDelete: (portalSlug: string, projectId: string, projectName: string) => dispatch(deleteProject(portalSlug, projectId, projectName)),
    onClearProjectState: () => dispatch(actions.clear_project_state()),
    setProjectSelected: (project: ProjectProps) => dispatch(set_project_selected(project)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(PortalProjects);
