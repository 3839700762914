import React, { useState } from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Input} from 'reactstrap';

interface DownloadLinkModalProps {
    className: string;
    downloadLinkItem: any;
    openModalDownloadLinks: boolean;
    submit: any;
    closeModalDownloadLinks: any;
    changeHandler: any;
}
const DownLoadLinks = (props: DownloadLinkModalProps) => {
    const { className, downloadLinkItem, openModalDownloadLinks } = props;
    return (
        <div>
            <Modal isOpen={openModalDownloadLinks} toggle={props.closeModalDownloadLinks} className={className}>
                <ModalHeader className='text-center'>Download Links </ModalHeader>
                <ModalBody>
                    <label> <strong> Index </strong></label>
                    <Input type={'text'}
                           name={'index'}
                           onChange={(event) => props.changeHandler(event)}
                           value={downloadLinkItem.index} />

                    <label><strong>Label</strong></label>
                    <Input type={'text'}
                           name={'label'}
                           onChange={(event) => props.changeHandler(event)}
                           value={downloadLinkItem.label} />

                    <label><strong> URL </strong></label>
                    <Input type={'text'}
                           name={'URL'}
                           onChange={(event) => props.changeHandler(event)}
                           value={downloadLinkItem.URL} />

                    <label><strong> Format </strong></label>
                    <Input type={'text'}
                           name={'format'}
                           onChange={(event) => props.changeHandler(event)}
                           value={downloadLinkItem.format} />

                    <label><strong> Size MB </strong></label>
                    <Input type={'text'}
                           name={'sizeMB'}
                           onChange={(event) => props.changeHandler(event)}
                           value={downloadLinkItem.sizeMB} />

                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={props.submit}>Submit</Button>
                    <Button color="secondary" onClick={props.closeModalDownloadLinks}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default DownLoadLinks;
