import React, { useState } from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Input} from 'reactstrap';

interface TagModalProps {
    buttonLabel: string;
    className: string;
    tagItem: any;
    openModal: boolean;
    submitTag: any;
    closeModal: any;
    changeHandler: any;
}
const EditAddTag = (props: TagModalProps) => {
    const { className, tagItem, openModal } = props;

    return (
        <div>
            <Modal isOpen={openModal} toggle={props.closeModal} className={className}>
                <ModalHeader className='text-center'>Tag</ModalHeader>
                <ModalBody>

                    <label> <strong> Key </strong></label>
                    <Input type={'text'}
                           name={'name'}
                           onChange={(event) => props.changeHandler(event)}
                           value={tagItem.name} />

                    <label><strong>Value</strong></label>
                    <Input type={'text'}
                           name={'value'}
                           onChange={(event) => props.changeHandler(event)}
                           value={tagItem.value} />

                    <label><strong> Description </strong></label>
                    <Input type={'textarea'}
                           name={'description'}
                           onChange={(event) => props.changeHandler(event)}
                           value={tagItem.description} />

                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={props.submitTag}>Submit</Button>
                    <Button color="secondary" onClick={props.closeModal}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default EditAddTag;
