import React, {Component} from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import * as actions from "../../store/actions/index";
import {LogoutProps} from "../../interfaces/auth";
import { detectPortal } from "../../store/actions/urlPortalDetectors";
import {generatePortalSpecificUrl} from "../../helpers/urlGenerators";


class Logout extends Component<LogoutProps, {}> {

  public componentDidMount() {
    this.props.onLogout();
  }

  render() {
    return (
      <>
        {
          !this.props.token ? (
            <Redirect to={generatePortalSpecificUrl(this.props.portal, "/login")} />
          ) : null
        }
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => dispatch(actions.logout()),
    onDetectPortal: (location: Location) => dispatch(detectPortal(location))
  };
};
const mapStateToProps = (state) => {
  return {
    token: state.login.token,
    portal: state.portal,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Logout);
