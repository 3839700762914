import React, {Component} from "react";
import {Col, Container, Row} from "reactstrap";
import * as actions from "../../store/actions"
import {connect} from "react-redux";
import {PortalDetailsProps} from "../../interfaces/portal";
import * as _ from "lodash";
import {clearPermissionState, getPermissions} from "../../store/actions/permission";
import PortalAdmins from "./PortalAdmins";
import PortalProjects from "./PortalProjects";
import PortalUsers from "./PortalUsers";
import PortalGroups from "../Groups/portalGroups";
import PortalVersion from "./PortalVersion";
import { changeSelectedPortal, setSelectedPortal } from "../../store/actions/portal";
import Tag from "../Tags/tag.component";
import AnnotationOptions from "../AnnotationOptions/annotation-options.component";
import ProjectParams from "../ProjectParams/project-params.component";
import AccessibleFolders from "../AccessibleFolders/accessible-folders";

class PortalDetails extends Component<PortalDetailsProps> {
  constructor(props) {
    super(props);
    this.state = {portalSlug: _.get(props.match.params, "portalSlug")};
  };

  componentDidMount(): void {
    setTimeout(async () => {
      const userPortal = _.find(this.props.loggedInUser.portals, (portal) => this.props.selectedPortal && portal.portal.toString() === this.props.selectedPortal.id.toString());
      this.props.onTryFetchProjectList(
        _.get(this.state, "portalSlug"));
      this.props.getUserPermissions(_.get(this.state, "portalSlug"), this.props.loggedInUser.id);
      if (this.props.loggedInUser.isSuperUser || userPortal?.isPortalAdmin) {
        await this.props.onTryFetchPortalAdminList(_.get(this.state, "portalSlug"));
        this.props.onTryFetchPortalUsersList(_.get(this.state, "portalSlug"));
      }
      if (!this.props.selectedPortal) {
        this.props.setSelectedPortal();
      }
    }, 0);
  }

  componentDidUpdate(prevProps, prevState): void {
    const userPortal = _.find(this.props.loggedInUser.portals, (portal) => this.props.selectedPortal && portal.portal.toString() === this.props.selectedPortal.id.toString());
    if ((this.props.loggedInUser.isSuperUser || userPortal?.isPortalAdmin) && (prevProps.portal_users_list?.length != this.props.portal_users_list?.length)) {
      this.props.onTryFetchPortalUsersList(_.get(this.state, "portalSlug"));
    }

    if ((this.props.loggedInUser.isSuperUser || userPortal?.isPortalAdmin) && (prevProps.portal_admin_list?.length != this.props.portal_admin_list?.length)) {
      this.props.onTryFetchPortalAdminList(_.get(this.state, "portalSlug"));
    }
  }

  componentWillUnmount(): void {
    this.props.onClearPortalUsersState();
  }

  render() {
    const userPortal = _.find(this.props.loggedInUser.portals, (portal) => this.props.selectedPortal && portal.portal.toString() === this.props.selectedPortal.id.toString());
    return (
      <>
        <Container>
          <Row>
            {this.props.loggedInUser.isSuperUser || userPortal?.isPortalAdmin ?
                <PortalProjects {...this.props}/>
                : null
            }
            {this.props.loggedInUser.isSuperUser || userPortal?.isPortalAdmin ?
              <PortalAdmins {...this.props}/>
              :
              null}
            {this.props.loggedInUser.isSuperUser || userPortal?.isPortalAdmin ?
              // @ts-ignore
              <PortalUsers {...this.props}/>
              :
              null}
            {this.props.loggedInUser.isSuperUser || userPortal?.isPortalAdmin ?
              // @ts-ignore
              <Col className="centerForm" lg={12}><PortalGroups {...this.props} /></Col>
              :
              null}
            {this.props.loggedInUser.isSuperUser || userPortal?.isPortalAdmin ?
              <Tag router={this.props.match} />
              : null
            }
            {this.props.loggedInUser.isSuperUser || userPortal?.isPortalAdmin ?
              <AnnotationOptions router={this.props.match} />
              : null
            }
            {this.props.loggedInUser.isSuperUser || userPortal?.isPortalAdmin ?
              <ProjectParams {...this.props} />
              : null
            }
            {this.props.loggedInUser.isSuperUser ?
              <AccessibleFolders changePortal={(portal) => { this.props.changeSelectedPortal(portal) }} portal={this.props.selectedPortal} />
              : null
            }
            {this.props.loggedInUser.isSuperUser || userPortal?.isPortalAdmin ?
              <PortalVersion {...this.props} />
              : null
            }
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.login.token,
    project_list: state.project.project_list,
    success: state.project.get_project_list_success,
    loading: state.portal.get_project_list_loading,
    portal_admin_list: state.portalListReducer.portal_admin_list,
    portal_users_list: state.portalUsersListReducer.portal_users_list,
    selectedPortal: state.portalListReducer.selectedPortal,
    permissions: state.userPermissions.permissions,
    loggedInUser: state.login.loggedInUser
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryFetchProjectList: (portal_name) => dispatch(actions.get_project_list(portal_name)),
    onTryFetchPortalAdminList: (portal_name) => dispatch(actions.get_portal_admin_list(portal_name)),
    onTryFetchPortalUsersList: (portalSlug) => dispatch(actions.get_portal_users_list(portalSlug)),
    onClearPortalUsersState: () => dispatch(actions.clearPortalUsersState()),
    onClearProjectState: () => dispatch(actions.clear_project_state()),
    getUserPermissions: (portalSlug: string, userId: string) => dispatch(getPermissions(portalSlug, userId)),
    clearUserPermissions: () => dispatch(clearPermissionState()),
    setSelectedPortal: (portal) => dispatch(setSelectedPortal()),
    changeSelectedPortal: (portal) => dispatch(changeSelectedPortal(portal)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(PortalDetails);
