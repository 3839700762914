import React from "react";
import * as _ from "lodash";
import { Button, Col, Row, Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import EditAddTag from "./components/add-tag.component";
import { axiosInstance } from "../../Axios";

interface TagProps {
    router: any;
}

interface TagState {
    tagsList: Array<any>;
    openModal: boolean;
    AddEdit: boolean;
    selectedTag: {
        name: string;
        value: string;
        description: string;
        resourceSlug: string;
    }
}

class Tag extends React.Component<TagProps, TagState> {

    constructor(props) {
        super(props);
        this.state = {
            tagsList: [],
            openModal: false,
            AddEdit: true,
            selectedTag: {
                name : '',
                value: '',
                description: '',
                resourceSlug: _.get(this.props.router.params, 'portalSlug')
            }
        };
    }

    componentDidMount(): void {
        this.fetchTags();
    }

    fetchTags() {
        if (this.props.router.params.portalSlug) {
            const _url = `tags/list/${this.props.router.params.portalSlug}`;
            const _headers = {};
            axiosInstance(_url, 'get', _headers, )
                .then(response => {
                    let res = _.get(response, "data");
                    if (res.success) {
                        this.setState({tagsList: res.object });
                    }
                }).catch(err => {});
        }
    }

    addTag = () => {
        this.resetTag();
        this.setState({ openModal: true, AddEdit: true });
    };

    closeModal = () => {
        this.setState({ openModal: false });
        this.resetTag();
    };

    submitTagHandler = () => {
        if(this.state.AddEdit){
            if (this.props.router.params.portalSlug) {
                const _url = `tags/add`;
                const _headers = {'content-type':'application/json'};
                const _tagBody = this.state.selectedTag;
                axiosInstance(_url, 'post', _headers, _tagBody)
                    .then(response => {
                        let res = _.get(response, "data");
                        if (res.success) {
                            this.state.tagsList.push(res.object);
                            this.setState({tagsList: this.state.tagsList });
                            this.componentDidMount();
                            this.resetTag();
                            this.closeModal();
                        }
                    }).catch(err => {
                    this.resetTag();
                    this.closeModal();
                });
            }
        } else {
            if (this.props.router.params.portalSlug) {
                const _tagID = _.get(this.state.selectedTag, '_id');
                const _url = `tags/update/${_tagID}`;
                const _headers = {'content-type':'application/json'};
                const _tagBody = this.state.selectedTag;
                axiosInstance(_url, 'patch', _headers, _tagBody)
                    .then(response => {
                        let res = _.get(response, "data");
                        if (res.success) {
                            this.resetTag();
                            this.closeModal();
                        }
                    }).catch(err => {
                    this.resetTag();
                    this.closeModal();
                });
            }
        }
    };

    resetTag = () => {
        this.setState({ selectedTag: {
                name: '',
                value: '',
                description: '',
                resourceSlug: _.get(this.props.router.params, 'portalSlug')}
        });
    };

    onChangeHandler = (event) => {
        event.preventDefault();
        const { value, name } : any = event.target;
        let _selectedTag = this.state.selectedTag;
        _selectedTag[name] = value;
        this.setState({ selectedTag: _selectedTag  });
    };

    removeTag = (tagID: string) => {
        if (this.props.router.params.portalSlug) {
            const _url = `tags/remove/${tagID}`;
            axiosInstance(_url, 'delete', {})
                .then(response => {
                    let res = _.get(response, "data");
                    if (res.success) {
                        _.remove(this.state.tagsList, tag => tag._id === tagID);
                        this.setState({tagsList: this.state.tagsList });
                    }
                }).catch(err => {
            });
        }
    };

    editTag = (tagID: string) => {
        const _tag : any = this.state.tagsList.filter(tag => tag._id === tagID)[0];
        this.setState({selectedTag: _tag, AddEdit: false, openModal: true });
    };

    render() {
        return (
            <div className="centerForm col-12 mx-0 px-0">
                {this.state.openModal ?
                    <EditAddTag openModal={this.state.openModal}
                        closeModal={this.closeModal}
                        className={'tag-add-edit'}
                        buttonLabel={'Submit'}
                        submitTag={this.submitTagHandler}
                        changeHandler={this.onChangeHandler}
                        tagItem={this.state.selectedTag} />
                    : null
                }
                <Row className="centerForm">
                    <h1 className="heading">Tags</h1>
                    <Col className="centerForm" lg={12}>
                        <div className='text-md-right'>
                            <Button onClick={() => this.addTag()}>
                                <span>
                                    <FontAwesomeIcon icon={faPlusCircle} />
                                </span>
                            </Button>
                        </div>
                        <Table responsive striped bordered>
                            <thead>
                                <tr>
                                    <th> No. </th>
                                    <th> Key </th>
                                    <th> Value </th>
                                    <th> Description </th>
                                    <th> Actions </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.tagsList.map((tag, index) => {
                                    return (
                                       <tr key={index}>
                                       <td>{index}</td>
                                       <td>{tag.name}</td>
                                       <td>{tag.value}</td>
                                       <td>{tag.description}</td>
                                       <td>
                                            <Button
                                                onClick={() => this.editTag(tag._id) }
                                                outline color="info">
                                                Edit
                                            </Button>
                                            <Button onClick={() => this.removeTag(tag._id)}
                                                    className={"ml-3"}
                                                    outline
                                                    color={"danger"}>
                                                Remove
                                            </Button>
                                       </td>
                                    </tr>)
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div>
        )
    }

}


export default Tag;
