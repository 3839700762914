import {CHANGE_DATASET} from "../actions/actionTypes/dataSetActionsTypes";
import {updateObject} from "../utility";

const initialState = {
  dataset: null
};

const reducer = (state=initialState, action) => {
  if (action.type === CHANGE_DATASET) {
    return updateObject(state, {dataset: action.dataset})
  }
  return state;
};

export default reducer;
