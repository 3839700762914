import * as permissions from "./permissions";
import * as _ from "lodash";
import LocalStorageWrapped from "./LocalStorageWrapped";


function hasPermission(user: object, model: string | null, permission: string | null) {
  if (_.get(user, "isSuperUser", false)) {
    return true;
  }

  let userPermissions = LocalStorageWrapped.getItem("userPermissions");
  let userGroups = LocalStorageWrapped.getItem("userGroups");

  if (model) {
    let modelPermissions = _.get(userPermissions, model);
    if (modelPermissions) {
      if (Array.isArray(modelPermissions)) {
        return modelPermissions.indexOf(permission) > -1;
      } else {
        return modelPermissions[permission] || false;
      }
    }
  }

  if (userGroups) {
    for (let i = 0; i < userGroups.length; i++) {
      let currentGroup = userGroups[i];
      let currentGroupObjectPermissions = currentGroup.permissions;
      let currentGroupModelPermissions = currentGroup.modelPermissions;
      for (let p = 0; p < currentGroupObjectPermissions.length; p++) {
        let currentObjectPermission = currentGroupObjectPermissions[p];
        if (currentObjectPermission.objectId === model && currentObjectPermission.name === permission) {
          return true;
        }
      }
      if (_.get(_.get(currentGroupModelPermissions, model, {}), permission, false)) {
        return true;
      }
    }
  }

  return false;
}

export const hasPermissionToCreateProjects = () => {
  let user = LocalStorageWrapped.getItem("userInfo");
  return hasPermission(user, "Project", permissions.ProjectPermissionsObject.CAN_ADD_PROJECT);
};

export const hasPermissionToChangeProject = (projectId?: string) => {
  let user = LocalStorageWrapped.getItem("userInfo");
  let hasModelPermission = hasPermission(user, "Project", permissions.ProjectPermissionsObject.CAN_CHANGE_PROJECT);
  if (projectId) {
    let hasObjectPermission = hasPermission(user, projectId, permissions.PermissionsObject.CAN_CHANGE);
    return hasModelPermission || hasObjectPermission;
  }

  return hasModelPermission;
};

export const hasPermissionToDeleteProject = (projectId?: string) => {
  let user = LocalStorageWrapped.getItem("userInfo");
  let hasModelPermission = hasPermission(user, "Project", permissions.ProjectPermissionsObject.CAN_DELETE_PROJECT);
  if (projectId) {
    let hasObjectPermission = hasPermission(user, projectId, permissions.PermissionsObject.CAN_DELETE);
    return hasModelPermission || hasObjectPermission;
  }

  return hasModelPermission;
}

export const hasPermissionToViewProject = (projectId?: string) => {
  let user = LocalStorageWrapped.getItem("userInfo");
  let hasModelPermission = hasPermission(user, "Project", permissions.ProjectPermissionsObject.CAN_VIEW_PROJECT);
  if (projectId) {
    return hasModelPermission || hasPermission(user, projectId, permissions.PermissionsObject.CAN_VIEW);
  }

  return hasModelPermission;
};

export const hasPermissionToViewAnnotations = () => {
  let user = LocalStorageWrapped.getItem("userInfo");
  return hasPermission(user, "Annotation", permissions.AnnotationPermissionsObject.CAN_VIEW_ANNOTATION);
};

export const hasPermissionToChangeAnnotations = () => {
  let user = LocalStorageWrapped.getItem("userInfo");
  return hasPermission(user, "Annotation", permissions.AnnotationPermissionsObject.CAN_CHANGE_ANNOTATION);
};

export const hasPermissionToAddAnnotations = () => {
  let user = LocalStorageWrapped.getItem("userInfo");
  return hasPermission(user, "Annotation", permissions.AnnotationPermissionsObject.CAN_ADD_ANNOTATIONS);
};
