import OtpInput from "react-otp-input";
import React, {useState} from "react";
import {Form} from "reactstrap";
import Countdown from "react-countdown";
import {useHistory} from "react-router";
import {generatePortalSpecificUrl} from "../../helpers/urlGenerators";
import {useSelector} from "react-redux";

export default function OtpForm(props) {
    const history = useHistory();
    const [now, setNow] = useState(Date.now())
    const portal = useSelector((state: any) => state.portal)

    const pad = n => {
        return String(n).padStart(2, '0')
    }

    return <Form style={{
        textAlign: "center",
    }}>
        <OtpInput
            containerStyle={{
                display: 'inline-block',
                marginRight: '2em',
            }}
            inputStyle={{
                width: '2em',
                height: '2em',
                margin: '0.5em',
                fontSize: '2em',
                borderRadius: '8px',
                border: '1px solid rgba(0, 0, 0, 0.3)',
            }}
            value={props.otp}
            onChange={props.setOtp}
            numInputs={6}
            inputType={"number"}
            shouldAutoFocus
            renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props} />}
        />
        <button
            onClick={props.onSubmitHandler}
            style={{
                height: '2em',
                fontSize: '2em',
                padding: '0 1em',
                backgroundImage: 'linear-gradient(to bottom, #1f8be6, #1178ce)',
                borderWidth: '0',
                borderRadius: '8px',
                color: 'white',
                textAlign: 'center',
                verticalAlign: 'center',
                textDecoration: 'none',
                display: 'inline-block',
            }}
            type="submit"
        >
            Verify
        </button>
        {props.timerStartSeconds() && props.timerEndSeconds() && <div>
            <div
                style={{
                    textAlign: 'center',
                    padding: '1.8em 0 0 0',
                    fontSize: '1.2em',
                }}
            >
                <div>Time Remaining</div>
                <div>
                    <Countdown
                        now={() => props.timerStartSeconds() * 1000 + (Date.now() - now)}
                        date={props.timerEndSeconds() * 1000}
                        renderer={props =>
                            <span>{props.hours ? pad(props.hours) + ':' : ''}{pad(props.minutes)}:{pad(props.seconds)}</span>}
                        onComplete={props.onTimerComplete}
                    />
                </div>
            </div>
        </div>}
    </Form>
}