import {updateObject} from "../utility";
import * as userActionTypes from "../actions/actionTypes/userActionTypes"

const initialState = {
  portal_users_list: [],
  get_portal_users_list_success: false,
  get_portal_users_list_loading: false,
  get_portal_users_list_errors: [],
  changeUserStatusLoading: false,
  changeUserStatusSuccess: false,
  changeUserStatusErrors: false,
  changeUserActivationLoading: false,
  changeUserActivationSuccess: false,
  changeUserActivationErrors: false,
};

const get_portal_users_list_success = (state, action) => {
  return updateObject(state, {
    get_portal_users_list_loading: false,
    get_portal_users_list_success: action.success,
    portal_users_list: action.portal_users_list,
    get_portal_users_list_errors: [],
  })
};
const get_portal_users_list_fail = (state, action) => {
  return updateObject(state, {
    get_portal_users_list_loading: false,
    get_portal_users_list_success: action.success,
    portal_users_list: [],
    get_portal_users_list_errors: action.errors,
  })
};

const get_portal_users_list_start = (state, _action) => {
  return updateObject(state, {
    get_portal_users_list_loading: true,
  })
};


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case userActionTypes.GET_USER_LIST_START:
      return get_portal_users_list_start(state, action);
    case userActionTypes.GET_USER_LIST_SUCCESS:
      return get_portal_users_list_success(state, action);
    case userActionTypes.GET_USER_LIST_FAIL:
      return get_portal_users_list_fail(state, action);
    case userActionTypes.CLEAR_USER_LIST_STATE:
      return updateObject(state, initialState);
    case userActionTypes.CHANGE_USER_STATUS_START:
      return updateObject(state, {changeUserStatusLoading: true});
    case userActionTypes.CHANGE_USER_STATUS_SUCCESS:
      return updateObject(state, {changeUserStatusSuccess: true, changeUserStatusLoading: false});
    case userActionTypes.CHANGE_USER_STATUS_FAIL:
      return updateObject(state, {changeUserStatusErrors: true, changeUserStatusLoading: false});
    case userActionTypes.CHANGE_USER_ACTIVATION_START:
      return updateObject(state, {changeUserActivationLoading: true});
    case userActionTypes.CHANGE_USER_ACTIVATION_SUCCESS:
      return updateObject(state, {changeUserActivationLoading: false, changeUserActivationSuccess: true});
    case userActionTypes.CHANGE_USER_ACTIVATION_FAIL:
      return updateObject(state, {changeUserActivationErrors: true, changeUserActivationLoading: false});
    default:
      return state;
  }
};

export default reducer;
