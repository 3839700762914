import {
  CHANGE_DATASET,
  DELETE_DATASET_FAIL,
  DELETE_DATASET_START,
  DELETE_DATASET_SUCCESS
} from "./actionTypes/dataSetActionsTypes";
import {axiosInstance} from "../../Axios";
import {get_project_list} from "./project";


export const changeDataSet = (dataset) => {
  return {
    type: CHANGE_DATASET,
    dataset: dataset
  }
};

export const deleteDataSet = (portalSlug: string, datasetId: string) => {
  return dispatch => {
    dispatch({type: DELETE_DATASET_START});
    axiosInstance(portalSlug + "/project/dataset/" + datasetId, "delete", {}, {})
      .then(res=>{
        dispatch({type:DELETE_DATASET_SUCCESS});
        dispatch(get_project_list(portalSlug));
      })
      .catch(err=>{
        dispatch({type:DELETE_DATASET_FAIL});
      })
  }
};
