import React from "react";
import { faTrash } from '@fortawesome/free-solid-svg-icons';//for icons
import { Button, Col, Collapse, CustomInput, Form, Input, Label, Row, Table } from "reactstrap";
import * as _ from "lodash";
import { connect } from "react-redux";
import { UpdateProjectProps } from "../../interfaces/project";
import { getProject, updateProject } from "../../store/actions/project";
import { deleteDataSet } from "../../store/actions/dataset";
import Spinner from "../UI/Spinner/Spinner";
import Switch from "react-switch";
import { Confirm, Toast } from "../../SWAL";
import {
    datasetTypeDropDown,
    FlightPath,
    INSITEDataType,
    KML,
    MODEL3D,
    Tiles3D,
    POINTS,
    Ortho,
    PANO,
    VIDEO,
    VIDEOSET,
    PoleImage,
    CustomDataset, customDatasetTypeDropDown, PinColourDropdown, Photos,
} from '../../interfaces/dataset';
import {
    initializeDownloadLinks,
    initializePanoParams,
    initializeVideoParams,
    restoreDatasetInitialState,
    setDatasetInitialState
} from "../../helpers/clearDataset";
import { axiosInstance } from "../../Axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './projects.styles.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import DownLoadLinks from "./components/download-links/download-links.component";
import PanoModal, { addPano, editPano, removePano } from "./components/pano-modal/pano-modal.component";
import VideoModal ,{ addVideo, editVideo, removeVideo } from "./components/pano-modal/video-modal.component";
import { deepStrictEqual } from "assert";
import ErrorDisplayer from "../UI/ErrorHandling/ErrorDisplayer";
import ColourPicker from "../common/colourPicker";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

interface FileObject {
    file: File,
    filename: string
};

interface UpdateState {
    projectId: string,
    portalSlug: string,
    project_name: { value: string, touched: boolean, valid: boolean },
    show: { value: boolean, valid: boolean, touched: boolean },
    dataSets: Array<any>,
    subProjects: Array<any>,
    dataSetsToBeDeleted: Array<any>,
    loadingState: boolean,
    error: { message: string, status: boolean },
    errorObj: any;
    openModalDownloadLinks: boolean;
    openModalPano: boolean;
    openModalVideo: boolean;
    downloadLink: any;
    selectedDS: any;
    downloadLinkMode: boolean;
    selectedFiles: Array<FileObject>;
    //  reportUrls:Array<String>;
    panoData: any;
    videoData:any;
    withoutGlobe: boolean;
}

class UpdateProject extends React.Component<UpdateProjectProps, UpdateState> {

    constructor(props: UpdateProjectProps) {
        super(props);
        this.state = {
            projectId: this.props.projectId,
            portalSlug: _.get(this.props.match.params, 'portalSlug'),
            errorObj: null,
            selectedFiles: [],
            //  reportUrls:[],
            project_name: {
                value: '',
                touched: false,
                valid: false,
            },
            show: {
                value: false,
                valid: false,
                touched: false,
            },
            dataSets: [],
            subProjects: [],
            dataSetsToBeDeleted: [],
            loadingState: false,
            error: { message: '', status: false },
            openModalDownloadLinks: false,
            openModalPano: false,
            openModalVideo: false,
            downloadLink: initializeDownloadLinks(),
            panoData: initializePanoParams(),
            videoData: initializeVideoParams(),
            selectedDS: 0,
            downloadLinkMode: true,
            withoutGlobe: false,

        };
    }

    getInitialState = (project: any) => {
        project.subProjects.unshift({id: "tempId", name: "None"});
        return {
            projectId: project.id,
            portalSlug: _.get(this.props.match.params, 'portalSlug'),
            project_name: {
                value: project.name,
                touched: !!(project.show),
                valid: !!(project.show),
            },
            show: {
                value: project.show,
                valid: !!(project.show),
                touched: !!(project.show),
            },
            withoutGlobe: project.withoutGlobe ? project.withoutGlobe: false,
            dataSets: this.initiallizeDataSet(project.dataset),
            subProjects: project.subProjects,
            dataSetsToBeDeleted: [],
            selectedFiles: [],
            // reportUrls:[],
            loadingState: false,
            error: { message: '', status: false }
        };
    };

    getInitialDataObject(dataSetType) {
        switch (dataSetType) {
            case INSITEDataType.TILE3D:
                return _.cloneDeep(Tiles3D);
            case INSITEDataType.KML:
                return _.cloneDeep(KML);
            case INSITEDataType.OBJ3D:
                return _.cloneDeep(MODEL3D);
            case INSITEDataType.FlightPath:
                return _.cloneDeep(FlightPath);
            case INSITEDataType.VIDEO:
                return _.cloneDeep(VIDEO);
            case INSITEDataType.VIDEOSET:
                return _.cloneDeep(VIDEOSET);
            case INSITEDataType.POINTS:
                return _.cloneDeep(POINTS);
            case INSITEDataType.PANO:
                return _.cloneDeep(PANO);
            case INSITEDataType.PoleImage:
                return _.cloneDeep(PoleImage);
            case INSITEDataType.CustomDataset:
                return _.cloneDeep(CustomDataset);
            case INSITEDataType.Photos:
                return _.cloneDeep(Photos);
        }
    }

    initiallizeDataSet = (_dataset?: any) => {
        let dataSetsFormatted = [];
        _dataset && _dataset.map(ds => {
            let _dataObject = {};
            switch (ds.datasetType) {
                case INSITEDataType.TILE3D:
                    _dataObject = _.cloneDeep(Tiles3D);
                    Object.keys(_dataObject).map((d, key) => {
                        _dataObject[d].value = ds.dsAttributes[d];
                        _dataObject[d].valid = !!(ds.dsAttributes[d]);
                        _dataObject[d].touched = !!(ds.dsAttributes[d]);
                        if (d === 'rustDetection') {
                            if( ds.dsAttributes[d] === 'false' || !ds.dsAttributes[d]){
                                _dataObject[d].checked = false;
                            }
                            else{
                                _dataObject[d].checked = true;
                            }
                        }
                    });
                    break;
                case INSITEDataType.KML:
                    _dataObject = _.cloneDeep(KML);
                    Object.keys(_dataObject).map((d, key) => {
                        _dataObject[d].value = ds.dsAttributes[d];
                        _dataObject[d].valid = !!(ds.dsAttributes[d]);
                        _dataObject[d].touched = !!(ds.dsAttributes[d]);
                        if (d === 'clamp_to_terrain') {
                            if( ds.dsAttributes[d] === 'false' || !ds.dsAttributes[d]){
                                _dataObject[d].checked = false;
                            }
                            else{
                                _dataObject[d].checked = true;
                            }
                        }
                    });
                    break;
                case INSITEDataType.OBJ3D:
                    _dataObject = _.cloneDeep(MODEL3D);
                    Object.keys(_dataObject).map((d, key) => {
                        _dataObject[d].value = ds.dsAttributes[d];
                        _dataObject[d].valid = !!(ds.dsAttributes[d]);
                        _dataObject[d].touched = !!(ds.dsAttributes[d]);
                    });
                    break;
                case INSITEDataType.FlightPath:
                    _dataObject = _.cloneDeep(FlightPath);
                    Object.keys(_dataObject).map((d, key) => {
                        _dataObject[d].value = ds.dsAttributes[d];
                        _dataObject[d].valid = !!(ds.dsAttributes[d]);
                        _dataObject[d].touched = !!(ds.dsAttributes[d]);
                        if (d === 'is360') {
                            if( ds.dsAttributes[d] === 'false' || !ds.dsAttributes[d]){
                                _dataObject[d].checked = false;
                            }
                            else{
                                _dataObject[d].checked = true;
                            }
                        }
                    });
                    break;
                case INSITEDataType.VIDEO:
                    _dataObject = _.cloneDeep(VIDEO);
                    Object.keys(_dataObject).map((d, key) => {
                        _dataObject[d].value = ds.dsAttributes[d];
                        _dataObject[d].valid = !!(ds.dsAttributes[d]);
                        _dataObject[d].touched = !!(ds.dsAttributes[d]);
                        if (d === 'is360') {
                            if( ds.dsAttributes[d] === 'false' || !ds.dsAttributes[d]){
                                _dataObject[d].checked = false;
                            }
                            else{
                                _dataObject[d].checked = true;
                            }
                        }
                    });
                    break;
                case INSITEDataType.VIDEOSET:
                    _dataObject = _.cloneDeep(VIDEOSET);
                    Object.keys(_dataObject).map((d, key) => {
                        _dataObject[d].value = ds.dsAttributes[d];
                        _dataObject[d].valid = !!(ds.dsAttributes[d]);
                        _dataObject[d].touched = !!(ds.dsAttributes[d]);
                        if (d === 'is360') {
                            if( ds.dsAttributes[d] === 'false' || !ds.dsAttributes[d]){
                                _dataObject[d].checked = false;
                            }
                            else{
                                _dataObject[d].checked = true;
                            }
                        }
                    });
                    break;
                case INSITEDataType.POINTS:
                    _dataObject = _.cloneDeep(POINTS);
                    Object.keys(_dataObject).map((d, key) => {
                        _dataObject[d].value = ds.dsAttributes[d];
                        _dataObject[d].valid = !!(ds.dsAttributes[d]);
                        _dataObject[d].touched = !!(ds.dsAttributes[d]);
                        if (d === 'classified') {
                            if( ds.dsAttributes[d] === 'false' || !ds.dsAttributes[d]){
                                _dataObject[d].checked = false;
                            }
                            else{
                                _dataObject[d].checked = true;
                            }
                        }
                    });
                    break;
                case INSITEDataType.ORTHO:
                    _dataObject = _.cloneDeep(Ortho);
                    Object.keys(_dataObject).map((d, key) => {
                        _dataObject[d].value = ds.dsAttributes[d];
                        _dataObject[d].valid = !!(ds.dsAttributes[d]);
                        _dataObject[d].touched = !!(ds.dsAttributes[d]);
                    });
                    break;
                case INSITEDataType.PANO:
                    _dataObject = _.cloneDeep(PANO);
                    Object.keys(_dataObject).map((d, key) => {
                        _dataObject[d].value = ds.dsAttributes[d];
                        _dataObject[d].valid = !!(ds.dsAttributes[d]);
                        _dataObject[d].touched = !!(ds.dsAttributes[d]);
                        if (d === 'useVrPlayer') {
                            if( ds.dsAttributes[d] === 'false' || !ds.dsAttributes[d]){
                                _dataObject[d].checked = false;
                            }
                            else{
                                _dataObject[d].checked = true;
                            }
                        }
                    });
                    break;
                case INSITEDataType.PoleImage:
                    _dataObject = _.cloneDeep(PoleImage);
                    Object.keys(_dataObject).map((d, key) => {
                        _dataObject[d].value = ds.dsAttributes[d];
                        _dataObject[d].valid = !!(ds.dsAttributes[d]);
                        _dataObject[d].touched = !!(ds.dsAttributes[d]);
                        if (d === 'clamp_to_terrain') {
                            if( ds.dsAttributes[d] === 'false' || !ds.dsAttributes[d]){
                                _dataObject[d].checked = false;
                            }
                            else{
                                _dataObject[d].checked = true;
                            }
                        }
                    });
                    break;

                case INSITEDataType.Photos:
                    _dataObject = _.cloneDeep(Photos);
                    Object.keys(_dataObject).map((d, key) => {
                        _dataObject[d].value = ds.dsAttributes[d];
                        _dataObject[d].valid = !!(ds.dsAttributes[d]);
                        _dataObject[d].touched = !!(ds.dsAttributes[d]);
                        if (d === 'clamp_to_terrain') {
                            if( ds.dsAttributes[d] === 'false' || !ds.dsAttributes[d]){
                                _dataObject[d].checked = false;
                            }
                            else{
                                _dataObject[d].checked = true;
                            }
                        }
                    });
                    break;

                case INSITEDataType.CustomDataset:
                    _dataObject = _.cloneDeep(CustomDataset);
                    Object.keys(_dataObject).map((d, key) => {
                        _dataObject[d].value = ds.dsAttributes[d];
                        _dataObject[d].valid = !!(ds.dsAttributes[d]);
                        _dataObject[d].touched = !!(ds.dsAttributes[d]);
                    });
                    break;
                default:
                    break;
            }
            const _dataset = {
                datasetType: { value: ds.datasetType, valid: false, touched: false },
                captureDate: { value: ds.captureDate, valid: false, touched: false },
                show: { value: ds.show, touched: false, valid: false, checked: ds.show },
                downloadLinks: ds.downloadLinks ? ds.downloadLinks : [],
                dataObject: _.cloneDeep(_dataObject),
                id: ds.id ? ds.id : null,
                reportUrls: ds.reportUrls,
                order: ds.order === undefined ?  null : ds.order
            };
            dataSetsFormatted.push(_dataset);
        });
        const sortedDataSets = _.sortBy(dataSetsFormatted, [(ds) => ds.order || 0]);
        return sortedDataSets;
    };
    multipleFileChangedHandler = (event, datasetIndex) => {
        const otherSelectedFiles = this.state.selectedFiles.filter((file) => {
            const datasetIdentifier = file.filename.substring(file.filename.lastIndexOf('_'));
            return !datasetIdentifier.includes(`_dataset${datasetIndex}`);
        });
        this.setState({
            selectedFiles: otherSelectedFiles.concat(Array.from(event.target.files).map((file: File, index: number) => {
                let fileName = file.name.replace(/ /g, "");
                return {
                    file,
                    filename: fileName.substring(0, fileName.lastIndexOf(".")) + `_dataset${datasetIndex}` + fileName.substring(fileName.lastIndexOf("."))
                }
            }))
        });
    };


    buildFormData(formData, data, parentKey) {
        if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
            Object.keys(data).forEach(key => {
                this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
            });
        } else {
            const value = data == null ? '' : data;

            formData.append(parentKey, value);
        }
    }

    datasetTypeChangeHandler = (event, ds, index) => {
        ds.datasetType = {
            value: Number(event.target.value),
            valid: event.target.value.trim() !== "",
            touched: true,
        };
        ds.dataObject = this.getInitialDataObject(ds.datasetType.value);
        this.setState({ dataSets: this.state.dataSets });
    };

    projectNameChangeHandler = (event) => {
        this.setState({
            project_name: {
                value: event.target.value,
                valid: event.target.value.trim() !== "",
                touched: true,
            }
        })
    };

    removeDataSetHandler = (index) => {
        let dataSets = this.state.dataSets;
        let dataSet = dataSets[index];
        let dataSetId = _.get(dataSet, "id", false);
        dataSets.splice(index, 1);
        let dataSetsToBeDeleted = this.state.dataSetsToBeDeleted;
        if (dataSetId) {
            Confirm(`Are you sure you want to delete this?`, "Yes.")
                .then(result => {
                    if (result.value) {
                        dataSetsToBeDeleted.push(dataSetId);
                        this.setState({ dataSetsToBeDeleted: dataSetsToBeDeleted });
                        const _portalSlug = _.get(this.props.match.params, "portalSlug");
                        this.props.onDataSetDelete(_portalSlug, dataSetId);
                    }
                })
        } else {
            this.setState({ dataSets: dataSets });
        }
        return;
    };

    checkboxHandler = (event, index, field) => {
        let dataSets = this.state.dataSets;
        dataSets[index].dataObject[field.fieldName] = {
            name: field.title,
            value: event.target.checked ? event.target.checked : false,
            checked: event.target.checked,
            valid: event.target.value.trim() !== "",
            touched: true,
            type: 'checkbox'
        };
        this.setState({ dataSets: dataSets });
    };

    checkboxHandlerVideoSet = (event, index, field) => {
        let dataSets = this.state.dataSets;
        dataSets[index].dataObject[field.fieldName] = {
            name: field.title,
            value: event.target.checked ? event.target.checked : false,
            checked: event.target.checked,
            valid: event.target.value.trim() !== "",
            touched: true,
            type: 'checkbox'
        };
        dataSets[index].dataObject.videoArray.value.forEach((val)=>{
            val.is360 = event.target.checked
        });
        this.setState({ dataSets: dataSets });
    };

    showhideChangeHandler = (event, ds, index) => {
        let dataSets = this.state.dataSets;
        dataSets[index].show = {
            value: event.target.checked ? event.target.checked : false,
            checked: event.target.checked,
            valid: true,
            touched: true,
        };
        this.setState({ dataSets: dataSets });
    };
    removereportUrls = (id, reportUrl) => {
        const _url = `${this.state.portalSlug}/project/${this.state.projectId}/report`;
        axiosInstance(_url, "delete", {}, {reportUrl}).then((res: any) => {
            const _dataObject = _.get(res, "data");
            const  {success } = _dataObject;
            if(success){
                console.log("Report File successfully deleted");
            }
            else {
                console.error("Report File not deleted sucessfully for: ", reportUrl);
            }
        }).catch(err => {
            console.error("Report File not deleted sucessfully for: ", reportUrl);

        });
        const datasetindex = this.state.dataSets?.findIndex((arr => arr.id === id));
        if (datasetindex > -1) {
            this.state.dataSets[datasetindex].reportUrls = this.state.dataSets[datasetindex]?.reportUrls?.filter((url) => url != reportUrl);
            this.setState({ dataSets: this.state.dataSets })
        }

    };

    customPinColorChangeHandler = (event, index, field) =>{
        let dataSets = this.state.dataSets;
        let selectedPin = _.find(PinColourDropdown, (pin)=>{
            if(pin.name === event.target.value){
                return pin.name;
            }
        });
        dataSets[index].dataObject[field.fieldName] = {
            name: field.title,
            value: selectedPin["name"],
            valid: event.target.value.trim() !== "",
            touched: true,
        };
        this.setState({ dataSets: dataSets });
    }


    customGPXFlightPathColorChangeHandler = (event, index, field) =>{
        let dataSets = this.state.dataSets;

        dataSets[index].dataObject[field.fieldName] = {
            name: field.title,
            value: event.hex,
            valid: event.hex !== "",
            touched: true,
            type: 'select'
        };
        this.setState({ dataSets: dataSets });
    }

    subProjectChangeHandler = (event, index, field) => {
        let dataSets = this.state.dataSets;
        let selectedSubProject = _.find(this.state.subProjects, (subProject)=>{
            if(subProject.name === String(event.target.value)){
                return subProject.name;
            }
        });
        dataSets[index].dataObject[field.fieldName] = {
            name: field.title,
            value: selectedSubProject["name"],
            valid: event.target.value.trim() !== "",
            touched: true,
            type: 'select'
        };
        this.setState({ dataSets: dataSets });
    }
        customDatasetChangeHandler = (event, index, field) =>{
        let dataSets = this.state.dataSets;
        let selectedDataset = _.find(customDatasetTypeDropDown, (dataset)=>{
            if(dataset.name === String(event.target.value)){
                return dataset.name;
            }
        });
        dataSets[index].dataObject[field.fieldName] = {
            name: field.title,
            value: selectedDataset["name"],
            valid: event.target.value.trim() !== "",
            touched: true,
            type: 'select',
            dstype : selectedDataset["id"],
        };
        this.setState({ dataSets: dataSets });

    }

    customDatasetValueChangeHandler = (event, index, field) => {
        if (field.fieldName === "csv_path") {
            let dataSets = this.state.dataSets;
            let csv_path = event.target.value;
            if(dataSets[index] && csv_path.length) {
                let datasetInfo = {
                    datasetType: dataSets[index].dataObject["datasetType"].dstype,
                    csv_path: csv_path,
                }
                this.setState({ loadingState: true });
                const _url = `${this.state.portalSlug}/project/${this.state.projectId}/custom_dataset`;
                axiosInstance(_url, "put", {}, {datasetInfo}).then((res: any) => {
                    const _dataObject = _.get(res, "data");
                    const {message, object, success} = _dataObject;
                    if (success) {
                        Toast.fire({
                            title:"Success",
                            titleText: "Custom Datasets have been successfully created.",
                            icon: "success"
                        });
                        this.getProjectDetails();
                        this.setState({errorObj: null});
                    } else {
                        Toast.fire("Failed", `Something went wrong`, "error");
                        this.setState({ error: { message: 'Something went wrong updating project.', status: true }});
                    }
                }).catch(err => {
                    Toast.fire("Failed", `Something went wrong`, "error");
                    this.setState({ error: { message: 'Something went wrong updating project.', status: true }, errorObj: err?.response?.data?.errors });

                });
            }
        }
    }

    fieldValueChangeHandler = (event, index, field) => {
        let dataSets = this.state.dataSets;
        dataSets[index].dataObject[field.fieldName] = {
            name: field.title,
            value: event.target.value,
            valid: event.target.value.trim() !== "",
            touched: true,
            type: event.target.type,
        };
        if(field.fieldName === "csv_path"){
            const datasetId = dataSets[index].id;
            const csv_path = event.target.value;
            if(datasetId && csv_path.length){
                const _url = `${this.state.portalSlug}/project/${this.state.projectId}/${datasetId}/pole_csv`;
                axiosInstance(_url, "put", {}, {csv_path}).then((res: any) => {
                    const _dataObject = _.get(res, "data");
                    const { message, object, success } = _dataObject;
                    if(success){
                        console.log("XML data saved successfully in Database");
                    }
                    else {
                        console.error("XML data not saved in DataBase for: ", csv_path);
                    }
                }).catch(err => {
                    console.error("XML data not saved in DataBase for: ", csv_path);

                });
            }
        }
        if(field.fieldName === "imagePath"){
            const datasetId = dataSets[index].id;
            const image_csv = event.target.value;
            if(datasetId && image_csv.length) {

                const _url = `${this.state.portalSlug}/project/${this.state.projectId}/${datasetId}/image_csv`;
                axiosInstance(_url, "put", {}, {image_csv}).then((res: any) => {
                    const _dataObject = _.get(res, "data");
                    const {message, object, success} = _dataObject;
                    if (success) {
                        console.log("XML data saved successfully in Database");
                    } else {
                        console.error("XML data not saved in DataBase for: ", image_csv);
                    }
                }).catch(err => {
                    console.error("XML data not saved in DataBase for: ", image_csv);

                });
            }
        }
        if(field.fieldName === "camera_path"){
            const datasetId = dataSets[index].id;
            if(event.target.value) {
                let fileExtension = event.target.value.split('.');
                fileExtension = fileExtension[fileExtension.length-1];
                if(fileExtension === 'xml') {
                    const camera_xml = event.target.value;
                    const _url = `${this.state.portalSlug}/project/${this.state.projectId}/${datasetId}/camera_xml`;
                    axiosInstance(_url, "put", {}, {camera_xml}).then((res: any) => {
                        const _dataObject = _.get(res, "data");
                        const {message, object, success} = _dataObject;
                        if (success) {
                            console.log("XML data saved successfully in Database");
                        } else {
                            console.error("XML data not saved in DataBase for: ", camera_xml);
                        }
                    }).catch(err => {
                        console.error("XML data not saved in DataBase for: ", camera_xml);

                    });
                }
                else if(fileExtension === 'csv'){
                    const image_csv = event.target.value;
                    const _url = `${this.state.portalSlug}/project/${this.state.projectId}/${datasetId}/image_csv`;
                    axiosInstance(_url, "put", {}, {image_csv}).then((res: any) => {
                        const _dataObject = _.get(res, "data");
                        const {message, object, success} = _dataObject;
                        if (success) {
                            console.log("XML data saved successfully in Database");
                        } else {
                            console.error("XML data not saved in DataBase for: ", image_csv);
                        }
                    }).catch(err => {
                        console.error("XML data not saved in DataBase for: ", image_csv);

                    });
                }
            }
        }
        this.setState({ dataSets: dataSets });
    };

    classIdChangeHandler = (event, index,dsIndex,)=>{
        let _dataset = this.state.dataSets;
        _dataset[dsIndex].dataObject.classArray.value[index].id = event.target.value;
        this.setState({dataSets:_dataset});
    }

    classImageUrlChangeHandler = (event, index,dsIndex,)=>{
        let _dataset = this.state.dataSets;
        _dataset[dsIndex].dataObject.imagePath.value[index].url = event.target.value;
        const datasetId = _dataset[dsIndex].id;
        const image_csv = event.target.value;
        if(datasetId && image_csv.length) {
            const _url = `${this.state.portalSlug}/project/${this.state.projectId}/${datasetId}/image_csv`;
            axiosInstance(_url, "put", {}, {image_csv}).then((res: any) => {
                const _dataObject = _.get(res, "data");
                const {message, object, success} = _dataObject;
                if (success) {
                    console.log("XML data saved successfully in Database");
                } else {
                    console.error("XML data not saved in DataBase for: ", image_csv);
                }
            }).catch(err => {
                console.error("XML data not saved in DataBase for: ", image_csv);

            });
        }

        this.setState({dataSets:_dataset});

    }

    classNameChangeHandler = (event, index,dsIndex,)=>{
        let _dataset = this.state.dataSets;
        _dataset[dsIndex].dataObject.classArray.value[index].name = event.target.value;
        this.setState({dataSets:_dataset});
    }

    classRemoveHandler = (index, dsIndex,)=>{
        let _dataset = this.state.dataSets;
        _dataset[dsIndex].dataObject.classArray.value.splice(index, 1);
        this.setState({dataSets:_dataset});
    }

    classImageRemoveHandler = (index, dsIndex,)=>{
        let _dataset = this.state.dataSets;
        const image_csv = _dataset[dsIndex].dataObject.imagePath.value[index];
        const _url = `${this.state.portalSlug}/project/${this.state.projectId}/${_dataset[dsIndex].id}/image_csv`;
        axiosInstance(_url, "delete", {}, {image_csv});
        _dataset[dsIndex].dataObject.imagePath.value.splice(index, 1);
        this.setState({dataSets:_dataset});
    }


    renderDataObject = (dataSetType: number, ds: any, index: number) => {
        if (dataSetType) {
            const _dataSet = ds;
            switch (dataSetType) {
                case INSITEDataType.TILE3D:
                    if (_.isEmpty(_dataSet.dataObject)) {
                        _dataSet.dataObject = _.cloneDeep(Tiles3D);
                    }
                    const _dataObject = _.cloneDeep(_dataSet.dataObject);
                    return Object.keys(_dataObject).map((d, key) => (
                        <Col key={key} className="col-12 col-sm-6 col-md-6 text-left">
                            <Label className="labelText">{_dataObject[d].name} </Label>
                            {  Tiles3D[d].name === 'Rust Detection' ?
                                <CustomInput id={`rust_detection-${index}`}
                                             onChange={(event) => this.checkboxHandler(event, index, { fieldName: d, title: _dataObject[d].name })}
                                             type={_dataObject[d].type}
                                             checked={_dataObject[d].checked}
                                             value={_dataObject[d].value}
                                             valid={_dataObject[d].valid && _dataObject[d].touched}
                                             invalid={!_dataObject[d].valid && _dataObject[d].touched} />
                                :
                                _dataObject[d].name === 'Sub Project' ?
                                    <Input
                                        type={_dataObject[d].type}
                                        name='select'
                                        className="inputItem"
                                        value={_dataObject[d].value}
                                        valid={_dataObject[d].valid && _dataObject[d].touched}
                                        invalid={!_dataObject[d].valid && _dataObject[d].touched}
                                        onChange={(event) => this.subProjectChangeHandler(event, index, {
                                            fieldName: d,
                                            title: _dataObject[d].name
                                        })}>
                                        {this.state.subProjects.map((value, iterate) => {
                                            return <option key={iterate} value={value.name}> {value.name} </option>
                                        })};
                                    </Input>
                                    :
                                    <Input
                                        type={_dataObject[d].type}
                                        className="inputItem"
                                        value={_dataObject[d].value}
                                        valid={_dataObject[d].valid && _dataObject[d].touched}
                                        invalid={!_dataObject[d].valid && _dataObject[d].touched}
                                        onChange={(event) => this.fieldValueChangeHandler(event, index, {
                                            fieldName: d,
                                            title: _dataObject[d].name
                                        })}
                                    />
                            }
                            <p />
                        </Col>
                    ));
                case INSITEDataType.KML:
                    if (_.isEmpty(_dataSet.dataObject)) {
                        _dataSet.dataObject = _.cloneDeep(KML);
                    }
                    const _dataObjectKML = _.cloneDeep(_dataSet.dataObject);
                    return Object.keys(_dataObjectKML).map((d, key) => (
                        <Col key={key} className="col-12 col-sm-6 col-md-6 text-left">
                            <Label className="labelText">{_dataObjectKML[d].name}</Label>
                            {  KML[d].name === 'Clamp to Terrain' ?
                                <CustomInput id={`clamp_to_terrian-${index}`}
                                    label="Clamp to terrain"
                                    onChange={(event) => this.checkboxHandler(event, index, { fieldName: d, title: _dataObjectKML[d].name })}
                                    type={_dataObjectKML[d].type}
                                    checked={_dataObjectKML[d].checked}
                                    value={_dataObjectKML[d].value}
                                    valid={_dataObjectKML[d].valid && _dataObjectKML[d].touched}
                                    invalid={!_dataObjectKML[d].valid && _dataObjectKML[d].touched} />
                                :
                                _dataObjectKML[d].name === 'Sub Project' ?
                                <Input
                                    type={_dataObjectKML[d].type}
                                    name='select'
                                    className="inputItem"
                                    value={_dataObjectKML[d].value}
                                    valid={_dataObjectKML[d].valid && _dataObjectKML[d].touched}
                                    invalid={!_dataObjectKML[d].valid && _dataObjectKML[d].touched}
                                    onChange={(event) => this.subProjectChangeHandler(event, index, {
                                        fieldName: d,
                                        title: _dataObjectKML[d].name
                                    })}>
                                    {this.state.subProjects.map((value, iterate) => {
                                        return <option key={iterate} value={value.name}> {value.name} </option>
                                    })};
                                </Input>
                                :
                                <Input
                                    type={_dataObjectKML[d].type}
                                    className="inputItem"
                                    checked={_dataObjectKML[d].value}
                                    value={_dataObjectKML[d].value}
                                    valid={_dataObjectKML[d].valid && _dataObjectKML[d].touched}
                                    invalid={!_dataObjectKML[d].valid && _dataObjectKML[d].touched}
                                    onChange={(event) => this.fieldValueChangeHandler(event, index, {
                                        fieldName: d,
                                        title: _dataObjectKML[d].name
                                    })}
                                />
                            }
                            <p />
                        </Col>
                    ));
                case INSITEDataType.OBJ3D:
                    if (_.isEmpty(_dataSet.dataObject)) {
                        _dataSet.dataObject = _.cloneDeep(MODEL3D);
                    }
                    const _dataObjectModel3d = _.cloneDeep(_dataSet.dataObject);
                    return Object.keys(_dataObjectModel3d).map((d, key) => (
                        <Col key={key} className="col-12 col-sm-6 col-md-6 text-left">
                            <Label className="labelText">{_dataObjectModel3d[d].name}</Label>
                            {_dataObjectModel3d[d].name === 'Sub Project' ?
                                <Input
                                    type={_dataObjectModel3d[d].type}
                                    name='select'
                                    className="inputItem"
                                    value={_dataObjectModel3d[d].value}
                                    valid={_dataObjectModel3d[d].valid && _dataObjectModel3d[d].touched}
                                    invalid={!_dataObjectModel3d[d].valid && _dataObjectModel3d[d].touched}
                                    onChange={(event) => this.subProjectChangeHandler(event, index, {
                                        fieldName: d,
                                        title: _dataObjectModel3d[d].name
                                    })}>
                                    {this.state.subProjects.map((value, iterate) => {
                                        return <option key={iterate} value={value.name}> {value.name} </option>
                                    })};
                                </Input>
                                :
                                <Input
                                    type={_dataObjectModel3d[d].type}
                                    className="inputItem"
                                    value={_dataObjectModel3d[d].value}
                                    valid={_dataObjectModel3d[d].valid && _dataObjectModel3d[d].touched}
                                    invalid={!_dataObjectModel3d[d].valid && _dataObjectModel3d[d].touched}
                                    onChange={(event) => this.fieldValueChangeHandler(event, index, {
                                        fieldName: d,
                                        title: _dataObjectModel3d[d].name
                                    })}
                                />
                            }
                            <p />
                        </Col>

                    ));
                case INSITEDataType.FlightPath:
                    if (_.isEmpty(_dataSet.dataObject)) {
                        _dataSet.dataObject = _.cloneDeep(FlightPath);
                    }
                    const _dataObjectFlight = _.cloneDeep(_dataSet.dataObject);
                    return Object.keys(_dataObjectFlight).map((d, key) => (
                        <Col key={key} className="col-12 col-sm-6 col-md-6 text-left">
                            <Label className="labelText">{_dataObjectFlight[d].name}</Label>
                            {
                                FlightPath[d].type === "checkbox" ?
                                    <CustomInput id={`is360-${index}`}
                                                 label="Is 360 Flight-Path"
                                                 onChange={(event) => this.checkboxHandler(event, index, {
                                                     fieldName: d,
                                                     title: _dataObjectFlight[d].name
                                                 })}
                                                 type={_dataObjectFlight[d].type}
                                                 checked={_dataObjectFlight[d].checked}
                                                 value={_dataObjectFlight[d].value}
                                                 valid={_dataObjectFlight[d].valid && _dataObjectFlight[d].touched}
                                                 invalid={!_dataObjectFlight[d].valid && _dataObjectFlight[d].touched}/>
                                    :
                                    _dataObjectFlight[d].name === 'Flight Colour' ?
                                        <tr key={index}>
                                            <ColourPicker onChange={(event)=> this.customGPXFlightPathColorChangeHandler(event,index,{
                                                fieldName: d,
                                                title: _dataObjectFlight[d].name
                                            })} index={index} colour={_dataObjectFlight[d].value} />
                                        </tr>
                                        :
                                    _dataObjectFlight[d].name === 'Sub Project' ?
                                        <Input
                                            type={_dataObjectFlight[d].type}
                                            name='select'
                                            className="inputItem"
                                            value={_dataObjectFlight[d].value}
                                            valid={_dataObjectFlight[d].valid && _dataObjectFlight[d].touched}
                                            invalid={!_dataObjectFlight[d].valid && _dataObjectFlight[d].touched}
                                            onChange={(event) => this.subProjectChangeHandler(event, index, {
                                                fieldName: d,
                                                title: _dataObjectFlight[d].name
                                            })}>
                                            {this.state.subProjects.map((value, iterate) => {
                                                return <option key={iterate} value={value.name}> {value.name} </option>
                                            })};
                                        </Input>
                                        :
                                        <Input type={_dataObjectFlight[d].type}
                                               className="inputItem"
                                               value={_dataObjectFlight[d].value}
                                               valid={_dataObjectFlight[d].valid && _dataObjectFlight[d].touched}
                                               invalid={!_dataObjectFlight[d].valid && _dataObjectFlight[d].touched}
                                               onChange={(event) => this.fieldValueChangeHandler(event, index, {
                                                   fieldName: d,
                                                   title: _dataObjectFlight[d].name
                                               })}
                                        />
                            }
                            <p />
                        </Col>
                    ));
                case INSITEDataType.VIDEO:
                    if (_.isEmpty(_dataSet.dataObject)) {
                        _dataSet.dataObject = _.cloneDeep(VIDEO);
                    }
                    const _dataObjectVideo = _.cloneDeep(_dataSet.dataObject);
                    return Object.keys(_dataObjectVideo).map((d, key) => (
                        <Col key={key} className="col-12 col-sm-6 col-md-6 text-left">
                            <Label className="labelText">{_dataObjectVideo[d].name}</Label>
                            {
                                VIDEO[d].type === "checkbox" ?
                                    <CustomInput id={`is360-${index}`}
                                                 label="Is 360 Video"
                                                 onChange={(event) => this.checkboxHandler(event, index, {
                                                     fieldName: d,
                                                     title: _dataObjectVideo[d].name
                                                 })}
                                                 type={_dataObjectVideo[d].type}
                                                 checked={_dataObjectVideo[d].checked}
                                                 value={_dataObjectVideo[d].value}
                                                 valid={_dataObjectVideo[d].valid && _dataObjectVideo[d].touched}
                                                 invalid={!_dataObjectVideo[d].valid && _dataObjectVideo[d].touched}/>
                                    :
                                    _dataObjectVideo[d].name === 'Sub Project' ?
                                        <Input
                                            type={_dataObjectVideo[d].type}
                                            name='select'
                                            className="inputItem"
                                            value={_dataObjectVideo[d].value}
                                            valid={_dataObjectVideo[d].valid && _dataObjectVideo[d].touched}
                                            invalid={!_dataObjectVideo[d].valid && _dataObjectVideo[d].touched}
                                            onChange={(event) => this.subProjectChangeHandler(event, index, {
                                                fieldName: d,
                                                title: _dataObjectVideo[d].name
                                            })}>
                                            {this.state.subProjects.map((value, iterate) => {
                                                return <option key={iterate} value={value.name}> {value.name} </option>
                                            })};
                                        </Input>
                                        :
                                        <Input type={_dataObjectVideo[d].type}
                                               className="inputItem"
                                               value={_dataObjectVideo[d].value}
                                               valid={_dataObjectVideo[d].valid && _dataObjectVideo[d].touched}
                                               invalid={!_dataObjectVideo[d].valid && _dataObjectVideo[d].touched}
                                               onChange={(event) => this.fieldValueChangeHandler(event, index, {
                                                   fieldName: d,
                                                   title: _dataObjectVideo[d].name
                                               })}
                                        />
                            }
                            <p />
                        </Col>
                    ));
                case INSITEDataType.VIDEOSET:
                    if (_.isEmpty(_dataSet.dataObject)) {
                        _dataSet.dataObject = _.cloneDeep(VIDEOSET);
                    }
                    const _dataObjectVideoSet = _.cloneDeep(_dataSet.dataObject);
                    let fieldArray = Object.keys(_dataObjectVideoSet).map((d, key) => (
                            <Col className={d === "is360" ? "col-12 text-left" : "col-12 col-sm-6 col-md-6 text-left"} key={key}>
                                <Label className="labelText">{_dataObjectVideoSet[d].name} </Label>
                                {VIDEOSET[d].name === 'Video Array' ? null :
                                    VIDEOSET[d].type === "checkbox" ?
                                        <CustomInput id={`is360-${index}`}
                                                     label="Select All 360"
                                                     onChange={(event) => this.checkboxHandlerVideoSet(event, index, {
                                                         fieldName: d,
                                                         title: _dataObjectVideoSet[d].name
                                                     })}
                                                     type={_dataObjectVideoSet[d].type}
                                                     checked={_dataObjectVideoSet[d].checked}
                                                     value={_dataObjectVideoSet[d].value}
                                                     valid={_dataObjectVideoSet[d].valid && _dataObjectVideoSet[d].touched}
                                                     invalid={!_dataObjectVideoSet[d].valid && _dataObjectVideoSet[d].touched}/>
                                        :
                                        _dataObjectVideoSet[d].name === 'Sub Project' ?
                                            <Input
                                                type={_dataObjectVideoSet[d].type}
                                                name='select'
                                                className="inputItem"
                                                value={_dataObjectVideoSet[d].value}
                                                valid={_dataObjectVideoSet[d].valid && _dataObjectVideoSet[d].touched}
                                                invalid={!_dataObjectVideoSet[d].valid && _dataObjectVideoSet[d].touched}
                                                onChange={(event) => this.subProjectChangeHandler(event, index, {
                                                    fieldName: d,
                                                    title: _dataObjectVideoSet[d].name
                                                })}>
                                                {this.state.subProjects.map((value, iterate) => {
                                                    return <option key={iterate}
                                                                   value={value.name}> {value.name} </option>
                                                })};
                                            </Input>
                                            :
                                            <Input
                                                type={_dataObjectVideoSet[d].type}
                                                className="inputItem"
                                                value={_dataObjectVideoSet[d].value}
                                                valid={_dataObjectVideoSet[d].valid && _dataObjectVideoSet[d].touched}
                                                invalid={!_dataObjectVideoSet[d].valid && _dataObjectVideoSet[d].touched}
                                                onChange={(event) => this.fieldValueChangeHandler(event, index, {
                                                    fieldName: d,
                                                    title: _dataObjectVideoSet[d].name
                                                })}
                                            />
                                }
                                <p />
                            </Col>
                        )
                    );
                    fieldArray.push(
                        <Row className="centerForm text-left m-0" style={{ paddingTop: 0 }}>
                            <Col className="centerForm" lg={12} style={{ marginTop: 0 }}>
                                <div className='text-md-right'>
                                    Add Video <Button onClick={() => addVideo(this, _dataSet, index)}>
                                        <span>
                                            <FontAwesomeIcon icon={faPlusCircle} />
                                        </span>
                                </Button>
                                </div>
                                <Table responsive striped bordered>
                                    <thead>
                                    <tr>
                                        <th> Lat </th>
                                        <th> Lon </th>
                                        <th> Height </th>
                                        <th> URL </th>
                                        <th> Name </th>
                                        <th> is360 </th>
                                        <th> Actions </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {_dataObjectVideoSet.videoArray.value.map((video, video_index) => {
                                        if(video.is360 === 'false' || !video.is360){
                                            video.is360 = false;
                                        }
                                        else{
                                            video.is360 = true;
                                        }
                                        return (
                                            <tr key={video_index}>
                                                <td>{video.lat}</td>
                                                <td>{video.lon}</td>
                                                <td>{video.height}</td>
                                                <td>{video.URL}</td>
                                                <td>{video.name}</td>
                                                <td>
                                                    <Input
                                                    type={"checkbox"}
                                                    name={'is360'}
                                                    value={video.is360}
                                                    checked={video.is360}
                                                    style={{position:"relative"}}
                                                    >
                                                    </Input>
                                                </td>
                                                <td>
                                                    <Button
                                                        onClick={() => editVideo(this, index, video_index)}
                                                        outline color="info">
                                                        Edit
                                                    </Button>
                                                    <Button onClick={() => removeVideo(this, _dataSet, index, video_index)}
                                                            className={"ml-3"}
                                                            outline
                                                            color={"danger"}>
                                                        Remove
                                                    </Button>
                                                </td>
                                            </tr>)
                                    })}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    )
                    return fieldArray;
                case INSITEDataType.POINTS:
                    if (_.isEmpty(_dataSet.dataObject)) {
                        _dataSet.dataObject = _.cloneDeep(POINTS);
                    }
                    if(_.isEmpty(_dataSet.dataObject.classArray.value)){
                        _dataSet.dataObject.classArray = _.cloneDeep(POINTS.classArray);
                    }
                    const _dataObjectPoints = _.cloneDeep(_dataSet.dataObject);
                    return Object.keys(_dataObjectPoints).map((d, key) => (
                    <>
                    {!_dataObjectPoints[d].hidden ?<Col key={key} className="col-12 col-sm-6 col-md-6 text-left">
                        <Label className="labelText">{_dataObjectPoints[d].name}</Label>
                        {_dataObjectPoints[d].name === 'Classified' ?
                            <CustomInput id={`classified-${index}`}
                                         onChange={(event) => this.checkboxHandler(event, index, {
                                             fieldName: d,
                                             title: _dataObjectPoints[d].name
                                         })}
                                         type={_dataObjectPoints[d].type}
                                         checked={_dataObjectPoints[d].checked}
                                         value={_dataObjectPoints[d].value}
                                         valid={_dataObjectPoints[d].valid && _dataObjectPoints[d].touched}
                                         invalid={!_dataObjectPoints[d].valid && _dataObjectPoints[d].touched}/>
                            :
                            _dataObjectPoints[d].name === 'Sub Project' ?
                                <Input
                                    type={_dataObjectPoints[d].type}
                                    name='select'
                                    className="inputItem"
                                    value={_dataObjectPoints[d].value}
                                    valid={_dataObjectPoints[d].valid && _dataObjectPoints[d].touched}
                                    invalid={!_dataObjectPoints[d].valid && _dataObjectPoints[d].touched}
                                    onChange={(event) => this.subProjectChangeHandler(event, index, {
                                        fieldName: d,
                                        title: _dataObjectPoints[d].name
                                    })}>
                                    {this.state.subProjects.map((value, iterate) => {
                                        return <option key={iterate}
                                                       value={value.name}> {value.name} </option>
                                    })};
                                </Input>
                                :

                                _dataObjectPoints[d].name === 'Class Array' ?
                                    <></> :
                                    <Input
                                        type={_dataObjectPoints[d].type}
                                        className="inputItem"
                                        checked={_dataObjectPoints[d].value}
                                        value={_dataObjectPoints[d].value}
                                        valid={_dataObjectPoints[d].valid && _dataObjectPoints[d].touched}
                                        invalid={!_dataObjectPoints[d].valid && _dataObjectPoints[d].touched}
                                        onChange={(event) => this.fieldValueChangeHandler(event, index, {
                                            fieldName: d,
                                            title: _dataObjectPoints[d].name
                                        })}
                                    />
                        }

                        {
                            _dataObjectPoints[d].name === 'Classified' && _dataObjectPoints[d].checked === true ?
                                <Col key={key} className="text-left">
                                    <div className='text-md-right'>
                                    <Button
                                            onClick={() => this.addClassification(ds, index)}>
                                             <span>
                                                    <FontAwesomeIcon icon={faPlusCircle}/>
                                             </span>
                                    </Button>
                                    </div>

                                    { _dataObjectPoints?.classArray?.value?.map((value, idx) => {
                                            return(
                                                <tr className="col-12 col-sm-6 col-md-6 text-left">
                                                    <td className="px-2">
                                                        <Label className="labelText">ID</Label>
                                                        <Input type="number"
                                                               className="inputItem"
                                                               placeholder= {value.id}
                                                               onChange={(event) =>{this.classIdChangeHandler(event, idx,index)}}
                                                        />
                                                    </td>
                                                    <td className="px-2">

                                                        <Label className="labelText">Related Name</Label>
                                                        <Input type="text"
                                                               className="inputItem"
                                                               placeholder ={value.name}
                                                               onChange={(event) =>{this.classNameChangeHandler(event, idx,index)}}
                                                        />
                                                    </td>
                                                    <td className="px-2" style={{verticalAlign: "bottom"}}>
                                                        <Button color="danger" className="mb-1" onClick={() => this.classRemoveHandler(idx,index)}>
                                                            <span>
                                                                <FontAwesomeIcon icon={faTrash} />
                                                            </span>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )

                                        })}
                                </Col> :
                                <></>
                        }

                        <p/>
                    </Col>: null}
                    </>

                    ));
                case INSITEDataType.ORTHO:
                    if (_.isEmpty(_dataSet.dataObject)) {
                        _dataSet.dataObject = _.cloneDeep(Ortho);
                    }
                    const _dataObjectOrtho = _.cloneDeep(_dataSet.dataObject);
                    return Object.keys(_dataObjectOrtho).map((d, key) => (
                        <Col key={key} className="col-12 col-sm-6 col-md-6 text-left">
                            <Label className="labelText">{_dataObjectOrtho[d].name}</Label>
                            {_dataObjectOrtho[d].name === 'Sub Project' ?
                                <Input
                                    type={_dataObjectOrtho[d].type}
                                    name='select'
                                    className="inputItem"
                                    value={_dataObjectOrtho[d].value}
                                    valid={_dataObjectOrtho[d].valid && _dataObjectOrtho[d].touched}
                                    invalid={!_dataObjectOrtho[d].valid && _dataObjectOrtho[d].touched}
                                    onChange={(event) => this.subProjectChangeHandler(event, index, {
                                        fieldName: d,
                                        title: _dataObjectOrtho[d].name
                                    })}>
                                    {this.state.subProjects.map((value, iterate) => {
                                        return <option key={iterate} value={value.name}> {value.name} </option>
                                    })};
                                </Input>
                                :
                                <Input type={_dataObjectOrtho[d].type}
                                       className="inputItem"
                                       value={_dataObjectOrtho[d].value}
                                       valid={_dataObjectOrtho[d].valid && _dataObjectOrtho[d].touched}
                                       invalid={!_dataObjectOrtho[d].valid && _dataObjectOrtho[d].touched}
                                       onChange={(event) => this.fieldValueChangeHandler(event, index, {
                                           fieldName: d,
                                           title: _dataObjectOrtho[d].name
                                       })}
                                />
                            }
                            <p />
                        </Col>
                    ));
                case INSITEDataType.PANO:
                    if (_.isEmpty(_dataSet.dataObject)) {
                        _dataSet.dataObject = _.cloneDeep(PANO);
                    }
                    const _dataObjectPano = _.cloneDeep(_dataSet.dataObject);
                    let fieldsArray = Object.keys(_dataObjectPano).map((d, key) => (
                        <Col className={d === "useVrPlayer" ? "col-12 text-left" : "col-12 col-sm-6 col-md-6 text-left"} key={key}>
                            <Label className="labelText">{_dataObjectPano[d].name} </Label>
                            {PANO[d].name === 'Pano Array' ? null :
                                PANO[d].type === "checkbox" ?
                                    <CustomInput id={`useVrPlayer-${index}`}
                                                 label="Show in VR Player"
                                                 onChange={(event) => this.checkboxHandler(event, index, {
                                                     fieldName: d,
                                                     title: _dataObjectPano[d].name
                                                 })}
                                                 type={_dataObjectPano[d].type}
                                                 checked={_dataObjectPano[d].checked}
                                                 value={_dataObjectPano[d].value}
                                                 valid={_dataObjectPano[d].valid && _dataObjectPano[d].touched}
                                                 invalid={!_dataObjectPano[d].valid && _dataObjectPano[d].touched}/>
                                    :
                                    _dataObjectPano[d].name === 'Sub Project' ?
                                        <Input
                                            type={_dataObjectPano[d].type}
                                            name='select'
                                            className="inputItem"
                                            value={_dataObjectPano[d].value}
                                            valid={_dataObjectPano[d].valid && _dataObjectPano[d].touched}
                                            invalid={!_dataObjectPano[d].valid && _dataObjectPano[d].touched}
                                            onChange={(event) => this.subProjectChangeHandler(event, index, {
                                                fieldName: d,
                                                title: _dataObjectPano[d].name
                                            })}>
                                            {this.state.subProjects.map((value, iterate) => {
                                                return <option key={iterate} value={value.name}> {value.name} </option>
                                            })};
                                        </Input>
                                        :
                                        <Input
                                            type={_dataObjectPano[d].type}
                                            className="inputItem"
                                            value={_dataObjectPano[d].value}
                                            valid={_dataObjectPano[d].valid && _dataObjectPano[d].touched}
                                            invalid={!_dataObjectPano[d].valid && _dataObjectPano[d].touched}
                                            onChange={(event) => this.fieldValueChangeHandler(event, index, {
                                                fieldName: d,
                                                title: _dataObjectPano[d].name
                                            })}
                                        />
                            }
                            <p />
                        </Col>
                    )
                    );
                    fieldsArray.push(
                        <Row className="centerForm text-left m-0" style={{ paddingTop: 0 }}>
                            <Col className="centerForm" lg={12} style={{ marginTop: 0 }}>
                                <div className='text-md-right'>
                                    Add Pano <Button onClick={() => addPano(this, _dataSet, index)}>
                                        <span>
                                            <FontAwesomeIcon icon={faPlusCircle} />
                                        </span>
                                    </Button>
                                </div>
                                <Table responsive striped bordered>
                                    <thead>
                                        <tr>
                                            <th> Lat </th>
                                            <th> Lon </th>
                                            <th> Height </th>
                                            <th> URL </th>
                                            <th> Style </th>
                                            <th> Colour </th>
                                            <th> Actions </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {_dataObjectPano.panoArray.value.map((pano, pano_index) => {
                                            return (
                                                <tr key={pano_index}>
                                                    <td>{pano.lat}</td>
                                                    <td>{pano.lon}</td>
                                                    <td>{pano.height}</td>
                                                    <td>{pano.URL}</td>
                                                    <td>{pano.style}</td>
                                                    <td>{pano.pinColour ? pano.pinColour : 'Yellow'}</td>
                                                    <td>
                                                        <Button
                                                            onClick={() => editPano(this, index, pano_index)}
                                                            outline color="info">
                                                            Edit
                                                                 </Button>
                                                        {_dataObjectPano.panoArray.value.length != 1 ?
                                                            <Button onClick={() => removePano(this, _dataSet, index, pano_index)}
                                                                    className={"ml-3"}
                                                                    outline
                                                                    color={"danger"}>
                                                                Remove
                                                            </Button>
                                                            : <></>
                                                        }

                                                    </td>
                                                </tr>)
                                        })}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    )
                    return fieldsArray;

                case INSITEDataType.PoleImage:
                    if (_.isEmpty(_dataSet.dataObject)) {
                        _dataSet.dataObject = _.cloneDeep(PoleImage);
                    }
                    const _dataPoleImageObject = _.cloneDeep(_dataSet.dataObject);
                    return Object.keys(_dataPoleImageObject).map((d, key) => (
                        <Col key={key} className="col-12 col-sm-6 col-md-6 text-left">
                            <Label className="labelText">{_dataPoleImageObject[d].name} </Label>
                            {
                                _dataPoleImageObject[d].name === 'Image Paths' ?
                                    <></> :
                                    _dataPoleImageObject[d].name === 'Pin Colour' ?
                                        <Input
                                            type={_dataPoleImageObject[d].type}
                                            name='select'
                                            className="inputItem"
                                            value={_dataPoleImageObject[d].value}
                                            valid={_dataPoleImageObject[d].valid && _dataPoleImageObject[d].touched}
                                            invalid={!_dataPoleImageObject[d].valid && _dataPoleImageObject[d].touched}
                                            onChange={(event) => this.customPinColorChangeHandler(event, index, {
                                                fieldName: d,
                                                title: _dataPoleImageObject[d].name
                                            })}>
                                            {PinColourDropdown.map((value, iterate) => {
                                                return <option key={iterate}
                                                               value={value.name}> {value.name} </option>
                                            })};
                                        </Input> :
                                        _dataPoleImageObject[d].name === 'Clamp to Terrain' ?
                                            <CustomInput id={`clamp_to_terrian-${index}`}
                                                         onChange={(event) => this.checkboxHandler(event, index, {
                                                             fieldName: d,
                                                             title: _dataPoleImageObject[d].name
                                                         })}
                                                         type={_dataPoleImageObject[d].type}
                                                         checked={_dataPoleImageObject[d].checked}
                                                         value={_dataPoleImageObject[d].value}
                                                         valid={_dataPoleImageObject[d].valid && _dataPoleImageObject[d].touched}
                                                         invalid={!_dataPoleImageObject[d].valid && _dataPoleImageObject[d].touched}/>
                                        :
                                        _dataPoleImageObject[d].name === 'Sub Project' ?
                                            <Input
                                                type={_dataPoleImageObject[d].type}
                                                name='select'
                                                className="inputItem"
                                                value={_dataPoleImageObject[d].value}
                                                valid={_dataPoleImageObject[d].valid && _dataPoleImageObject[d].touched}
                                                invalid={!_dataPoleImageObject[d].valid && _dataPoleImageObject[d].touched}
                                                onChange={(event) => this.subProjectChangeHandler(event, index, {
                                                    fieldName: d,
                                                    title: _dataPoleImageObject[d].name
                                                })}>
                                                {this.state.subProjects.map((value, iterate) => {
                                                    return <option key={iterate}
                                                                   value={value.name}> {value.name} </option>
                                                })};
                                            </Input>
                                            :
                                            <Input
                                                type={_dataPoleImageObject[d].type}
                                                className="inputItem"
                                                value={_dataPoleImageObject[d].value}
                                                valid={_dataPoleImageObject[d].valid && _dataPoleImageObject[d].touched}
                                                invalid={!_dataPoleImageObject[d].valid && _dataPoleImageObject[d].touched}
                                                onChange={(event) => this.fieldValueChangeHandler(event, index, {
                                                    fieldName: d,
                                                    title: _dataPoleImageObject[d].name
                                                })}
                                            />

                            }
                            {
                                _dataPoleImageObject[d].name === 'Image Paths' ?
                                    <Col key={key} className="text-left">
                                        <div className='text-md-right'>
                                            <Button
                                                onClick={() => this.addImagesURL(ds, index)}>
                                             <span>
                                                    <FontAwesomeIcon icon={faPlusCircle}/>
                                             </span>
                                            </Button>
                                        </div>

                                        { _dataPoleImageObject?.imagePath?.value?.map((value, idx) => {
                                            return(
                                                <div>
                                                    <tr className={"col-12 col-sm-6 col-md-6 text-left"}>
                                                        <td style={{width: "inherit"}}>
                                                            <Label className="labelText">URL</Label>
                                                            <Input type="text"
                                                                   className="inputItem"
                                                                   value = {value.url}
                                                                   onChange={(event) =>{this.classImageUrlChangeHandler(event, idx,index)}}
                                                            />
                                                        </td>
                                                        {
                                                            idx === 0 ?
                                                                <td className="px-4" style={{verticalAlign: "bottom"}}>
                                                                    <span className="mb-1"></span>
                                                                </td>

                                                                :
                                                            <td className="px-2" style={{verticalAlign: "bottom"}}>
                                                                <Button color="danger" className="mb-1"
                                                                        onClick={() => this.classImageRemoveHandler(idx, index)}>
                                                                <span>
                                                                    <FontAwesomeIcon icon={faTrash}/>
                                                                </span>
                                                                </Button>
                                                            </td>
                                                        }
                                                    </tr>
                                                </div>
                                            )

                                        })}
                                    </Col> :
                                    <></>
                            }
                            <p />
                        </Col>
                    ));

                case INSITEDataType.Photos:
                    if (_.isEmpty(_dataSet.dataObject)) {
                        _dataSet.dataObject = _.cloneDeep(Photos);
                    }
                    const _dataCameraImageObject = _.cloneDeep(_dataSet.dataObject);
                    return Object.keys(_dataCameraImageObject).map((d, key) => (
                        <Col key={key} className="col-12 col-sm-6 col-md-6 text-left">
                            <Label className="labelText">{_dataCameraImageObject[d].name} </Label>
                            {
                                _dataCameraImageObject[d].name === 'Image Paths' ?
                                    <></> :
                                    _dataCameraImageObject[d].name === 'Clamp to Terrain' ?
                                        <CustomInput id={`clamp_to_terrian-${index}`}
                                                     onChange={(event) => this.checkboxHandler(event, index, {
                                                         fieldName: d,
                                                         title: _dataCameraImageObject[d].name
                                                     })}
                                                     type={_dataCameraImageObject[d].type}
                                                     checked={_dataCameraImageObject[d].checked}
                                                     value={_dataCameraImageObject[d].value}
                                                     valid={_dataCameraImageObject[d].valid && _dataCameraImageObject[d].touched}
                                                     invalid={!_dataCameraImageObject[d].valid && _dataCameraImageObject[d].touched}/>
                                        :
                                        _dataCameraImageObject[d].name === 'Photos Colour' ?
                                            <tr key={index}>
                                                <ColourPicker onChange={(event)=> this.customGPXFlightPathColorChangeHandler(event,index,{
                                                    fieldName: d,
                                                    title: _dataCameraImageObject[d].name
                                                })} index={index} colour={_dataCameraImageObject[d].value} />
                                            </tr>
                                            :
                                        _dataCameraImageObject[d].name === 'Sub Project' ?
                                            <Input
                                                type={_dataCameraImageObject[d].type}
                                                name='select'
                                                className="inputItem"
                                                value={_dataCameraImageObject[d].value}
                                                valid={_dataCameraImageObject[d].valid && _dataCameraImageObject[d].touched}
                                                invalid={!_dataCameraImageObject[d].valid && _dataCameraImageObject[d].touched}
                                                onChange={(event) => this.subProjectChangeHandler(event, index, {
                                                    fieldName: d,
                                                    title: _dataCameraImageObject[d].name
                                                })}>
                                                {this.state.subProjects.map((value, iterate) => {
                                                    return <option key={iterate}
                                                                   value={value.name}> {value.name} </option>
                                                })};
                                            </Input>
                                            :
                                            <Input
                                                type={_dataCameraImageObject[d].type}
                                                className="inputItem"
                                                value={_dataCameraImageObject[d].value}
                                                valid={_dataCameraImageObject[d].valid && _dataCameraImageObject[d].touched}
                                                invalid={!_dataCameraImageObject[d].valid && _dataCameraImageObject[d].touched}
                                                onChange={(event) => this.fieldValueChangeHandler(event, index, {
                                                    fieldName: d,
                                                    title: _dataCameraImageObject[d].name
                                                })}
                                            />

                            }
                            {
                                _dataCameraImageObject[d].name === 'Image Paths' ?
                                    <Col key={key} className="text-left">
                                        <div className='text-md-right'>
                                            <Button
                                                onClick={() => this.addImagesURL(ds, index)}>
                                             <span>
                                                    <FontAwesomeIcon icon={faPlusCircle}/>
                                             </span>
                                            </Button>
                                        </div>

                                        { _dataCameraImageObject?.imagePath?.value?.map((value, idx) => {
                                            return(
                                                <div>
                                                    <tr className={"col-12 col-sm-6 col-md-6 text-left"}>
                                                        <td style={{width: "inherit"}}>
                                                            <Label className="labelText">URL</Label>
                                                            <Input type="text"
                                                                   className="inputItem"
                                                                   value = {value.url}
                                                                   onChange={(event) =>{this.classImageUrlChangeHandler(event, idx,index)}}
                                                            />
                                                        </td>
                                                        {
                                                            idx === 0 ?
                                                                <td className="px-4" style={{verticalAlign: "bottom"}}>
                                                                    <span className="mb-1"></span>
                                                                </td>

                                                                :
                                                                <td className="px-2" style={{verticalAlign: "bottom"}}>
                                                                    <Button color="danger" className="mb-1"
                                                                            onClick={() => this.classImageRemoveHandler(idx, index)}>
                                                                <span>
                                                                    <FontAwesomeIcon icon={faTrash}/>
                                                                </span>
                                                                    </Button>
                                                                </td>
                                                        }
                                                    </tr>
                                                </div>
                                            )

                                        })}
                                    </Col> :
                                    <></>
                            }
                            <p />
                        </Col>
                    ));

                case INSITEDataType.CustomDataset:
                    if (_.isEmpty(_dataSet.dataObject)) {
                        _dataSet.dataObject = _.cloneDeep(CustomDataset);
                    }
                    const _dataObjectCustom = _.cloneDeep(_dataSet.dataObject);
                    return Object.keys(_dataObjectCustom).map((d, key) => (
                        <Col key={key} className="col-12 col-sm-6 col-md-6 text-left">
                            <Label className="labelText">{_dataObjectCustom[d].name} </Label>
                            {_dataObjectCustom[d].name === 'Dataset Type' ?
                                <Input
                                    type={_dataObjectCustom[d].type}
                                    name='select'
                                    className="inputItem"
                                    value={_dataObjectCustom[d].value}
                                    valid={_dataObjectCustom[d].valid && _dataObjectCustom[d].touched}
                                    invalid={!_dataObjectCustom[d].valid && _dataObjectCustom[d].touched}
                                    onChange={(event) => this.customDatasetChangeHandler(event, index, {
                                        fieldName: d,
                                        title: _dataObjectCustom[d].name
                                    })}>
                                    {customDatasetTypeDropDown.map((value, iterate) => {
                                        return <option key={iterate} value={value.name}> {value.name} </option>
                                    })};
                                </Input>
                                :
                                <Input
                                    type={_dataObjectCustom[d].type}
                                    className="inputItem"
                                    value={_dataObjectCustom[d].value}
                                    valid={_dataObjectCustom[d].valid && _dataObjectCustom[d].touched}
                                    invalid={!_dataObjectCustom[d].valid && _dataObjectCustom[d].touched}
                                    onChange={(event) => this.customDatasetValueChangeHandler(event, index, {
                                        fieldName: d,
                                        title: _dataObjectCustom[d].name
                                    })}
                                />
                            }
                            <p />
                        </Col>
                    ));
                default:
                    break;
            }
        }
    };

    submitHandler = (event) => {
        event.preventDefault();
        const _portalSlug = _.get(this.props.match.params, "portalSlug");
        let _datasets = [];
        const _project: any = {
            name: this.state.project_name.value,
            show: this.state.show.value,
            projectId: this.state.projectId,
            withoutGlobe: this.state.withoutGlobe,

        };
        this.state.dataSets.forEach((ds, index) => {
            const _mapperDs: any = {
                datasetType: ds.datasetType.value,
                captureDate: ds.captureDate.value,
                show: ds.show.checked,
                downloadLinks: ds.downloadLinks,
                reportUrls: ds.reportUrls,
                order: index
            };
            const _dsAttributes = {};
            if (!_.isEmpty(ds.dataObject)) {
                for (let [key, objectAsValue] of Object.entries(ds.dataObject)) {
                    _dsAttributes[key] = objectAsValue['value'];
                }
                if (_.get(ds, "id", false)) {
                    _mapperDs.id = _.get(ds, "id");
                }
                _mapperDs.dsAttributes = { ..._dsAttributes };
                _datasets.push(_mapperDs);
            }
        });
        _project.dataset = [..._datasets];

        const _projectForm_data = new FormData();
        this.buildFormData(_projectForm_data, _project, null);

        for (let i = 0; i < this.state.selectedFiles.length; i++) {
            var nameofFile = this.state.selectedFiles[i].filename;
            _projectForm_data.append('files', this.state.selectedFiles[i].file, nameofFile);
        }

        this.updateProjectDetails(_projectForm_data);
    };

    handleShowChange = (e) => {
        this.setState((prevState, prevProps) => {
            return { show: { value: !_.get(prevState, "show").value, valid: true, touched: true } }
        })
    };

    handleWithoutGlobeChange = (e) => {
        this.setState({withoutGlobe: !this.state.withoutGlobe});
    };


    handleDateChange = (date, ds, index) => {
        if (date) {
            const _timeStamp = new Date().toLocaleTimeString();
            const _date = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${_timeStamp}`;
            ds.captureDate = {
                value: _date,
                touched: true,
                valid: true,
            };
            this.setState({ dataSets: this.state.dataSets });
        } else {
            ds.captureDate = {
                value: '',
                touched: true,
                valid: true,
            };
            this.setState({ dataSets: this.state.dataSets });
        }

    };

    handleAddMore = () => {
        const _dataset = {
            datasetType: { value: INSITEDataType.TILE3D, touched: false, valid: false },
            captureDate: { value: '', touched: false, valid: false },
            show: { value: true, touched: false, valid: false, checked: true },
            downloadLinks: [],
            dataObject: this.getInitialDataObject(INSITEDataType.TILE3D),

        };
        const _datasets = this.state.dataSets;
        _datasets.push(_dataset);
        this.setState({ dataSets: _datasets });
    };

    isFormValid = () => {
        const validObject: any = {
            tile3d: true,
            kml: true,
            mesh: true,
            flight: true,
            video : true,
            datasetAdded: this.state.dataSets.length > 0
        };
        this.state.dataSets.map(dataset => {
            switch (dataset.datasetType.value) {
                case INSITEDataType.TILE3D:
                    validObject.tile3d = (dataset.dataObject.tile_path.valid &&
                        dataset.dataObject.name.valid &&
                        dataset.dataObject.height_offset.valid);
                    break;
                case INSITEDataType.KML:
                    validObject.kml = (dataset.dataObject.kml_path.valid &&
                        dataset.dataObject.name.valid &&
                        dataset.dataObject.height_offset.valid);
                    break;
                case INSITEDataType.OBJ3D:
                    validObject.mesh = (dataset.dataObject.mesh_path.valid &&
                        dataset.dataObject.name.valid &&
                        dataset.dataObject.latitude.valid &&
                        dataset.dataObject.longitude.valid &&
                        dataset.dataObject.rotation.valid &&
                        dataset.dataObject.scale.valid);
                    break;
                case INSITEDataType.FlightPath:
                    validObject.flight = ((dataset.dataObject.flight_path.valid &&
                        dataset.dataObject.name.valid) || (dataset.dataObject.flight_path_gpx.valid &&
                        dataset.dataObject.name.valid));
                    break;
                case INSITEDataType.VIDEO:
                    validObject.video = (dataset.dataObject.video_path.valid &&
                        dataset.dataObject.name.valid);
                    break;
            }
        });
        return (this.state.project_name.valid &&
            validObject.datasetAdded &&
            validObject.tile3d &&
            validObject.kml &&
            validObject.mesh &&
            validObject.flight &&
            validObject.video);
    };

    goBack = () => {
        this.props.history.push(`/details/${this.state.portalSlug}`);
    };

    getProjectDetails() {
        const _url = `${this.state.portalSlug}/project/${this.state.projectId}`;
        axiosInstance(_url, "get", {}, {}).then((res: any) => {
            const _dataObject = _.get(res, "data");
            const { message, object, success } = _dataObject;
            if (!_.isEmpty(object) && success) {
                this.setState(_.cloneDeep(this.getInitialState(object)));
            }
        }).catch(err => {
            this.setState({ error: { message: 'Something went wrong retrieving project.', status: true } })
        });
    }

    updateProjectDetails(project: FormData) {
        this.setState({ loadingState: true });
        let url = `${this.state.portalSlug}/project/${this.state.projectId}`;

        axiosInstance(url, "put", { 'Content-Type': 'application/x-www-form-urlencoded' }, project)
            .then(res => {
                Toast.fire({
                    title:"Success",
                    titleText: project.get('name') + " has been successfully updated.",
                    icon: "success"
                  });
                this.setState({errorObj: null});
                this.getProjectDetails();
            }).catch(err => {
                Toast.fire("Failed", `Something went wrong`, "error");
                this.setState({ error: { message: 'Something went wrong updating project.', status: true }, errorObj: err?.response?.data?.errors })
            });
    }

    reformatDateSelection = (dateCapture) => {
        let _reformatDate = dateCapture;
        if (dateCapture === '') {
            _reformatDate = new Date();
        } else {
            _reformatDate = Date.parse(dateCapture);
        }
        return _reformatDate;
    };

    addDownloadLinks = (ds, index) => {
        this.setState(
            (prevState: any, props: any) => {
                return {
                    openModalDownloadLinks: true,
                    downloadLinkMode: true,
                    downloadLink: initializeDownloadLinks(),
                    selectedDS: index
                };
            }
        );
    };

    addClassification = (ds, index) => {
    let _dataset = this.state.dataSets;
    const newField = {
        id:null,
        name:'',
        colour:null
    }
    _dataset[index].dataObject.classArray.value.push(newField);
    this.setState({dataSets:_dataset});

    };

    addImagesURL = (ds, index) => {
        let _dataset = this.state.dataSets;
        const newField = {
            url:'',
        }
        _dataset[index].dataObject.imagePath.value.push(newField);
        this.setState({dataSets:_dataset});

    };


    closeModal = () => {
        this.setState({ openModalDownloadLinks: false, openModalPano: false, downloadLink: initializeDownloadLinks() });
    };

    closeVideoModal = () => {
        this.setState({ openModalDownloadLinks: false, openModalVideo: false, downloadLink: initializeDownloadLinks() });
    };

    submitDownloadLinks = () => {
        const _datasetIndex = this.state.selectedDS;
        const _downloadItem = this.state.downloadLink;
        let dataSets = this.state.dataSets;
        if (!this.state.downloadLinkMode) {
            const _downLoadIndex = _.findIndex(dataSets[_datasetIndex].downloadLinks, {
                'index': _downloadItem.index,
                'label': _downloadItem.label
            });
            dataSets[_datasetIndex].downloadLinks.splice(_downLoadIndex, 1);
        }
        dataSets[_datasetIndex].downloadLinks.push(this.state.downloadLink);
        this.setState({
            dataSets: dataSets,
            downloadLink: initializeDownloadLinks(),
            openModalDownloadLinks: false
        });
    };

    onChangeDownloadLinksHandler = (event) => {
        event.preventDefault();
        const { value, name }: any = event.target;
        let _selectedDownloadLink = this.state.downloadLink;
        _selectedDownloadLink[name] = value;
        this.setState({ downloadLink: _selectedDownloadLink });
    };

    removeDownloadLink = (ds, ds_index, downloadLink_index) => {
        let dataSets = this.state.dataSets;
        dataSets[ds_index].downloadLinks.splice(downloadLink_index, 1);
        this.setState({
            dataSets: dataSets,
            downloadLink: initializeDownloadLinks(),
            openModalDownloadLinks: false,
            selectedDS: 0
        });
    };

    editDownloadLink = (ds_index, index) => {
        const _downloadLinkItem = this.state.dataSets[ds_index].downloadLinks[index];
        this.setState(
            (prevState: any, props: any) => {
                return {
                    openModalDownloadLinks: true,
                    downloadLink: _downloadLinkItem,
                    selectedDS: ds_index,
                    downloadLinkMode: false
                };
            }
        );
    };

    onChangePanoHandler = (event) => {
        event.preventDefault();
        const { value, name }: any = event.target;
        let _panoData = this.state.panoData;
        _panoData[name] = value;
        this.setState({ panoData: _panoData });
    };

    onChangeVideoHandler = (event) => {
        event.preventDefault();
        const { value, name }: any = event.target;
        let _videoData = this.state.videoData;
        _videoData[name] = value;
        this.setState({ videoData: _videoData });
    };

    onChangeCheckboxHandler = (event) => {
      //  event.preventDefault();
        const  value = event.target.checked ? event.target.checked : false;
        const {name}:any  = event.target;
        let _videoData = this.state.videoData;
        _videoData[name] = value;
        this.setState({ videoData: _videoData });
    };


    submitPano = () => {
        let dataSets = this.state.dataSets;
        const _datasetIndex = this.state.selectedDS;

        // we use a temporary 'index' property on the pano data to keep track of whether we're editing or adding a new one
        // index is -1 if new, and we delete that index property before storing it
        const panoEditIndex = this.state.panoData.index;
        delete this.state.panoData['index']
        if (panoEditIndex >= 0) {
            dataSets[_datasetIndex].dataObject.panoArray.value[panoEditIndex] = this.state.panoData;
        } else {
            dataSets[_datasetIndex].dataObject.panoArray.value.push(this.state.panoData);
        }

        this.setState({
            dataSets: dataSets,
            panoData: initializePanoParams(),
            openModalPano: false
        });
    };

    submitVideo = () => {
        let dataSets = this.state.dataSets;
        const _datasetIndex = this.state.selectedDS;

        // we use a temporary 'index' property on the pano data to keep track of whether we're editing or adding a new one
        // index is -1 if new, and we delete that index property before storing it
        const videoEditIndex = this.state.videoData.index;
        delete this.state.videoData['index']
        if (videoEditIndex >= 0) {
            dataSets[_datasetIndex].dataObject.videoArray.value[videoEditIndex] = this.state.videoData;
        } else {
            dataSets[_datasetIndex].dataObject.videoArray.value.push(this.state.videoData);
        }

        this.setState({
            dataSets: dataSets,
            videoData: initializeVideoParams(),
            openModalVideo : false
        });
    };


    onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const dataSets = Array.from(this.state.dataSets);
        const [removed] = dataSets.splice(result.source.index, 1);
        dataSets.splice(result.destination.index, 0, removed);

        this.setState({ dataSets });
    }


    render() {
        let form =
            <>
                {this.state.openModalDownloadLinks ?
                    <DownLoadLinks
                        openModalDownloadLinks={this.state.openModalDownloadLinks}
                        closeModalDownloadLinks={this.closeModal}
                        className={'download-links-add'}
                        submit={this.submitDownloadLinks}
                        changeHandler={this.onChangeDownloadLinksHandler}
                        downloadLinkItem={this.state.downloadLink} />
                    : null
                }
                {this.state.openModalPano ?
                    <PanoModal
                        openModalPano={this.state.openModalPano}
                        closeModalDownloadLinks={this.closeModal}
                        className={'download-links-add'}
                        submit={this.submitPano}
                        changeHandler={this.onChangePanoHandler}
                        panoData={this.state.panoData} />
                    : null
                }
                {this.state.openModalVideo ?
                    <VideoModal
                        openModalVideo={this.state.openModalVideo}
                        closeModalDownloadLinks={this.closeVideoModal}
                        className={'download-links-add'}
                        submit={this.submitVideo}
                        changeHandler={this.onChangeVideoHandler}
                        changeHandlerCheckbox = {this.onChangeCheckboxHandler}
                        videoData={this.state.videoData} />
                    : null
                }
                <Row> <h1 className="heading">Update Project</h1> </Row>
                <Form>
                    <Row className='justify-content-center mb-4'>
                        <Col className="col-12 col-sm-6 col-md-6 text-left">
                            <Label className="labelText">Name</Label>
                            <Input
                                className="inputItem"
                                value={this.state.project_name.value}
                                valid={this.state.project_name.valid && this.state.project_name.touched}
                                invalid={!this.state.project_name.valid && this.state.project_name.touched}
                                onChange={(event) => this.projectNameChangeHandler(event)}
                            />
                            <ErrorDisplayer errorObj={this.state.errorObj} keyName="name" />
                            <p />
                        </Col>
                    </Row>
                    <Row className='d-flex justify-content-center align-items-center mb-4'>
                        <Col className="d-flex flex-column align-items-center mx-2" sm={3}>
                            <Label className="labelText">Show Project</Label>
                            <br />
                            <Switch
                                checked={this.state.show.value}
                                onChange={this.handleShowChange}
                                onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                                id="material-switch-show"
                            />
                        </Col>

                        <Col className="d-flex flex-column align-items-center mx-2" sm={3}>
                            <Label className="labelText">Without Globe</Label>
                            <br />
                            <Switch
                                checked={this.state.withoutGlobe}
                                onChange={this.handleWithoutGlobeChange}
                                onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                                id="material-switch-without-globe"
                            />
                        </Col>
                    </Row>

                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                    {this.state.dataSets.map((ds, index) => (
                                        <Draggable key={ds.id} draggableId={ds.id} index={index}>
                                            {(provided) => (
                                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                    {/* Your existing code to render the dataset goes here */}
                                                    <>
                                                        <div className='m-2'>
                                                            <Row id={`ds_${index}`} className='collapse-header'>Dataset {index}</Row>
                                                            <Collapse isOpen={true} toggler={`#ds_${index}`}>
                                                                <Row className='mb-4 collapse-body'>
                                                                    <Col key={index} className="col-12 col-sm-6 col-md-6 text-left">
                                                                        <Label className="labelText">Dataset Type</Label>
                                                                        <Input type='select' name='select'
                                                                               className="inputItem"
                                                                               value={ds.datasetType.value}
                                                                               valid={ds.datasetType.valid && ds.datasetType.touched}
                                                                               invalid={!ds.datasetType.valid && ds.datasetType.touched}
                                                                               onChange={(event) => this.datasetTypeChangeHandler(event, ds, index)}>
                                                                            {datasetTypeDropDown.map((value, iterate) => {
                                                                                return <option key={iterate} value={value.id}> {value.name} </option>
                                                                            })};
                                                                        </Input>
                                                                    </Col>
                                                                    {this.renderDataObject(ds.datasetType.value, ds, index)}
                                                                    <Col className="col-12 col-sm-6 col-md-6 text-left">
                                                                        <Label className="labelText"> Hide/Show</Label>
                                                                        <CustomInput
                                                                            type="checkbox"
                                                                            name='show'
                                                                            id={`show-${index + 1}`}
                                                                            label={`Show`}
                                                                            checked={ds.show.checked}
                                                                            value={ds.show.value}
                                                                            valid={ds.show.valid && ds.show.touched}
                                                                            onChange={(event) => this.showhideChangeHandler(event, ds, index)}>
                                                                        </CustomInput>
                                                                    </Col>
                                                                    <Col key={index + 1} className="col-12 col-sm-6 col-md-6 text-left">
                                                                        <Label className="labelText">Capture Date</Label>
                                                                        <DatePicker
                                                                            placeholderText="Add capture Date"
                                                                            dateFormat="dd/MM/yyyy"
                                                                            selected={this.reformatDateSelection(ds.captureDate.value)}
                                                                            onChange={(event) => this.handleDateChange(event, ds, index)}
                                                                            className="inputItem w-100 form-control"
                                                                            showWeekNumbers
                                                                        />
                                                                    </Col>
                                                                    <Col className="col-12 col-sm-6 col-md-6 text-left">
                                                                        <Label className="labelText">Upload Report files</Label>
                                                                        <Input type="file" multiple onChange={(e) => this.multipleFileChangedHandler(e, index)} />
                                                                        {ds?.reportUrls?.length ?
                                                                            <ul className="m-0 p-0">
                                                                                {
                                                                                    ds?.reportUrls?.map((reportUrl) => {//datasets report urls on frontend

                                                                                        const fileName = reportUrl.substring(reportUrl.lastIndexOf('/') + 1);
                                                                                        return <li className='mx-0 my-2 p-0'>{fileName}
                                                                                            <Button color="danger" className={'ml-1'} onClick={() => this.removereportUrls(ds.id, reportUrl)}>
                                                                        <span>
                                                                            <FontAwesomeIcon icon={faTrash} />
                                                                        </span>
                                                                                            </Button>
                                                                                        </li>


                                                                                    })

                                                                                }
                                                                            </ul> : null

                                                                        }
                                                                    </Col>
                                                                    <Row className="centerForm text-left m-0">
                                                                        <Col className="centerForm" lg={12}>
                                                                            <Table responsive>
                                                                                <tr>
                                                                                    <td style={{ textAlign: "left" }}><Label className="labelText">Download Links</Label></td>
                                                                                    <td style={{ textAlign: "right" }}><Button onClick={() => this.addDownloadLinks(ds, index)}>
                                                                <span>
                                                                    <FontAwesomeIcon icon={faPlusCircle} />
                                                                </span>
                                                                                    </Button></td>
                                                                                </tr>
                                                                            </Table>

                                                                            <Table responsive striped bordered>
                                                                                <thead>
                                                                                <tr>
                                                                                    <th> Index </th>
                                                                                    <th> Label </th>
                                                                                    <th> URL </th>
                                                                                    <th> Format </th>
                                                                                    <th> SizeMB </th>
                                                                                    <th> Actions </th>
                                                                                </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                {ds.downloadLinks.map((downloadLink, download_index) => {
                                                                                    return (
                                                                                        <tr key={download_index}>
                                                                                            <td>{downloadLink.index}</td>
                                                                                            <td>{downloadLink.label}</td>
                                                                                            <td>... {downloadLink.URL.slice(-40)}</td>
                                                                                            <td>{downloadLink.format}</td>
                                                                                            <td>{downloadLink.sizeMB}</td>
                                                                                            <td>
                                                                                                <Button outline
                                                                                                        color="info"
                                                                                                        onClick={() => this.editDownloadLink(index, download_index)}>
                                                                                                    Edit
                                                                                                </Button>
                                                                                                <Button className={"ml-3"}
                                                                                                        outline
                                                                                                        color={"danger"}
                                                                                                        onClick={() => this.removeDownloadLink(ds, index, download_index)}>
                                                                                                    Remove
                                                                                                </Button>
                                                                                            </td>
                                                                                        </tr>)
                                                                                })}
                                                                                </tbody>
                                                                            </Table>
                                                                        </Col>
                                                                    </Row>
                                                                    {index > 0 &&
                                                                        <Button className='mx-auto m-3'
                                                                                outline color={`danger`}
                                                                                onClick={_ => this.removeDataSetHandler(index)}>Remove Dataset {index}</Button>
                                                                    }
                                                                </Row>
                                                            </Collapse>
                                                        </div>
                                                    </>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>

                    <Button outline color={"info"} onClick={this.handleAddMore}>Add Another Dataset</Button>
                    <br /><br />
                    <Button disabled={!this.isFormValid()} onClick={(event) => this.submitHandler(event)} color="success" outline> Save Changes </Button>
                    <br /><br />
                    <Button onClick={(e) => this.goBack()} color="danger" outline> Cancel </Button>

                </Form>
            </>;
        let content = null;
        let redirect = null;
        let error = "";
        if (this.state.loadingState) {
            content = <Spinner />
        } else {
            content = form;
        }
        if (this.state.error.status) {
            error = `${this.state.error.message}`;
            content = form;
        }
        return (
            <>
                <div className="centerForm">
                    <div>
                        {redirect}
                        {content}
                        {error}
                    </div>
                </div>
            </>
        );
    }

    componentWillReceiveProps(nextProps) { }

    componentDidMount() {
        this.setState({ loadingState: true });
        this.getProjectDetails();
    }

    componentWillUnmount() {
    }

}


const mapDispatchToProps = dispatch => {
    return {
        // getProject: (portalName, projectId) => dispatch(getProject(projectId, portalName)),
        onUpdateProject: (portalName: string, project: any) => dispatch(updateProject(portalName, project)),
        onDataSetDelete: (portalSlug: string, dataSetId: string) => dispatch(deleteDataSet(portalSlug, dataSetId))
    }
};

const mapStateToProps = (state, ownProps) => {
    let projectData = {};

    if (ownProps.location.state) {
        projectData = {
            projectName: ownProps.location.state.projectName,
            projectId: ownProps.location.state.projectId,
            tiles_path: ownProps.location.state.tiles_path,
            camera_path: ownProps.location.state.camera_path,
            show: ownProps.location.state.show,
            dataSets: ownProps.location.state.dataSets,
            portalSlug: ownProps.location.state.portalSlug,
        }
    } else {
        projectData = {
            loading: true,
            projectId: _.get(ownProps.match.params, 'projectId')
        }
    }

    return {
        loading: state.project.updateProjectLoading || state.project.getProjectLoading,
        success: state.project.updateProjectSuccess,
        errors: state.project.updateProjectErrors,
        portal: state.portal,
        ...projectData,
        selectedProject: state.project.selectedProject
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProject);
