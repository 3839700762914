import React, {Component} from 'react'
import './Home.css'
import {connect} from 'react-redux';
import {Row, Col} from 'reactstrap'
import {Redirect} from 'react-router'
import {generatePortalSpecificUrl} from "../../helpers/urlGenerators";
import {IProps} from "../../interfaces/home";
import LocalStorageWrapped from "../../LocalStorageWrapped";
import * as _ from "lodash";

class Home extends Component<IProps, {}> {

    render() {
        let redirect = null;
        const authInfo = LocalStorageWrapped.getItem("authInfo");
        const userInfo = LocalStorageWrapped.getItem("userInfo");
        const portalInfo = LocalStorageWrapped.getItem("portalInfo");
        let portal = null;
        if(portalInfo) {
            portal = {
                portalName: portalInfo.portalName,
                found: true,
                id: portalInfo.portalId,
                portalType: portalInfo.portalType,
                slug: portalInfo.portalSlug
            };
        }
        if (authInfo && !authInfo.token) {
            redirect = <Redirect to={generatePortalSpecificUrl(portal, "/login")}/>
        } else if (userInfo.isSuperUser) {
            redirect = <Redirect to={generatePortalSpecificUrl(portal, "/portals")} />
        }
        else if(userInfo.portals.length === 1) {
            redirect=<Redirect to={generatePortalSpecificUrl(portal, `/details/${portalInfo.portalSlug}`)}/>
        }
        else {
            redirect = <Redirect to={generatePortalSpecificUrl(portal, "/portals")} />
        }
        return (
            <Row className="content mx-auto">
                <Col>
                    {redirect}
                    <h3>Welcome to AUAV inSite</h3>
                </Col>

            </Row>

        )
    }

}

const mapStateToProps = (state: any) => {
    return {
        isAuthenticated: state.login.token !== "",
        first_name:    _.get(state.login.loggedInUser, "firstName", ""),
        last_name:     _.get(state.login.loggedInUser, "lastName",  ""),
        portal:        state.portal,
        isSuperUser:   _.get(state.login.loggedInUser, "isSuperUser",   false),
    }
};

export default connect(mapStateToProps)(Home);
