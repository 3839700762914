import React, {Component} from "react";
import {connect} from "react-redux";
import {
  Col,
  Row,
  Form,
  FormGroup,
  Input,
  Button,
  Label,
} from "reactstrap";
import {Redirect} from "react-router-dom";
import "./Auth.module.css";
import "./Login.css"
import Spinner from "../UI/Spinner/Spinner";
import * as actions from "../../store/actions/loginActions";
import {ILoginState, LoginProps} from "../../interfaces/auth";
//import SocialAuth from "./SocialAuth";
import {parse} from 'query-string';
import {generatePortalSpecificUrl} from "../../helpers/urlGenerators";
import {axiosInstance, axiosInterceptors, axiosObj, requestHandler} from "../../Axios";
import * as _ from "lodash";
import {MySwal} from "../../SWAL";
import { SecretQuestionOptionTypes } from "../../interfaces/user";
import GoogleCaptchaV2 from './GoogleCaptchaV2'
import { withRouter } from "react-router-dom";
import LocalStorageWrapped from "../../LocalStorageWrapped";
import {setLoginStep2VerificationToken} from "../../store/actions/login";


class Login extends Component<LoginProps, ILoginState> {
  public state: ILoginState;
  checkEvent;
  constructor(props: LoginProps) {
    super(props);
    this.state = {
      userExists: false,
      isSecretQuestionRequired: false,
      secretQuestionText: '',
      secretQuestionType: '',
      captchaToken: '',
      isDisabled: true,
      controls: {
        email: {
          value: "",
          valid: false,
          touched: false,
          validation: {
            required: true,
            isEmail: true,
          },
        },
        password: {
          value: "",
          valid: false,
          touched: false,
          validation: {
            required: true,
          },
          
        },
        answer:{
          value: '',
          valid: false,
          touched: false,
          validation:{
              required: true,
          }
        }
      },
    };
  }

  public inputChangedHandler = (event: React.ChangeEvent<HTMLInputElement>, controlName: string) => {
    const updatedControls = {
      ...this.state.controls,
      [controlName]: {
        ...this.state.controls[controlName],
        value: event.target.value,
        valid: this.checkValidity(event.target.value, this.state.controls[controlName].validation),
        touched: true
      }
    };
    this.setState({controls: updatedControls}, () => this.checkLoginValidity());

    if (controlName === 'email') {
      this.debouncedCheck(event.target.value);
    }

  };


  inputHandler = (val: string) =>{
    this.setState({captchaToken: val }, () => this.checkLoginValidity());
  }


  secretQuestionInput = () => {

    switch (this.state.secretQuestionType) {
      case SecretQuestionOptionTypes.STRING:
        return <Input type='text' value={this.state.controls.answer.value} className="inputItem mb-3" onChange={(event) => this.inputChangedHandler(event, "answer")}/>
        break;
    case SecretQuestionOptionTypes.NUMBER:
        return <Input type='number' value={this.state.controls.answer.value} className="inputItem mb-3" onChange={(event) => this.inputChangedHandler(event, "answer")}/>
        break;
    case SecretQuestionOptionTypes.DATE:
        return <Input type='date' value={this.state.controls.answer.value} className="inputItem mb-3" onChange={(event) => this.inputChangedHandler(event, "answer")}/>

    }
}


  componentDidUpdate(){
    
    if(this.props.errors?.secret_question_required && !this.state.isSecretQuestionRequired){
      this.setState({isSecretQuestionRequired: this.props.errors?.secret_question_required, 
        secretQuestionText: this.props.errors?.secret_question.question,
        secretQuestionType: this.props.errors?.secret_question.type},() => this.checkLoginValidity());
  }

}


  checkUserExists = async (email) => {

    const response = await axiosInstance(`users/locked-status?email=${encodeURIComponent(email)}`, "get", {});

    const resp_questionRequired =  _.get(response, 'data.object.isSecretQuestionRequired',false);
    const resp_Secretquestion =  _.get(response, 'data.object.userSecretQuestion',false);
    const resp_secretQuestionType = _.get(response, 'data.object.userSecretQuestionType',false);

    this.setState({isSecretQuestionRequired: resp_questionRequired,
      secretQuestionText: resp_Secretquestion , 
      secretQuestionType:resp_secretQuestionType}) ;

    return { userExists: !!_.get(response, 'data.userExists', false), isEnabled: !!_.get(response, 'data.isEnabled', false) ,
            isSecretQuestionRequired: !!_.get(response, 'data.object.isSecretQuestionRequired', false)
            };
  } 

  debouncedCheck(email) {
    clearTimeout(this.checkEvent);
    this.checkEvent = setTimeout(async () => {
      const userExistance = await this.checkUserExists(email);
      this.setState({ userExists: userExistance.userExists});
      if (userExistance.userExists) {
        const controls = { ...this.state.controls };
        for (const controlName of Object.keys(controls)) {
          if (controlName !== 'email') {
            controls[controlName].value = '';
          }
        }
        this.setState({ controls }, () => this.checkLoginValidity());
      }
    }, 1000);
  };


  checkLoginValidity = () => {
    const controls = this.state.controls;
    let isDisabled = !(
        controls.email.valid
        && controls.email.touched
        && controls.password.valid
        && controls.password.touched
    );

    if (this.state.isSecretQuestionRequired) {
      isDisabled = (
          isDisabled
          || !(
              controls.answer.valid
              && controls.answer.touched
              && this.state.captchaToken
          )
      );
    }

    this.setState({ isDisabled })
  }

  
  public checkValidity(value, rules) {
    let isValid = true;
    
    if (!rules) {
      return true;
    }

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }

    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid;
    }

    if (rules.isEmail) {
      const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/;
      // const pattern = /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/;
      isValid = pattern.test(value) && isValid;
    }

    // if(rules.shouldMatchPassword){
    //   isValid = value === this.state.controls.password.value && isValid
    // }

    return isValid;
  }

  public onSubmitHandler = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    const {search} = this.props.location;
    const _trimSpacePassword = this.state.controls.password.value.trim();
    const _lowerCaseEmail = this.state.controls.email.value.toLowerCase();
    const _secretQuestiontext = this.state.secretQuestionText;
    const _secretQuestionAnswer = this.state.controls.answer.value;
    const _captchaToken =  this.state.captchaToken;

    if (search) {
      const _splitNextURI = search.split('=').pop();
      this.props.onLogin(
          _lowerCaseEmail,
          _trimSpacePassword,
          _secretQuestiontext,
          _secretQuestionAnswer,
          _captchaToken,
          _splitNextURI
      );
    } else {
      this.props.onLogin(
          _lowerCaseEmail,
          _trimSpacePassword,
          _secretQuestiontext,
          _secretQuestionAnswer,
          _captchaToken
      );
    }

    while (!LocalStorageWrapped.getItem("userInfo")) {
      await new Promise(r => setTimeout(r, 200))
    }

    if (LocalStorageWrapped.getItem("userInfo").twofaEnabled) {
        this.props.history.push(generatePortalSpecificUrl(this.props.portal, `/login-step2?${search}`));
    }
    else if (LocalStorageWrapped.getItem("userInfo").twofaEmailEnabled) {
      this.props.history.push(generatePortalSpecificUrl(this.props.portal, `/login-step2-email?${search}`));
    }else {
        this.setState({ captchaToken: "" }, () => this.checkLoginValidity());
    }
  };

  public componentDidMount() {
    this.props.onSetLoginStep2VerificationToken('not-empty-string');

    let queryParams = parse(window.location.search);
    if (Object.keys(queryParams).length !== 0) {
      if (Object.keys(queryParams)[0] === "token") {
        this.props.onSocialLogin(queryParams.token);
      } else if (Object.keys(queryParams)[0] === "a" && queryParams.a === "0") {
        MySwal.fire("Not Approved Or Disabled", "your account is not approved yet. You account needs to be approved by portal admin", "error");
        _.set(this.props.errors, "email", "your account is not approved yet. You account needs to be approved by portal admin");
      }
    }
  }

  setHeader = () => {
    if (this.props.token !== null) {
      axiosInterceptors.push(
        axiosObj.interceptors.request.use(
          request => requestHandler(request)
        )
      );
    }
  };

  render() {
    const controls = this.state.controls;
    let authRedirect = null;
    let content = null;
    let passwordErrorMessage = null;
    let emailErrorMessage = null;
    let portalNotFoundMessage = null;
    let generalMessage = null;


    if (this.props.errors !== null) {
      if(this.props.errors.portalNotFound) {
        portalNotFoundMessage = <p style={{color: "red"}}>{this.props.errors.portalNotFound}</p>;
      }
      if(this.props.errors.general) {

        portalNotFoundMessage = <p style={{color: "red"}}>{this.props.errors.general}</p>;
      }
      else if (this.props.errors.email) {
        emailErrorMessage = <p style={{color: "red"}}>{this.props.errors.email}</p>;
      }
      if (this.props.errors.password) {
        passwordErrorMessage = <p style={{color: "red"}}>{this.props.errors.password}</p>;
      }
      if(this.props.errors.request) {
          portalNotFoundMessage = <p style={{color: "red"}}>{this.props.errors.request}</p>;
      }
    }

    if (this.props.success) {
        this.setHeader();
        authRedirect = <Redirect to={generatePortalSpecificUrl(this.props.portal, this.props.authRedirectPath)}/>;
    }

    if (this.props.loading) {
      content = <Spinner/>;
    } else {
      content = (
        <>
         <Row>
            <Col className="mx-auto brand-logo" sm={12} md={6} lg={6}>
              <a href="https://auav.com.au/insite/">
                <img src="/icons/AUAV_logo_login.jpg" alt="inSiteLogo"/>
              </a>
            </Col>
            <Col className="mx-auto" sm={12} md={6} lg={6}>
              <Form>
                <FormGroup>
                  <label className="login-form-label">Email</label>
                  <Input
                    value={controls.email.value}
                    valid={controls.email.valid && controls.email.touched}
                    invalid={(!controls.email.valid
                    && controls.email.touched) || (emailErrorMessage !== null)}
                    onChange={(event) => this.inputChangedHandler(event, "email")}
                    type="email"
                    name="email"
                    id="loginEmail"
                    placeholder="Email"
                  />
                  {emailErrorMessage}
                </FormGroup>
                <FormGroup>
                  <label className="login-form-label">Password</label>
                  <Input
                    value={controls.password.value}
                    valid={controls.password.valid && controls.password.touched}
                    invalid={(!controls.password.valid && controls.password.touched) || (passwordErrorMessage !== null)}
                    onChange={(event) => this.inputChangedHandler(event, "password")}
                    type="password"
                    name="password"
                    id="loginPassword"
                    placeholder="Password"
                  />
                  
                  {this.state.isSecretQuestionRequired? 
                  <>
                    <br/>
                    <Label className="login-form-label">Security Question</Label><br/>

                    <Label name="Secret Question" id="secQuestions" className="font-weight-normal"
                    >
                    {this.state.secretQuestionText}
                    </Label>

                   {this.secretQuestionInput()}

                   <GoogleCaptchaV2 inputHandler = {this.inputHandler}/>
   

                  </>
                  
                  : null}
                  {passwordErrorMessage}
                  {portalNotFoundMessage}
                  {generalMessage}
                </FormGroup>
              <Row>
                {/*<Col className="text-left">*/}
                  {/*<SocialAuth/>*/}
                {/*</Col>*/}

        
                <Col className="text-left">
                  <Button
                    // tslint:disable-next-line: jsx-no-multiline-js
                    disabled={this.state.isDisabled}
                    onClick={(event) => this.onSubmitHandler(event)}
                    className="sign-in-button"
                    type="submit">
                    Sign in
                  </Button>
                  <a style={{marginLeft:'20px'}} href={`/forget-password`}>Forget password?</a>
                </Col>
              </Row>
            </Form>
        </Col>
         </Row>
        {/*<Row className="mx-auto text-center mt-3">*/}
          {/*<Col sm={12}>*/}
            {/*<p>Don't have a account ?</p> */}
            {/*<NavLink to={generatePortalSpecificUrl(this.props.portal, "/signup")}>Signup</NavLink>*/}
          {/*</Col>*/}
      {/*</Row>*/}
    </>
    );
    }

    return (
      <Row className="login-wrapper">
        <Col className="mx-auto" sm={10} md={8} lg={6}>
            <div className="login-form">
              {authRedirect}
              {content}
            </div>
            <Row className='text-center powered_by'>
                <span style={{ fontSize: '14px', color: '#7F7F7F' }}>For assistance, <a style={{ fontSize: '14px'}} href="mailto:support@auav.com.au">email AUAV Support</a></span>
            </Row>
        </Col>
      </Row>
    );

  }
}

const mapStateToProps = (state) => {
  return {
    message: state.login.message,
    loading: state.login.loading,
    errors: state.login.errors,
    success: state.login.success,
    authRedirectPath: state.login.authRedirectPath,
    portal: state.portal,
    token: state.login.token,
    loginStep2VerificationToken: state.login.loginStep2VerificationToken
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (
        email: string,
        password: string,
        secretQuestion?:string,
        secretQuestionAnswer?: string,
        captchaToken?: string,
        next?: string
    ) => dispatch(actions.login(
        email,
        password,
        secretQuestion,
        secretQuestionAnswer,
        captchaToken,
        next
    )),
    onSocialLogin: (token: string) => dispatch(actions.socialLogin(token)),
    onSetLoginStep2VerificationToken: (token: string) => dispatch(actions.setLoginStep2VerificationToken(token)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));