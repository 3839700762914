import classes from "./info.module.css";
import { faInfo, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Tooltip } from "reactstrap";


interface InfoProps {
    message: string;
    identifier: string;
}

const Info = (props: InfoProps) => {

    const [tooltipOpen, setTooltipOpen] = useState(false);

    return (
        <>
            <span id={`${props.identifier}-icon`} className={`ml-2 ${classes.cursorPointer}`}>
                <FontAwesomeIcon icon={faInfoCircle} />
            </span>
            <Tooltip isOpen={tooltipOpen} target={`${props.identifier}-icon`} toggle={() => setTooltipOpen(!tooltipOpen)}>
                {props.message}
            </Tooltip>
        </>
    );
};

export default Info;