import {
  DETECT_PORTAL_FAIL,
  DETECT_PORTAL_START,
  DETECT_PORTAL_SUCCESS,
  GET_PORTAL_FAIL,
  GET_PORTAL_START,
  GET_PORTAL_SUCCESS,
} from "../actions/actionTypes/portalActionTypes";
import {IPortalReducer} from "../types";
import {updateObject} from '../utility';
import LocalStorageWrapped from "../../LocalStorageWrapped";


const InitialState: IPortalReducer = {
  portalName: "",
  comments: '',
  found: false,
  id: "",
  portalType: "",
  slug: "",
  version: '',
  updatingPortal: {}
};

const detectPortalStart = (state, _action) => {
  return updateObject(state, {found: false});
};

const detectPortalSuccess = (state, action) => {
  const portalInfo = {
    portalName: action.name,
    portalId: action.id,
    portalType: action.portalType,
    portalSlug: action.slug,
    version: action.version,
    createdAt: action.createdAt,
    id: action.id,
    name: action.name,
    slug: action.slug,
    customScripts: action.customScripts,
  };
  LocalStorageWrapped.setItem("portalInfo", portalInfo);
  LocalStorageWrapped.setItem("selectedPortal", portalInfo);
  return updateObject(state, {
    portalName: action.portalName,
    found: action.found,
    id: action.id,
    portalType: action.portalType,
    slug: action.slug,
    comments: action.comments,
    version: action.version
  });
};

const getPortalSuccess = (state, action) => {
  return updateObject(state, {
    updatingPortal: {
      comments: action.comments,
      portalName: action.portalName,
      portalSlug: action.portalSlug,
      portalId: action.portalId,
      customScripts: action.customScripts,
      version: action.version,
      loading: false,
      success: true,
    }
  });
}

const detectPortalFail = (state, _action) => {
  LocalStorageWrapped.removeItem("portalInfo");
  return updateObject(state, {
    portalName: "",
    comments: '',
    found: false,
    id: "",
    portalType: "",
    slug: "",
    version: ''
  })
};

const reducer = (state: IPortalReducer = InitialState, action) => {
  switch (action.type) {
    case DETECT_PORTAL_START:
      return detectPortalStart(state, action);
    case DETECT_PORTAL_SUCCESS:
      return detectPortalSuccess(state, action);
    case DETECT_PORTAL_FAIL:
      return detectPortalFail(state, action);

    case GET_PORTAL_START:
      return updateObject(state, {updatingPortal: { loading: true, success: false }});
    case GET_PORTAL_SUCCESS:
      return getPortalSuccess(state, action);
    case GET_PORTAL_FAIL:
      return updateObject(state, {updatingPortal: { loading: false, success: false }});
  
    default:
      return state
  }
};

export default reducer;
