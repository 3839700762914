import React, { Component } from "react";
import { Button, Col, Table } from "reactstrap";
import { NavLink } from "react-router-dom";
import * as _ from "lodash";
import { PortalUsersProps, PortalUserStates } from "../../interfaces/portal";
import * as actions from "../../store/actions";
import { connect } from "react-redux";
import { Confirm } from "../../SWAL";
import { changeUserActivation, ChangeUserStatus, deleteUser, unlockUser } from "../../store/actions/user";
import { generatePortalSpecificUrl } from "../../helpers/urlGenerators";
import ChangePasswordComponent from '../common/password-change/password-change.component';
import { last } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

class PortalUsers extends Component<PortalUsersProps, PortalUserStates> {
  constructor(props) {
    super(props);
    this.state = {
      portalSlug: _.get(props.match.params, "portalSlug"),
      setPasswordModal: false,
      userId: '',
      userEmail: '',
      selectedUsers: []
    };
  };

  componentDidMount(): void {
    setTimeout(async () => {
      this.props.onTryFetchPortalUsersList(
        _.get(this.state, "portalSlug"));
    }, 0);
  }

  componentWillUnmount(): void {
    this.props.onClearPortalUsersState();
  }

  HandleUserStatusChange = async (userId: string, firstName: string, lastName: string) => {
    const name = `${firstName} ${lastName}`;
    let status = null;
    status = "make-portal-admin";
    await this.props.onChangeUserStatus(this.state.portalSlug, status, userId, name);
  };

  HandleUserPasswordChange = async (userId: string, email: string) => {
    this.setState({ setPasswordModal: true, userId: userId, userEmail: email });
  };

  closeSetPasswordModal = () => {
    this.setState({ setPasswordModal: false, userEmail: '', userId: '' });
  };

  handleUnlockUser = (user) => {
    const userName = _.get(user, "firstName") + " " + _.get(user, "lastName");
    const userRole = _.get(user, "isPortalAdmin") ? "PortalAdmin" : "PortalUser";
    Confirm("Do you really want to unlock " + userName, "Yes, Unlock!")
      .then(result => {
        if (result.value) {
          this.props.unlockUser(_.get(user, "id"), this.state.portalSlug, userRole);
        }
      })
  }

  handleUserActivation = (user) => {
    const action = _.get(user, "isEnabled") ? "disable" : "enable";
    const userRole = _.get(user, "isPortalAdmin") ? "PortalAdmin" : "PortalUser";
    if (action === "disable") {
      const userName = _.get(user, "firstName") + " " + _.get(user, "lastName");
      Confirm("Do you really want to Disable " + userName, "Yes, Disable!")
        .then(result => {
          if (result.value) {
            this.props.changeUserActivation(action, _.get(user, "id"), this.state.portalSlug, userRole);
          }
        })
    } else {
      this.props.changeUserActivation(action, _.get(user, "id"), this.state.portalSlug, userRole);
    }
  };

  handleUserDelete = (user) => {
    const userName = _.get(user, "firstName") + " " + _.get(user, "lastName");
    Confirm("Do you really want to Delete " + userName, "Yes, Delete!")
      .then(result => {
        if (result.value) {
          this.props.deleteUser(_.get(user, "id"), this.state.portalSlug);
        }
      })
  }

  handleCheckboxChange = (userId) => {
    const { selectedUsers } = this.state;
    const selectedIndex = selectedUsers.indexOf(userId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedUsers, userId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelected = newSelected.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1),
      );
    }

    this.setState({ selectedUsers: newSelected });
  };

 handleDeleteSelectedUsers = async () => {
  const { selectedUsers } = this.state;
  const { deleteUser, changeUserActivation } = this.props;

   const changeActivationPromises = selectedUsers.map(userId => {
     const user = this.props.portal_users_list.find(item => item.id === userId);
     if (user && user.isEnabled) {
       const userRole = _.get(user, "isPortalAdmin") ? "PortalAdmin" : "PortalUser";
       return changeUserActivation("disable", userId, this.state.portalSlug, userRole);
     }
     return Promise.resolve(); // Return a resolved promise for users who don't need to be disabled
   });

   await Promise.all(changeActivationPromises);

   // Proceed to delete the users once all activation changes have been made
   const deleteUserPromises = selectedUsers.map(userId => {
     const user = this.props.portal_users_list.find(item => item.id === userId);
     return deleteUser(userId, this.state.portalSlug);
   });

   await Promise.all(deleteUserPromises);

   // Clear the selected users state
   this.setState({ selectedUsers: [] });
};

  
  getDaysPast = (dateString: string): string => {
    const today = new Date();
    const date = new Date(dateString);
    const msInDay = 1000 * 60 * 60 * 24;
    const daysPast = Math.floor((today.getTime() - date.getTime()) / msInDay);
    if (daysPast === 0) {
      return 'Today';
    } else {
      return `${daysPast} day${daysPast > 1 ? 's' : ''} ago`
    }
  }

  render() {
    const userPortal = _.find(this.props.loggedInUser.portals, (portal) => portal.portal.toString() === this.props?.selectedPortal?.id?.toString());
    return (
      <div className="centerForm" >
        {this.state.setPasswordModal
          ? <ChangePasswordComponent email={this.state.userEmail}
            portal={this.props?.selectedPortal.slug}
            id={this.state.userId}
            modalOpen={this.state.setPasswordModal}
            toggleModal={this.closeSetPasswordModal} />
          : null}
        <>
          <Col lg={12}>
            <h1 className="heading">Portal Users</h1>
            {/*<MyTable portalSlug={_.get(this.state, "portalSlug")} type="portal user" users={this.props.portal_users_list} entityName={"portal users"}/><br/>*/}
            <Table responsive striped bordered>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Created At</th>
                  <th>Last login</th>
                  {(this.props.loggedInUser.isSuperUser || userPortal?.isPortalAdmin) && <th>Actions</th>}
                  {(this.props.loggedInUser.isSuperUser || userPortal?.isPortalAdmin) && (
                    <th style={{padding: '3px', paddingBottom: '8px'}}>

                      <button
                          onClick={this.handleDeleteSelectedUsers} style={{border: 'none',  backgroundColor: 'white'}}>
                        <img src={process.env.PUBLIC_URL + "/bin.svg"}
                             style={{width: '20px', height: '20px'}}
                             title="Delete user icon"
                             alt="Delete user icon" className="icon"/>
                      </button>
                    </th>
                  )}
                  {this.props.loggedInUser.isSuperUser && <th>Status</th>}
                </tr>
              </thead>
              <tbody>
                {this.props.portal_users_list && this.props.portal_users_list.map((item, index) => (
                  <tr key={index}>
                    {/* Render user information */}
                    <td>{item.firstName} {item.lastName}</td>
                    <td>{item.email}</td>
                    <td>{item.createdAt.split("T")[0]}</td>
                    <td>{item.lastlogin ? this.getDaysPast(item.lastlogin) : "Unknown"}</td>
                    {(this.props.loggedInUser.isSuperUser || userPortal?.isPortalAdmin) &&
                      <td>
                        {this.props.loggedInUser.isSuperUser &&
                          <Button
                            onClick={() => this.HandleUserStatusChange(_.get(item, "id"), item.firstName, item.lastName)}
                            outline color="info">
                            Make portal admin
                          </Button>
                        }
                        <Button onClick={_ => this.handleUserActivation(item)} className={"ml-3"} outline
                          color={item.isEnabled ? "danger" : "success"}>
                          {item.isEnabled ? "Disable" : "Enable"}
                        </Button>
                        {
                          !item.isEnabled && (
                            <div>
                              <Button onClick={_ => this.handleUserDelete(item)} className={"ml-3"} outline color={"danger"}>
                                Delete
                              </Button>
                            </div>
                          )
                        }
                        <NavLink to={generatePortalSpecificUrl(this.props.selectedPortal, `/user/update/${item.id}`)}>
                          <Button
                            className={"ml-3"}
                            outline
                            color={"info"}>
                            Edit
                          </Button>
                        </NavLink>
                        <Button
                          className={"ml-3"}
                          onClick={() => this.HandleUserPasswordChange(_.get(item, "id"), item.email)}
                          outline
                          color="info">
                          Set Password
                        </Button>
                      </td>
                    }
                    {(this.props.loggedInUser.isSuperUser || userPortal?.isPortalAdmin) &&
                      <td>
                        <input
                          type="checkbox"
                          onChange={() => this.handleCheckboxChange(item.id)}
                          checked={this.state.selectedUsers.includes(item.id)}
                        />
                      </td>
                    }
                    {/* Render unlock button if the user is a superuser */}
                    {this.props.loggedInUser.isSuperUser &&
                      <td>
                        {item.lockedStage ? (
                          <>
                            <p style={{ color: 'red' }}>{`Locked at`} <br /> {new Date(item.lockedTime).toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "2-digit" })}</p>
                            <Button outline color="success" onClick={_ => this.handleUnlockUser(item)}>Unlock</Button>
                          </>
                        ) : 'Active'}
                      </td>
                    }
                  </tr>
                ))}
              </tbody>

            </Table>
           


            <NavLink to={"/user/create/" + _.get(this.state, "portalSlug", "")}>
              <Button outline color="success">Add New User</Button>{' '}
            </NavLink>
          </Col>
        </>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.login.token,
    portal_users_list: state.portalUsersListReducer.portal_users_list,
    selectedPortal: state.portalListReducer.selectedPortal,
    loggedInUser: state.login.loggedInUser
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryFetchPortalUsersList: (portalSlug) => dispatch(actions.get_portal_users_list(portalSlug)),
    onClearPortalUsersState: () => dispatch(actions.clearPortalUsersState()),
    onChangeUserStatus: (portalSlug: string, status: string, userId: string, userName: string) => dispatch(ChangeUserStatus(portalSlug, status, userId, userName)),
    changeUserActivation: (action: string, userId: string, portalSlug: string, userRole: string) => dispatch(changeUserActivation(action, userId, portalSlug, userRole)),
    deleteUser: (userId: string, portalSlug: string) => dispatch(deleteUser(userId, portalSlug, "PortalUser")),
    unlockUser: (userId: string, portalSlug: string, userRole: string) => dispatch(unlockUser(userId, portalSlug, "PortalUser"))
  }
};



export default connect(mapStateToProps, mapDispatchToProps)(PortalUsers);
