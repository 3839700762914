import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Input} from 'reactstrap';
import { AnnotationOptionConditionsTargets, AnnotationOptionTypes, INSITEAnnotationTypes } from '../../../interfaces/annotation-options';
import Info from '../../UI/Info/Info';
import Select from 'react-select'
import _ from 'lodash';
import AnnotationOptionCondition, { ConditionObject } from './annotation-options-conditions.component';

interface OptionModalProps {
    buttonLabel: string;
    className: string;
    optionItem: any;
    openModal: boolean;
    submitOption: any;
    closeModal: any;
    annotationOptions: Array<any>;
}
const EditAddOption = (props: OptionModalProps) => {
    const { className, optionItem, openModal } = props;

    const [values, setValues] = useState(optionItem.values);
    const [valuesValid, setValuesValid] = useState(true);
    const [name, setName] = useState(optionItem.name);
    const [nameValid, setNameValid] = useState(true);
    const [label, setLabel] = useState(optionItem.label);
    const [labelValid, setLabelValid] = useState(true);
    const [type, setType] = useState(optionItem.type);
    const [selectedAnnotationTypes, setSelectedAnnotationTypes] = useState(optionItem.annotationTypes || []);
    const [defaultValue, setDefaultValue] = useState(optionItem.defaultValue);

    const isInitialMount = useRef(true);

    const [conditions, setConditions] = useState(optionItem.conditions || {});

    const [submitted, setSubmitted] = useState(false);

    const addValue = () => {
        const updatedValues = values.concat('');
        setValues(updatedValues);
    }

    const valuesChangeHandler = (event, index) => {
        event.preventDefault();
        const { value } : any = event.target;
        const updatedValues = values.slice();
        updatedValues[index] = value;
        
        setValues(updatedValues);
    }

    useEffect(() => {
        validateData();
    }, [name, label, type, values]);

    const submitOption = (event) => {
        setSubmitted(true);
        if (validateData(true)) {
            const selectedAnnotationTypeIds = selectedAnnotationTypes.map(val => val.value);
            props.submitOption({ name, conditions, label, values, type, defaultValue, annotationTypes: selectedAnnotationTypeIds });
        }
    }

    useEffect(() => {
        // Donot run on initial mount to avoid overriding of value sent in prop
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            if (type === AnnotationOptionTypes.DROPDOWN) {
                setDefaultValue(_.first(values));
            } else if (type === AnnotationOptionTypes.CHECKBOX) {
                setDefaultValue(false);
            } else {
                setDefaultValue('');
            }
        }
    }, [type, setDefaultValue, isInitialMount]);

    const validateData = (fromSubmit?): boolean => {
        const isFormSubmitted = fromSubmit ?? submitted;
        if (!isFormSubmitted) {
            return true;
        }
        let isValid = true;
        if (!name) {
            setNameValid(false);
            isValid = false;
        } else {
            setNameValid(true);
        }

        if (!label) {
            setLabelValid(false);
            isValid = false;
        } else {
            setLabelValid(true);
        }

        if (type === AnnotationOptionTypes.DROPDOWN && !values.length) {
            setValuesValid(false);
            isValid = false;
        } else {
            setValuesValid(true);
        }

        return isValid;
    }

    const removeValue = (index) => {
        const updatedValues = values.slice();
        updatedValues.splice(index, 1);
        setValues(updatedValues);
    }

    const AnnotationTypes = useMemo(() => {
        const mappedAnnTypes = Object.keys(INSITEAnnotationTypes).map((name) => {
            return {
                label: _.capitalize(name),
                value: INSITEAnnotationTypes[name]
            }
        });

        if (selectedAnnotationTypes?.length) {
            const mappedSelectedAnnTypes = mappedAnnTypes.filter((annType) => selectedAnnotationTypes.includes(annType.value))
            setSelectedAnnotationTypes(mappedSelectedAnnTypes);
        }

        return mappedAnnTypes;
    }, [INSITEAnnotationTypes]);

    const toggleSelectAll = useCallback(() => {
        if (selectedAnnotationTypes.length < AnnotationTypes.length) {
            setSelectedAnnotationTypes(AnnotationTypes);
        } else {
            setSelectedAnnotationTypes([]);
        }
        
    }, [selectedAnnotationTypes, AnnotationTypes]);

    const handleConditionChange = useCallback((conditionObj: ConditionObject, conditionType: AnnotationOptionConditionsTargets) => {
        const updatedConditions = {...conditions, [conditionType]: conditionObj};
        setConditions(updatedConditions);
    }, []);

    const defaultValueInput = useMemo(() => {
        switch(type) {
            case AnnotationOptionTypes.DROPDOWN:
                return (
                    <Input type='select'
                        value={defaultValue}
                        onChange={(e) => { setDefaultValue(e.target.value) }}>
                        {
                            values.map((option, index) => {
                                return (<option key={index} value={option}> {option} </option>)
                            })
                        }
                    </Input>
                )
            case AnnotationOptionTypes.CHECKBOX:
                return (
                    <Input type='checkbox'
                        checked={defaultValue}
                        className={'position-static ml-2 d-inline-block'}
                        onChange={(e) => { setDefaultValue(e.target.value) }}>
                    </Input>
                )
            case AnnotationOptionTypes.FILE:
                return;
            case AnnotationOptionTypes.INTEGER:
            case AnnotationOptionTypes.FLOAT:
                return (
                    <Input type='number'
                        value={defaultValue}
                        onChange={(e) => { setDefaultValue(e.target.value) }}>
                    </Input>
                )
            case AnnotationOptionTypes.STRING:
            default:
                return (
                    <Input type='textarea' rows="1"
                        value={defaultValue}
                        onChange={(e) => { setDefaultValue(e.target.value) }}>
                    </Input>
                )
        }
    }, [type, values, defaultValue, setDefaultValue]);

    return (
        <div>
            <Modal isOpen={openModal} toggle={props.closeModal} className={className}>
                <ModalHeader className='text-center'>Annotation Option</ModalHeader>
                <ModalBody>
                    <label className="d-block">
                        <strong> Option Name </strong>
                        <Info identifier={'name'} message={'Identifier of annotation option'}></Info>
                    </label>
                    <Input type={'text'}
                           name={'name'}
                           onChange={(event) => setName(event.target.value)}
                           value={name} />
                    { !nameValid && <span className="text-danger">Name cannot be empty</span> }

                    <label className="d-block">
                        <strong> Option Label </strong>
                        <Info identifier={'label'} message={'Display name of annotation option'}></Info>
                    </label>
                    <Input type={'text'}
                           name={'value'}
                           onChange={(event) => setLabel(event.target.value)}
                           value={label} />
                    { !labelValid && <span className="text-danger">Label cannot be empty</span> }

                    <label className="d-block">
                        <strong> Option Type </strong>
                        <Input type={'select'} value={type} onChange={(event) => setType(event.target.value)}>
                            {
                                Object.values(AnnotationOptionTypes).map((option, i) => 
                                    <option key={`type-${i}`} value={option}>{option}</option>
                                )
                            }
                        </Input>
                    </label>

                    <label className="d-block">
                        <strong>Show in annotation types (empty will show in point only)</strong>
                        <div className="d-flex">
                            <div style={{'flex': 1}}>
                                <Select options={AnnotationTypes} value={selectedAnnotationTypes} onChange={(val) => setSelectedAnnotationTypes(val)} isMulti={true} />
                            </div>
                            <Button className='ml-2' outline color="primary" onClick={toggleSelectAll}>{selectedAnnotationTypes.length < AnnotationTypes.length ? 'Select all': 'Unselect all'}</Button>
                        </div>
                    </label>
                    {
                        type === AnnotationOptionTypes.DROPDOWN && (
                            <>
                                <label className="mt-2"><strong>Option Values</strong></label>
                                <Button className={'ml-2'} onClick={() => addValue()}>
                                    <span>
                                        <FontAwesomeIcon icon={faPlusCircle} />
                                    </span>
                                </Button>
                                <br />
                                { !valuesValid && <span className="text-danger">Dropdown must have atleast one value</span> }
                                {
                                    values.map((value, index) => 
                                        <div key={`value-${index}`} className={'d-flex align-items-center'}>
                                            <Input type={'text'}
                                                name={'value'}
                                                placeholder={'Value'}
                                                className={'my-2'}
                                                onChange={(event) => valuesChangeHandler(event, index)}
                                                value={value} />
                                            
                                            <Button color="danger" className={'ml-2'} onClick={() => removeValue(index)}>
                                                <span>
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </span>
                                            </Button>
                                        </div>
                                    )
                                }
                            </>
                        )
                    }

                    <label className="d-block">
                        <strong> Default Value: </strong>
                        {defaultValueInput}
                    </label>

                    <label className="d-block">
                        <strong>Conditions</strong>
                            {
                                Object.values(AnnotationOptionConditionsTargets).map((target) => {
                                    return (
                                        <div key={`annotion-option-${target}`} className="d-flex my-2 flex-column">
                                            <b>{`${_.capitalize(target)} when: `}</b>
                                            <AnnotationOptionCondition annotationOptions={props.annotationOptions} annotationOptionName={name} annotationOptionType={type} condition={conditions[target] || null} onChange={(condition) => { handleConditionChange(condition, target) }}></AnnotationOptionCondition>
                                        </div>
                                    )
                                })
                            }
                    </label>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={submitOption}>Submit</Button>
                    <Button color="secondary" onClick={props.closeModal}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default EditAddOption;
