import React from "react";
import {connect} from "react-redux";
import {GroupDetailsProps, GroupDetailsState} from "../../interfaces/groups";
import {Col, Container, Row} from "reactstrap";
import * as _ from "lodash";
import {getGroupDetails} from "../../store/actions/portalGroups";
import GroupPermissions from "./groupPermissions";
import GroupUsers from "./groupUsers";


class GroupDetails extends React.Component<GroupDetailsProps, GroupDetailsState> {
  constructor(props) {
    super(props);
    this.state = {
      groupId: _.get(this.props.match.params, "groupId"),
      portal: _.get(this.props.match.params, "portal")
    }
  }

  async componentDidMount() {
    await this.props.getGroupDetails(this.state.portal, this.state.groupId);
  }
  render() {
    return (
      <Container>
        <Row>
          <Col className="centerForm" lg={12}>
            <h1 className="heading">Group details</h1>
            {this.props.selectGroupDetails &&
            <GroupPermissions permissions={this.props.selectGroupDetails.permissionsMapped}
                              modelPermissions={this.props.selectGroupDetails.modelPermissions}
                              groupId={this.state.groupId} portal={this.state.portal}/>
            }
          </Col>
        </Row>
        <GroupUsers groupId={this.state.groupId} portal={this.state.portal} />
      </Container>
    )
  }
}

const mapStateToProps = state => {
  return {
    selectGroupDetails: state.portalGroups.selectGroupDetails
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getGroupDetails: (portal: string, groupId: string) => dispatch(getGroupDetails(portal, groupId))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupDetails);
