import './password-change.styles.css';
import React, { Component } from 'react';
import { FormGroup, Input, Form, Label, Alert, Button, Modal, ModalBody } from 'reactstrap';
import { ChangeSecretQuestionProps, IChangeSecretQuestionState, IValidation, SetPasswordProps } from "../../../interfaces/auth";
import { axiosInstance } from "../../../Axios";
import * as _ from "lodash";
import { ManualChangeEvent } from '../../../interfaces/forms';
import { SecretQuestionOptionTypes } from '../../../interfaces/user';

class ChangeSecretQuestionComponent extends Component<ChangeSecretQuestionProps, IChangeSecretQuestionState> {

    constructor(props: ChangeSecretQuestionProps) {
        super(props);
        this.state = {
            controls: {
                selected_secret_question: {
                    value: '',
                    valid: false,
                    touched: false,
                    validation: {
                        required: true,
                    },

                },
                answer: {
                    value: '',
                    valid: false,
                    touched: false,
                    validation: {
                        required: true,
                    }
                }
            },
            // passwordMissMatchError: '',
            // passwordChangeSuccess: false,
            loadingState: false,
            secretQuestion: [],
            secretQuestionError: '',
            secretQuestionChangeSuccess: false,
        }
    }

    inputChangedHandler = (event: React.ChangeEvent<HTMLInputElement> | ManualChangeEvent, controlName: string, onComplete?: () => void) => {
        const updatedControls = {
            ...this.state.controls,
            [controlName]: {
                ...this.state.controls[controlName],
                value: event.target.value,
                valid: this.checkValidity(event.target.value, this.state.controls[controlName].validation),
                touched: true,
            },
        };
        this.setState({ controls: updatedControls }, onComplete);
    };

    checkValidity(value: string, rules: IValidation) {
        let isValid = true;

        if (!rules) {
            return true;
        }

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid;
        }

        if (rules.isEmail) {
            const pattern = /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/;
            isValid = pattern.test(value) && isValid;
        }

        if (rules.shouldMatchNewPassword) {
            isValid = value === this.state.controls.new_password.value && isValid;
        }

        return isValid;
    }

    submitHandler = (event) => {
        this.setState({ secretQuestionError: "" });
        event.preventDefault();

        const secretQuestionData = {
            "email": this.props.email,
            "secret_question": this.state.controls.selected_secret_question.value,
            "answer": this.state.controls.answer.value,
        };
        const _url = 'users/set-secret-question';
        const _headers = { 'Content-Type': 'application/json' };
        this.setState({ loadingState: true });
        axiosInstance(_url, 'post', _headers, JSON.stringify(secretQuestionData))
            .then(response => {
                let res = _.get(response, "data");
                if (res.success) {
                    this.setState({ secretQuestionChangeSuccess: true, loadingState: false });
                }
            }).catch(err => {
                this.setState({ secretQuestionError: err.response.data.errors, loadingState: false });
            });
    };

    secretQuestionInput = () => {
        const selectedSecretQuestion = this.state.secretQuestion.find((sq) => sq.id === this.state.controls.selected_secret_question.value);

        switch (selectedSecretQuestion?.type) {
            case SecretQuestionOptionTypes.STRING:
                return <Input type='text' value={this.state.controls.answer.value} className="inputItem mb-3" onChange={(event) => this.inputChangedHandler(event, "answer")} />
                break;
            case SecretQuestionOptionTypes.NUMBER:
                return <Input type='number' value={this.state.controls.answer.value} className="inputItem mb-3" onChange={(event) => this.inputChangedHandler(event, "answer")} />
                break;
            case SecretQuestionOptionTypes.DATE:
                return <Input type='date' value={this.state.controls.answer.value} className="inputItem mb-3" onChange={(event) => this.inputChangedHandler(event, "answer")} />
        }
    }

    getSecretQuestions = () => {

        const _url = 'users/secret-questions';
        const _headers = { 'Content-Type': 'application/json' };

        axiosInstance(_url, 'get', _headers)
            .then(response => {
                let res = _.get(response, "data");
                if (res.success) {
                    this.setState({ secretQuestion: res.result }, () => {
                        if (this.props.secretQuestion) {
                            const selectedSecretQuestion = this.state.secretQuestion.find((sq) => sq.question === this.props.secretQuestion);
                            this.inputChangedHandler({ target: { value: selectedSecretQuestion.id } }, "selected_secret_question");
                        }
                    });
                }
            }).catch(err => {
                this.setState({ secretQuestionError: err.response.data.message, loadingState: false });
            });

    }

    reInitState = () => {
        this.setState({
            controls: {
                selected_secret_question: {
                    value: '',
                    valid: false,
                    touched: false,
                    validation: {
                        required: true,
                    },

                },

                answer: {
                    value: '',
                    valid: false,
                    touched: false,
                    validation: {
                        required: true,
                    }
                },
            },
            loadingState: false,
            secretQuestion: [],
            secretQuestionError: '',
            secretQuestionChangeSuccess: false,
        });
    };

    componentDidMount() {
        this.reInitState();
        this.getSecretQuestions();
    }

    componentWillUnmount() {
        this.reInitState();
    }


    render() {
        let form = (
            <Form>
                <FormGroup>
                    <h1 className="heading"> Set Security Question </h1>
                    <Label className="labelText">Security Question</Label>
                    <select name="Secret Questions" id="secQuestions" className="inputItem mb-3 form-control"
                        value={this.state.controls.selected_secret_question.value}
                        onChange={(event) => {
                            this.setState({
                                controls: {
                                    ...this.state.controls,
                                    selected_secret_question: {
                                        ...this.state.controls.selected_secret_question,
                                        value: event.target.value,
                                        valid: this.checkValidity(event.target.value, this.state.controls.selected_secret_question.validation),
                                        touched: true,
                                    },
                                    answer: { ...this.state.controls.answer, value: '', touched: false, valid: false }
                                }
                            });
                        }
                        }>

                        <option>Select a question...</option>

                        {this.state.secretQuestion.map((question) => {
                            return (
                                <option value={question.id}>{question.question}</option>

                            )
                        })};
                    </select>

                    {
                        this.secretQuestionInput()
                    }

                    <div className='text-right'>
                        <Button disabled={!(this.state.controls.selected_secret_question.valid &&
                            this.state.controls.selected_secret_question.touched &&
                            this.state.controls.answer.valid &&
                            this.state.controls.answer.touched)}

                            onClick={(event) => this.submitHandler(event)}
                            color="info">Submit</Button>
                        <Button className={"ml-3"}
                            color="secondary"
                            onClick={() => this.props.toggleModal()}>Cancel</Button>
                    </div>
                </FormGroup>
            </Form>
        );

        return (
            <div className="centerForm">
                <Modal isOpen={this.props.modalOpen} toggle={() => this.props.toggleModal()}>
                    <ModalBody>
                        {this.state.secretQuestionChangeSuccess ?
                            <div> <Alert color="success"> Security Question Changed Successfully.</Alert>
                                <Button color="secondary" onClick={() => this.props.toggleModal()}>Back</Button>
                            </div>
                            :
                            <div> {form} </div>
                        }
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default ChangeSecretQuestionComponent;
