import * as projectActionTypes from "./actionTypes/userViewableProjectsActionTypes";
import {axiosInstance} from "../../Axios";
import * as _ from "lodash";


const getUserViewableProjectsStart = () => {
  return {
    type: projectActionTypes.GET_USER_VIEWABLE_PROJECTS_START
  }
};

const getUserViewableProjectsSuccess = (projects) => {
  return {
    type: projectActionTypes.GET_USER_VIEWABLE_PROJECTS_SUCCESS,
    projects
  }
};

const getUserViewableProjectsFail = (error) => {
  return {
    type: projectActionTypes.GET_USER_VIEWABLE_PROJECTS_FAIL,
    error
  }
};


export const getUserViewableProjects = () => {
  return async dispatch => {
    dispatch(getUserViewableProjectsStart());
    let url = `users/viewable-projects`;
    let response = null;
    try {
      response = await axiosInstance(url, "GET");
    } catch (e) {
      dispatch(getUserViewableProjectsFail(e.message));
    }

    let projects = _.get(response, "data.result");
    dispatch(getUserViewableProjectsSuccess(projects));
  }
};
