import _ from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { AnnotationOptionConditionTypes, AnnotationOptionConditions, AnnotationOptionTypes, AnnotationOptionConditionParams } from "../../../interfaces/annotation-options";
import { Input } from "reactstrap";

export interface ConditionObject {
    fieldName: string;
    conditionType: AnnotationOptionConditionTypes;
    paramType: AnnotationOptionConditionParams;
    value: string;
}

export interface AnnotationOptionConditionProps {
    condition: ConditionObject;
    annotationOptionType: AnnotationOptionTypes;
    annotationOptionName: string;
    onChange: (condition: ConditionObject) => void;
    annotationOptions: Array<any>;
}

const AnnotationOptionCondition: React.FC<AnnotationOptionConditionProps> = (props) => {
    const [conditionField, setConditionField] = useState(props.condition ? props.condition.fieldName : '');
    const [conditionType, setConditionType] = useState(props.condition ? props.condition.conditionType : '');
    const [paramType, setParamType] = useState(props.condition ? props.condition.paramType : '');
    const [conditionValue, setConditionValue] = useState(props.condition ? props.condition.value : '');

    const isInitialMount = useRef(true);

    const ValidAnnotationOptionConditions = useMemo(() => {
        return AnnotationOptionConditions.filter((annOpCondition) => {
            const conditionFieldObject = props.annotationOptions.find((annOp) => annOp.name && annOp.name === conditionField);
            return annOpCondition.validFor.includes(conditionFieldObject?.type);
        });
    }, [props.annotationOptions, AnnotationOptionConditions, conditionField]);

    const ValidParamTypes = useMemo(() => {
        return AnnotationOptionConditions.find((condition) => condition.type === conditionType)?.paramChoices || [];
    }, [AnnotationOptionConditions, conditionType]);

    useEffect(() => {
        if (!ValidParamTypes.includes(paramType as AnnotationOptionConditionParams)) {
            setParamType(_.first(ValidParamTypes));
        }
    }, [ValidParamTypes, setParamType]);

    useEffect(() => {
        if (!ValidAnnotationOptionConditions.some((condition) => condition.type === conditionType)) {
            setConditionType(_.first(ValidAnnotationOptionConditions)?.type);
        }
    }, [ValidAnnotationOptionConditions, setConditionType]);

    useEffect(() => {
        // Donot run on initial mount to avoid overriding of value sent in prop
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            if (paramType === AnnotationOptionConditionParams.FIELD) {
                const validAnnOptions = props.annotationOptions.filter(annOp => annOp.name !== props.annotationOptionName);
                setConditionValue(_.first(validAnnOptions).name);
            } else {
                setConditionValue('');
            }
        }
    }, [paramType, setConditionValue, isInitialMount]);

    useEffect(() => {
        const conditionObj: ConditionObject = {
            conditionType: conditionType as AnnotationOptionConditionTypes,
            fieldName: conditionField,
            paramType: paramType as AnnotationOptionConditionParams,
            value: conditionValue
        }
        props.onChange(conditionObj);
    }, [conditionField, conditionType, paramType, conditionValue]);

    const ConditionValueInput = useMemo(() => {

        let input = null;
        let label = null;
        switch (paramType) {
            case AnnotationOptionConditionParams.FIELD:
                input =  (
                    <Input className='my-1' type='select' value={conditionValue} onChange={(event) => setConditionValue(event.target.value)}>
                        {
                            props.annotationOptions.filter((option) => ![conditionField, props.annotationOptionName].includes(option.name)).map((option, i) => 
                                <option key={`val-option-${i}`} value={option.name}>{option.label}</option>
                            )
                        }
                    </Input>
                )
                label = 'Other field';
                break;
            case AnnotationOptionConditionParams.TEXT:
                input = <Input className='my-1' type='text' value={conditionValue} onChange={(event) => setConditionValue(event.target.value)}></Input>
                label = 'Value';
                break;
            case AnnotationOptionConditionParams.NUMBER:
                input = <Input className='my-1' type='number' value={conditionValue} onChange={(event) => setConditionValue(event.target.value)}></Input>
                label = 'Value';
                break;
            default:
                return null;
        }

        return (
            <div className='d-flex w-100 align-items-center'>
                <label className="d-block mr-2 my-0 w-25">
                    <strong> {label} </strong>
                </label>
                <div className='flex-grow-1'>
                    {input}
                </div>
            </div>
        )
    }, [paramType, conditionField, conditionValue, setConditionValue, AnnotationOptionConditionParams, props.annotationOptions, props.annotationOptionName]);

    
    return (
        <div className='d-flex flex-column align-items-center ml-2'>

            <div className='d-flex w-100 align-items-center'>
                <label className="d-block mr-2 my-0 w-25">
                    <strong> Field name: </strong>
                </label>
                <div className='flex-grow-1'>
                    <Input className='my-1' type='select' value={conditionField} onChange={(event) => setConditionField(event.target.value)}>
                        <option key={`option-empty`} value=''>No Condition</option>
                        {
                            props.annotationOptions.filter(annOp => annOp.name !== props.annotationOptionName).map((annotationOption, i) => 
                                <option key={`option-${i}`} value={annotationOption.name}>{annotationOption.label}</option>
                            )
                        }
                    </Input>
                </div>
            </div>
            {
                ValidAnnotationOptionConditions.length ?
                <div className='d-flex w-100 align-items-center'>
                    <label className="d-block mr-2 my-0 w-25">
                        <strong> Condition: </strong>
                    </label>
                    <div className='flex-grow-1'>
                        <Input className='my-1' type='select' value={conditionType} onChange={(event) => setConditionType(event.target.value as AnnotationOptionConditionTypes)}>
                            {
                                ValidAnnotationOptionConditions.map((annOptionCondition, i) => 
                                    <option key={`condition-${i}`} value={annOptionCondition.type}>{annOptionCondition.label}</option>
                                )
                            }
                        </Input>
                    </div>
                </div> : null
            }
            {
                ValidParamTypes.length > 1 ?
                <div className='d-flex w-100 align-items-center'>
                    <label className="d-block mr-2 my-0 w-25">
                        <strong> Value type: </strong>
                    </label>
                    <div className='flex-grow-1'>
                        <Input className='my-1' type='select' value={paramType} onChange={(event) => setParamType(event.target.value as AnnotationOptionConditionTypes)}>
                            {
                                ValidParamTypes.map((param, i) => 
                                    <option key={`param-${i}`} value={param}>{_.capitalize(param)}</option>
                                )
                            }
                        </Input>
                    </div>
                </div> : null
            }
            {
                paramType ? ConditionValueInput : null
            }
        </div>
    );
}

export default AnnotationOptionCondition;