import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content"

export const MySwal = withReactContent(Swal);

export const Toast = MySwal.mixin({
  toast: true,
  position: 'top-right',
  showConfirmButton: false,
  timer: 3000,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", MySwal.stopTimer);
    toast.addEventListener("mouseleave", MySwal.resumeTimer);
  }
});

export const Confirm = (message: string,  confirmBtnText: string, title: string = "Are you sure?") => {
  return MySwal.fire({
      title: title,
      text: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: confirmBtnText
    })
};

export const InputSWAL = () => {
    // @ts-ignore
    return  MySwal.fire({
        title: "Input Portal name",
        input: "text",
        inputLabel: "Your Portal name",
        inputPlaceholder: "Enter new portal name",
    });
};
