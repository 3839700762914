import {axiosInstance} from "../../Axios";
import * as _ from "lodash";
import * as userActionTypes from "./actionTypes/userActionTypes";
import {get_portal_admin_list} from "./portal";
import {MySwal, Toast} from "../../SWAL";


const get_portal_user_list_start = () => {
  return {
    type: userActionTypes.GET_USER_LIST_START
  }
};
const get_portal_admin_list_fail = (success: boolean, errors: []) => {
  return {
    type: userActionTypes.GET_USER_LIST_FAIL,
    get_portal_users_list_success: success,
    get_portal_users_list_errors: errors,
  }
};

const get_portal_user_list_success = (success: boolean, portal_users_list: []) => {
  return {
    type: userActionTypes.GET_USER_LIST_SUCCESS,
    get_portal_users_list_success: success,
    portal_users_list: portal_users_list,
  }
};

export const get_portal_users_list = (slug: string) => {
  return dispatch => {
    dispatch(get_portal_user_list_start());
    axiosInstance(`${slug}/users`, "get", {})
      .then(
        response => {
          let res = _.get(response, "data");
          const portal_users_list = res.result;
          const success = res.success;
          if (success) {
            dispatch(get_portal_user_list_success(success, portal_users_list));
          }
        }).catch(err => {
        if(err.response) {
            const errors = err.response.data.errors;
            dispatch(get_portal_admin_list_fail(false, errors));
        }
    });
  }
};

export const clearPortalUsersState = () => {
  return {
    type: userActionTypes.CLEAR_USER_LIST_STATE,
  }
};

const ChangeUserStatusStart = () => {
  return {
    type: userActionTypes.CHANGE_USER_STATUS_START,
  }
};

const ChangeUserStatusSuccess = (resMessage: string) => {
  return {
    type: userActionTypes.CHANGE_USER_STATUS_SUCCESS,
  }
};

export const ChangeUserStatusFail = (errMessage: string) => {
  return {
    type: userActionTypes.CHANGE_USER_STATUS_FAIL,
  }
};

export const ChangeUserStatus = (portalSlug: string, status: string, userId: string, userName: string) => {
  return dispatch => {
    dispatch(ChangeUserStatusStart());
    axiosInstance(`${portalSlug}/users/${status}/${userId}`, "post", {},{})
      .then(res => {
        let response = _.get(res, "data");
        if(status === "remove-as-admin") {
          MySwal.fire({
            title:"Removed!",
            text: userName + " has been removed as portal admin.",
            icon: "success"
          });
        }
        else {
          Toast.fire({
              icon: "success",
              titleText: userName + " was successfully made admin"
            })
        }
        dispatch(ChangeUserStatusSuccess(response.message));
        dispatch(get_portal_users_list(portalSlug));
        dispatch(get_portal_admin_list(portalSlug));

      })
      .catch( err => {
        let errResponse = _.get(err,"data");
        if(status === "remove-as-admin") {
          MySwal.fire({
            title:"Error!",
            titleText:"Error occurred while removing " + userName + " as portal admin.",
            icon:"error"}
          );
        }
        else {
          MySwal.fire({
            title:"Error!",
            titleText:"Error occurred while making " + userName + " a portal admin.",
            icon:"error"
          }
          );
        }

        dispatch(ChangeUserStatusFail(errResponse? errResponse.message: ""));
      })
  }
};

const changeUserActivationStart = () => {
  return  {
    type: userActionTypes.CHANGE_USER_ACTIVATION_START
  }
};

const changeUserActivationSuccess = () => {
  return {
    type: userActionTypes.CHANGE_USER_STATUS_SUCCESS
  }
};

const changeUserActivationFail = () => {
  return {
    type: userActionTypes.CHANGE_USER_ACTIVATION_FAIL
  }
};

export const changeUserActivation = (action: string, userId: string, portalSlug: string, userRole) => {
  return dispatch => {
    dispatch(changeUserActivationStart());
      return axiosInstance(portalSlug + "/users/" + action + "/" + userId, "get", {}, {})
      .then(_ => {
        dispatch(changeUserActivationSuccess());
        Toast.fire("Success", "Successfully " + action + "d!", "success");
        if(userRole === "PortalAdmin") {
          dispatch(get_portal_admin_list(portalSlug));
        }
        else {
          dispatch(get_portal_users_list(portalSlug));
        }
      })
      .catch(err=>{
        if(action === "disable") {
          MySwal.fire({
            title:"Error!",
            titleText:"Error occurred while disabling.",
            icon:"error"}
          );
        }
        else {
          MySwal.fire({
            title:"Error!",
            titleText:"Error occurred while enabling.",
            icon:"error"}
          );
        }
        dispatch(changeUserActivationFail());
      })
  }
};

export const deleteUser = (userId: string, portalSlug: string, userRole: string) => {
  return dispatch => {
    axiosInstance(`${portalSlug}/users/${userId}`, "delete", {}, {})
      .then(_ => {
        Toast.fire("Success", "Successfully Deleted!", "success");
        if(userRole === "PortalAdmin") {
          dispatch(get_portal_admin_list(portalSlug));
        }
        else {
          dispatch(get_portal_users_list(portalSlug));
        }
      })
      .catch(err=>{
        MySwal.fire({
          title:"Error!",
          titleText:"Error occurred while deleting user.",
          icon:"error"}
        );
      })
  }
}

export const unlockUser = (userId: string, portalSlug: string, userRole: string) => {
  return dispatch => {
    axiosInstance(`${portalSlug}/users/user-unlock/${userId}`, "PUT", {}, {})
      .then(_ => {
        Toast.fire("Success", "Successfully Unlocked!", "success");
        if(userRole === "PortalAdmin") {
          dispatch(get_portal_admin_list(portalSlug));
        }
        else {
          dispatch(get_portal_users_list(portalSlug));
        }
      })
      .catch(err=>{
        MySwal.fire({
          title:"Error!",
          titleText:"Error occurred while deleting user.",
          icon:"error"}
        );
      })
  }
}
