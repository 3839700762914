import React, {Component} from "react"
import {ForgetPasswordProps, IForgetPasswordState} from "../../interfaces/auth";
import {Alert, Button, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {Link} from "react-router-dom";
import {axiosInstance} from "../../Axios";


class ForgetPassword extends Component<ForgetPasswordProps, IForgetPasswordState>{
    checkEvent;
    constructor(props: ForgetPasswordProps) {
        super(props);
        this.state = {
            controls: {
                email: {
                    value: "",
                    valid: false,
                    touched: false,
                    validation: {
                        required: true,
                        isEmail: true,
                    }
                }
            },
            isDisabled: true,
            passwordChangeSuccess: false,
            passwordChangeFail:false,
        }
    }


    public inputChangedHandler = (event: React.ChangeEvent<HTMLInputElement>, controlName: string) => {
        const updatedControls = {
            ...this.state.controls,
            [controlName]: {
                ...this.state.controls[controlName],
                value: event.target.value,
                valid: this.checkValidity(event.target.value, this.state.controls[controlName].validation),
                touched: true
            }
        };
        this.setState({controls: updatedControls}, () => this.checkLoginValidity());
    };

    onSubmitHandler = async (event)=>{
        event.preventDefault();
        let email = this.state.controls.email.value;
        const response :any = await axiosInstance(`users/forget-password`,"post", {},{email});
        if(response?.data?.success){
            this.setState({passwordChangeSuccess: true});
        }else{
            this.setState({passwordChangeFail: true});
        }
    }

    checkLoginValidity = () => {
        const controls = this.state.controls;
        let isDisabled = !(controls.email.valid && controls.email.touched);
        this.setState({ isDisabled })
    }

    public checkValidity(value, rules) {
        let isValid = true;

        if (!rules) {
            return true;
        }

        if (rules.required) {
            isValid = value.trim() !== "" && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid;
        }

        if (rules.isEmail) {
            const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}$/;
            // const pattern = /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/;
            isValid = pattern.test(value) && isValid;
        }

        return isValid;
    }


    render() {
        const controls = this.state.controls;

        let form = (
            <Form>
                <FormGroup>
                    <h1 className="heading"> Reset Password </h1>
                    <Label className="labelText">Enter your email address</Label>
                    <Input
                        value={controls.email.value}
                        valid={controls.email.valid && controls.email.touched}
                        invalid={(!controls.email.valid
                            && controls.email.touched)}
                        onChange={(event) => this.inputChangedHandler(event, "email")}
                        type="email"
                        name="email"
                        id="loginEmail"
                        placeholder="Email address"
                    />
                    <Button
                        style={{margin:'10px'}}
                        disabled={this.state.isDisabled}
                        onClick={(event) => this.onSubmitHandler(event)}
                        className="sign-in-button"
                        type="submit">
                        Send
                    </Button>
                </FormGroup>
            </Form>
        )
        return (
            <div className="mx-5">
                <Row>
                    <Col sm={12} md={8} lg={7} className="centerForm">
                        <Form>
                            {this.state.passwordChangeSuccess ?
                                <div> <Alert color="success"> Email to reset password sent successfully.</Alert>
                                    <Link color="secondary" to='/login' > Back </Link>
                                </div>
                                :
                                    this.state.passwordChangeFail ?
                                        <div><Alert color="success"> Email to reset password sent successfully. </Alert>
                                            <Link color="secondary" to='/login'> Back </Link>
                                        </div>
                                        :
                                        <div> {form} </div>
                            }
                        </Form>
                    </Col>
                </Row>
            </div>
        );

    }

}

export default ForgetPassword;