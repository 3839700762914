import {updateObject} from "../utility";
import {ViewableProjectsStates} from "../../interfaces/project";
import * as projectActionTypes from "../actions/actionTypes/userViewableProjectsActionTypes";


const initialState: ViewableProjectsStates = {
  projects: [],
  error: '',
  success: false,
  loading: false
};

const getUserViewableProjectsStart = (state) => {
  return updateObject(state, {loading: true});
};

const getUserViewableProjectsSuccess = (state, action) => {
  return updateObject(state, {loading: false, success: true, projects: action.projects});
};

const getUserViewableProjectsFail = (state, action) => {
  return updateObject(state, {loading: false, success: false, error: action.error});
};

const reducer = (state: ViewableProjectsStates = initialState, action) => {
  switch (action.type) {
    case projectActionTypes.GET_USER_VIEWABLE_PROJECTS_START:
      return getUserViewableProjectsStart(state);
    case projectActionTypes.GET_USER_VIEWABLE_PROJECTS_SUCCESS:
      return getUserViewableProjectsSuccess(state, action);
    case projectActionTypes.GET_USER_VIEWABLE_PROJECTS_FAIL:
      return getUserViewableProjectsFail(state, action);
    default:
      return state;
  }
};

export default reducer;
