import React, {Component} from "react";
import {connect} from "react-redux";
import * as actions from "../../store/actions";
import {ProjectPermissionsProps, ProjectPermissionsState} from "../../interfaces/permissions";
import {Table} from "reactstrap";
import PermissionsCollapse from "./Collapse";
import {ProjectProps} from "../../interfaces/portal";


class ProjectPermissions extends Component<ProjectPermissionsProps, ProjectPermissionsState> {
  state = {
    isOpen: [],
    checked:[]
  };

  componentDidMount(): void {
    setTimeout(() => {
      this.props.getProjectList(this.props.portalSlug);
      let isOpen = [];
      for (let i = 0; i < this.props.projectList.length; i++) {
        isOpen.push(false);
      }
      this.setState({isOpen: isOpen});
    }, 100)
  }

  toggle = (index) => {
    let isOpen = this.state.isOpen;
    isOpen[index] = !isOpen[index];
    this.setState({isOpen: isOpen});
  };


  render() {

    return (
      <>
        <div><Table striped bordered small>
          <thead>
          <tr>
            <th>Name</th>
            <th>Created At</th>
            <th>Permissions</th>
          </tr>
          </thead>
          <tbody>
          {
            this.props.projectList && this.props.projectList.map((project: ProjectProps, index) => (
              <tr key={index}>
                {/*<th scope="row">{index + 1}</th>*/}
                <td>
                  {project.name}
                </td>
                <td>{project.createdAt.split("T")[0]}</td>
                <td>
                  <PermissionsCollapse userId={this.props.userId} portalSlug={this.props.portalSlug} objectId={project.id} objectModel={"project"}/>
                </td>
              </tr>
            ))
          }
          </tbody>
        </Table>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    projectList: state.project.project_list
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getProjectList: (portalName: string) => dispatch(actions.get_project_list(portalName))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPermissions);
