import React, {Component} from "react";
import {Col, Container, InputGroup, Row, Input, Button} from "reactstrap";
import {NavLink} from "react-router-dom";
import {connect} from "react-redux";
import * as actions from "../../store/actions";
import {changeSelectedPortal, changeStatusPortal} from "../../store/actions/portal";
import {
    MessageListingProps,
    MessageListingState,
} from "../../interfaces/portal";
import {axiosInstance} from "../../Axios";
import * as _ from "lodash";


class MessageListing extends Component<MessageListingProps, MessageListingState> {
    state = {
        portal: this.props.portal,
        typedMessage: '',
        chatPage: 1,
    };


    componentDidMount() {
        setTimeout(() => {
            const scrollDiv = document.getElementById('chat_box_popover');
            if(scrollDiv){
                scrollDiv.scrollTop = scrollDiv.scrollHeight;
                scrollDiv.addEventListener(("scroll"),()=>{
                    if(scrollDiv.scrollTop === 0){
                        let page = this.state.chatPage + 1;
                        this.props.getPortalMessages(this.state.portal,page);
                        this.setState({chatPage: page})
                    }
                });
            }
        },100);
    }

    sendMessage(){
        if(this.state.portal.id && this.state.typedMessage && this.props.loggedInUser.id){

            axiosInstance(`portal/${this.state.portal.slug}/${this.state.portal.id}/${this.props.loggedInUser.id}/chat-message`, "put", {},{message:this.state.typedMessage}).then(response => {
                let res = _.get(response, "data");
                const success = res.success;
                if (success) {
                    this.setState({typedMessage: ''});
                    this.props.getPortalMessages(this.state.portal,1);
                    let objDiv = document.getElementById("chat_box_popover");
                    objDiv.scrollTop = objDiv.scrollHeight;
                }
            }).catch(err => {
                const errors = (err && err.response && err.response.data) ? err.response.data.errors : 'something went wrong';
            });
        }
    }

    saveMessage(message){
        this.setState({typedMessage: message});
    }

    displayChat(){
        let displayChat = [];
        let chat = this.props.portal_chat;
        chat = _.sortBy(chat, (obj)=>{return obj.messageTime});
        if(chat.length > 0){
            chat.forEach((chatObj,index)=>{
                if(chatObj.userId === String(this.props.loggedInUser.id)){
                    displayChat.push(
                        <div key={index} className = {'div-current-user'}>
                            <p className={'message-current-user'}>{chatObj.message}
                            </p>
                        </div>
                    );
                }else{
                    displayChat.push(
                        <div key={index} className = {'div-other-users'}>
                            <p className={'message-other-users'}>
                                <span style={{fontSize:'smaller',fontStyle:'italic'}}>{chatObj.userEmail}</span><br/>
                                {chatObj.message}
                            </p>
                        </div>
                    );
                }

            })
        }
        return displayChat;

    }


    textField(){
        let field = <div>
                        <input className={"typed-message"} type={'text'} placeholder={"Message"} style={{width:'195px', paddingLeft:'5px'}} value={this.state.typedMessage}
                               onChange={(e)=>this.saveMessage(e.target.value)}/>
                        <Button  className={'send-button'}   disabled={!this.state.typedMessage.length}
                                 onClick={(e)=>{this.sendMessage()}}>Send</Button>
                    </div>
        return field;
    }
    render() {
        return (
            <>
                {
                    this.props.portal_chat.length > 10 ?
                        <div id={'chat_box_popover'}>
                            {
                                this.displayChat()
                            }
                            {
                                this.textField()
                            }
                        </div>
                        :
                        <div id={'chat_box_popover_empty'}>
                            {
                                this.displayChat()
                            }
                            {
                                this.textField()
                            }
                        </div>
                }
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.login.token,
        portal_list: state.portalListReducer.portal_list,
        success: state.portalListReducer.get_portal_list_success || state.portalListReducer.changeStateSuccess,
        loading: state.portalListReducer.get_portal_list_loading,
        changeStatusLoading: state.portalListReducer.changeStateLoading,
        loggedInUser: state.login.loggedInUser,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onTryFetchPortalList: () => dispatch(actions.get_portal_list()),
        changeSelectedPortal: (portal) => dispatch(changeSelectedPortal(portal)),
        onChangePortalStatus: (status, portalId, portalName) => dispatch(changeStatusPortal(status, portalId, portalName))

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageListing);
