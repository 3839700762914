import * as React from "react";
import {Button, Col, Form, Input, Label, Row, Container} from "reactstrap";
import {create_portal} from "../../store/actions";
import {connect} from "react-redux"
import Spinner from "../UI/Spinner/Spinner";
import {Redirect} from "react-router";
import {clearPortalState} from "../../store/actions/portal";
import {CreatePortalProps, CreatePortalState} from "../../interfaces/portal";
import {NavLink} from "react-router-dom";
import './Portal.css';
import ErrorDisplayer from "../UI/ErrorHandling/ErrorDisplayer";

class CreatePortal extends React.Component<CreatePortalProps, CreatePortalState> {
    state = {
        name: "",
        comments: '',
        image: null,
        customScripts: '',
        imagePreviewUrl: null,
        disableForm: true,
    };

    portalNameChangeHandler = (e) => {
        let disableForm = e.target.value.trim() === "";
        this.setState({
            name: e.target.value,
            disableForm: disableForm
        })
    };

    portalCommentChangeHandler = (e) => {
        this.setState({
            comments: e.target.value,
        })
    };

    portalScriptChangeHandler = (e) => {
        this.setState({
            customScripts: e.target.value,
        })
    };

    submitHandler = (e) => {
        e.preventDefault();
        // if the form is disabled but someone changes disabled to false from developer console.
        if(this.state.disableForm) {
            return;
        }
        const _portalDetails = {name: this.state.name,
                                comments: this.state.comments,
                                image: this.state.image,
                                customScripts: this.state.customScripts,
                                portalId: null,
                                };
        this.props.onPortalCreate(_portalDetails, false);
        this.props.toggleCreatePortalComponent();
    };

    clearState = () => {
        this.props.onClearPortalState();
    };

    handleImageChange(e) {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onload = () => {
            this.setState({
                image: file,
                imagePreviewUrl: reader.result,
            });
        };
        reader.readAsDataURL(file)
    }


    render() {
        if(!this.props.isSuperUser) {
            return <Redirect to="/"/>;
        }
        let imagePreview = null;
        if (this.state.imagePreviewUrl) {
            imagePreview = (<img style={{maxHeight: "300px", maxWidth: "300px"}} alt={"logo"} src={this.state.imagePreviewUrl} />);
        } else {
            imagePreview = (<div className="previewText">Please select an Image for Preview</div>);
        }
        let content = null;
        let redirect = null;
        if (this.props.loading) {
            content = <Spinner/>
        } else {
            content = (
                <Container style={{padding: '3px'}}>
                    <Form>
                        <Row>
                            <Col lg={12}>
                                <Label className="labelText">Name</Label>
                                <Input
                                    className="inputItem"
                                    value={this.state.name}
                                    onChange={(event) => this.portalNameChangeHandler(event)}
                                />
                                <ErrorDisplayer errorObj={this.props.errors} keyName="name"/>

                                <p style={{color: "red"}}>{this.props.errors.name}</p>
                            </Col>
                            <Col className="mx-auto" sm={12}>
                                <Label className="labelText">Comment</Label>
                                <Input
                                    className="inputItem"
                                    value={this.state.comments}
                                    onChange={(event) => this.portalCommentChangeHandler(event)}
                                />
                                <ErrorDisplayer errorObj={this.props.errors} keyName="comments"/>

                            </Col>
                            {!this.props.isSuperUser? '':
                                <Col className="mx-auto" sm={12}>
                                    <Label className="labelText">Custom JS Script URL</Label>
                                    <Input className="inputItem"
                                        value={this.state.customScripts}
                                        onChange={(event) => this.portalScriptChangeHandler(event)}
                                    />
                                </Col>
                            }
                            <Col className="mx-auto" sm={12}>
                                <Label className="labelText">Image</Label>
                                <Input
                                  type="file"
                                  // value={this.state.image}
                                  onChange={(event) => this.handleImageChange(event)}
                                />
                                {imagePreview}
                                {/*<p style={{color: "red"}}>{errorMessage}</p>*/}
                            </Col>
                        </Row>
                        <Row className="px-2 d-flex justify-content-center">
                            <Button onClick={(event) => this.submitHandler(event)}
                                    disabled={this.state.disableForm}
                                    color="success">
                                    Create Portal
                            </Button>
                            &nbsp;&nbsp;&nbsp;
                            <Button className='cancel-portal-creation-btn' color="danger">
                                <NavLink className='cancel-portal-anchor' to={`/portals/`} onClick={this.props.toggleCreatePortalComponent} style={{ color: '#dc3545', width: '100%' }}>Cancel</NavLink>
                            </Button>
                        </Row>
                    </Form>
                </Container>);
        }
        if(this.props.success) {
            let redirectUrl = "/details/" + this.props.slug;
            redirect = <Redirect to={redirectUrl}/>
            this.clearState();
        }
        return (
            <>
                <div className="centerForm createPortalForm" style={{width:"700px"}}>
                    {redirect}
                    {content}
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.login.token,
        isSuperUser: state.login.loggedInUser.isSuperUser,
        loading: state.portalListReducer.loading,
        errors: state.portalListReducer.errors,
        success: state.portalListReducer.success,
        name: state.portalListReducer.name,
        slug: state.portalListReducer.slug,
        selectedPortal: state.portalListReducer.selectedPortal,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onPortalCreate: (portalDetails, createCopy) => dispatch(create_portal(portalDetails,createCopy)),
        onClearPortalState: () => dispatch(clearPortalState()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePortal);
