export const AnnotationPermissions = [
  "CAN_ADD_ANNOTATIONS",
  "CAN_CHANGE_ANNOTATION",
  "CAN_VIEW_ANNOTATION"
];

export const AnnotationPermissionsObject = {
  CAN_ADD_ANNOTATIONS: "CAN_ADD_ANNOTATIONS",
  CAN_CHANGE_ANNOTATION: "CAN_CHANGE_ANNOTATION",
  CAN_VIEW_ANNOTATION: "CAN_VIEW_ANNOTATION"
};

export const ProjectPermissions = [
  "CAN_ADD_PROJECT",
  "CAN_CHANGE_PROJECT",
  "CAN_VIEW_PROJECT"
];

export const ProjectPermissionsObject = {
  CAN_ADD_PROJECT: "CAN_ADD_PROJECT",
  CAN_CHANGE_PROJECT: "CAN_CHANGE_PROJECT",
  CAN_VIEW_PROJECT: "CAN_VIEW_PROJECT",
  CAN_DELETE_PROJECT: "CAN_DELETE_PROJECT"
};

export const PortalPermissionsObject = {
  CAN_DELETE_PORTAL: "CAN_DELETE_PORTAL",
  CAN_CHANGE_PORTAL: "CAN_CHANGE_PORTAL",
  CAN_ADD_PORTAL: "CAN_ADD_PORTAL",
  CAN_LIST_PORTAL: "CAN_LIST_PORTAL",
};

export const ObjectPermissions = ["CAN_VIEW", "CAN_CHANGE", "CAN_DELETE"];

export const UserObjectPermissions = ["CAN_VIEW"];

export const PermissionsObject = {
  CAN_VIEW: ObjectPermissions[0],
  CAN_CHANGE: ObjectPermissions[1],
  CAN_DELETE: ObjectPermissions[2]
};

export const ModelPermissionsObject = {
  "Project": ProjectPermissionsObject,
  "Annotation": AnnotationPermissionsObject
};
