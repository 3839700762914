import React, {Component, useEffect, useState} from 'react';
import {axiosInstance} from "../../Axios";
import LocalStorageWrapped from "../../LocalStorageWrapped";
import {generatePortalSpecificUrl} from "../../helpers/urlGenerators";
import {useSelector} from "react-redux";
import OtpInput from 'react-otp-input';
import {Button, Form, Input} from "reactstrap";
import {MySwal} from "../../SWAL";
import Card from "../UI/Card";
import OtpForm from "../UI/OtpForm";

export default function Configure2fa(props) {
    const [twofaEnabled, setTwofaEnabled] = useState<boolean>(LocalStorageWrapped.getItem("userInfo").twofaEnabled)
    const portal = useSelector((state: any) => state.portal)
    const [secret, setSecret] = useState<any>(false)
    const [otp, setOtp] = useState<any>(null)

    useEffect(() => {
        if (!twofaEnabled) {
            axiosInstance(
                generatePortalSpecificUrl(
                    portal,
                    "users/generate-otp-secret"
                ),
                "post",
                {},
                {}
            ).then((response: any) => {
                const {secret, qrImageDataUrl} = response.data.object
                setSecret({secret, qrImageDataUrl})
                // setTwofaEnabled(response.data.object.twofaEnabled)
            }).catch(error => {
                // setTwofaEnabled(error.response.data.object.twofaEnabled)
            })
        }
    }, [portal, twofaEnabled])

    const onSubmitHandler = (e) => {
        e.preventDefault();
        axiosInstance(
            generatePortalSpecificUrl(
                portal,
                "users/verify-otp"
            ),
            "post",
            {},
            {token: otp}
        ).then((response: any) => {
            MySwal.fire(
                'Alert',
                response.data.message,
                "info"
            );

            setTwofaEnabled(response.data.object.twofaEnabled)

            const userInfo = LocalStorageWrapped.getItem("userInfo")
            userInfo.twofaEnabled = response.data.object.twofaEnabled
            LocalStorageWrapped.setItem("userInfo", userInfo)
        }).catch(error => {
            MySwal.fire(
                'OTP Error',
                error.response.data.message,
                "error"
            );

            setTwofaEnabled(error.response.data.object.twofaEnabled)

            const userInfo = LocalStorageWrapped.getItem("userInfo")
            userInfo.twofaEnabled = error.response.data.object.twofaEnabled
            LocalStorageWrapped.setItem("userInfo", userInfo)
        })
    }

    const onDisableHandler = (e) => {
        e.preventDefault();
        axiosInstance(
            generatePortalSpecificUrl(
                portal,
                "users/disable-2fa"
            ), "post", {}, {}
        ).then((response: any) => {
            MySwal.fire(
                'Alert',
                response.data.message,
                "info"
            );

            setTwofaEnabled(response.data.object.twofaEnabled)

            const userInfo = LocalStorageWrapped.getItem("userInfo")
            userInfo.twofaEnabled = response.data.object.twofaEnabled
            LocalStorageWrapped.setItem("userInfo", userInfo)
        }).catch(error => {
            // MySwal.fire(
            //     'OTP Error',
            //     error.response.data.message,
            //     "error"
            // );
            //
            // setTwofaEnabled(error.response.data.object.twofaEnabled)
            //
            // const userInfo = LocalStorageWrapped.getItem("userInfo")
            // userInfo.twofaEnabled = error.response.data.object.twofaEnabled
            // LocalStorageWrapped.setItem("userInfo", userInfo)
        })
    }

    return <div>
        {
            twofaEnabled
                ? <Card style={{
                    justifyContent: 'left',
                }}>
                    <div style={{
                        fontSize: '1.2em',
                        alignSelf: 'center',
                        textAlign: 'left',
                    }}>
                        <p>
                            2FA Status: <b>{twofaEnabled ? 'Enabled ✓' : 'Disabled'}</b>
                        </p>
                        <p>
                            To disable 2FA or to change the authenticator
                            application, <a
                            style={{
                                fontSize: '1em'
                            }}
                            onClick={onDisableHandler}
                            href="">
                            click here
                        </a>
                        </p>
                    </div>
                </Card>
                : <Card style={{
                    display: secret ? 'block' : 'none',
                }}>
                    <div style={{
                        fontSize: '1.2em',
                    }}>
                        Scan the following QR code or manually input the secret
                        in <a
                            style={{
                                fontSize: '1em'
                            }}
                            target="_blank"
                            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                        >
                            Google Authenticator
                        </a> and then enter an OTP below to enable 2-Factor Authentication
                    </div>
                    <div style={{
                        textAlign: 'center',
                        padding: '6em 0',
                    }}>
                        <img src={secret.qrImageDataUrl}/>
                        <p style={{
                            fontSize: '1.2em',
                        }}>Secret: <b>{secret.secret}</b></p>
                    </div>
                    <OtpForm
                        otp={otp}
                        setOtp={setOtp}
                        onSubmitHandler={onSubmitHandler}
                        timerStartSeconds={() => false}
                        timerEndSeconds={() => false}
                        onTimerComplete={timeDelta => {
                        }}
                    />
                </Card>
        }
    </div>
}