import _ from "lodash";
import { axiosInstance } from "../Axios";
import LocalStorageWrapped from "../LocalStorageWrapped";
import { logout } from "../store/actions";
import { TokenUpdate } from "../store/actions/updateToken";
import { checkCookieExist, setCookie } from "./cookieHandler";

const isTokenExpired = (token) => (Date.now() >= JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString()).exp * 1000);

const getNewToken = () => {
    return (dispatch: any) => {
        axiosInstance('users/new-token', 'post').then(
            async (response) => {
                let res = _.get(response, "data");

                if (res.success) {
                    const _domainName = process.env.REACT_APP_DOMAIN;
                    setCookie(res.object.token, _domainName);

                    const authInfo = {
                        token: res.object.token,
                        loginType: "email",
                        success: res.success
                    };

                    LocalStorageWrapped.setItem("authInfo", authInfo);
                    dispatch(TokenUpdate(res.object.token));
                }

                else {
                    dispatch(logout());
                }

            }
        ).catch(
            err => {
                console.log(err);
                dispatch(logout());
            }
        )
    }
    
}


export const updateLocalStorageToken = () =>{
    return (dispatch: any) => {
        const cookieToken = checkCookieExist('_insiteToken');
        const authInfo = LocalStorageWrapped.getItem("authInfo");
        if(cookieToken && !isTokenExpired(cookieToken) && authInfo){
            authInfo.token = cookieToken;
            LocalStorageWrapped.setItem("authInfo", authInfo);
        }
        else {
            dispatch(logout());
        }
    }

}

export const updateToken = () => {
    return (dispatch: any) =>{
    const authInfo = LocalStorageWrapped.getItem("authInfo");
    if (authInfo) {
        const prevToken = authInfo.token;
        if (prevToken && !isTokenExpired(prevToken) ){
            dispatch(getNewToken());
        } 
        else{
            dispatch(logout());
        }
    }
}

}
