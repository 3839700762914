import React, {Component} from "react";
import Switch from "react-switch";
import {ReactSwitchProps, ReactSwitchState} from "../../../interfaces/switch";
import {Confirm} from "../../../SWAL";
import {connect} from "react-redux";
import {changePermission} from "../../../store/actions/permission";

class ReactSwitch extends Component<ReactSwitchProps, ReactSwitchState> {
  constructor(props) {
    super(props);
    this.state = {checked: this.props.checked, portalSlug: this.props.portalSlug, userId: this.props.userId};
  };
  handleChange = (checked) => {
    if(!checked) {
      Confirm("Do you really want to remove this permission?", "Yes, Remove!")
        .then(result=>{
          if(result.value){
            this.setState({checked});
            this.props.changePermission("remove-model-permission", this.state.portalSlug, this.state.userId, this.props.permissionName, this.props.model);
          }
        })
    }
    else{
      this.setState({checked});
      this.props.changePermission("give-model-permission", this.state.portalSlug, this.state.userId, this.props.permissionName, this.props.model);
    }
  };

  render() {
    return (
      <label>
        <Switch
          checked={this.state.checked}
          onChange={this.handleChange}
          onColor="#86d3ff"
          onHandleColor="#2693e6"
          handleDiameter={30}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          height={20}
          width={48}
          className="react-switch"
          id="material-switch"
        />
      </label>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {

  }
};

const mapDispatchToProps = dispatch => {
  return {
    changePermission: (action: string, portalSlug: string, userId: string, permissionName: string, model: string) =>
      dispatch(changePermission(action, portalSlug, userId, permissionName, model))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ReactSwitch);
