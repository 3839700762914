export const CREATE_PORTAL_SUCCESS = 'CREATE_PORTAL_SUCCESS';
export const CREATE_PORTAL_FAILURE = 'CREATE_PORTAL_FAILURE';
export const CREATE_PORTAL_START = 'CREATE_PORTAL_START';

export const UPDATE_PORTAL_START = "UPDATE_PORTAL_START";
export const UPDATE_PORTAL_SUCCESS = "UPDATE_PORTAL_SUCCESS";
export const UPDATE_PORTAL_FAIL = "UPDATE_PORTAL_FAIL";

export const GET_PORTAL_LIST_START = 'GET_PORTAL_LIST_START';
export const GET_PORTAL_LIST_SUCCESS = 'GET_PORTAL_LIST_SUCCESS';
export const GET_PORTAL_LIST_FAIL = 'GET_PORTAL_LIST_FAIL';
export const CLEAR_PORTAL_STATE = 'CLEAR_PORTAL_STATE';
export const GET_PORTAL_GROUP_LIST_START = 'GET_PORTAL_GROUP_LIST_START';
export const GET_PORTAL_GROUP_LIST_SUCCESS = 'GET_PORTAL_GROUP_LIST_SUCCESS';
export const GET_PORTAL_GROUP_LIST_FAIL = 'GET_PORTAL_GROUP_LIST_FAIL';
export const GET_ALL_USERS_LIST_START = 'GET_ALL_USERS_LIST_START';
export const GET_ALL_USERS_LIST_SUCCESS = 'GET_ALL_USERS_LIST_SUCCESS';
export const GET_ALL_USERS__LIST_FAIL = 'GET_ALL_USERS__LIST_FAIL';
export const GET_ALL_DATASETS_LIST_START = 'GET_ALL_DATASETS_LIST_START';
export const GET_ALL_DATASETS_LIST_SUCCESS = 'GET_ALL_DATASETS_LIST_SUCCESS';
export const GET_ALL_DATASETS_LIST_FAIL = 'GET_ALL_DATASETS_LIST_FAIL';
export const GET_ALL_PROJECTS_LIST_START = 'GET_ALL_PROJECTS_LIST_START';
export const GET_ALL_PROJECTS_LIST_SUCCESS = 'GET_ALL_PROJECTS_LIST_SUCCESS';
export const GET_ALL_PROJECTS_LIST_FAIL = 'GET_ALL_PROJECTS_LIST_FAIL';
export const DETECT_PORTAL_START = 'DETECT_PORTAL_START';
export const DETECT_PORTAL_SUCCESS = 'DETECT_PORTAL_SUCCESS';
export const DETECT_PORTAL_FAIL = 'DETECT_PORTAL_FAIL';

export const GET_PORTAL_ADMIN_LIST_SUCCESS = 'GET_PORTAL_ADMIN_LIST_SUCCESS';
export const GET_PORTAL_ADMIN_LIST_START = 'GET_PORTAL_ADMIN_LIST_START';
export const GET_PORTAL_ADMIN_LIST_FAIL = 'GET_PORTAL_ADMIN_LIST_FAIL';
export const CHANGE_SELECTED_PORTAL = "CHANGE_SELECTED_PORTAL";
export const CLEAR_SELECTED_PORTAL = 'CLEAR_SELECTED_PORTAL';
export const SET_SELECTED_PORTAL = "SET_SELECTED_PORTAL";

export const DEACTIVATE_PORTAL_START = 'DEACTIVATE_PORTAL_START';
export const DEACTIVATE_PORTAL_SUCCESS = 'DEACTIVATE_PORTAL_SUCCESS';
export const DEACTIVATE_PORTAL_FAIL = 'DEACTIVATE_PORTAL_FAIL';

export const ACTIVATE_PORTAL_START = 'ACTIVATE_PORTAL_START';
export const ACTIVATE_PORTAL_SUCCESS = 'ACTIVATE_PORTAL_SUCCESS';
export const ACTIVATE_PORTAL_FAIL = 'ACTIVATE_PORTAL_FAIL';


export const BACKUP_PORTAL_START = 'BACKUP_PORTAL_START';
export const BACKUP_PORTAL_SUCCESS = 'BACKUP_PORTAL_SUCCESS';
export const BACKUP_PORTAL_FAIL = 'BACKUP_PORTAL_FAIL';


export const GET_PORTAL_START = 'GET_PORTAL_START';
export const GET_PORTAL_SUCCESS = 'GET_PORTAL_SUCCESS';
export const GET_PORTAL_FAIL = 'GET_PORTAL_FAIL';
