import * as actionTypes from './actionTypes/projectActionTypes';
import {axiosInstance} from "../../Axios";
import * as _ from 'lodash';
import {MySwal, Toast} from "../../SWAL";
import { BackendRoutes } from "../../api/backendRoutes";
import LocalStorageWrapped from "../../LocalStorageWrapped";
import {CLEAR_PROJET_SELECTED} from "./actionTypes/projectActionTypes";

const create_project_start = () => {
  return {
    type: actionTypes.CREATE_PROJECT_START,
  };
};

const create_project_success = (success: boolean, project_id: string, slug: string, name: string, show: boolean, dataSets: []) => {
  return {
    type: actionTypes.CREATE_PROJECT_SUCCESS,
    success: success,
    slug: slug,
    name: name,
    show: show,
    dataSets: dataSets
  };
};

const create_project_failure = (errors: object) => {
  return {
    type: actionTypes.CREATE_PROJECT_FAILURE,
    errors: errors,
  }
};

export const create_project = (url: string, projectBody) => {
  const requestBody = { ...projectBody };
  let headers = { 'Content-Type': 'application/json' };
  return dispatch => {
    dispatch(create_project_start());
    axiosInstance(url, 'post', headers,
      JSON.stringify(requestBody))
      .then(response => {
        let res = _.get(response, "data");
        if (res.success) {
          const response = res.object;
          localStorage.setItem(`${response.slug}`, JSON.stringify(response));
          dispatch(create_project_success(res.success, response._id, response.slug, response.name, response.show, response.dataSets));
          MySwal.fire("Success", "Project was successfully created", "success");
        }
      }).catch(err => {
      dispatch(create_project_failure(err.response.data.errors));
      err?.response?.data?.message && MySwal.fire({title:"Error", titleText:err.response.data.message, icon:"error"});
    });
  }
};

const get_project_list_start = () => {
  return {
    type: actionTypes.GET_PROJECT_LIST_START,
  }
};

const get_project_list_success = (success: boolean, project_list: []) => {
  return {
    type: actionTypes.GET_PROJECT_LIST_SUCCESS,
    success: success,
    project_list: project_list,
  }
};

const get_project_list_fail = (success: boolean, errors: []) => {
  return {
    type: actionTypes.GET_PROJECT_LIST_FAIL,
    success: success,
    errors: errors,
  }
};

export const get_project_list = (portal_name: string) => {
  return dispatch => {
    dispatch(get_project_list_start());
    axiosInstance(`${portal_name}/project/`, "get", {},).then(response => {
      let res = _.get(response, "data");
      const project_list = res.result;
      const success = res.success;
      if (success) {
        dispatch(get_project_list_success(success, project_list));
      }
    }).catch(err => {
      const errors = (err && err.response && err.response.data) ? err.response.data.errors : 'something went wrong';
      dispatch(get_project_list_fail(false, errors));
    });
  }
};

export const clear_project_state = () => {
  LocalStorageWrapped.setItem("selectedProject", {});
  return {
    type: actionTypes.CLEAR_PROJECT_STATE,
  }
};

const changeProjectStateStart = () => {
  return {
    type: actionTypes.CHANGE_PROJECT_STATE_START,
  }
};

const changeProjectStateFail = () => {
  return {
    type: actionTypes.GET_PROJECT_LIST_FAIL,
  }
};

const changeProjectStateSuccess = () => {
  return {
    type: actionTypes.CHANGE_PROJECT_STATE_SUCCESS
  }
};

export const changeProjectState = (action: string, portalSlug: string, projectId: string, projectName: string) => {
  return dispatch => {
    dispatch(changeProjectStateStart());
    let _URL = `${portalSlug}/${BackendRoutes.setProjectStatus}`;
    const body = {
      projectId: projectId,
      projectStatus : (action === 'enable')
    };
    axiosInstance(_URL, 'post', {}, body)
      .then(_ => {
        dispatch(changeProjectStateSuccess());
        if (action === 'enable') {
          Toast.fire({
            icon: "success",
            titleText: projectName + " was successfully activated"
          });
        } else {
          MySwal.fire({
            title:"Deactivated!",
            titleText:projectName + " has been deactivated.",
            icon:"success"
          }
          )
        }
        dispatch(get_project_list(portalSlug));
      })
      .catch(err => {
        if (action === "delete") {
          MySwal.fire({
            title:"Error!",
            titleText:"Error occurred while deactivating " + projectName + "!",
            icon:"error"}
          );
        } else {
          MySwal.fire({
            title:"Error!",
            titleText:"Error occurred while activating " + projectName + "!",
            icon:"error"
          }
          );
        }
        dispatch(changeProjectStateFail());
      })
  }
};


export const deleteProject = (portalSlug: string, projectId: string, projectName: string) => {
    return dispatch => {
        dispatch(changeProjectStateStart());
        let _URL = `${portalSlug}/${BackendRoutes.deleteProject}`;
        const body = { projectId: projectId, deleteProject: true };
        axiosInstance(_URL, 'post', {}, body).then(_ => {
                dispatch(changeProjectStateSuccess());
                Toast.fire({
                    icon: "success",
                    titleText: projectName + " was successfully deleted"
                });
                dispatch(get_project_list(portalSlug));
            })
            .catch(err => {
                MySwal.fire( {title:"Error!",
                    titleText:"Error occurred while deleting" + projectName + "!",
                    icon:"error"}
                );
                dispatch(changeProjectStateFail());
            })
    }
};


const updateProjectStart = () => {
  return {
    type: actionTypes.UPDATE_PROJECT_START
  }
};

const updateProjectSuccess = () => {
  return {
    type: actionTypes.UPDATE_PROJECT_SUCCESS
  }
};

const updateProjectFail = () => {
  return {
    type: actionTypes.UPDATE_PROJECT_FAIL
  }
};

const getProjectSuccess = (project) => {
  return {
    type: actionTypes.GET_PROJECT_SUCCESS,
    project: project
  }
};


export const set_project_selected = (project) => {
  LocalStorageWrapped.setItem("selectedProject", project);
  return {
    type: actionTypes.SET_PROJET_SELECTED,
    project: project
  }
}

export const clear_selected_project_state = () => {
  LocalStorageWrapped.setItem("selectedProject", {});
  return {
    type: actionTypes.CLEAR_PROJET_SELECTED,
  }
};


export const getProject = (projectId: string, portalSlug: string) => {
  return dispatch => {
    dispatch({type: actionTypes.GET_PROJECT_START});
    axiosInstance(portalSlug + "/project/" + projectId, "get", {}, {})
      .then(res => {
        const response = _.get(res, "data");
        dispatch(getProjectSuccess(response.object));
      }).catch(err => {
        dispatch({type: actionTypes.GET_PROJECT_FAIL});
      })
  }
};

export const updateProject = (portalName: string, project: any) => {
  return dispatch => {
    dispatch(updateProjectStart());
    let url = portalName + "/project/" + project.projectId;
    let requestBody = {...project};
    axiosInstance(url, "put", {}, requestBody)
      .then(res => {
        dispatch(updateProjectSuccess());
        dispatch(get_project_list(portalName));
        MySwal.fire(`Updated!, ${project.name} has been successfully updated.`, "success")
      }).catch(err => {
        dispatch(updateProjectFail());
      });
  }
};
